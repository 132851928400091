// src/pages/Disclaimer.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './LegalPages.scss';

const Disclaimer = () => {
  return (
    <Container className="legal-container my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">Disclaimer</h1>

              <h2 className="mt-4">1. No Financial Advice</h2>
              <p>
                The TBLUNT tokens and the services provided on this platform are for utility and informational purposes only. We do not offer financial, legal, or investment advice. Any decisions made based on the information provided are your sole responsibility.
              </p>

              <h2 className="mt-4">2. Risks Involved</h2>
              <p>
                Cryptocurrency investments are subject to market risks, including high volatility and potential loss of principal. You should be aware of these risks and only invest what you can afford to lose. The value of TBLUNT tokens may fluctuate significantly and is not guaranteed.
              </p>

              <h2 className="mt-4">3. No Guarantees</h2>
              <p>
                We do not guarantee the accuracy, completeness, or reliability of any information provided on this platform. TBLUNT tokens are provided "as is," without warranties of any kind, either express or implied.
              </p>

              <h2 className="mt-4">4. Regulatory Compliance</h2>
              <p>
                The use of TBLUNT tokens and the services offered on this platform may be subject to regulatory requirements in your jurisdiction. It is your responsibility to ensure compliance with all applicable laws and regulations.
              </p>

              <h2 className="mt-4">5. Limitation of Liability</h2>
              <p>
                To the fullest extent permitted by law, we are not liable for any indirect, incidental, consequential, or punitive damages arising out of your use of the platform or inability to use the services. This includes, but is not limited to, loss of profits, data, or other intangible losses.
              </p>

              <h2 className="mt-4">6. No Warranties</h2>
              <p>
                We provide the platform and its services "as is" and "as available," without any warranties or conditions of any kind. We do not warrant that the platform will be uninterrupted, secure, or free from errors.
              </p>

              <h2 className="mt-4">7. Indemnification</h2>
              <p>
                You agree to indemnify, defend, and hold harmless us, our affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including legal fees and costs, arising out of or in any way connected with your access to or use of the platform, or your violation of these terms.
              </p>

              <h2 className="mt-4">8. Third-Party Services</h2>
              <p>
                Our platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
              </p>

              <h2 className="mt-4">9. Changes to This Disclaimer</h2>
              <p>
                We may update this Disclaimer from time to time. Any changes will be posted on this page with a revised effective date. Your continued use of the platform after such changes constitutes your acceptance of the updated Disclaimer.
              </p>

              <h2 className="mt-4">10. Contact Us</h2>
              <p>
                If you have any questions about this Disclaimer, please contact us at:
              </p>
              <ul>
                <li><strong>Email:</strong> <a href="mailto:contact@turtleblunt.com" className="text-info">contact@turtleblunt.com</a></li>
                <li><strong>Contact Page:</strong> <a href="https://turtleblunt.com/contact" target="_blank" rel="noopener noreferrer" className="text-info">https://turtleblunt.com/contact</a></li>
              </ul>

              <p className="mt-4">
                By using our platform, you acknowledge that you have read, understood, and agree to this Disclaimer.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Disclaimer;
