import React, { useState, useEffect, useMemo } from 'react';
import { Card, Typography, Spin, Alert } from 'antd';
import { TransactionOutlined } from '@ant-design/icons';
// Use only your already working ethers imports:
import { Contract, formatUnits, JsonRpcProvider } from "ethers";
import './TokenCreationFeeTopPanelTblunt.scss';

const { Title, Text } = Typography;

const TokenCreationFeeTopPanelTblunt = ({
  tokenCreationManagerAddress,
  tokenCreationManagerAbi,
  creationFeeDecimals,
  tbluntTokenAddress,
  tbluntTokenAbi,
  ethPriceUSD,
}) => {
  const [creationFee, setCreationFee] = useState(null);
  const [salePriceETH, setSalePriceETH] = useState(null); // TBLUNT sale price in ETH
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Debug: log the key addresses that must be defined
  console.log("RPC URL:", process.env.REACT_APP_RPC_URL);
  console.log("tokenCreationManagerAddress:", tokenCreationManagerAddress);
  console.log("tbluntTokenAddress:", tbluntTokenAddress);

  // Create a read-only provider using your RPC URL (must be defined in .env)
  const provider = useMemo(() => {
    const rpcUrl = process.env.REACT_APP_RPC_URL;
    if (!rpcUrl) {
      setError("RPC URL is not defined in environment variables");
      return null;
    }
    return new JsonRpcProvider(rpcUrl);
  }, []);

  // Create the token creation manager contract instance (if provider and address available)
  const tokenCreationManagerContract = useMemo(() => {
    if (!provider || !tokenCreationManagerAddress) return null;
    try {
      const instance = new Contract(tokenCreationManagerAddress, tokenCreationManagerAbi, provider);
      console.log("TokenCreationManager contract instance created at:", instance.address);
      return instance;
    } catch (err) {
      console.error("Error initializing token creation manager contract:", err);
      setError("Error initializing token creation manager contract.");
      return null;
    }
  }, [provider, tokenCreationManagerAddress, tokenCreationManagerAbi]);

  // Create the TBLUNT token contract instance (if provider and address available)
  const tbluntContract = useMemo(() => {
    if (!provider || !tbluntTokenAddress) return null;
    try {
      const instance = new Contract(tbluntTokenAddress, tbluntTokenAbi, provider);
      console.log("TBLUNT contract instance created at:", instance.address);
      return instance;
    } catch (err) {
      console.error("Error initializing TBLUNT token contract:", err);
      setError("Error initializing TBLUNT token contract.");
      return null;
    }
  }, [provider, tbluntTokenAddress, tbluntTokenAbi]);

  // Fetch the creation fee and the TBLUNT sale price from the contracts
  useEffect(() => {
    const fetchData = async () => {
      // If the contracts are not ready, do not continue
      if (!tokenCreationManagerContract || !tbluntContract) {
        console.warn("Contracts not ready:", tokenCreationManagerContract, tbluntContract);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        // Get the creation fee (as a BigNumber) and format it using the provided decimals
        const feeBN = await tokenCreationManagerContract.creationFee();
        const formattedFee = formatUnits(feeBN, creationFeeDecimals);
        console.log("Fetched Creation Fee:", formattedFee);
        setCreationFee(formattedFee);

        // Get the TBLUNT sale price (assumed to be in wei) and convert to a number in ETH
        const priceBN = await tbluntContract.tokenPrice();
        const salePrice = parseFloat(formatUnits(priceBN, "ether"));
        console.log("Fetched TBLUNT Sale Price (ETH):", salePrice);
        setSalePriceETH(salePrice);
      } catch (err) {
        console.error("Error fetching fee or sale price:", err);
        setError("Error fetching fee or sale price.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tokenCreationManagerContract, tbluntContract, creationFeeDecimals]);

  // Calculate the fee in USD using the fetched fee value, sale price, and the ETH/USD rate.
  const creationFeeUSD = useMemo(() => {
    if (salePriceETH !== null && ethPriceUSD && creationFee !== null) {
      return (Number(creationFee) * salePriceETH * ethPriceUSD).toFixed(2);
    }
    return "0.00";
  }, [salePriceETH, ethPriceUSD, creationFee]);

  return (
    <Card className="creation-fee-top-panel-tblunt" bordered={false}>
      <div className="fee-details">
        <Title level={5} className="fee-title">
          <TransactionOutlined /> Creation Fee
        </Title>
        {loading ? (
          <Spin />
        ) : error ? (
          <Alert message={error} type="error" showIcon className="fee-error-alert" />
        ) : (
          <div className="fee-values">
            {/* Use an explicit check so that a fee of "0" displays correctly */}
            <Text strong>{creationFee !== null ? creationFee : "N/A"} TBLUNT</Text>
            <Text type="secondary">(${creationFeeUSD} USD)</Text>
          </div>
        )}
      </div>
    </Card>
  );
};

export default TokenCreationFeeTopPanelTblunt;
