// src/components/SupportWidget.js

import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, ListGroup, Spinner, Alert, Badge } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaTimes, FaPaperPlane } from 'react-icons/fa';
import { SocketContext } from '../contexts/SocketContext';
import axios from 'axios';
import { Web3Context } from '../Web3Context'; // Use Web3Context
import './SupportWidget.scss'; // Import custom SCSS

const SupportWidget = () => {
  const socket = useContext(SocketContext);
  const { address, connectWallet } = useContext(Web3Context); // Obtain wallet address
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [liveChat, setLiveChat] = useState(true);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState(null);
  const [unread, setUnread] = useState(false);

  // State to track Socket.IO connection status
  const [isConnected, setIsConnected] = useState(false);

  // State to prevent multiple offline messages
  const [hasAppendedOffline, setHasAppendedOffline] = useState(false);

  useEffect(() => {
    // Fetch initial live chat status from public endpoint
    axios.get('https://testnet.turtleblunt.com/api/admin/live-chat-status') // Update URL as needed
      .then(response => {
        console.log('Live Chat Status:', response.data.liveChat);
        setLiveChat(response.data.liveChat);
      })
      .catch(err => {
        console.error('Error fetching live chat status:', err);
      });

    // Setup Socket.IO event listeners
    if (socket) {
      // Handle successful connection
      socket.on('connect', () => {
        console.log('Connected to support Socket.IO server');
        setIsConnected(true);
        if (address) {
          socket.emit('joinRoom', address.toLowerCase());
        }
      });

      // Handle connection errors
      socket.on('connect_error', (err) => {
        console.error('Socket.IO connection error:', err);
        setIsConnected(false);
      });

      // Handle disconnection
      socket.on('disconnect', () => {
        console.log('Disconnected from support Socket.IO server');
        setIsConnected(false);
      });

      // Listen for incoming messages
      socket.on('receiveMessage', (data) => {
        if (data.walletAddress.toLowerCase() === address.toLowerCase()) {
          setMessages(prevMessages => [...prevMessages, {
            sender: data.sender,
            message: data.message,
            timestamp: new Date(data.timestamp),
          }]);

          // Show notification
          showUserNotification('New message from Support');

          // Set unread indicator if the chat window is not open
          if (!isOpen) {
            setUnread(true);
          }
        }
      });
    }

    // Cleanup on unmount
    return () => {
      if (socket) {
        socket.off('connect');
        socket.off('connect_error');
        socket.off('disconnect');
        socket.off('receiveMessage');
      }
    };
  }, [socket, address, isOpen]);

  useEffect(() => {
    if (address && socket) {
      socket.emit('joinRoom', address.toLowerCase());
      fetchMessages();
    }
    // eslint-disable-next-line
  }, [address, socket]);

  useEffect(() => {
    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      Notification.requestPermission();
    }
  }, []);

  // Function to append offline message to chat
  const appendOfflineMessage = () => {
    const offlineMsg = 'Your message was received. Please expect a response within 24 hours.';
    setMessages(prevMessages => [
      ...prevMessages,
      {
        sender: 'system',
        message: offlineMsg,
        timestamp: new Date(),
      },
    ]);
    showUserNotification('Support is currently offline. You will receive a response within 24 hours.');
    setHasAppendedOffline(true); // Prevent multiple offline messages
  };

  const toggleChat = () => {
    if (!address) {
      // Prompt user to connect wallet
      connectWallet();
    } else {
      setIsOpen(!isOpen);
      if (!isOpen) {
        fetchMessages();
        setUnread(false); // Reset unread indicator when opening chat
        setHasAppendedOffline(false); // Reset offline message flag when opening chat
      }
    }
  };

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://testnet.turtleblunt.com/api/support/messages?walletAddress=${address.toLowerCase()}`); // Update URL as needed
      setMessages(response.data.messages.map(msg => ({
        sender: msg.sender,
        message: msg.message,
        timestamp: new Date(msg.timestamp),
      })));
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError('Failed to load messages.');
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    setLoading(true);
    setError('');
    try {
      if (isConnected) {
        // Send message via Socket.IO
        socket.emit('sendMessage', {
          walletAddress: address.toLowerCase(),
          message: newMessage.trim(),
        });

        // Optimistically add the message to the chat
        setMessages(prevMessages => [...prevMessages, {
          sender: 'user',
          message: newMessage.trim(),
          timestamp: new Date(),
        }]);
        setNewMessage('');

        if (!liveChat && !hasAppendedOffline) {
          // If live chat is OFF, append offline message
          appendOfflineMessage();
        }
      } else {
        // Cannot send message as backend is offline
        setError('Cannot send message: Support is offline.');
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message.');
    } finally {
      setLoading(false);
    }
  };

  const showUserNotification = (msg) => {
    if (Notification.permission === 'granted') {
      new Notification(msg);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification(msg);
        }
      });
    }
    // In-app notification
    setNotification(msg);
    setTimeout(() => setNotification(null), 5000);
  };

  return (
    <>
      {/* In-App Notification */}
      {notification && (
        <div className="support-notification">
          {notification}
        </div>
      )}

      {/* Chat Icon Button */}
      <div className="support-widget">
        <Button
          variant={isConnected ? (liveChat ? "success" : "warning") : "danger"}
          onClick={toggleChat}
          className="chat-button"
          aria-label="Customer Support"
        >
          {isConnected ? (liveChat ? <FaCheckCircle size={24} /> : <FaTimesCircle size={24} />) : <FaTimesCircle size={24} />}
          {unread && (
            <Badge bg="danger" pill className="unread-badge">
              1
            </Badge>
          )}
        </Button>

        {/* Chat Window */}
        {isOpen && (
          <div className="chat-window">
            <div className="chat-header">
              <span>Customer Support</span>
              <Button variant="link" onClick={toggleChat}>
                <FaTimes size={18} />
              </Button>
            </div>
            <div className="chat-body">
              {error && <Alert variant="danger">{error}</Alert>}
              {loading ? (
                <div className="text-center my-3">
                  <Spinner animation="border" />
                </div>
              ) : (
                <ListGroup variant="flush" className="message-list">
                  {messages.map((msg, idx) => (
                    <ListGroup.Item key={idx} className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                      <div className={`message ${msg.sender === 'user' ? 'user-message' : msg.sender === 'admin' ? 'admin-message' : 'system-message'}`}>
                        <strong>{msg.sender === 'user' ? 'You' : msg.sender === 'admin' ? 'Admin' : 'System'}:</strong> {msg.message}
                        <div className="timestamp">
                          {msg.timestamp.toLocaleString()}
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {!isConnected && (
                <Alert variant="danger" className="mt-3">
                  Cannot connect to support server. Please check your connection.
                </Alert>
              )}
              {isConnected && !liveChat && (
                <Alert variant="warning" className="mt-3">
                  Live chat is currently offline. Your messages will be delivered once it's back online.
                </Alert>
              )}
            </div>
            <div className="chat-footer">
              <Form.Control
                type="text"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                disabled={!isConnected || loading}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
                className="message-input"
              />
              <Button
                variant="primary"
                onClick={sendMessage}
                disabled={!isConnected || !newMessage.trim() || loading}
                className="send-button"
              >
                <FaPaperPlane />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SupportWidget;
