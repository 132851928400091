// src/App.js

import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import '@fortawesome/fontawesome-free/css/all.min.css';
import NetworkBar from './components/NetworkBar';
import BaseNetworkInfoBar from './components/BaseNetworkInfoBar';
import BrandInfoBar from './components/BrandInfoBar';
import CreateToken from './components/CreateToken';
import CreateTokenFactory from './components/CreateTokenFactory';
import TokenSalePage from './components/TokenSalePage';
import ListingPage from './components/ListingPage';
import InteractorPage from './components/InteractorPage';
import HomePage from './components/HomePage';
import SupportWidget from './components/SupportWidget';
import { SocketProvider } from './contexts/SocketContext';
import TokenSocketContext, { TokenSocketProvider } from './contexts/TokenSocketContext'; // Import the new context
import FAQ from './pages/FAQ';
import Documentation from './pages/Documentation';
import WhitePaper from './pages/WhitePaper';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactPage from './pages/ContactPage';
import TermsOfUse from './pages/TermsOfUse';
import Disclaimer from './pages/Disclaimer';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import ErrorBoundary from './components/ErrorBoundary';
import { useWeb3 } from './Web3Context';
import './App.scss';
import UserTokensPanel from './components/UserTokensPanel'; // Import the new component

const GA_MEASUREMENT_ID = 'G-L2QMB1Z6C9';

const initializeGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID, {
    gaOptions: {
      anonymize_ip: false, // Anonymize IP for privacy
    },
  });
  ReactGA.send("pageview");
};

// ------------------------------------------
// ADD NOTIFICATION BAR COMPONENT HERE
// ------------------------------------------
const NotificationBar = () => {
  return (
    <div
      style={{
        background: '#ff9800',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
      }}
    >
      <strong>We strongly recommend creating your token on the test network first.</strong>
      <button
        onClick={() => window.open('https://testnet.turtleblunt.com', '_blank')}
        style={{
          background: '#ffffff',
          color: '#ff9800',
          borderRadius: '5px',
          padding: '10px 20px',
          marginLeft: '10px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Go to Testnet
      </button>
    </div>
  );
};

const App = () => {
  const { loading } = useWeb3();
  const location = useLocation();

  // Determine if we are on the CreateToken or CreateTokenFactory pages
  const isCreateTokenPage = location.pathname === '/CreateToken';
  const isCreateTokenFactoryPage = location.pathname === '/CreateTokenFactory';

  // Check for "widget" mode
  const searchParams = new URLSearchParams(location.search);
  const isWidget = searchParams.get('widget') === 'true';

  const [isGAInitialized, setIsGAInitialized] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('userCookieConsent');
    if (consent === 'true') {
      initializeGA();
      setIsGAInitialized(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('userCookieConsent', 'true');
    initializeGA();
    setIsGAInitialized(true);
  };

  const handleDeclineCookies = () => {
    localStorage.setItem('userCookieConsent', 'false');
    console.log('User declined cookies');
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <div>Connecting to wallet...</div>
      </div>
    );
  }

  return (
    <SocketProvider>
      <TokenSocketProvider> {/* Wrap with TokenSocketProvider */}
        <ErrorBoundary>
          {/* Conditionally render NetworkBar */}
          {!isWidget && <NetworkBar />}

          {/* Conditionally render BRAND and BASE NETWORK banners on HOME page ("/") */}
          {!isWidget && location.pathname === '/' && (
            <>
              <BrandInfoBar />
              <BaseNetworkInfoBar />
            </>
          )}

          {/* ------------------------------------------
              CONDTIONALLY RENDER NOTIFICATION BAR
              on /CreateToken and /CreateTokenFactory
             ------------------------------------------ */}
          {!isWidget && (isCreateTokenPage || isCreateTokenFactoryPage) && (
            <NotificationBar />
          )}

          {/* Main Content */}
          <div id="main-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/CreateToken" element={<CreateToken />} />
              <Route path="/CreateTokenFactory" element={<CreateTokenFactory />} />
              <Route path="/token-sale/:tokenAddress" element={<TokenSalePage />} />
              <Route path="/interactor" element={<InteractorPage />} />
              <Route path="/interactor/:tokenAddress" element={<InteractorPage />} />
              <Route path="/listings" element={<ListingPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/Documentation" element={<Documentation />} />
              <Route path="/whitepaper" element={<WhitePaper />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>
            <SupportWidget />
<UserTokensPanel />
          </div>
          {/* Conditionally render Footer */}
          {!isWidget && <Footer />}

          {/* Conditionally render ToastContainer */}
          {!isWidget && (
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          )}

          {/* Default Cookie Consent Banner */}
          {!isGAInitialized && (
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              enableDeclineButton
              onAccept={handleAcceptCookies}
              onDecline={handleDeclineCookies}
              cookieName="userCookieConsent"
              style={{
                background: "#4CAF50", 
                color: "white",
                fontSize: "16px",
                borderRadius: "0px",
                padding: "20px",
                position: "fixed",
                bottom: "0",
                left: "0",
                width: "100%",
                zIndex: "9999",
              }}
              buttonStyle={{
                background: "#f1f1f1",
                color: "#4CAF50",
                fontSize: "14px",
                borderRadius: "5px",
                padding: "10px 20px",
                marginLeft: "10px",
              }}
              declineButtonStyle={{
                background: "#f44336",
                color: "white",
                fontSize: "14px",
                borderRadius: "5px",
                padding: "10px 20px",
                marginRight: "10px",
              }}
            >
              We use cookies to enhance your experience. By clicking "Accept", you agree to our use of cookies. Read our{" "}
              <a
                href="/privacy-policy"
                style={{ color: "#FFD700", textDecoration: "underline" }}
              >
                Privacy Policy
              </a>{" "}
              for more information.
            </CookieConsent>
          )}
        </ErrorBoundary>
      </TokenSocketProvider>
    </SocketProvider>
  );
};

export default App;
