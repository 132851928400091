// src/Web3Context.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import {
  useAppKitAccount,
  useAppKitProvider,
  useAppKitNetwork,
  useAppKit,
  useAppKitTheme,
  useDisconnect,
  useAppKitEvents,
  useAppKitState,
  useWalletInfo,
} from '@reown/appkit/react';
import { toast } from 'react-toastify';

export const Web3Context = createContext();

const Web3Provider = ({ children }) => {
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');
  const { caipNetwork } = useAppKitNetwork();
  const { open } = useAppKit();
  const { themeMode, setThemeMode, themeVariables, setThemeVariables } = useAppKitTheme();
  const { disconnect } = useDisconnect();
  const events = useAppKitEvents();
  const { open: isModalOpen, selectedNetworkId } = useAppKitState();
  const { walletInfo } = useWalletInfo();

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [numericChainId, setNumericChainId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initialize lastCreatedToken from localStorage (if present)
  const [lastCreatedToken, setLastCreatedToken] = useState(() => {
    const storedToken = localStorage.getItem('lastCreatedToken');
    return storedToken ? JSON.parse(storedToken) : null;
  });

  const updateLastCreatedToken = (tokenData) => {
    setLastCreatedToken(tokenData);
    localStorage.setItem('lastCreatedToken', JSON.stringify(tokenData));
  };

  const clearLastCreatedToken = () => {
    setLastCreatedToken(null);
    localStorage.removeItem('lastCreatedToken');
  };

  const connectWallet = async () => {
    try {
      setLoading(true);
      open(); // Open Reown AppKit modal
      localStorage.setItem('isWalletConnected', 'true');
    } catch (error) {
      console.error('Error connecting wallet:', error);
      toast.error(`Error connecting wallet: ${error.message}`, {
        toastId: 'wallet-connect-error',
      });
      localStorage.setItem('isWalletConnected', 'false');
    } finally {
      setLoading(false);
    }
  };

  const disconnectWallet = async () => {
    try {
      await disconnect();
      toast.info('Wallet disconnected.', {
        toastId: 'wallet-disconnect-info',
      });
      setProvider(null);
      setSigner(null);
      setNumericChainId(null);
      clearLastCreatedToken();
      localStorage.setItem('isWalletConnected', 'false');
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
      toast.error(`Error disconnecting wallet: ${error.message}`, {
        toastId: 'wallet-disconnect-error',
      });
    }
  };

  useEffect(() => {
    const initializeProvider = async () => {
      if (isConnected && walletProvider) {
        console.log('Initializing provider with walletProvider:', walletProvider);
        try {
          const ethersProvider = new ethers.BrowserProvider(walletProvider);
          setProvider(ethersProvider);

          const signerInstance = await ethersProvider.getSigner();
          setSigner(signerInstance);

          const networkData = await ethersProvider.getNetwork();
          const chainIdNumber = Number(networkData.chainId);
          setNumericChainId(chainIdNumber);
        } catch (error) {
          console.error('Error initializing provider:', error);
          toast.error(`Error initializing provider: ${error.message}`, {
            toastId: 'provider-init-error',
          });
          setProvider(null);
          setSigner(null);
          setNumericChainId(null);
        }
      } else {
        setProvider(null);
        setSigner(null);
        setNumericChainId(null);
      }
      setLoading(false);
    };

    initializeProvider();
  }, [isConnected, walletProvider]);

  useEffect(() => {
    if (events && typeof events.on === 'function' && typeof events.off === 'function') {
      const handleModalOpen = () => console.log('Reown AppKit modal opened.');
      const handleModalClose = () => console.log('Reown AppKit modal closed.');

      events.on('open', handleModalOpen);
      events.on('close', handleModalClose);

      return () => {
        events.off('open', handleModalOpen);
        events.off('close', handleModalClose);
      };
    } else {
      console.error('Reown AppKit Events not available or incorrect.', { events });
    }
  }, [events]);

  useEffect(() => {
    console.log(`Modal is ${isModalOpen ? 'open' : 'closed'}.`);
    console.log(`Selected Network ID: ${selectedNetworkId}`);
  }, [isModalOpen, selectedNetworkId]);

  useEffect(() => {
    let timeoutId;
    const autoReconnect = async () => {
      const wasConnected = localStorage.getItem('isWalletConnected') === 'true';
      if (wasConnected && !isConnected) {
        timeoutId = setTimeout(async () => {
          if (!isConnected) {
            console.log('Attempting auto-reconnect after delay...');
            await connectWallet();
          }
        }, 1000);
      }
    };

    autoReconnect();
    return () => clearTimeout(timeoutId);
  }, [isConnected]);

  return (
    <Web3Context.Provider
      value={{
        provider,
        signer,
        address,
        numericChainId,
        connectWallet,
        disconnectWallet,
        connected: isConnected,
        loading,
        themeMode,
        setThemeMode,
        themeVariables,
        setThemeVariables,
        walletInfo,
        caipNetwork,
        caipNetworkId: caipNetwork?.id,
        lastCreatedToken,
        updateLastCreatedToken,
        clearLastCreatedToken,
        walletProvider, // Expose walletProvider if needed
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3 = () => useContext(Web3Context);

export default Web3Provider;
