// src/components/TokenTypeFeatures.js

import React from "react";
import PropTypes from "prop-types"; // For prop type validation
import { Card, List, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./TokenTypeFeatures.scss"; // Ensure this path is correct based on your project structure

const { Title, Paragraph } = Typography;

/**
 * Updated base features with additional details.
 * (Exact from the "perfect" version)
 */
const BASE_FEATURES = [
  "ERC20 Standard",
  "Permit (EIP-2612)",
  "Ownable",
  "Reentrancy Guard",
  "Burnable",
  "Set Token Price (Configured in Interactor Page for Presale)",
  "Metadata URL (Editable in Interactor Page)",
  "Free AI-Generated Images",
  "Full Contract Ownership",
  "No Post-Creation Contracts",
  "Sales Page Integration Widget",
  "Customer Support",
];

/**
 * Feature details for better clarity.
 * Notice we have separate feature texts for
 * "Mintable (No cap)" vs. "Mintable (Capped)" etc.
 * (Exact from the "perfect" version)
 */
const FEATURE_DETAILS = {
  "Set Token Price (Configured in Interactor Page for Presale)": (
    <span>
      Set Token Price <em>(Configured in Interactor Page for Presale)</em>
    </span>
  ),
  "Metadata URL (Editable in Interactor Page)": (
    <span>
      Metadata URL <em>(Editable in Interactor Page)</em>
    </span>
  ),
  "Maximum Token Supply Enforcement": (
    <span>
      Maximum Token Supply Enforcement{" "}
      <em>(Cap set post token creation in Interactor Page, can only be set once)</em>
    </span>
  ),
  Pausable: (
    <span>
      Pausable <em>(Manual control via Interactor Page)</em>
    </span>
  ),
  "Mintable (No cap; owner can always mint more tokens)": (
    <span>
      Mintable <em>(No cap; owner can always mint more tokens)</em>
    </span>
  ),
  "Mintable (Owner can mint until cap is reached)": (
    <span>
      Mintable <em>(Owner can mint until the cap is reached)</em>
    </span>
  ),
};

/**
 * Keywords to identify core features.
 * (Exact from the "perfect" version)
 */
const coreFeatureKeywords = [
  "ERC20 Standard",
  "Permit (EIP-2612)",
  "Ownable",
  "Reentrancy Guard",
  "Burnable",
  "Set Token Price (Configured in Interactor Page for Presale)",
  "Metadata URL (Editable in Interactor Page)",
  "Free AI-Generated Images",
  "Full Contract Ownership",
  "No Post-Creation Contracts",
  "Sales Page Integration Widget",
  "Customer Support",
];

/**
 * For the Factory page, we have these 6 tokenType keys:
 *   StandardERC20
 *   MintableERC20
 *   PausableERC20
 *   MintablePausableERC20
 *   CappedMintableERC20
 *   CappedMintablePausableERC20
 *
 * (Exact text from the "perfect" version, adapted to your 6 keys.)
 */
const tokenFeaturesMap = {
  StandardERC20: {
    description:
      "Basic ERC20 token with standard functionalities. The initial supply acts as the cap since there is no minting function.",
    features: [
      ...BASE_FEATURES,
      // Not mintable, so no "Mintable" line
    ],
  },
  MintableERC20: {
    description:
      "ERC20 token that allows unlimited minting. There is no cap, so the owner can always mint more tokens.",
    features: [
      ...BASE_FEATURES,
      "Mintable (No cap; owner can always mint more tokens)",
    ],
  },
  PausableERC20: {
    description:
      "ERC20 token that can be manually paused and unpaused via the Interactor page. No minting function, so the initial supply is the cap by default.",
    features: [
      ...BASE_FEATURES,
      "Pausable",
    ],
  },
  MintablePausableERC20: {
    description:
      "ERC20 token that can mint new tokens (no cap) and be manually paused/unpaused. The owner can always mint more tokens.",
    features: [
      ...BASE_FEATURES,
      "Mintable (No cap; owner can always mint more tokens)",
      "Pausable",
    ],
  },
  CappedMintableERC20: {
    description:
      "ERC20 token with a supply cap. The owner can mint tokens until the cap is reached. The cap is set once after token creation in the Interactor page.",
    features: [
      ...BASE_FEATURES,
      "Maximum Token Supply Enforcement",
      "Mintable (Owner can mint until cap is reached)",
    ],
  },
  CappedMintablePausableERC20: {
    description:
      "ERC20 token with a supply cap, allowing minting until the cap is reached, and the ability to pause/unpause transfers.",
    features: [
      ...BASE_FEATURES,
      "Maximum Token Supply Enforcement",
      "Mintable (Owner can mint until cap is reached)",
      "Pausable",
    ],
  },
};

/**
 * 
 * @param {Object} props
 * @param {string} props.tokenType - "StandardERC20", "MintableERC20", etc.
 */
const TokenTypeFeatures = ({ tokenType }) => {
  const currentType = tokenFeaturesMap[tokenType] || {};
  const { description = "", features = [] } = currentType;

  // Separate "core" from "advanced" features
  const coreFeatures = features.filter((f) => coreFeatureKeywords.includes(f));
  const advancedFeatures = features.filter((f) => !coreFeatureKeywords.includes(f));

  return (
    <Card bordered={false} className="token-type-features">
      {/* Show description */}
      {description && (
        <Paragraph className="token-description">{description}</Paragraph>
      )}

      {/* Core Features */}
      {coreFeatures.length > 0 && (
        <div className="feature-category">
          <Title level={5} className="category-title core-title">
            Core Features
          </Title>
          <List
            dataSource={coreFeatures}
            renderItem={(feature) => (
              <List.Item className="feature-item">
                <span className="feature-text">
                  {FEATURE_DETAILS[feature] || feature}
                </span>
              </List.Item>
            )}
            size="small"
            bordered={false}
            className="features-list core-features"
          />
        </div>
      )}

      {/* Advanced / Additional Features */}
      {advancedFeatures.length > 0 && (
        <div className="feature-category">
          <Title level={5} className="category-title advanced-title">
            Advanced Features
          </Title>
          <List
            dataSource={advancedFeatures}
            renderItem={(feature) => (
              <List.Item className="feature-item">
                <CheckOutlined className="feature-checkmark" />
                <span className="feature-text">
                  {FEATURE_DETAILS[feature] || feature}
                </span>
              </List.Item>
            )}
            size="small"
            bordered={false}
            className="features-list advanced-features"
          />
        </div>
      )}
    </Card>
  );
};

TokenTypeFeatures.propTypes = {
  tokenType: PropTypes.oneOf([
    "StandardERC20",
    "MintableERC20",
    "PausableERC20",
    "MintablePausableERC20",
    "CappedMintableERC20",
    "CappedMintablePausableERC20",
  ]).isRequired,
};

export default TokenTypeFeatures;
