// src/pages/PrivacyPolicy.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './LegalPages.scss'; // Shared styling for legal pages

const PrivacyPolicy = () => {
  return (
    <Container className="legal-container my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">Privacy Policy</h1>
              <p><strong>Effective Date:</strong> December 23rd, 2024</p>
              
              <h2 className="mt-5">1. Introduction</h2>
              <p>
                Welcome to our decentralized application (DApp). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
              </p>
              <p>
                By accessing or using our DApp, you agree to the terms of this Privacy Policy. If you do not agree with the terms, please do not use our services.
              </p>

              <h2 className="mt-5">2. Information We Collect</h2>
              <p>
                <strong>Personal Data:</strong> We do not intentionally collect, process, or store personal data such as names, email addresses, or other identifying information unless you voluntarily provide it to us through forms or direct communication.
              </p>
              <p>
                <strong>Usage Data:</strong> We may collect non-personal information about your interactions with our DApp. This includes data such as your IP address, browser type, operating system, referring URLs, pages viewed, and the dates/times of your visits.
              </p>
              <p>
                <strong>Blockchain Data:</strong> As a DApp, certain transactions and interactions with the blockchain (e.g., wallet addresses, transaction hashes) are publicly accessible and may be logged for operational purposes.
              </p>

              <h2 className="mt-5">3. How We Use Your Information</h2>
              <p>
                We use the collected information for various purposes, including:
              </p>
              <ul>
                <li>To provide and maintain our services</li>
                <li>To improve and personalize your experience</li>
                <li>To monitor and analyze usage and trends</li>
                <li>To manage user accounts and provide customer support</li>
                <li>To comply with legal obligations</li>
              </ul>

              <h2 className="mt-5">4. Google Analytics</h2>
              <p>
                We use Google Analytics, a web analytics service provided by Google LLC ("Google"). Google Analytics uses "cookies," which are text files placed on your device to help analyze how you use our DApp.
              </p>
              <p>
                The information generated by the cookie about your use of the DApp (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the DApp, compiling reports on DApp activities, and providing other services related to DApp activities and internet usage.
              </p>
              <p>
                You can opt out of the use of cookies by selecting the appropriate settings on your browser; however, please note that some parts of our DApp may not function properly if you disable cookies.
              </p>
              <p>
                For more information on how Google uses your data, please visit the <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a>.
              </p>

              <h2 className="mt-5">5. Cookies and Tracking Technologies</h2>
              <p>
                In addition to Google Analytics, we may use other cookies or similar tracking technologies to enhance your experience, analyze usage patterns, and improve our services. These may include:
              </p>
              <ul>
                <li>Session cookies to maintain your login state</li>
                <li>Preference cookies to remember your settings</li>
                <li>Analytics cookies to collect anonymous usage data</li>
              </ul>
              <p>
                You can manage your cookie preferences through your browser settings. Please note that disabling certain cookies may affect the functionality of our DApp.
              </p>

              <h2 className="mt-5">6. Data Sharing and Disclosure</h2>
              <p>
                We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
              </p>
              <ul>
                <li><strong>With Your Consent:</strong> We may share your information with third parties when you have given us explicit consent to do so.</li>
                <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our DApp, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
              </ul>
              <p>
                We do not transfer your personal information to third parties for their marketing purposes without your explicit consent.
              </p>

              <h2 className="mt-5">7. Data Retention</h2>
              <p>
                We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
              </p>
              <p>
                Usage data and blockchain data may be retained for extended periods to ensure the security and integrity of our services and for analytical purposes.
              </p>

              <h2 className="mt-5">8. Data Security</h2>
              <p>
                We implement appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
              </p>

              <h2 className="mt-5">9. International Data Transfers</h2>
              <p>
                Your information, including personal data, may be transferred to and maintained on servers located outside your country of residence. By using our DApp, you consent to the transfer of your information to countries outside of your country of residence, which may have different data protection laws than your jurisdiction.
              </p>

              <h2 className="mt-5">10. Children's Privacy</h2>
              <p>
                Our DApp is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently received personal information from a child under 18, we will delete such information from our records.
              </p>

              <h2 className="mt-5">11. Your Rights</h2>
              <p>
                Depending on your jurisdiction, you may have the following rights regarding your personal data:
              </p>
              <ul>
                <li><strong>Access:</strong> The right to request access to your personal data.</li>
                <li><strong>Rectification:</strong> The right to request correction of inaccurate or incomplete data.</li>
                <li><strong>Deletion:</strong> The right to request deletion of your personal data.</li>
                <li><strong>Restriction of Processing:</strong> The right to request the restriction of processing your personal data.</li>
                <li><strong>Data Portability:</strong> The right to receive your personal data in a structured, commonly used format.</li>
                <li><strong>Objection:</strong> The right to object to the processing of your personal data.</li>
              </ul>
              <p>
                To exercise these rights, please contact us using the information provided below. We will respond to your request within the applicable timeframe as required by law.
              </p>

              <h2 className="mt-5">12. Changes to This Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
              </p>

              <h2 className="mt-5">13. Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
              </p>
              <ul>
                <li><strong>Email:</strong> <a href="mailto:contact@turtleblunt.com" className="text-info">contact@turtleblunt.com</a></li>
                <li><strong>Contact Page:</strong> <a href="https://turtleblunt.com/contact" target="_blank" rel="noopener noreferrer" className="text-info">https://turtleblunt.com/contact</a></li>
              </ul>

              <p className="mt-4">
                By using our DApp, you acknowledge and accept this Privacy Policy.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
