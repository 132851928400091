// src/components/TokenCreationFeeTopPanel.js

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Card, Typography, Spin, Alert } from 'antd';
import { TransactionOutlined } from '@ant-design/icons';
import { Contract, JsonRpcProvider, formatUnits } from 'ethers';
import TokenCreationManagerAbi from "../abis/TokenFactoryCreationManagerAbi.json";
import './TokenCreationFeeTopPanel.scss';

const { Title, Text } = Typography;

// Utility functions for cookie management
const setCookie = (name, value, hours) => {
  const d = new Date();
  d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
  const cname = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let c of ca) {
    c = c.trim();
    if (c.indexOf(cname) === 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return "";
};

const TokenCreationFeeTopPanel = ({
  paymentMethod,
  tokenCreationManagerAddress, // Now required prop
  creationFeeDecimals = 18, // Default to 18 decimals if not provided
  onFeeChange, // New callback prop
}) => {
  // Determine the manager address
  const effectiveManagerAddress =
    tokenCreationManagerAddress || process.env.REACT_APP_ETH_TOKEN_FACTORY_CREATION_MANAGER_ADDRESS;
  
  // ABI for the TokenCreationManager contract
  const contractABI = TokenCreationManagerAbi.abi || TokenCreationManagerAbi;

  const [creationFee, setCreationFee] = useState(null);
  const [loadingFee, setLoadingFee] = useState(true);
  const [feeError, setFeeError] = useState(null);

  const [ethPriceUSD, setEthPriceUSD] = useState(null);

  // Ref to store the timeout ID for cleanup
  const retryTimeoutRef = useRef(null);

  // Initialize a read-only provider
  const provider = useMemo(() => {
    const rpcUrl = process.env.REACT_APP_RPC_URL;
    if (!rpcUrl) {
      setFeeError("RPC URL is not defined in environment variables");
      return null;
    }
    return new JsonRpcProvider(rpcUrl);
  }, []);

  // Initialize the TokenCreationManager contract
  const tokenCreationManagerContract = useMemo(() => {
    if (!provider || !effectiveManagerAddress) {
      return null;
    }
    try {
      const contractInstance = new Contract(effectiveManagerAddress, contractABI, provider);
      return contractInstance;
    } catch (err) {
      console.error("Error initializing contract:", err);
      setFeeError("Error initializing token creation manager contract.");
      return null;
    }
  }, [provider, effectiveManagerAddress, contractABI]);

  // Fetch the creation fee from the contract
  useEffect(() => {
    const fetchFee = async () => {
      if (!tokenCreationManagerContract) {
        setLoadingFee(false);
        return;
      }
      setLoadingFee(true);
      try {
        const feeBN = await tokenCreationManagerContract.creationFee();
        const formattedFee = formatUnits(feeBN, creationFeeDecimals);
        setCreationFee(formattedFee);
        if (onFeeChange) {
          onFeeChange(formattedFee); // Notify parent component
        }
      } catch (err) {
        console.error("Error fetching creation fee:", err);
        setFeeError("Error fetching creation fee.");
      } finally {
        setLoadingFee(false);
      }
    };
    fetchFee();
  }, [tokenCreationManagerContract, creationFeeDecimals, onFeeChange]);

  // Fetch the ETH price in USD with retry logic
  useEffect(() => {
    let isMounted = true; // Flag to prevent state updates after unmount

    const fetchEthPriceUSD = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        if (!response.ok) {
          throw new Error(`CoinGecko API responded with status ${response.status}`);
        }
        const priceData = await response.json();
        const ethPrice = priceData.ethereum.usd;
        if (isMounted) {
          setEthPriceUSD(ethPrice);
          setFeeError(null); // Clear any previous errors
          setCookie('ethPriceUSD', ethPrice, 1); // Store in cookie for 1 hour
        }
      } catch (err) {
        console.error("Error fetching ETH price from CoinGecko:", err);
        if (isMounted) {
          setFeeError("Error fetching ETH price. Retrying...");
          // Retry after 5 seconds
          retryTimeoutRef.current = setTimeout(fetchEthPriceUSD, 5000);
        }
      }
    };

    // Check if the ETH price is already stored in the cookie
    const cachedEthPrice = getCookie('ethPriceUSD');
    if (cachedEthPrice) {
      setEthPriceUSD(parseFloat(cachedEthPrice));
    } else {
      fetchEthPriceUSD();
    }

    // Cleanup function to clear the timeout and prevent state updates after unmount
    return () => {
      isMounted = false;
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, []);

  // Compute the creation fee in USD
  const creationFeeUSDComputed = useMemo(() => {
    if (creationFee && ethPriceUSD) {
      const feeFloat = parseFloat(creationFee);
      const usdCost = feeFloat * ethPriceUSD;
      return usdCost.toFixed(2);
    }
    return "0.00";
  }, [creationFee, ethPriceUSD]);

  return (
    <Card className="creation-fee-top-panel" bordered={false}>
      {(loadingFee || ethPriceUSD === null) ? (
        <Spin />
      ) : feeError ? (
        <Alert message={feeError} type="error" showIcon />
      ) : (
        <div className="fee-details">
          <Title level={5} className="fee-title">
            <TransactionOutlined /> Creation Fee
          </Title>
          <div className="fee-values">
            <Text strong>
              {creationFee} {paymentMethod.toUpperCase()}
            </Text>
            <Text type="secondary">(${creationFeeUSDComputed} USD)</Text>
          </div>
        </div>
      )}
    </Card>
  );
};

export default TokenCreationFeeTopPanel;
