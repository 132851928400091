// src/components/TokenSalePage.js

import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { 
  Contract, 
  isAddress, 
  parseEther, 
  formatUnits, 
  JsonRpcProvider 
} from 'ethers';
import TokenABI from '../abis/TokenABI.json';
import { toast } from 'react-toastify';
import {
  Container,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  Dropdown,
} from 'react-bootstrap';
import { Web3Context } from '../Web3Context';
import { 
  FaCopy, 
  FaShoppingCart, 
  FaShareAlt, 
  FaFacebookF, 
  FaTwitter, 
  FaRedditAlien 
} from 'react-icons/fa';
import ImageWithFallback from './ImageWithFallback';
import { copyToClipboard } from '../utils/clipboard';
import { formatNumber, formatDate } from '../utils/format';

import styles from './TokenSalePage.module.scss';

const TokenSalePage = () => {
  const { tokenAddress } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isWidget = searchParams.get('widget') === 'true';
  const headerImgUrl = searchParams.get('headerimg') || '';
  const titleParam = searchParams.get('title') || '';

  const [customTitle, setCustomTitle] = useState('');

  useEffect(() => {
    setCustomTitle(titleParam);
  }, [titleParam]);

  const {
    provider: connectedProvider,
    signer,
    address,
    connected,
    loading: web3Loading,
    connectWallet,
    walletProvider,
  } = useContext(Web3Context);

  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [decimals, setDecimals] = useState(18);
  const [tokenPrice, setTokenPrice] = useState(0); // Changed to store as number
  const [totalSupply, setTotalSupply] = useState(0); // Changed to store as number
  const [metaUrl, setMetaUrl] = useState('');
  const [metadata, setMetadata] = useState(null);
  const [metadataError, setMetadataError] = useState(null);

  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [ethAmount, setEthAmount] = useState('');
  const [purchaseAmountError, setPurchaseAmountError] = useState(null);
  const [ethAmountError, setEthAmountError] = useState(null);
  const [purchaseError, setPurchaseError] = useState(null);
  const [receipt, setReceipt] = useState(() => {
    const cachedReceipt = localStorage.getItem(`receipt-${tokenAddress}`);
    return cachedReceipt ? JSON.parse(cachedReceipt) : null;
  });
  const [showReceipt, setShowReceipt] = useState(receipt !== null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);

  const [contract, setContract] = useState(null);
  const [ethBalance, setEthBalance] = useState(0); // Changed to store as number
  const [ethPriceUSD, setEthPriceUSD] = useState(null);

  // Explorer URL from environment variable
  const explorerUrl = process.env.REACT_APP_EXPLORER_URL || 'https://basescan.org/';

  const getExplorerUrl = useCallback(
    (txHashOrAddress) => {
      return txHashOrAddress
        ? `${explorerUrl}${txHashOrAddress.length === 66 ? 'tx/' : 'address/'}${txHashOrAddress}`
        : '#';
    },
    [explorerUrl]
  );

  // Memoize the read-only provider
  const memoizedReadOnlyProvider = useMemo(() => {
    return new JsonRpcProvider(process.env.REACT_APP_BASE_SEPOLIA_RPC_URL);
  }, []);

  // Function to fetch ETH balance (only if connected)
  const fetchEthBalance = useCallback(async () => {
    if (connectedProvider && address) {
      try {
        const balance = await connectedProvider.getBalance(address);
        const formattedBalance = parseFloat(formatUnits(balance, 18)).toFixed(10);
        setEthBalance(parseFloat(formattedBalance)); // Store as number
        console.log(`Fetched ETH balance: ${formattedBalance} ETH`);
      } catch (err) {
        console.error('Error fetching ETH balance:', err);
        setEthBalance(0);
      }
    }
  }, [connectedProvider, address]);

  // Function to fetch ETH price in USD
  const fetchEthPriceUSD = useCallback(async () => {
    try {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
      );
      if (!response.ok) {
        throw new Error(`CoinGecko API responded with status ${response.status}`);
      }
      const priceData = await response.json();
      const ethPrice = priceData.ethereum.usd;
      setEthPriceUSD(ethPrice);
      console.log(`Fetched ETH price: $${ethPrice} USD`);
    } catch (err) {
      console.error('Error fetching ETH price:', err);
      setEthPriceUSD(null);
    }
  }, []);

  // Fetch ETH balance and price if connected
  useEffect(() => {
    if (connected && connectedProvider && address) {
      fetchEthBalance();
      fetchEthPriceUSD();
      // Refresh ETH price every minute
      const interval = setInterval(() => {
        fetchEthPriceUSD();
      }, 60000); // 60,000 ms = 1 minute
      return () => clearInterval(interval);
    }
  }, [connected, connectedProvider, address, fetchEthBalance, fetchEthPriceUSD]);

  // Function to fetch metadata
  const fetchMetadata = useCallback(async (url) => {
    if (!url || url.trim() === '') {
      setMetadata(null);
      setMetadataError(null);
      return;
    }

    try {
      console.log('Fetching metadata from:', url);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch metadata: ${response.statusText}`);
      }
      const data = await response.json();
      setMetadata(data);
      setMetadataError(null);
      return { success: true, data };
    } catch (err) {
      console.error('Error fetching metadata:', err);
      setMetadataError('Failed to fetch metadata.');
      return { success: false, error: err };
    }
  }, []);

  // Initialize token details
  useEffect(() => {
    const initialize = async () => {
      console.log('TokenSalePage: Initializing token details...');
      try {
        if (!isAddress(tokenAddress)) {
          console.log('TokenSalePage: Invalid token address:', tokenAddress);
          setError('Invalid token address provided.');
          setLoadingData(false);
          return;
        }

        // Determine which provider or signer to use
        const activeRunner = connected && signer ? signer : memoizedReadOnlyProvider;

        // Initialize Contract with the active runner (Signer or Provider)
        const tempContract = new Contract(tokenAddress, TokenABI, activeRunner);
        console.log('TokenSalePage: Initialized Contract:', tempContract);
        setContract(tempContract);

        // Fetch each token detail individually with error handling
        const [name, symbol, dec, supply, price, currentMetaUrl] = await Promise.all([
          (async () => {
            try {
              return await tempContract.name();
            } catch (err) {
              console.error('Error fetching name:', err);
              setTokenName('N/A');
              return null;
            }
          })(),
          (async () => {
            try {
              return await tempContract.symbol();
            } catch (err) {
              console.error('Error fetching symbol:', err);
              setTokenSymbol('N/A');
              return null;
            }
          })(),
          (async () => {
            try {
              return await tempContract.decimals();
            } catch (err) {
              console.error('Error fetching decimals:', err);
              setDecimals(18);
              return null;
            }
          })(),
          (async () => {
            try {
              const supply = await tempContract.totalSupply();
              return supply;
            } catch (err) {
              console.error('Error fetching totalSupply:', err);
              setTotalSupply(0);
              return null;
            }
          })(),
          (async () => {
            try {
              const price = await tempContract.tokenPrice();
              return price;
            } catch (err) {
              console.error('Error fetching tokenPrice:', err);
              setTokenPrice(0);
              return null;
            }
          })(),
          (async () => {
            try {
              return tempContract.metaUrl ? await tempContract.metaUrl() : '';
            } catch (err) {
              console.error('Error fetching metaUrl:', err);
              return '';
            }
          })(),
        ]);

        // Set fetched token details
        if (name !== null) setTokenName(name);
        if (symbol !== null) setTokenSymbol(symbol);

        if (dec !== null) {
          const decimalsNumber = Number(dec);
          setDecimals(decimalsNumber);
          console.log(`Decimals set to: ${decimalsNumber}`);
        }

        if (supply !== null) {
          const formattedSupply = parseFloat(formatUnits(supply, Number(dec) || 18));
          setTotalSupply(formattedSupply);
          console.log(`Total Supply set to: ${formattedSupply}`);
        } else {
          setTotalSupply(0);
        }

        if (price !== null) {
          const rawPrice = parseFloat(formatUnits(price, 18));
          setTokenPrice(rawPrice);
          console.log(`Token Price set to: ${rawPrice} ETH`);
        } else {
          setTokenPrice(0);
        }

        if (currentMetaUrl) {
          setMetaUrl(currentMetaUrl);
          const metadataResult = await fetchMetadata(currentMetaUrl);
          if (metadataResult.success) {
            setMetadata(metadataResult.data);
          } else {
            if (!metadata) {
              setMetadataError('Failed to fetch metadata from metaUrl.');
            }
          }
        }

        // Cache token data without formatting
        const tokenDataToCache = {
          name: name || 'N/A',
          symbol: symbol || 'N/A',
          decimals: Number(dec) || 18,
          totalSupply: supply ? parseFloat(formatUnits(supply, Number(dec) || 18)) : 'N/A',
          tokenPrice: price ? parseFloat(formatUnits(price, 18)) : 0,
          metaUrl: currentMetaUrl || '',
          metadata: metadata || null,
          metadataError: metadataError || null,
          cachedAt: Date.now(),
        };

        try {
          console.log('Token Data to Cache:', tokenDataToCache);
          localStorage.setItem(`tokenData-${tokenAddress}`, JSON.stringify(tokenDataToCache));
          console.log('TokenSalePage: Token data cached.');
        } catch (error) {
          console.error('Error caching token data:', error);
        }

        setLoadingData(false);
      } catch (err) {
        console.error('TokenSalePage: Error initializing Token Sale Page:', err);
        setError(
          'Failed to load token details. Please ensure the token address is correct and you are connected to the right network.'
        );
        setLoadingData(false);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress, connected, signer, fetchMetadata, memoizedReadOnlyProvider]);

  // Log contract updates
  useEffect(() => {
    if (contract) {
      console.log('TokenSalePage: Contract state updated:', contract);
    }
  }, [contract]);

  // Handle ETH amount change
  const handleEthAmountChange = (e) => {
    let value = e.target.value;
    console.log('ETH Amount Changed:', value);
    setEthAmountError(null);
    setPurchaseError(null);

    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setEthAmount(value);

      if (value && !isNaN(value) && tokenPrice !== 0) {
        const tokens = parseFloat(value) / tokenPrice;
        console.log(`Calculating Tokens: ${parseFloat(value)} ETH / ${tokenPrice} ETH/token = ${tokens} tokens`);
        setPurchaseAmount(tokens.toFixed(6));
      } else {
        setPurchaseAmount('');
      }
    }
  };

  // Handle ETH amount blur
  const handleEthAmountBlur = () => {
    if (ethAmount && !isNaN(ethAmount)) {
      const formattedValue = parseFloat(ethAmount).toFixed(6);
      setEthAmount(formattedValue);
    }
  };

  // Handle Token amount change
  const handleTokenAmountChange = (e) => {
    const value = e.target.value;
    console.log('Token Amount Changed:', value);
    setPurchaseAmountError(null);
    setPurchaseError(null);

    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setPurchaseAmount(value);

      if (value && !isNaN(value) && tokenPrice !== 0) {
        const eth = parseFloat(value) * tokenPrice;
        console.log(`Calculating ETH: ${parseFloat(value)} tokens * ${tokenPrice} ETH/token = ${eth} ETH`);
        setEthAmount(eth.toFixed(6));
      } else {
        setEthAmount('');
      }
    }
  };

  // Handle purchase action (open review modal)
  const handlePurchase = (e) => {
    e.preventDefault();

    setPurchaseAmountError(null);
    setEthAmountError(null);
    setPurchaseError(null);

    const trimmedEthAmount = ethAmount.trim();
    const trimmedPurchaseAmount = purchaseAmount.trim();

    // Validate ETH amount
    if (!trimmedEthAmount || isNaN(trimmedEthAmount) || Number(trimmedEthAmount) <= 0) {
      setEthAmountError('Please enter a valid amount of ETH to spend.');
      return;
    }

    // Validate purchase amount
    if (!trimmedPurchaseAmount || isNaN(trimmedPurchaseAmount) || Number(trimmedPurchaseAmount) <= 0) {
      setPurchaseAmountError(`Please enter a valid amount of ${tokenSymbol} to purchase.`);
      return;
    }

    // Check ETH balance
    if (Number(trimmedEthAmount) > ethBalance) {
      setEthAmountError('Insufficient ETH balance.');
      return;
    }

    // Check if contract is initialized
    if (!contract) {
      setPurchaseError('Smart contract is not initialized.');
      toast.error('Smart contract is not initialized.', {
        toastId: 'purchase-contract-error',
      });
      return;
    }

    // Check if 'buyTokens' exists in the contract
    if (typeof contract.buyTokens !== 'function') {
      setPurchaseError('buyTokens function is not available in the contract.');
      toast.error('buyTokens function is not available in the contract.', {
        toastId: 'purchase-buyTokens-error',
      });
      return;
    }

    // Open the review modal
    setShowReviewModal(true);
  };

  // Confirm purchase after review
  const confirmPurchase = async () => {
    setShowReviewModal(false);
    setProcessing(true);
    setPurchaseError(null);

    try {
      const requiredETH = parseEther(ethAmount.toString());
      console.log('TokenSalePage: Sending transaction with ETH:', requiredETH.toString());

      const tx = await contract.buyTokens({ value: requiredETH });
      toast.info('Transaction submitted. Awaiting confirmation...', {
        toastId: 'purchase-tx-submitted',
      });
      console.log('TokenSalePage: Transaction submitted:', tx);

      const receiptTx = await tx.wait();
      if (!receiptTx || (!receiptTx.transactionHash && !receiptTx.hash)) {
        throw new Error('Transaction receipt is missing the transaction hash.');
      }

      const txHash = receiptTx.transactionHash || receiptTx.hash;
      toast.success('Tokens purchased successfully!', {
        toastId: 'purchase-success',
      });
      console.log('TokenSalePage: Transaction confirmed:', txHash);

      const purchasedTokens = purchaseAmount;
      const spentETH = ethAmount;
      const spentUSD = ethPriceUSD
        ? parseFloat(ethAmount) * ethPriceUSD
        : 0.00;
      const timestamp = formatDate(new Date().toISOString());

      const newReceipt = {
        purchasedTokens,
        spentETH,
        spentUSD,
        txHash,
        timestamp,
      };
      setReceipt(newReceipt);
      setShowReceipt(true);
      setPurchaseAmount('');
      setEthAmount('');

      // Save to localStorage
      localStorage.setItem(`receipt-${tokenAddress}`, JSON.stringify(newReceipt));

      fetchEthBalance();
      fetchEthPriceUSD(); // Update ETH price after purchase
    } catch (err) {
      console.error('TokenSalePage: Error purchasing tokens:', err);
      if (err.code === 4001) {
        toast.error('Transaction rejected by user.', {
          toastId: 'purchase-tx-rejected',
        });
      } else {
        toast.error('Failed to purchase tokens.', {
          toastId: 'purchase-tx-failed',
        });
        setPurchaseError('Failed to purchase tokens. Please try again.');
      }
    } finally {
      setProcessing(false);
    }
  };

  // Set ETH amount to half of balance
  const handleSetHalfBalance = () => {
    const halfBalance = (Number(ethBalance) / 2).toFixed(6);
    setEthAmount(halfBalance);
    if (parseFloat(tokenPrice) !== 0) {
      const tokens = parseFloat(halfBalance) / parseFloat(tokenPrice);
      setPurchaseAmount(tokens.toFixed(6));
    }
  };

  // Set ETH amount to max balance
  const handleSetMaxBalance = () => {
    const maxBalance = Number(ethBalance).toFixed(6);
    setEthAmount(maxBalance);
    if (parseFloat(tokenPrice) !== 0) {
      const tokens = parseFloat(maxBalance) / parseFloat(tokenPrice);
      setPurchaseAmount(tokens.toFixed(6));
    }
  };

  // Close receipt modal
  const handleCloseReceipt = () => {
    setShowReceipt(false);
    // Keep the receipt so it's shown at the bottom
  };

  // Truncate transaction hash for display
  const truncateHash = (hash) => {
    if (!hash) return "";
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  // Calculate ETH spending in USD
  const ethSpendingUSD = ethAmount && ethPriceUSD ? parseFloat(ethAmount) * ethPriceUSD : 0;

  // Function to add token to wallet
  const addTokenToWallet = async () => {
    if (!tokenAddress || !tokenSymbol || !decimals) {
      toast.error('Token information is incomplete.', { toastId: 'add-token-missing-info' });
      console.log('Add Token Error: Token information is incomplete.');
      return;
    }

    const tokenImage = metadata?.image || 'https://yourdomain.com/path-to-default-token-image.png'; // Ensure you have a valid image URL
    console.log('Adding token with details:', { tokenAddress, tokenSymbol, decimals, tokenImage });

    // Prepare the parameters for wallet_watchAsset
    const tokenDetails = {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: Number(decimals),
        image: tokenImage,
      },
    };

    // Check if wallet_watchAsset is supported
    const support = isWalletWatchAssetSupported();
    if (!support) {
      // Provide fallback instructions
      toast.warn('Your wallet does not support adding tokens automatically. Please add the token manually.', { toastId: 'add-token-warning' });
      console.log('wallet_watchAsset not supported. Providing manual instructions.');
      return;
    }

    try {
      // Use walletProvider directly, else fallback to window.ethereum
      const walletProviderInstance = walletProvider || window.ethereum;
      console.log('walletProviderInstance:', walletProviderInstance);

      if (!walletProviderInstance || typeof walletProviderInstance.request !== 'function') {
        throw new Error('Wallet provider is not available.');
      }

      const wasAdded = await walletProviderInstance.request({
        method: 'wallet_watchAsset',
        params: tokenDetails,
      });

      console.log('wasAdded:', wasAdded);

      if (wasAdded) {
        toast.success(`Token ${tokenSymbol} has been added to your wallet!`, { toastId: 'add-token-success' });
        console.log(`Token ${tokenSymbol} added successfully.`);
      } else {
        toast.info(`Token ${tokenSymbol} was not added to your wallet.`, { toastId: 'add-token-info' });
        console.log(`Token ${tokenSymbol} was not added.`);
      }
    } catch (error) {
      console.error('TokenSalePage: Error adding token to wallet:', error);
      toast.error(`Failed to add token to wallet: ${error.message}`, { toastId: 'add-token-error' });
    }
  };

  // Check if wallet_watchAsset is supported
  const isWalletWatchAssetSupported = () => {
    const walletProviderInstance = walletProvider || window.ethereum;
    return (
      walletProviderInstance &&
      typeof walletProviderInstance.request === 'function'
    );
  };

  // Function to clear receipt from state and localStorage
  const clearReceipt = () => {
    setReceipt(null);
    localStorage.removeItem(`receipt-${tokenAddress}`);
    toast.info('Purchase receipt has been cleared.', { toastId: 'clear-receipt' });
  };

  // Function to handle sharing on different platforms
  const handleShareClick = (platform) => {
    const shareUrl = `${window.location.origin}/token-sale/${tokenAddress}`;
    const shareText = `Check out this token sale: ${tokenName} (${tokenSymbol})`;

    let url = '';
    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'reddit':
        url = `https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareText)}`;
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  // Function to handle copying the sale page URL to clipboard
  const handleCopyLink = (e) => {
    e.stopPropagation(); // Prevent triggering any parent click events
    const shareUrl = `${window.location.origin}/token-sale/${tokenAddress}`;
    copyToClipboard(shareUrl);
    // Removed toast notification
    // toast.success('Sale page link copied to clipboard!', {
    //   toastId: `copy-link-${tokenAddress}`,
    // });
  };

  // Function to handle copying the receipt link to clipboard (Optional)
  const handleCopyReceiptLink = () => {
    const receiptLink = `${window.location.origin}/token-sale/${tokenAddress}`;
    copyToClipboard(receiptLink);
    // Removed toast notification
    // toast.success('Receipt link copied to clipboard!', {
    //   toastId: `copy-receipt-link-${tokenAddress}`,
    // });
  };

  // Function to handle sharing receipt on different platforms (Optional)
  const handleShareReceiptClick = (platform) => {
    const receiptLink = `${window.location.origin}/token-sale/${tokenAddress}`;
    const shareText = `I just purchased ${purchaseAmount} ${tokenSymbol} tokens! Check out this transaction: ${receiptLink}`;

    let url = '';
    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(receiptLink)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(receiptLink)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'reddit':
        url = `https://www.reddit.com/submit?url=${encodeURIComponent(receiptLink)}&title=${encodeURIComponent(shareText)}`;
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Container className={`${styles.tokenSaleContainer}`}>
      <Card className={`${styles.card}`}>
        {/* Header Section: Always Rendered */}
        <div className={styles.header}>
          {headerImgUrl && (
            <div className={styles.headerImageContainer}>
              <ImageWithFallback
                src={headerImgUrl}
                alt={`${tokenName} Token Sale Header`}
                fallbackSrc="/path-to-default-image.png" // Ensure you have a fallback image in your public folder
                className={styles.headerImage}
              />
            </div>
          )}
          {customTitle && (
            <div className={styles.headerContent}>
              <h2>{customTitle}</h2>
              <p>Purchase {tokenSymbol} tokens using ETH.</p>
            </div>
          )}

          {/* Share Dropdown: Only Rendered in Non-Widget Mode */}
          {!isWidget && (
            <Dropdown
              className="position-absolute top-0 end-0 m-3"
              onClick={(e) => e.stopPropagation()} // Prevent card click when interacting with Dropdown
            >
              <Dropdown.Toggle
                variant="link"
                className="p-0 text-light"
                id={`dropdown-share-${tokenAddress}`}
                aria-label={`Share ${tokenSymbol} Token Sale`}
              >
                <FaShareAlt size={20} />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end" className={styles.shareDropdownMenu}>
                <Dropdown.Item onClick={() => handleShareClick('facebook')} aria-label="Share on Facebook">
                  <FaFacebookF className="me-2 text-primary" /> Facebook
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleShareClick('twitter')} aria-label="Share on Twitter">
                  <FaTwitter className="me-2 text-info" /> Twitter
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleShareClick('reddit')} aria-label="Share on Reddit">
                  <FaRedditAlien className="me-2 text-danger" /> Reddit
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleCopyLink} aria-label="Copy Link">
                  <FaCopy className="me-2 text-secondary" /> Copy Link
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        {/* Widget Mode Specific Component */}
        {isWidget && (
          <div className="widget-button-container">
            <appkit-account-button />
          </div>
        )}

        {/* Token Image */}
        {metadata && metadata.image && (
          <Row className="justify-content-center mb-4">
            <Col xs={12} sm={8} md={6} lg={4} className="text-center">
              <ImageWithFallback
                src={metadata.image}
                alt={`${metadata.name || tokenName} Image`}
                fallbackSrc="/path-to-default-token-image.png" // Ensure you have a fallback image
                className={styles.tokenImage}
              />
            </Col>
          </Row>
        )}

        {/* Metadata Error */}
        {metadataError && (
          <Alert variant="warning" className="text-center">
            {metadataError}
          </Alert>
        )}

        {/* Token Description */}
        {metadata && metadata.description && (
          <div className="mb-4 text-center">
            <p>{metadata.description}</p>
          </div>
        )}

        {/* Social Links */}
        {metadata && (
          <div className="mb-4 text-center">
            {metadata.website && (
              <p>
                <strong>Website: </strong>
                <a
                  href={metadata.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary"
                >
                  {metadata.website}
                </a>
              </p>
            )}
            {metadata.social && (
              <div>
                {metadata.social.twitter && (
                  <p>
                    <strong>Twitter: </strong>
                    <a
                      href={metadata.social.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      {metadata.social.twitter}
                    </a>
                  </p>
                )}
                {metadata.social.discord && (
                  <p>
                    <strong>Discord: </strong>
                    <a
                      href={metadata.social.discord}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      {metadata.social.discord}
                    </a>
                  </p>
                )}
              </div>
            )}
          </div>
        )}

        {/* Contract Address */}
        <div className="d-flex justify-content-center mb-4">
          <Card
            className="p-2 bg-secondary text-white border-0 d-flex flex-row align-items-center flex-wrap"
            style={{ maxWidth: '600px' }}
          >
            <strong>Contract Address: </strong>
            <a
              href={getExplorerUrl(tokenAddress)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white me-2 text-break"
              style={{ textDecoration: 'underline', fontSize: '0.85rem' }}
            >
              {tokenAddress}
            </a>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Copy Address</Tooltip>}
            >
              <Button
                variant="outline-secondary"
                onClick={() => copyToClipboard(tokenAddress)}
                size="sm"
                className="ms-2"
                aria-label="Copy Contract Address"
              >
                <FaCopy />
              </Button>
            </OverlayTrigger>
          </Card>
        </div>

        {/* Token Details */}
        <Card className="mb-4 p-3 bg-dark text-light border-0">
          <Row className="justify-content-center text-center">
            <Col xs={12} md={4} className="mb-3">
              <h5>{tokenName}</h5>
              <p className="mb-0">{tokenSymbol}</p>
            </Col>
            <Col xs={12} md={4} className="mb-3">
              <h5>{formatNumber(totalSupply)}</h5>
              <p className="mb-0">Total Supply</p>
            </Col>
          </Row>
        </Card>

        {/* Purchase Form */}
        <Card className="p-4 bg-dark text-light border-0">
          <Form onSubmit={handlePurchase}>
            {/* ETH Balance Display */}
            {connected && (
              <Row className="justify-content-center mb-3">
                <Col xs={12} md={6} className="text-center">
                  <Alert variant="info" className="py-2">
                    Current ETH Balance: {formatNumber(ethBalance)} ETH
                  </Alert>
                </Col>
              </Row>
            )}

            {/* Swap ETH for Tokens Label */}
            <Row className="justify-content-center mb-2">
              <Col xs={12} md={6} className="text-center">
                <p>Swap ETH for {tokenSymbol}</p>
              </Col>
            </Row>

            {/* ETH Amount Input */}
            <Row className="justify-content-center mb-4">
              <Col xs={12} md={6} lg={4}>
                <Form.Group controlId="ethAmount" className="mb-3">
                  <Form.Label className={styles.formLabel}>Amount of ETH</Form.Label>
                  <InputGroup className={styles.inputGroup}>
                    <Form.Control
                      type="text"
                      placeholder="Enter amount of ETH"
                      value={ethAmount}
                      onChange={handleEthAmountChange}
                      onBlur={handleEthAmountBlur}
                      required
                      className={styles.formControl}
                      disabled={processing}
                      aria-label="Amount of ETH to spend"
                      inputMode="decimal"
                    />
                    {/* Removed the two buttons with icons (FaPercentage and FaExpand) */}
                  </InputGroup>
                  {ethAmountError && (
                    <div className={styles.errorText}>{ethAmountError}</div>
                  )}
                  {ethSpendingUSD > 0 && (
                    <div className={styles.usdEstimate}>
                      ≈ ${formatNumber(ethSpendingUSD.toFixed(2))} USD
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Receive Tokens Label */}
            <Row className="justify-content-center mb-2">
              <Col xs={12} md={6} className="text-center">
                <p>Receive {tokenSymbol} for ETH</p>
              </Col>
            </Row>

            {/* Token Amount Input */}
            <Row className="justify-content-center mb-4">
              <Col xs={12} md={6} lg={4}>
                <Form.Group controlId="purchaseAmount" className="mb-3">
                  <Form.Label className={styles.formLabel}>Amount of {tokenSymbol}</Form.Label>
                  <InputGroup className={styles.inputGroup}>
                    <Form.Control
                      type="text"
                      placeholder={`Enter number of ${tokenSymbol}`}
                      value={purchaseAmount}
                      onChange={handleTokenAmountChange}
                      required
                      className={styles.formControl}
                      disabled={processing}
                      aria-label={`Amount of ${tokenSymbol} to purchase`}
                      inputMode="decimal"
                    />
                  </InputGroup>
                  {purchaseAmountError && (
                    <div className={styles.errorText}>{purchaseAmountError}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* Purchase Button and Share Icon */}
            <Row className="justify-content-center">
              <Col xs={12} md={6} lg={4} className="text-center d-flex justify-content-center align-items-center">
                <div className="me-3 w-100">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={processing || (!connected && ethAmount)}
                    className="w-100"
                    aria-label="Purchase Tokens"
                  >
                    {processing ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Processing...
                      </>
                    ) : (
                      'Purchase Tokens'
                    )}
                  </Button>
                </div>
                {/* Share Icon Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    className="p-0 text-light"
                    id={`dropdown-share-purchase-${tokenAddress}`}
                    aria-label={`Share ${tokenSymbol} Token Sale`}
                  >
                    <FaShareAlt size={24} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end" className={styles.shareDropdownMenu}>
                    <Dropdown.Item onClick={() => handleShareClick('facebook')} aria-label="Share on Facebook">
                      <FaFacebookF className="me-2 text-primary" /> Facebook
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShareClick('twitter')} aria-label="Share on Twitter">
                      <FaTwitter className="me-2 text-info" /> Twitter
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShareClick('reddit')} aria-label="Share on Reddit">
                      <FaRedditAlien className="me-2 text-danger" /> Reddit
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleCopyLink} aria-label="Copy Link">
                      <FaCopy className="me-2 text-secondary" /> Copy Link
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            {/* Error Alert */}
            {purchaseError && (
              <Row className="justify-content-center">
                <Col xs={12} md={6} lg={4} className="text-center">
                  <Alert variant="danger" className="mt-3">
                    {purchaseError}
                  </Alert>
                </Col>
              </Row>
            )}
          </Form>
        </Card>
      </Card>

      {/* Review Purchase Modal */}
      <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)} centered>
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title>Review Your Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          <Container>
            <Row className="mb-2">
              <Col><strong>ETH to Spend:</strong></Col>
              <Col className="text-end">{ethAmount} ETH</Col>
            </Row>
            <Row className="mb-2">
              <Col><strong>Equivalent USD:</strong></Col>
              <Col className="text-end">${formatNumber(ethSpendingUSD.toFixed(2))} USD</Col>
            </Row>
            <Row className="mb-2">
              <Col><strong>Tokens to Receive:</strong></Col>
              <Col className="text-end">{purchaseAmount} {tokenSymbol}</Col>
            </Row>
            <Row className="mb-2">
              <Col><strong>Swap:</strong></Col>
              <Col className="text-end">ETH for {tokenSymbol}</Col>
            </Row>
            {/* Slippage Tolerance and Note have been removed */}
          </Container>
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button variant="secondary" onClick={() => setShowReviewModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmPurchase}>
            Confirm Purchase
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Receipt Modal */}
      <Modal show={showReceipt} onHide={handleCloseReceipt} centered>
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title>Purchase Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          {receipt ? (
            <Container>
              <Row className="mb-2">
                <Col><strong>Tokens Purchased:</strong></Col>
                <Col className="text-end">{receipt.purchasedTokens} {tokenSymbol}</Col>
              </Row>
              <Row className="mb-2">
                <Col><strong>ETH Spent:</strong></Col>
                <Col className="text-end">{receipt.spentETH} ETH</Col>
              </Row>
              <Row className="mb-2">
                <Col><strong>Equivalent USD:</strong></Col>
                <Col className="text-end">${formatNumber(receipt.spentUSD.toFixed(2))} USD</Col>
              </Row>
              <Row className="mb-2 align-items-center">
                <Col><strong>Transaction Hash:</strong></Col>
                <Col className="text-end d-flex align-items-center justify-content-end">
                  {receipt.txHash ? (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                      >
                        <a
                          href={getExplorerUrl(receipt.txHash)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary me-2"
                          style={{ wordBreak: "break-all", textDecoration: "underline" }}
                        >
                          {truncateHash(receipt.txHash)}
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                      >
                        <Button
                          variant="outline-light"
                          size="sm"
                          onClick={() => copyToClipboard(receipt.txHash)}
                          className={styles.copyButton}
                          aria-label="Copy Transaction Hash"
                        >
                          <FaCopy />
                        </Button>
                      </OverlayTrigger>
                    </>
                  ) : (
                    "Transaction hash unavailable."
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col><strong>Timestamp:</strong></Col>
                <Col className="text-end">{receipt.timestamp}</Col>
              </Row>

              {/* Add Token to Wallet Button */}
              <div className={styles.addToWalletButton}>
                <Button variant="info" onClick={addTokenToWallet} className="rounded-pill" aria-label={`Add ${tokenSymbol} to Wallet`}>
                  Add {tokenSymbol} to Wallet
                </Button>
              </div>

              {/* Clear Receipt Button */}
              <div className="mt-3 text-end">
                <Button variant="danger" size="sm" onClick={clearReceipt}>
                  Clear Receipt
                </Button>
              </div>
            </Container>
          ) : (
            <p>No receipt information available.</p>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button variant="secondary" onClick={() => { handleCloseReceipt(); clearReceipt(); }}>
            Clear Receipt
          </Button>
          <Button variant="primary" onClick={handleCloseReceipt}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Persistent Receipt Display */}
      {receipt && (
        <Card className={`${styles.receiptCard}`}>
          <Card.Body>
            <Card.Title className={styles.receiptTitle}>Purchase Receipt</Card.Title>
            <Row className="mb-3">
              <Col xs={6}><strong>Tokens Purchased:</strong></Col>
              <Col xs={6} className="text-end">{receipt.purchasedTokens} {tokenSymbol}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}><strong>ETH Spent:</strong></Col>
              <Col xs={6} className="text-end">{receipt.spentETH} ETH</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}><strong>Equivalent USD:</strong></Col>
              <Col xs={6} className="text-end">${formatNumber(receipt.spentUSD.toFixed(2))} USD</Col>
            </Row>
            <Row className="mb-3 align-items-center">
              <Col xs={6}><strong>Transaction Hash:</strong></Col>
              <Col xs={6} className="text-end d-flex align-items-center justify-content-end">
                {receipt.txHash ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                    >
                      <a
                        href={getExplorerUrl(receipt.txHash)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary me-2"
                        style={{
                          textDecoration: "underline",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "block",
                          maxWidth: "200px",
                          fontFamily: "'Courier New', Courier, monospace",
                        }}
                      >
                        {truncateHash(receipt.txHash)}
                      </a>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Copy Transaction Hash</Tooltip>}
                    >
                      <Button
                        variant="outline-light"
                        size="sm"
                        onClick={() => copyToClipboard(receipt.txHash)}
                        className={styles.copyButton}
                        aria-label="Copy Transaction Hash"
                      >
                        <FaCopy />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  "Transaction hash unavailable."
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}><strong>Timestamp:</strong></Col>
              <Col xs={6} className="text-end">{receipt.timestamp}</Col>
            </Row>

            {/* Add Token to Wallet Button */}
            <div className={styles.addToWalletButton}>
              <Button variant="info" onClick={addTokenToWallet} className="rounded-pill" aria-label={`Add ${tokenSymbol} to Wallet`}>
                Add {tokenSymbol} to Wallet
              </Button>
            </div>

            {/* Clear Receipt Button */}
            <div className="mt-3 text-end">
              <Button variant="danger" size="sm" onClick={clearReceipt}>
                Clear Receipt
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

// Helper function to validate Ethereum addresses
const isAddressValid = (address) => {
  return isAddress(address);
};

// Export the component
export default TokenSalePage;
