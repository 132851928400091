/* eslint-env es2020 */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Contract, parseUnits, formatUnits, Interface } from "ethers";
import { useWeb3 } from "../Web3Context";
import TokenCreationManagerAbi from "../abis/TokenFactoryCreationManagerAbi.json";
import TBLUNTAbi from "../abis/TbluntToken.json";
import {
  Card,
  Form,
  Button,
  Alert,
  Spin,
  Modal,
  Layout,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import TokenInfo from "./TokenInfo";
import { copyToClipboard } from "../utils/clipboard";
import "./CreateTokenFactory.scss";
import { io } from "socket.io-client";
import TokenCreationFeeTopPanelTblunt from "./TokenCreationFeeTopPanelTblunt";

// Now we simply import it and pass the tokenType:
import TokenTypeFeatures from "./TokenTypeFeatures";
import FactoryCreateTokenFormInputs from "./FactoryCreateTokenFormInputs"; // Your custom form inputs

const { Content } = Layout;
const { Title, Paragraph } = Typography;

// TBLUNT decimals
const TBLUNT_DECIMALS = 2;

// Map each tokenType to the manager address:
const managerAddressByTokenType = {
  StandardERC20:
    process.env.REACT_APP_TBLUNT_TOKEN_FACTORY_CREATION_MANAGER_ADDRESS,
  MintableERC20:
    process.env.REACT_APP_TBLUNT_MINTABLE_TOKEN_FACTORY_CREATION_MANAGER,
  PausableERC20:
    process.env.REACT_APP_TBLUNT_PAUSABLE_TOKEN_FACTORY_CREATION_MANAGER,
  MintablePausableERC20:
    process.env.REACT_APP_TBLUNT_MINTABLE_PAUSABLE_TOKEN_FACTORY_CREATION_MANAGER,
  CappedMintableERC20:
    process.env.REACT_APP_TBLUNT_CAPPED_MINTABLE_TOKEN_FACTORY_CREATION_MANAGER,
  CappedMintablePausableERC20:
    process.env.REACT_APP_TBLUNT_CAPPED_MINTABLE_PAUSABLE_TOKEN_FACTORY_CREATION_MANAGER,
};

// Validation constants
const MIN_INITIAL_PRICE = "0.000000000000000001";
const MAX_INITIAL_PRICE = "100000000000000000";
const MIN_INITIAL_SUPPLY = "1";
const MAX_INITIAL_SUPPLY = "10000000000000000000000";

const CreateTokenFactory = ({ onSetCreationFee }) => {
  const {
    signer,
    address,
    numericChainId,
    error: web3Error,
    lastCreatedToken,
    updateLastCreatedToken,
    clearLastCreatedToken,
  } = useWeb3();

  // TBLUNT token address (from env)
  const tbluntTokenAddress = process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS;

  // --------------------------
  // STATE
  // --------------------------
  const [contractName, setContractName] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [decimals, setDecimals] = useState(4);
  const [initialPrice, setInitialPrice] = useState("");
  const [creationFee, setCreationFee] = useState(null); // raw BN string
  const [tbluntBalance, setTbluntBalance] = useState(null);

  const [error, setError] = useState(web3Error);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showReview, setShowReview] = useState(false);
  const [newTokenAddress, setNewTokenAddress] = useState(
    lastCreatedToken?.tokenAddress || null
  );
  const [transactionHash, setTransactionHash] = useState(
    lastCreatedToken?.transactionHash || ""
  );
  const [widgetCode, setWidgetCode] = useState(
    lastCreatedToken?.widgetCode || ""
  );
  const [tokensAfterTax, setTokensAfterTax] = useState("");
  const [agreed, setAgreed] = useState(false);

  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [queuePosition, setQueuePosition] = useState(null);

  // Socket
  const [socket, setSocket] = useState(null);

  // Processing modal steps
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [processingSteps] = useState([
    "Approving TBLUNT Token Usage",
    "Requesting Token Creation",
    "Creating Token",
  ]);
  const [currentStep, setCurrentStep] = useState(0);

  // Error modal state
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Retry
  const [lastRequestParams, setLastRequestParams] = useState(null);

  // Token Type
  const [tokenType, setTokenType] = useState("StandardERC20");
  const [status, setStatus] = useState("pending"); // 'pending', 'successful', or 'failed'

  // --------------------------
  // SELECT MANAGER ADDRESS
  // --------------------------
  const selectedManagerAddress = useMemo(() => {
    return managerAddressByTokenType[tokenType] || "";
  }, [tokenType]);

  // --------------------------
  // CONTRACT INSTANCES
  // --------------------------
  const tokenCreationManagerContract = useMemo(() => {
    if (!signer || !selectedManagerAddress) return null;
    return new Contract(
      selectedManagerAddress,
      TokenCreationManagerAbi.abi,
      signer
    );
  }, [signer, selectedManagerAddress]);

  const tbluntContract = useMemo(() => {
    if (!signer || !tbluntTokenAddress) return null;
    return new Contract(tbluntTokenAddress, TBLUNTAbi.abi, signer);
  }, [signer, tbluntTokenAddress]);

  // --------------------------
  // SOCKET INITIALIZATION
  // --------------------------
  useEffect(() => {
    const newSocket = io("https://testnet.turtleblunt.com", {
      path: "/tokenfactory/socket.io",
      reconnectionAttempts: 50,
      reconnectionDelay: 3000,
      transports: ["websocket"],
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  // --------------------------
  // SOCKET EVENT HANDLERS
  // --------------------------
  useEffect(() => {
    if (!socket || !address) return;

    socket.emit("joinUserRoom", address);

    const handleQueueUpdate = (data) => {
      if (!data || !data.queue) return;
      const found = data.queue.find(
        (q) => q.requestId?.toString() === currentRequestId
      );
      setQueuePosition(found?.position || null);
    };

    const handleTokenStatusUpdate = (data) => {
      if (!data) return;
      const { tokenId, status: newStatus, tokenAddress, error: eventError } = data;

      if (tokenId === currentRequestId) {
        console.log("Received tokenStatusUpdate:", data);
        if (newStatus === "successful") {
          setStatus("successful");
          setNewTokenAddress(tokenAddress);
          setSuccess("Token created successfully!");
          setShowProcessingModal(false);
          setShowReview(false);
          setLoading(false);
          setCurrentStep(processingSteps.length);

          updateLastCreatedToken({
            tokenAddress,
            name,
            symbol,
            initialSupply,
            decimals,
            transactionHash,
            widgetCode,
          });
        } else if (newStatus === "failed") {
          setStatus("failed");
          setError(eventError);
          setErrorMessage(eventError);
          setShowErrorModal(true);
          setShowProcessingModal(false);
          setLoading(false);
          setCurrentStep(0);
        }
      }
    };

    const handleAddressTokenStatus = (data) => {
      if (!data || !data.status) return;
      console.log("Received addressTokenStatus:", data);

      if (data.status === "successful") {
        setStatus("successful");
        setNewTokenAddress(data.tokenAddress);
        setSuccess("Token created successfully!");
        setShowProcessingModal(false);
        setShowReview(false);
        setLoading(false);
        setCurrentStep(processingSteps.length);

        updateLastCreatedToken({
          tokenAddress: data.tokenAddress,
          name,
          symbol,
          initialSupply,
          decimals,
          transactionHash: data.transactionHash || transactionHash,
          widgetCode,
        });
      } else if (data.status === "failed") {
        setStatus("failed");
        setError(data.error);
        setErrorMessage(data.error);
        setShowErrorModal(true);
        setShowProcessingModal(false);
        setLoading(false);
        setCurrentStep(0);
      }
    };

    socket.on("queueUpdate", handleQueueUpdate);
    socket.on("tokenStatusUpdate", handleTokenStatusUpdate);
    socket.on("addressTokenStatus", handleAddressTokenStatus);

    return () => {
      socket.off("queueUpdate", handleQueueUpdate);
      socket.off("tokenStatusUpdate", handleTokenStatusUpdate);
      socket.off("addressTokenStatus", handleAddressTokenStatus);
    };
  }, [
    socket,
    address,
    currentRequestId,
    processingSteps.length,
    updateLastCreatedToken,
    name,
    symbol,
    initialSupply,
    decimals,
    transactionHash,
    widgetCode,
  ]);

  useEffect(() => {
    if (socket && address) {
      socket.on("connect", () => {
        socket.emit("joinUserRoom", address);
        socket.emit("requestCurrentStatus", { userAddress: address });
      });
    }
  }, [socket, address]);

  // --------------------------
  // FETCH TBLUNT BALANCES & FEE
  // --------------------------
  const fetchBalancesAndFee = useCallback(async () => {
    if (!tbluntContract || !address || !tokenCreationManagerContract) return;
    try {
      const [tbluntBal, feeBN] = await Promise.all([
        tbluntContract.balanceOf(address),
        tokenCreationManagerContract.creationFee(),
      ]);
      setTbluntBalance(tbluntBal);
      setCreationFee(feeBN.toString());
    } catch (err) {
      console.error("Error fetching TBLUNT balances/fee:", err);
      setError("Error fetching TBLUNT balances/fee.");
      setShowErrorModal(true);
    }
  }, [tbluntContract, address, tokenCreationManagerContract]);

  useEffect(() => {
    fetchBalancesAndFee();
  }, [fetchBalancesAndFee]);

  // Optionally let parent know about the creation fee
  useEffect(() => {
    if (onSetCreationFee && creationFee) {
      const formattedFee = formatUnits(creationFee, TBLUNT_DECIMALS);
      onSetCreationFee(formattedFee);
    }
  }, [creationFee, onSetCreationFee]);

  // --------------------------
  // FETCH TBLUNT SALE PRICE
  // --------------------------
  const [salePriceETH, setSalePriceETH] = useState(null);
  useEffect(() => {
    const fetchSalePrice = async () => {
      try {
        if (!tbluntContract) throw new Error("TBLUNT contract not available.");
        const price = await tbluntContract.tokenPrice();
        setSalePriceETH(parseFloat(formatUnits(price, "ether")));
      } catch (err) {
        console.error("Error fetching TBLUNT sale price:", err);
        setError("Error fetching TBLUNT sale price.");
        setShowErrorModal(true);
      }
    };
    fetchSalePrice();
  }, [tbluntContract]);

  // --------------------------
  // FETCH ETH → USD
  // --------------------------
  const [ethPriceUSD, setEthPriceUSD] = useState(() => {
    const savedPrice = localStorage.getItem("ethPriceUSD");
    return savedPrice ? parseFloat(savedPrice) : null;
  });
  useEffect(() => {
    const fetchEthPriceUSD = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        if (!response.ok) {
          throw new Error(`CoinGecko API responded with status ${response.status}`);
        }
        const priceData = await response.json();
        const ethPrice = priceData.ethereum.usd;
        setEthPriceUSD(ethPrice);
        localStorage.setItem("ethPriceUSD", ethPrice);
      } catch (err) {
        console.error("Error fetching ETH price from CoinGecko:", err);
      }
    };
    if (ethPriceUSD === null) {
      fetchEthPriceUSD();
    }
    const retryInterval = setInterval(() => {
      if (ethPriceUSD === null) {
        fetchEthPriceUSD();
      }
    }, 60000);
    return () => clearInterval(retryInterval);
  }, [ethPriceUSD]);

  // --------------------------
  // REVIEW DATA
  // --------------------------
  const reviewItems = [
    { field: "Token Type", value: tokenType },
    { field: "Contract Name", value: contractName },
    { field: "Token Name", value: name },
    { field: "Token Symbol", value: symbol },
    { field: "Initial Supply", value: initialSupply },
    { field: "Decimals", value: decimals },
    { field: "Initial Price (ETH)", value: initialPrice },
    {
      field: "Tokens After Tax (99.99%)",
      value: `${tokensAfterTax} ${symbol}`,
    },
  ];

  // --------------------------
  // FORM VALIDATION & REVIEW
  // --------------------------
  const handleReview = () => {
    setError(null);
    setSuccess(null);
    if (!agreed) {
      setError("You must agree to the Disclaimer and Terms of Service to proceed.");
      setShowErrorModal(true);
      return;
    }
    try {
      const initialSupplyBN = parseUnits(initialSupply.toString(), decimals);
      const tokensAfterTaxBN = (initialSupplyBN * 9999n) / 10000n; // 0.01% tax
      setTokensAfterTax(formatUnits(tokensAfterTaxBN, decimals));
    } catch (err) {
      setError("Invalid input for initial supply or decimals.");
      setShowErrorModal(true);
      return;
    }
    setShowReview(true);
  };

  // --------------------------
  // CREATE TOKEN
  // --------------------------
  const handleCreateToken = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      if (!signer || !address) throw new Error("Wallet not connected.");
      if (!tbluntBalance) throw new Error("TBLUNT balance is unavailable.");
      if (!creationFee) throw new Error("Token creation fee is unavailable.");
      if (!numericChainId || isNaN(numericChainId)) {
        throw new Error("Invalid chain ID. Please connect to a valid network.");
      }
      if (!tokenCreationManagerContract) {
        throw new Error("No TBLUNT token creation manager contract found.");
      }

      // Check TBLUNT balance
      const balanceBN = BigInt(tbluntBalance.toString());
      const feeBN = BigInt(creationFee);
      if (balanceBN < feeBN) {
        throw new Error("Insufficient TBLUNT balance to create token.");
      }

      setShowProcessingModal(true);
      setCurrentStep(0);
      setLastRequestParams({
        contractName,
        name,
        symbol,
        initialSupply,
        decimals,
        initialPrice,
        tokenType,
      });

      // Step 0: Approve TBLUNT
      const approveTx = await tbluntContract.approve(selectedManagerAddress, creationFee);
      await approveTx.wait();
      setCurrentStep(1);

      // Step 1: Request token creation
      const finalSupply = initialSupply.toString();
      const initialPriceParsed = parseUnits(initialPrice.toString(), "ether");
      const createTx = await tokenCreationManagerContract.requestTokenCreation(
        name,
        symbol,
        finalSupply,
        decimals,
        initialPriceParsed.toString(),
        tokenType,
        contractName
      );
      setTransactionHash(createTx.hash);
      const receipt = await createTx.wait();

      let reqId = null;
      for (const log of receipt.logs) {
        try {
          const iface = new Interface(TokenCreationManagerAbi.abi);
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === "TokenCreationRequested") {
            reqId = parsedLog.args.requestId;
            break;
          }
        } catch (err) {
          // not this log
        }
      }
      if (!reqId) {
        throw new Error("Unable to retrieve request ID from transaction logs.");
      }

      setCurrentRequestId(reqId.toString());
      setCurrentStep(2);

      // We'll wait for the socket (tokenStatusUpdate) to confirm success or fail
    } catch (err) {
      console.error("Error creating token:", err);
      setError(`Error creating token: ${err.message}`);
      setErrorMessage(`Error creating token: ${err.message}`);
      setShowErrorModal(true);
      setLoading(false);
      setShowProcessingModal(false);
      setCurrentStep(0);
    }
  };

  // --------------------------
  // HANDLERS
  // --------------------------
  const handleCreateAnother = () => {
    // Mark the last created token as "noshow" on the backend
    if (socket && currentRequestId && address) {
      socket.emit("setDisplayState", {
        address,
        requestId: currentRequestId,
        display: "noshow",
      });
    }

    setContractName("");
    setName("");
    setSymbol("");
    setInitialSupply("");
    setDecimals(4);
    setInitialPrice("");
    setTokensAfterTax("");
    setNewTokenAddress(null);
    setTransactionHash("");
    setWidgetCode("");
    setSuccess(null);
    setError(null);
    setShowReview(false);
    setAgreed(false);
    setCurrentRequestId(null);
    setQueuePosition(null);
    setCurrentStep(0);
    setLastRequestParams(null);
    setTokenType("StandardERC20");
    clearLastCreatedToken();
  };

  const handleRetry = () => {
    if (lastRequestParams) {
      setContractName(lastRequestParams.contractName);
      setName(lastRequestParams.name);
      setSymbol(lastRequestParams.symbol);
      setInitialSupply(lastRequestParams.initialSupply);
      setDecimals(lastRequestParams.decimals);
      setInitialPrice(lastRequestParams.initialPrice);
      setTokenType(lastRequestParams.tokenType);
      setShowErrorModal(false);
      setShowReview(true);
    }
  };

  // --------------------------
  // RENDER
  // --------------------------
  return (
    <Layout className="create-token-layout mt-5 px-3">
      {!lastCreatedToken ? (
        <Content>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24}>
              <Card className="create-token-form">
                {/* Instructional Section */}
                <div className="instructional-section mb-4">
                  <Title level={4} className="toggle-title-2">
                    <InfoCircleOutlined /> How to Create Your ERC20 Token
                  </Title>
                  <Paragraph>
                    Make sure you have enough TBLUNT to pay the creation fee. After
                    confirming, the fee will be taken immediately and your token
                    creation request will be sent to the backend.
                  </Paragraph>
                </div>

                {/* Error and Success Alerts */}
                {error && (
                  <Alert message={error} type="error" showIcon className="mb-4" />
                )}
                {success && (
                  <Alert message={success} type="success" showIcon className="mb-4" />
                )}

                {/* Queue Position Alert (Optional) */}
                {currentRequestId && queuePosition && loading && (
                  <Alert
                    message={`Your token creation request is in the queue. Position: ${queuePosition}`}
                    type="info"
                    showIcon
                    className="text-center mb-4"
                  />
                )}

                {/* Main Form */}
                {!showProcessingModal && !showReview && (
                  <Form
                    onFinish={handleReview}
                    layout="vertical"
                    className="create-token-form-container"
                    style={{ maxWidth: "800px", margin: "0 auto" }}
                  >
                    {/* Token Type Selection */}
                    <Form.Item label="Token Type" required>
                      <div className="token-type-buttons">
                        <Button
                          type={tokenType === "StandardERC20" ? "primary" : "default"}
                          onClick={() => {
                            setTokenType("StandardERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "StandardERC20" ? "selected" : ""
                          }`}
                        >
                          Standard ERC20
                          <Tooltip title="Basic ERC20 token without additional functionalities.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        <Button
                          type={tokenType === "MintableERC20" ? "primary" : "default"}
                          onClick={() => {
                            setTokenType("MintableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "MintableERC20" ? "selected" : ""
                          }`}
                        >
                          Mintable ERC20
                          <Tooltip title="ERC20 token with minting capabilities.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        <Button
                          type={tokenType === "PausableERC20" ? "primary" : "default"}
                          onClick={() => {
                            setTokenType("PausableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "PausableERC20" ? "selected" : ""
                          }`}
                        >
                          Pausable ERC20
                          <Tooltip title="ERC20 token with pausable token transfers.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        <Button
                          type={
                            tokenType === "MintablePausableERC20" ? "primary" : "default"
                          }
                          onClick={() => {
                            setTokenType("MintablePausableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "MintablePausableERC20" ? "selected" : ""
                          }`}
                        >
                          Mintable &amp; Pausable ERC20
                          <Tooltip title="ERC20 token that can mint new tokens and pause transfers.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        <Button
                          type={tokenType === "CappedMintableERC20" ? "primary" : "default"}
                          onClick={() => {
                            setTokenType("CappedMintableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "CappedMintableERC20" ? "selected" : ""
                          }`}
                        >
                          Capped &amp; Mintable ERC20
                          <Tooltip title="ERC20 token with a supply cap and minting capabilities.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        <Button
                          type={
                            tokenType === "CappedMintablePausableERC20"
                              ? "primary"
                              : "default"
                          }
                          onClick={() => {
                            setTokenType("CappedMintablePausableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "CappedMintablePausableERC20" ? "selected" : ""
                          }`}
                        >
                          Capped, Mintable &amp; Pausable
                          <Tooltip title="ERC20 token with supply cap, minting, and pausing functionalities.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                      </div>
                    </Form.Item>

                    {/* Creation Fee Panel */}
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24}>
                        <TokenCreationFeeTopPanelTblunt
                          tokenCreationManagerAddress={selectedManagerAddress}
                          tokenCreationManagerAbi={TokenCreationManagerAbi.abi}
                          creationFeeDecimals={TBLUNT_DECIMALS}
                          tbluntTokenAddress={tbluntTokenAddress}
                          tbluntTokenAbi={TBLUNTAbi.abi}
                          ethPriceUSD={ethPriceUSD}
                        />
                      </Col>
                    </Row>

                    {/* Centralized Features/Description */}
                    <div className="features-section mb-4">
                      <TokenTypeFeatures tokenType={tokenType} />
                    </div>

                    {/* Form Inputs */}
                    <FactoryCreateTokenFormInputs
                      contractName={contractName}
                      setContractName={setContractName}
                      name={name}
                      setName={setName}
                      symbol={symbol}
                      setSymbol={setSymbol}
                      initialSupply={initialSupply}
                      setInitialSupply={setInitialSupply}
                      decimals={decimals}
                      setDecimals={setDecimals}
                      initialPrice={initialPrice}
                      setInitialPrice={setInitialPrice}
                      agreed={agreed}
                      setAgreed={setAgreed}
                      MIN_INITIAL_SUPPLY={MIN_INITIAL_SUPPLY}
                      MAX_INITIAL_SUPPLY={MAX_INITIAL_SUPPLY}
                      MIN_INITIAL_PRICE={MIN_INITIAL_PRICE}
                    />

                    {/* (Optional) Fee Panel again here */}
                    <TokenCreationFeeTopPanelTblunt
                      tokenCreationManagerAddress={selectedManagerAddress}
                      tokenCreationManagerAbi={TokenCreationManagerAbi.abi}
                      creationFeeDecimals={TBLUNT_DECIMALS}
                      tbluntTokenAddress={tbluntTokenAddress}
                      tbluntTokenAbi={TBLUNTAbi.abi}
                      ethPriceUSD={ethPriceUSD}
                    />

                    {/* Review Button */}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        className="submit-button"
                      >
                        Review
                      </Button>
                    </Form.Item>
                  </Form>
                )}

                {/* --- Review Modal --- */}
                {showReview && !showProcessingModal && (
                  <Modal
                    visible={showReview}
                    onCancel={() => setShowReview(false)}
                    footer={null}
                    centered
                    maskClosable={!loading}
                    width={460}
                    style={{ top: 30 }}
                    bodyStyle={{
                      backgroundColor: "#2c2c2c",
                      padding: "1rem",
                      borderRadius: "1rem",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.8)",
                    }}
                    className="review-modal"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "1rem",
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          margin: 0,
                          color: "#a5d6a7",
                          textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                          fontWeight: "bold",
                          fontSize: "1.25rem",
                        }}
                      >
                        <InfoCircleOutlined style={{ marginRight: "8px" }} />
                        Review Token Creation
                      </Title>

                      {/* Review Grid (no creation fee items here) */}
                      <div
                        style={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "0.5rem",
                        }}
                      >
                        {reviewItems.map((item, index) => {
                          const cardStyle = {
                            backgroundColor: "#343a40",
                            borderRadius: "0.5rem",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
                            padding: "0.5rem",
                            color: "#fff",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.25rem",
                            fontSize: "0.9rem",
                          };
                          const fieldStyle = {
                            color: "#a5d6a7",
                            fontWeight: "bold",
                            textShadow: "1px 1px 4px rgba(0,0,0,0.6)",
                          };
                          const valueStyle = {
                            color: "#ffffff",
                            textShadow: "0px 0px 2px rgba(255,255,255,0.1)",
                          };
                          return (
                            <div key={index} style={cardStyle}>
                              <div style={fieldStyle}>{item.field}</div>
                              <div style={valueStyle}>{item.value}</div>
                            </div>
                          );
                        })}
                      </div>

                      {/* Fee Panel in the review modal */}
                      <Row
                        gutter={[16, 16]}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      >
                        <Col xs={24}>
                          <TokenCreationFeeTopPanelTblunt
                            tokenCreationManagerAddress={selectedManagerAddress}
                            tokenCreationManagerAbi={TokenCreationManagerAbi.abi}
                            creationFeeDecimals={TBLUNT_DECIMALS}
                            tbluntTokenAddress={tbluntTokenAddress}
                            tbluntTokenAbi={TBLUNTAbi.abi}
                            ethPriceUSD={ethPriceUSD}
                          />
                        </Col>
                      </Row>

                      <Row gutter={8} style={{ width: "100%", marginTop: "0.5rem" }}>
                        <Col span={12}>
                          <Button
                            onClick={() => setShowReview(false)}
                            disabled={loading}
                            block
                            className="back-button"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            type="primary"
                            onClick={handleCreateToken}
                            disabled={
                              loading ||
                              (tbluntBalance &&
                                BigInt(tbluntBalance.toString()) < BigInt(creationFee))
                            }
                            block
                            className="confirm-button"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {loading ? (
                              <>
                                <Spin size="small" style={{ marginRight: "4px" }} />
                                Processing...
                              </>
                            ) : (
                              "Confirm"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                )}
              </Card>
            </Col>
          </Row>
        </Content>
      ) : (
        // AFTER SUCCESS: SHOW TOKEN INFO
        <Content>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card className="create-token-form">
                <Title level={3} className="text-center mb-4">
                  Token Created Successfully!
                </Title>
                <TokenInfo
                  name={lastCreatedToken.name}
                  symbol={lastCreatedToken.symbol}
                  initialSupply={lastCreatedToken.initialSupply}
                  decimals={lastCreatedToken.decimals}
                  newTokenAddress={lastCreatedToken.tokenAddress}
                  transactionHash={lastCreatedToken.transactionHash}
                  widgetCode={lastCreatedToken.widgetCode}
                  copyToClipboard={copyToClipboard}
                  chainId={numericChainId}
                />
                <Alert
                  message="You can now generate an image and update metadata to customize your sale page."
                  type="info"
                  showIcon
                  className="mb-4"
                  style={{ textAlign: "center" }}
                />
                <Button
                  type="primary"
                  onClick={handleCreateAnother}
                  block
                  className="create-another-btn"
                >
                  Create Another Token
                </Button>
              </Card>
            </Col>
          </Row>
        </Content>
      )}

      {/* Processing Modal */}
      <Modal
        visible={showProcessingModal}
        footer={null}
        closable={false}
        centered
        width={400}
        className="processing-modal"
      >
        <div className="stepper-container">
          {processingSteps.map((step, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;
            const cardStyle = {
              backgroundColor: isCompleted
                ? "#66bb6a"
                : isCurrent
                ? "#17a2b8"
                : "#343a40",
              transform: isCompleted || isCurrent ? "scale(1.05)" : "scale(1)",
              transition: "background-color 0.3s ease, transform 0.3s ease",
              border: "none",
              borderRadius: "0.5rem",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              width: "100%",
              marginBottom: "1rem",
              padding: "1rem",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            };
            const iconStyle = { fontSize: "1.5rem" };
            return (
              <div key={index} style={cardStyle}>
                {isCompleted ? (
                  <CheckCircleOutlined style={iconStyle} />
                ) : isCurrent ? (
                  <Spin />
                ) : (
                  <InfoCircleOutlined style={iconStyle} />
                )}
                <span>{step}</span>
              </div>
            );
          })}

          {currentStep === 2 && (
            <div style={{ marginTop: "1rem", textAlign: "center", color: "#fff" }}>
              <Spin /> Creating Token...
            </div>
          )}
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        visible={showErrorModal}
        onCancel={() => setShowErrorModal(false)}
        footer={[
          <Button
            key="close"
            onClick={() => setShowErrorModal(false)}
            className="close-button"
          >
            Close
          </Button>,
          lastRequestParams && (
            <Button
              key="retry"
              type="primary"
              onClick={handleRetry}
              disabled={loading}
              className="retry-button"
            >
              Retry
            </Button>
          ),
        ]}
        centered
        className="error-modal"
      >
        <Alert
          message={errorMessage || "An unexpected error occurred."}
          type="error"
          showIcon
        />
      </Modal>
    </Layout>
  );
};

export default CreateTokenFactory;
