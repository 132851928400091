/* eslint-env es2020 */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Contract, parseUnits, formatUnits, Interface } from "ethers";
import { useWeb3 } from "../Web3Context";
import TokenCreationManagerAbi from "../abis/TokenFactoryCreationManagerAbi.json";
import {
  Card,
  Form,
  Button,
  Alert,
  Spin,
  Modal,
  Layout,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import TokenCreationFeeTopPanel from "./TokenCreationFeeTopPanel";
import TokenInfo from "./TokenInfo";
import { copyToClipboard } from "../utils/clipboard";
import "./CreateTokenFactory.scss";
import { io } from "socket.io-client";

// Centralized features
import TokenTypeFeatures from "./TokenTypeFeatures";
import FactoryCreateTokenFormInputs from "./FactoryCreateTokenFormInputs";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

// Validation constants
const MIN_INITIAL_PRICE = "0.000000000000000001";
const MAX_INITIAL_PRICE = "100000000000000000";
const MIN_INITIAL_SUPPLY = "1";
const MAX_INITIAL_SUPPLY = "10000000000000000000000";

// Manager addresses by tokenType
const managerAddressByTokenType = {
  StandardERC20:
    process.env.REACT_APP_ETH_TOKEN_FACTORY_CREATION_MANAGER_ADDRESS,
  MintableERC20:
    process.env.REACT_APP_ETH_MINTABLE_TOKEN_FACTORY_CREATION_MANAGER,
  PausableERC20:
    process.env.REACT_APP_ETH_PAUSABLE_TOKEN_FACTORY_CREATION_MANAGER,
  MintablePausableERC20:
    process.env.REACT_APP_ETH_MINTABLE_PAUSABLE_TOKEN_FACTORY_CREATION_MANAGER,
  CappedMintableERC20:
    process.env.REACT_APP_ETH_CAPPED_MINTABLE_TOKEN_FACTORY_CREATION_MANAGER,
  CappedMintablePausableERC20:
    process.env.REACT_APP_ETH_CAPPED_MINTABLE_PAUSABLE_TOKEN_FACTORY_CREATION_MANAGER,
};

const CreateTokenFactoryEth = () => {
  const {
    signer,
    address,
    numericChainId,
    error: web3Error,
    lastCreatedToken,
    updateLastCreatedToken,
    clearLastCreatedToken,
  } = useWeb3();

  // -------------------------
  //  State
  // -------------------------
  const [tokenType, setTokenType] = useState("StandardERC20");
  const [contractName, setContractName] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [decimals, setDecimals] = useState(4);
  const [initialPrice, setInitialPrice] = useState("");
  const [creationFee, setCreationFee] = useState("0"); // Fee in ETH

  const [error, setError] = useState(web3Error);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);

  const [newTokenAddress, setNewTokenAddress] = useState(
    lastCreatedToken?.tokenAddress || null
  );
  const [transactionHash, setTransactionHash] = useState(
    lastCreatedToken?.transactionHash || ""
  );
  const [widgetCode, setWidgetCode] = useState(lastCreatedToken?.widgetCode || "");
  const [tokensAfterTax, setTokensAfterTax] = useState("");
  const [agreed, setAgreed] = useState(false);

  // We'll still track the current request ID
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [queuePosition, setQueuePosition] = useState(null);

  // For the step modal
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [processingSteps] = useState([
    "Requesting Token Creation",
    "Compiling...",
    "Deploying...",
    "Finalizing...",
  ]);
  const [currentStep, setCurrentStep] = useState(0);

  // Error modal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Retry
  const [lastRequestParams, setLastRequestParams] = useState(null);

  // Socket
  const [socket, setSocket] = useState(null);

  // Manager address
  const selectedManagerAddress = useMemo(() => {
    return managerAddressByTokenType[tokenType] || "";
  }, [tokenType]);

  // Manager contract
  const selectedManagerContract = useMemo(() => {
    if (!signer || !selectedManagerAddress) return null;
    return new Contract(
      selectedManagerAddress,
      TokenCreationManagerAbi.abi,
      signer
    );
  }, [signer, selectedManagerAddress]);

  // -------------------------
  //  SOCKET LOGIC
  // -------------------------
  useEffect(() => {
    // Connect to your backend
    const newSocket = io("https://testnet.turtleblunt.com", {
      path: "/tokenfactory/socket.io",
      reconnectionAttempts: 50,
      reconnectionDelay: 3000,
      transports: ["websocket"],
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (!socket || !address) return;

    // Join user-specific room for status updates
    socket.emit("joinUserRoom", address);

    const handleTokenStatusUpdate = (data) => {
      if (!currentRequestId) return;
      if (data.tokenId !== currentRequestId.toString()) return;

      console.log("Received tokenStatusUpdate (ETH page):", data);
      if (data.status === "successful") {
        setSuccess("Token created successfully!");
        setNewTokenAddress(data.tokenAddress);
        setShowProcessingModal(false);
        setShowReview(false);
        setLoading(false);
        setCurrentStep(processingSteps.length);

        // Update global context
        updateLastCreatedToken({
          tokenAddress: data.tokenAddress,
          name,
          symbol,
          initialSupply,
          decimals,
          transactionHash: data.transactionHash || transactionHash,
          widgetCode,
        });
      } else if (data.status === "failed") {
        setError(data.error || "Token creation failed.");
        setErrorMessage(data.error || "Token creation failed.");
        setShowErrorModal(true);
        setShowProcessingModal(false);
        setLoading(false);
        setCurrentStep(0);
      }
    };

    const handleAddressTokenStatus = (data) => {
      if (!data || !data.status) return;
      console.log("Received addressTokenStatus (ETH page):", data);

      if (data.status === "successful") {
        setSuccess("Token created successfully!");
        setNewTokenAddress(data.tokenAddress);
        setShowProcessingModal(false);
        setShowReview(false);
        setLoading(false);
        setCurrentStep(processingSteps.length);

        updateLastCreatedToken({
          tokenAddress: data.tokenAddress,
          name,
          symbol,
          initialSupply,
          decimals,
          transactionHash: data.transactionHash || transactionHash,
          widgetCode,
        });
      } else if (data.status === "failed") {
        setError(data.error || "Token creation failed.");
        setErrorMessage(data.error || "Token creation failed.");
        setShowErrorModal(true);
        setShowProcessingModal(false);
        setLoading(false);
        setCurrentStep(0);
      }
    };

    const handleQueueUpdate = (data) => {
      if (!currentRequestId) return;
      if (!data?.queue) return;
      const found = data.queue.find(
        (q) => q.requestId === currentRequestId.toString()
      );
      setQueuePosition(found ? found.position : null);
    };

    // Listen to events
    socket.on("tokenStatusUpdate", handleTokenStatusUpdate);
    socket.on("addressTokenStatus", handleAddressTokenStatus);
    socket.on("queueUpdate", handleQueueUpdate);

    // On reconnect, request current status
    socket.on("connect", () => {
      socket.emit("joinUserRoom", address);
      socket.emit("requestCurrentStatus", { userAddress: address });
    });

    return () => {
      socket.off("tokenStatusUpdate", handleTokenStatusUpdate);
      socket.off("addressTokenStatus", handleAddressTokenStatus);
      socket.off("queueUpdate", handleQueueUpdate);
      socket.off("connect");
    };
  }, [
    socket,
    address,
    currentRequestId,
    name,
    symbol,
    initialSupply,
    decimals,
    transactionHash,
    widgetCode,
    processingSteps.length,
    updateLastCreatedToken,
  ]);

  // -------------------------
  //  FORM VALIDATION & REVIEW
  // -------------------------
  const handleReview = () => {
    setError(null);
    setSuccess(null);
    if (!agreed) {
      setError("You must agree to the Disclaimer and Terms to proceed.");
      setErrorMessage("You must agree to the Disclaimer and Terms to proceed.");
      setShowErrorModal(true);
      return;
    }
    try {
      // compute taxed supply: 0.01% tax
      const supplyBN = parseUnits(initialSupply.toString(), decimals);
      const taxedBN = (supplyBN * 9999n) / 10000n;
      setTokensAfterTax(formatUnits(taxedBN, decimals));
    } catch (err) {
      setError("Invalid initial supply or decimals.");
      setErrorMessage("Invalid initial supply or decimals.");
      setShowErrorModal(true);
      return;
    }
    setShowReview(true);
  };

  // -------------------------
  //  CREATE TOKEN
  // -------------------------
  const handleCreateToken = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      if (!signer || !address) throw new Error("Wallet not connected.");
      if (!numericChainId || isNaN(numericChainId)) {
        throw new Error("Invalid chain ID. Connect to a valid network.");
      }
      if (!selectedManagerContract) {
        throw new Error("No TokenCreationManager contract found.");
      }

      setShowProcessingModal(true);
      setCurrentStep(0);

      setLastRequestParams({
        contractName,
        name,
        symbol,
        initialSupply,
        decimals,
        initialPrice,
        tokenType,
      });

      const finalSupply = initialSupply.toString();
      const initialPriceParsed = parseUnits(initialPrice.toString(), "ether");

      // The creation fee in ETH is passed as msg.value
      const tx = await selectedManagerContract.requestTokenCreation(
        name,
        symbol,
        finalSupply,
        decimals,
        initialPriceParsed.toString(),
        tokenType,
        contractName,
        {
          value: parseUnits(creationFee, "ether"),
        }
      );
      setTransactionHash(tx.hash);
      const receipt = await tx.wait();

      // parse logs to find requestId
      let reqId = null;
      for (const log of receipt.logs) {
        try {
          const iface = new Interface(TokenCreationManagerAbi.abi);
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === "TokenCreationRequested") {
            reqId = parsedLog.args.requestId;
            break;
          }
        } catch (err) {
          // not this log
        }
      }
      if (!reqId) {
        throw new Error("Unable to retrieve request ID from logs.");
      }
      setCurrentRequestId(reqId.toString());
      setCurrentStep(1);
      // We'll rely on the socket events to confirm success/failure
    } catch (err) {
      console.error("Error creating token:", err);
      setErrorMessage(`Error creating token: ${err.message}`);
      setError(`Error creating token: ${err.message}`);
      setShowErrorModal(true);
      setLoading(false);
      setShowProcessingModal(false);
      setCurrentStep(0);
    }
  };

  // -------------------------
  //  HANDLE CLOSE ERROR => noshow
  // -------------------------
  const handleCloseErrorModal = () => {
    // If we have a valid request (after logs) we can set display=noshow
    if (socket && currentRequestId && address) {
      console.log("setDisplayState => noshow on error close...");
      socket.emit("setDisplayState", {
        address,
        requestId: currentRequestId,
        display: "noshow",
      });
    }
    setShowErrorModal(false);
    setError(null);
    setErrorMessage("");
  };

  // -------------------------
  //  CREATE ANOTHER => noshow
  // -------------------------
  const handleCreateAnother = () => {
    // Mark last request as noshow if we have one
    if (socket && currentRequestId && address) {
      console.log("setDisplayState => noshow on createAnother...");
      socket.emit("setDisplayState", {
        address,
        requestId: currentRequestId,
        display: "noshow",
      });
    }

    // Reset UI
    setContractName("");
    setName("");
    setSymbol("");
    setInitialSupply("");
    setDecimals(4);
    setInitialPrice("");
    setTokensAfterTax("");
    setNewTokenAddress(null);
    setTransactionHash("");
    setWidgetCode("");
    setSuccess(null);
    setError(null);
    setShowReview(false);
    setAgreed(false);
    setCurrentRequestId(null);
    setQueuePosition(null);
    setCurrentStep(0);
    setLastRequestParams(null);
    setTokenType("StandardERC20");
    setCreationFee("0");
    clearLastCreatedToken();
  };

  // -------------------------
  //  RETRY
  // -------------------------
  const handleRetry = () => {
    if (lastRequestParams) {
      setContractName(lastRequestParams.contractName);
      setName(lastRequestParams.name);
      setSymbol(lastRequestParams.symbol);
      setInitialSupply(lastRequestParams.initialSupply);
      setDecimals(lastRequestParams.decimals);
      setInitialPrice(lastRequestParams.initialPrice);
      setTokenType(lastRequestParams.tokenType);
      setShowErrorModal(false);
      setShowReview(true);
    }
  };

  // -------------------------
  //  REVIEW ITEMS
  // -------------------------
  const reviewItems = [
    { field: "Token Type", value: tokenType },
    { field: "Contract Name", value: contractName },
    { field: "Token Name", value: name },
    { field: "Token Symbol", value: symbol },
    { field: "Initial Supply", value: initialSupply },
    { field: "Decimals", value: decimals },
    { field: "Initial Price (ETH)", value: initialPrice },
    {
      field: "Tokens After Tax (99.99%)",
      value: `${tokensAfterTax} ${symbol}`,
    },
  ];

  // -------------------------
  //  RENDER
  // -------------------------
  return (
    <Layout className="create-token-layout mt-5 px-3">
      {/* If no lastCreatedToken => show form. Otherwise => Token info */}
      {!lastCreatedToken ? (
        <Content>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card className="create-token-form">
                {/* Instructional Section */}
                <div className="instructional-section mb-4">
                  <Title level={4} className="toggle-title-2">
                    <InfoCircleOutlined /> How to Create Your ERC20 Token
                  </Title>
                  <Paragraph>
                    Make sure you have enough ETH to pay the creation fee. Once
                    confirmed, the fee will be taken immediately and your token
                    creation request will be sent to the backend.
                  </Paragraph>
                </div>

                {/* Error/Success Alerts */}
                {error && (
                  <Alert message={error} type="error" showIcon className="mb-4" />
                )}
                {success && (
                  <Alert message={success} type="success" showIcon className="mb-4" />
                )}

                {/* Queue Position Alert */}
                {currentRequestId && queuePosition && loading && (
                  <Alert
                    message={`Your token creation request is in the queue. Position: ${queuePosition}`}
                    type="info"
                    showIcon
                    className="text-center mb-4"
                  />
                )}

                {/* Form */}
                {!showProcessingModal && !showReview && (
                  <Form
                    onFinish={handleReview}
                    layout="vertical"
                    className="create-token-form-container"
                    style={{ maxWidth: "800px", margin: "0 auto" }}
                  >
                    {/* Token Type Selection */}
                    <Form.Item label="Token Type" required>
                      <div className="token-type-buttons">
                        {/* Standard */}
                        <Button
                          type={
                            tokenType === "StandardERC20" ? "primary" : "default"
                          }
                          onClick={() => {
                            setTokenType("StandardERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "StandardERC20" ? "selected" : ""
                          }`}
                        >
                          Standard ERC20
                          <Tooltip title="Basic ERC20 token with standard functionalities.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        {/* Mintable */}
                        <Button
                          type={tokenType === "MintableERC20" ? "primary" : "default"}
                          onClick={() => {
                            setTokenType("MintableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "MintableERC20" ? "selected" : ""
                          }`}
                        >
                          Mintable ERC20
                          <Tooltip title="ERC20 token with minting capabilities.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        {/* Pausable */}
                        <Button
                          type={tokenType === "PausableERC20" ? "primary" : "default"}
                          onClick={() => {
                            setTokenType("PausableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "PausableERC20" ? "selected" : ""
                          }`}
                        >
                          Pausable ERC20
                          <Tooltip title="ERC20 token with pausable transfers.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        {/* Mintable + Pausable */}
                        <Button
                          type={
                            tokenType === "MintablePausableERC20" ? "primary" : "default"
                          }
                          onClick={() => {
                            setTokenType("MintablePausableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "MintablePausableERC20" ? "selected" : ""
                          }`}
                        >
                          Mintable &amp; Pausable
                          <Tooltip title="ERC20 token that can mint and pause.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        {/* Capped + Mintable */}
                        <Button
                          type={
                            tokenType === "CappedMintableERC20" ? "primary" : "default"
                          }
                          onClick={() => {
                            setTokenType("CappedMintableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "CappedMintableERC20" ? "selected" : ""
                          }`}
                        >
                          Capped &amp; Mintable
                          <Tooltip title="ERC20 token with supply cap + minting.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                        {/* Capped + Mintable + Pausable */}
                        <Button
                          type={
                            tokenType === "CappedMintablePausableERC20"
                              ? "primary"
                              : "default"
                          }
                          onClick={() => {
                            setTokenType("CappedMintablePausableERC20");
                            clearLastCreatedToken();
                            setNewTokenAddress(null);
                            setTransactionHash("");
                            setWidgetCode("");
                            setSuccess(null);
                            setError(null);
                          }}
                          className={`token-type-button ${
                            tokenType === "CappedMintablePausableERC20" ? "selected" : ""
                          }`}
                        >
                          Capped, Mintable &amp; Pausable
                          <Tooltip title="ERC20 token with cap, minting, and pausing.">
                            <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                          </Tooltip>
                        </Button>
                      </div>
                    </Form.Item>

                    {/* Creation Fee (ETH) */}
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24}>
                        <TokenCreationFeeTopPanel
                          paymentMethod="ETH"
                          tokenCreationManagerAddress={selectedManagerAddress}
                          onFeeChange={setCreationFee}
                        />
                      </Col>
                    </Row>

                    {/* Centralized features & description */}
                    <div className="features-section mb-4">
                      <TokenTypeFeatures tokenType={tokenType} />
                    </div>

                    {/* Form Inputs */}
                    <FactoryCreateTokenFormInputs
                      contractName={contractName}
                      setContractName={setContractName}
                      name={name}
                      setName={setName}
                      symbol={symbol}
                      setSymbol={setSymbol}
                      initialSupply={initialSupply}
                      setInitialSupply={setInitialSupply}
                      decimals={decimals}
                      setDecimals={setDecimals}
                      initialPrice={initialPrice}
                      setInitialPrice={setInitialPrice}
                      agreed={agreed}
                      setAgreed={setAgreed}
                      MIN_INITIAL_SUPPLY={MIN_INITIAL_SUPPLY}
                      MAX_INITIAL_SUPPLY={MAX_INITIAL_SUPPLY}
                      MIN_INITIAL_PRICE={MIN_INITIAL_PRICE}
                    />

                    {/* Fee Panel again (optional) */}
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24}>
                        <TokenCreationFeeTopPanel
                          paymentMethod="ETH"
                          tokenCreationManagerAddress={selectedManagerAddress}
                          onFeeChange={setCreationFee}
                        />
                      </Col>
                    </Row>

                    {/* Review Button */}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        className="submit-button"
                      >
                        Review
                      </Button>
                    </Form.Item>
                  </Form>
                )}

                {/* Review Modal */}
                {showReview && !showProcessingModal && (
                  <Modal
                    visible={showReview}
                    onCancel={() => setShowReview(false)}
                    footer={null}
                    centered
                    maskClosable={!loading}
                    width={460}
                    style={{ top: 30 }}
                    bodyStyle={{
                      backgroundColor: "#2c2c2c",
                      padding: "1rem",
                      borderRadius: "1rem",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.8)",
                    }}
                    className="review-modal"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "1rem",
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          margin: 0,
                          color: "#a5d6a7",
                          textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                          fontWeight: "bold",
                          fontSize: "1.25rem",
                        }}
                      >
                        <InfoCircleOutlined style={{ marginRight: "8px" }} />
                        Review Token Creation
                      </Title>

                      {/* Grid of review items */}
                      <div
                        style={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "0.5rem",
                        }}
                      >
                        {reviewItems.map((item, idx) => {
                          const cardStyle = {
                            backgroundColor: "#343a40",
                            borderRadius: "0.5rem",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
                            padding: "0.5rem",
                            color: "#fff",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.25rem",
                            fontSize: "0.9rem",
                          };
                          const fieldStyle = {
                            color: "#a5d6a7",
                            fontWeight: "bold",
                            textShadow: "1px 1px 4px rgba(0,0,0,0.6)",
                          };
                          const valueStyle = {
                            color: "#ffffff",
                            textShadow: "0px 0px 2px rgba(255,255,255,0.1)",
                          };
                          return (
                            <div key={idx} style={cardStyle}>
                              <div style={fieldStyle}>{item.field}</div>
                              <div style={valueStyle}>{item.value}</div>
                            </div>
                          );
                        })}
                      </div>

                      {/* Fee Panel in the review modal */}
                      <TokenCreationFeeTopPanel
                        paymentMethod="ETH"
                        tokenCreationManagerAddress={selectedManagerAddress}
                        onFeeChange={() => {}}
                      />

                      {/* Taxed Supply Alert */}
                      <Alert
                        message={`Taxed Supply: ${tokensAfterTax} ${symbol}`}
                        description={`A 0.01% tax reduces your supply from ${initialSupply} to ${tokensAfterTax}.`}
                        type="info"
                        showIcon
                        style={{
                          width: "100%",
                          fontSize: "0.85rem",
                          textAlign: "left",
                        }}
                      />

                      {/* Buttons */}
                      <Row gutter={8} style={{ width: "100%", marginTop: "0.5rem" }}>
                        <Col span={12}>
                          <Button
                            onClick={() => setShowReview(false)}
                            disabled={loading}
                            block
                            className="back-button"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            type="primary"
                            onClick={handleCreateToken}
                            disabled={loading}
                            block
                            className="confirm-button"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {loading ? (
                              <>
                                <Spin size="small" style={{ marginRight: "4px" }} />
                                Processing...
                              </>
                            ) : (
                              "Confirm and Create"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                )}
              </Card>
            </Col>
          </Row>
        </Content>
      ) : (
        // AFTER SUCCESS: SHOW TOKEN INFO
        <Content>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card className="create-token-form">
                <Title level={3} className="text-center mb-4">
                  Token Created Successfully!
                </Title>
                <TokenInfo
                  name={lastCreatedToken.name}
                  symbol={lastCreatedToken.symbol}
                  initialSupply={lastCreatedToken.initialSupply}
                  decimals={lastCreatedToken.decimals}
                  newTokenAddress={lastCreatedToken.tokenAddress}
                  transactionHash={lastCreatedToken.transactionHash}
                  widgetCode={lastCreatedToken.widgetCode}
                  copyToClipboard={copyToClipboard}
                  chainId={numericChainId}
                />
                <Alert
                  message="You can now generate an image and update metadata to customize your sale page."
                  type="info"
                  showIcon
                  className="mb-4"
                  style={{ textAlign: "center" }}
                />
                <Button
                  type="primary"
                  onClick={handleCreateAnother}
                  block
                  className="create-another-btn"
                >
                  Create Another Token
                </Button>
              </Card>
            </Col>
          </Row>
        </Content>
      )}

      {/* Processing Modal */}
      <Modal
        visible={showProcessingModal}
        footer={null}
        closable={false}
        centered
        width={400}
        className="processing-modal"
      >
        <div className="stepper-container">
          {processingSteps.map((step, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;
            return (
              <div
                key={index}
                style={{
                  backgroundColor: isCompleted
                    ? "#66bb6a"
                    : isCurrent
                    ? "#17a2b8"
                    : "#343a40",
                  transform: isCompleted || isCurrent ? "scale(1.05)" : "scale(1)",
                  transition: "background-color 0.3s ease, transform 0.3s ease",
                  border: "none",
                  borderRadius: "0.5rem",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  width: "100%",
                  marginBottom: "1rem",
                  padding: "1rem",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {isCompleted ? (
                  <CheckCircleOutlined style={{ fontSize: "1.5rem" }} />
                ) : isCurrent ? (
                  <Spin />
                ) : (
                  <InfoCircleOutlined style={{ fontSize: "1.5rem" }} />
                )}
                <span>{step}</span>
              </div>
            );
          })}
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal
        visible={showErrorModal}
        onCancel={handleCloseErrorModal}
        footer={[
          <Button
            key="close"
            onClick={handleCloseErrorModal}
            className="close-button"
          >
            Close
          </Button>,
          lastRequestParams && (
            <Button
              key="retry"
              type="primary"
              onClick={handleRetry}
              disabled={loading}
              className="retry-button"
            >
              Retry
            </Button>
          ),
        ]}
        centered
        className="error-modal"
      >
        <Alert
          message={errorMessage || "An unexpected error occurred."}
          type="error"
          showIcon
        />
      </Modal>
    </Layout>
  );
};

export default CreateTokenFactoryEth;
