// src/pages/TermsOfUse.js

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './LegalPages.scss';

const TermsOfUse = () => {
  return (
    <Container className="legal-container my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">Terms of Use</h1>
              <p><strong>Effective Date:</strong> December 23rd, 2024</p>

              <h2 className="mt-5">1. Eligibility</h2>
              <p>You must be of legal age in your jurisdiction to use this site. By using our site, you represent that you meet this requirement.</p>

              <h2>2. Purpose of the Site</h2>
              <p>Our site provides an interface to create and manage ERC20 tokens using TBLUNT tokens as credits. The site is provided for informational and utility purposes only and does not constitute financial advice.</p>

              <h2>3. Use of TBLUNT Tokens</h2>
              <p>TBLUNT tokens serve as credits within our platform. They are not investments, and their value may be purely utility-driven.</p>

              <h2>4. No Financial Advice</h2>
              <p>All features and content are provided “as is.” We do not offer financial, legal, or investment advice. Conduct your own research or consult a professional before making any decisions.</p>

              <h2>5. Intellectual Property</h2>
              <p>All content on the site is our property or used under license. You may not use it without permission. Any tokens you create are your responsibility; ensure you have the right to use any included content.</p>

              <h2>6. User Conduct</h2>
              <p>You agree not to use the site for unlawful or malicious activity and not to disrupt the site’s operation or infrastructure.</p>

              <h2>7. Third-Party Links</h2>
              <p>The site may contain links to external sites. We do not control or endorse these sites and are not responsible for their content.</p>

              <h2>8. No Warranty</h2>
              <p>We provide the site and its services "as is" and "as available," without any warranties. We do not guarantee error-free, secure, or uninterrupted operation.</p>

              <h2>9. Limitation of Liability</h2>
              <p>To the fullest extent permitted by law, we are not liable for indirect or consequential damages arising from your use of the site.</p>

              <h2>10. Indemnification</h2>
              <p>You agree to indemnify us from any claims arising from your use of the site or violation of these Terms.</p>

              <h2>11. Changes to Terms</h2>
              <p>We may update these Terms over time. Continued use of the site indicates acceptance of the updated Terms.</p>

              <h2>12. Governing Law and Jurisdiction</h2>
              <p>These Terms are governed by the laws of the United States of America, and disputes shall be resolved in the courts of the United States of America.</p>

              <h2>13. Contact Us</h2>
              <p>If you have questions about these Terms, please contact us at:</p>
              <ul>
                <li><strong>Email:</strong> <a href="mailto:contact@turtleblunt.com" className="text-info">contact@turtleblunt.com</a></li>
                <li><strong>Contact Page:</strong> <a href="https://turtleblunt.com/contact" target="_blank" rel="noopener noreferrer" className="text-info">https://turtleblunt.com/contact</a></li>
              </ul>

              <p className="mt-4">By using the site, you acknowledge that you have read, understood, and agree to these Terms of Use.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfUse;
