/********************************************************************
 * src/components/ListingPage/ListingPage.js
 ********************************************************************/

import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { Layout, Row, Col, Spin, Input, Select, Typography, Card } from 'antd';

import { Web3Context } from '../Web3Context';
import TokenCard from './TokenCard';
import { formatNumber } from '../utils/format';
import styles from './ListingPage.module.scss';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ListingPage = () => {
  // 1) Token data from the socket
  const [tokens, setTokens] = useState([]);
  // 2) Loading spinner
  const [loading, setLoading] = useState(true);

  // 3) Search and sort states
  const [searchQuery, setSearchQuery] = useState('');
  const [sortCriteria, setSortCriteria] = useState('name-asc');

  // Adjust if needed
  const serverBaseUrl = 'https://testnet.turtleblunt.com';

  // If you need them, from Web3Context
  const { provider, signer, connected } = useContext(Web3Context);

  // Explorer link generator
  const getExplorerUrl = useCallback(
    (address) => `https://basescan.org/address/${address}`,
    []
  );

  /**
   * Fetch an image from token.metaUrl (if any) or use a default placeholder.
   */
  const fetchTokenImage = async (token) => {
    if (!token.metaUrl) {
      return '/images/default-token.png';
    }
    try {
      const resp = await fetch(token.metaUrl);
      if (!resp.ok) throw new Error('Bad metadata response');
      const meta = await resp.json();
      return meta.image || '/images/default-token.png';
    } catch (err) {
      console.error('fetchTokenImage error:', err);
      return '/images/default-token.png';
    }
  };

  /**
   * Enrich tokens with imageUrl + formatted totalSupply.
   */
  const fetchAllTokenMetadata = async (rawTokens) => {
    return Promise.all(
      rawTokens.map(async (t) => {
        const imageUrl = await fetchTokenImage(t);
        return {
          ...t,
          totalSupply: formatNumber(t.totalSupply),
          imageUrl,
        };
      })
    );
  };

  /**
   * Connect to the /tokeninfo Socket.IO namespace, fetch data, handle updates.
   */
  useEffect(() => {
    const socket = io(`${serverBaseUrl}/tokeninfo`, {
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('[ListingPage] Connected to /tokeninfo');
      setLoading(true);
      socket.emit('requestInitialData');
    });

    socket.on('initialData', async (data) => {
      console.log('[ListingPage] initialData:', data);
      const tokenArray = data?.testnet || [];
      try {
        const enriched = await fetchAllTokenMetadata(tokenArray);
        setTokens(enriched);
      } catch (err) {
        console.error('Error fetching metadata in initialData:', err);
        setTokens(tokenArray);
      }
      setLoading(false);
    });

    socket.on('tokenUpdate', async (data) => {
      console.log('[ListingPage] tokenUpdate:', data);
      const tokenArray = data?.testnet || [];
      try {
        const enriched = await fetchAllTokenMetadata(tokenArray);
        setTokens(enriched);
      } catch (err) {
        console.error('Error fetching metadata in tokenUpdate:', err);
        setTokens(tokenArray);
      }
    });

    socket.on('disconnect', () => {
      console.log('[ListingPage] Disconnected from /tokeninfo');
    });

    socket.io.on('error', (error) => {
      console.error('[ListingPage] socket.io error:', error);
      toast.error('Connection error with token server.', {
        toastId: 'socket-connection-error',
      });
      setLoading(false);
    });

    return () => {
      socket.disconnect();
    };
  }, [serverBaseUrl]);

  /**
   * Filter & sort tokens in one step:
   *  1) Filter by searchQuery in t.name
   *  2) Sort by name (asc/desc) or creationTimestampMs (asc/desc)
   */
  const filteredAndSortedTokens = useMemo(() => {
    // 1) Filter
    const filtered = tokens.filter((t) =>
      t.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // 2) Sort
    if (sortCriteria.startsWith('name')) {
      filtered.sort((a, b) => {
        const compare = a.name.localeCompare(b.name);
        return sortCriteria === 'name-asc' ? compare : -compare;
      });
    } else if (sortCriteria.startsWith('date')) {
      // Use numeric timestamp in ms
      filtered.sort((a, b) => {
        // creationTimestampMs might be missing or undefined, so fallback to 0
        const aMs = a.creationTimestampMs || 0;
        const bMs = b.creationTimestampMs || 0;
        return sortCriteria === 'date-asc' ? aMs - bMs : bMs - aMs;
      });
    }
    return filtered;
  }, [tokens, searchQuery, sortCriteria]);

  return (
    <Layout className={styles.listingContainer}>
      <Content
        className={styles.content}
        style={{ paddingTop: 100 /* push below any fixed menu if needed */ }}
      >
        <Card
          style={{
            marginBottom: 20,
            backgroundColor: '#2c2c2c',
            borderRadius: 8,
            position: 'relative',
            zIndex: 999,
          }}
        >
          <div className={styles.controls}>
            <Input
              placeholder="Search by name..."
              className={styles.formControl}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
            />
            <Select
              value={sortCriteria}
              onChange={(value) => setSortCriteria(value)}
              className={styles.formSelect}
            >
              <Option value="name-asc">Name (A-Z)</Option>
              <Option value="name-desc">Name (Z-A)</Option>
              <Option value="date-asc">Creation Date (Oldest)</Option>
              <Option value="date-desc">Creation Date (Newest)</Option>
            </Select>
          </div>
        </Card>

        {loading && tokens.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Spin size="large" tip="Loading tokens..." />
          </div>
        ) : (
          <Row gutter={[16, 16]} justify="center">
            {filteredAndSortedTokens.length > 0 ? (
              filteredAndSortedTokens.map((token) => (
                <Col
                  key={token.address}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <TokenCard token={token} getExplorerUrl={getExplorerUrl} />
                </Col>
              ))
            ) : (
              <Col span={24} style={{ textAlign: 'center', marginTop: '2rem' }}>
                <Title level={4} style={{ color: '#fff' }}>
                  No tokens found.
                </Title>
              </Col>
            )}
          </Row>
        )}
      </Content>
    </Layout>
  );
};

export default ListingPage;
