// src/contexts/ConsentContext.js

import React, { createContext, useState, useEffect } from 'react';

export const ConsentContext = createContext();

export const ConsentProvider = ({ children }) => {
  const [hasConsented, setHasConsented] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('userCookieConsent');
    if (consent === 'true') {
      setHasConsented(true);
    }
  }, []);

  const acceptConsent = () => {
    localStorage.setItem('userCookieConsent', 'true');
    setHasConsented(true);
  };

  const declineConsent = () => {
    localStorage.setItem('userCookieConsent', 'false');
    setHasConsented(false);
  };

  return (
    <ConsentContext.Provider value={{ hasConsented, acceptConsent, declineConsent }}>
      {children}
    </ConsentContext.Provider>
  );
};
