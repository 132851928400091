// src/components/TokenTypeFeaturesStandard.js

import React from "react";
import PropTypes from "prop-types"; // For prop type validation
import { Card, List, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./TokenTypeFeatures.scss";

const { Title, Paragraph } = Typography;

/**
 * Updated base features with additional details.
 * Same exact lines as the "perfect" version, but you have extra items
 * like 'Interact seamlessly...' and 'Metadata Updates' in your original code.
 * We'll keep them as well, so your panel remains consistent.
 */
const BASE_FEATURES = [
  "ERC20 Standard",
  "Permit (EIP-2612)",
  "Ownable",
  "Reentrancy Guard",
  "Burnable",
  "Set Token Price (Configured in Interactor Page for Presale)",
  "Metadata URL (Editable in Interactor Page)",
  "Interact seamlessly with your token using our intuitive interface.",
  "Metadata Updates",
  "Free AI-Generated Images",
  "Full Contract Ownership",
  "No Post-Creation Contracts",
  "Sales Page Integration Widget",
  "Customer Support",
];

/**
 * Feature details, copied from the "perfect" version,
 * plus any references from your original code (like "Manual Pausing/Unpausing").
 */
const FEATURE_DETAILS = {
  "Set Token Price (Configured in Interactor Page for Presale)": (
    <span>
      Set Token Price <em>(Configured in Interactor Page for Presale)</em>
    </span>
  ),
  "Metadata URL (Editable in Interactor Page)": (
    <span>
      Metadata URL <em>(Editable in Interactor Page)</em>
    </span>
  ),
  "Maximum Token Supply Enforcement": (
    <span>
      Maximum Token Supply Enforcement{" "}
      <em>(Cap set post token creation in Interactor Page, can only be set once)</em>
    </span>
  ),
  "Manual Pausing/Unpausing": (
    <span>
      Manual Pausing/Unpausing <em>(No scheduling, controlled via Interactor Page)</em>
    </span>
  ),
  "Advanced Minting Controls": (
    <span>
      Advanced Minting Controls <em>(Owner can configure minting behavior via Interactor Page)</em>
    </span>
  ),
  "Enhanced Security Controls": (
    <span>
      Enhanced Security Controls <em>(Additional checks and guardrails for token operations)</em>
    </span>
  ),
  "Flexible Minting Options": (
    <span>
      Flexible Minting Options <em>(No cap; owner can always mint more tokens)</em>
    </span>
  ),
  "Mintable (No cap; owner can always mint more tokens)": (
    <span>
      Mintable <em>(No cap; owner can always mint more tokens)</em>
    </span>
  ),
  "Mintable (Owner can mint until cap is reached)": (
    <span>
      Mintable <em>(Owner can mint until the cap is reached)</em>
    </span>
  ),
};

/**
 * Token types for this alternate panel:
 *   - standard
 *   - mintablePausable
 *   - pausable
 *   - mintable
 *   - cappedmintable
 *   - cappedmintablepausable
 *
 * We apply the same “perfect” logic from the other panel,
 * just changing the tokenType keys and describing them accordingly.
 */
const tokenFeaturesMap = {
  standard: {
    description:
      "Basic ERC20 token with standard functionalities. The initial supply acts as the total supply (no minting).",
    features: [...BASE_FEATURES],
  },
  mintablePausable: {
    description:
      "ERC20 token that can mint new tokens (no cap) and supports manual pausing/unpausing. The owner can always mint more tokens.",
    features: [
      ...BASE_FEATURES,
      "Advanced Minting Controls",
      "Manual Pausing/Unpausing",
    ],
  },
  pausable: {
    description:
      "ERC20 token that allows manual pausing/unpausing of transfers. No minting; the initial supply is the total supply.",
    features: [
      ...BASE_FEATURES,
      "Manual Pausing/Unpausing",
    ],
  },
  mintable: {
    description:
      "ERC20 token that supports unlimited minting (no cap). The owner can always mint more tokens.",
    features: [
      ...BASE_FEATURES,
      "Flexible Minting Options",
    ],
  },
  cappedmintable: {
    description:
      "ERC20 token with a supply cap set post-creation in the Interactor Page. The owner can mint until the cap is reached.",
    features: [
      ...BASE_FEATURES,
      "Maximum Token Supply Enforcement",
      "Enhanced Security Controls",
    ],
  },
  cappedmintablepausable: {
    description:
      "ERC20 token with a supply cap (owner can mint until the cap is reached), plus manual pausing/unpausing. All parameters set via Interactor Page.",
    features: [
      ...BASE_FEATURES,
      "Maximum Token Supply Enforcement",
      "Advanced Minting Controls",
      "Manual Pausing/Unpausing",
      "Comprehensive Control Over Token Dynamics",
    ],
  },
};

/**
 * Keywords to identify "Core" vs. "Advanced" features.
 * We combine your original base features with the perfect version references.
 */
const coreFeatureKeywords = [
  "ERC20 Standard",
  "Permit (EIP-2612)",
  "Ownable",
  "Reentrancy Guard",
  "Burnable",
  "Set Token Price (Configured in Interactor Page for Presale)",
  "Metadata URL (Editable in Interactor Page)",
  "Interact seamlessly with your token using our intuitive interface.",
  "Metadata Updates",
  "Free AI-Generated Images",
  "Full Contract Ownership",
  "No Post-Creation Contracts",
  "Sales Page Integration Widget",
  "Customer Support",
];

/**
 * 
 * @param {Object} props
 * @param {string} props.tokenType - a key like 'standard', 'mintablePausable', etc.
 */
const TokenTypeFeatures = ({ tokenType }) => {
  const currentToken = tokenFeaturesMap[tokenType] || {};
  const { description = "", features = [] } = currentToken;

  // Same core vs. advanced feature separation
  const coreFeatures = features.filter((f) => coreFeatureKeywords.includes(f));
  const advancedFeatures = features.filter((f) => !coreFeatureKeywords.includes(f));

  return (
    <Card bordered={false} className="token-type-features">
      {/* Description */}
      {description && (
        <Paragraph className="token-description">{description}</Paragraph>
      )}

      {/* Core Features */}
      {coreFeatures.length > 0 && (
        <div className="feature-category">
          <Title level={5} className="category-title core-title">
            Core Features
          </Title>
          <List
            dataSource={coreFeatures}
            renderItem={(feature) => (
              <List.Item className="feature-item">
                {/* If a feature has a detailed entry in FEATURE_DETAILS, show that */}
                <span className="feature-text">
                  {FEATURE_DETAILS[feature] || feature}
                </span>
              </List.Item>
            )}
            size="small"
            bordered={false}
            className="features-list core-features"
          />
        </div>
      )}

      {/* Advanced Features */}
      {advancedFeatures.length > 0 && (
        <div className="feature-category">
          <Title level={5} className="category-title advanced-title">
            Advanced Features
          </Title>
          <List
            dataSource={advancedFeatures}
            renderItem={(feature) => (
              <List.Item className="feature-item">
                <CheckOutlined className="feature-checkmark" />
                <span className="feature-text">
                  {FEATURE_DETAILS[feature] || feature}
                </span>
              </List.Item>
            )}
            size="small"
            bordered={false}
            className="features-list advanced-features"
          />
        </div>
      )}
    </Card>
  );
};

TokenTypeFeatures.propTypes = {
  tokenType: PropTypes.oneOf([
    "standard",
    "mintablePausable",
    "pausable",
    "mintable",
    "cappedmintable",
    "cappedmintablepausable",
  ]).isRequired,
};

export default TokenTypeFeatures;
