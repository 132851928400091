// src/components/InteractorPage/InteractorPage.js

import React, { useEffect, useState, useContext } from 'react';
import { Contract, isAddress, parseUnits, formatUnits, id } from 'ethers'; // Updated import for Ethers v6
import TokenABI from '../abis/TokenABI.json';
import {
  Container,
  Form,
  Button,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
  Row,
  Col,
  InputGroup,
} from 'react-bootstrap';
import { Web3Context } from '../Web3Context';
import { FaCopy } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import styles from './InteractorPage.module.scss'; // Importing the CSS Module
import { copyToClipboard } from '../utils/clipboard';
import { formatNumber, formatDate } from '../utils/format'; // Import formatting utilities

const InteractorPage = () => {
  const { tokenAddress: urlTokenAddress } = useParams();
  const { provider, signer, address, connectWallet, connected, loading: walletLoading } = useContext(Web3Context);

  const [contractAddress, setContractAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [contract, setContract] = useState(null);

  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [decimals, setDecimals] = useState(null);
  const [totalSupply, setTotalSupply] = useState('');
  const [tokenPrice, setTokenPrice] = useState(''); // Now a string with full precision
  const [owner, setOwner] = useState('');
  const [metaUrl, setMetaUrl] = useState('');
  const [metadata, setMetadata] = useState(null);

  const [userBalance, setUserBalance] = useState('');
  const [ethBalance, setEthBalance] = useState('');
  const [contractEthBalance, setContractEthBalance] = useState('0');

  const [newPrice, setNewPrice] = useState('');
  const [burnAmount, setBurnAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const explorerUrl = process.env.REACT_APP_EXPLORER_URL || 'https://basescan.org/';

  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [metadataName, setMetadataName] = useState('');
  const [metadataDescription, setMetadataDescription] = useState('');
  const [metadataImage, setMetadataImage] = useState('');
  const [metadataWebsite, setMetadataWebsite] = useState('');
  const [metadataTwitter, setMetadataTwitter] = useState('');

  // Image Generation State
  const [keywords, setKeywords] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  // States for storing previously generated images
  const [previousImages, setPreviousImages] = useState([]);
  const [newMetaUrl, setNewMetaUrl] = useState('');

  // States for the previously generated image modal
  const [showPrevImageModal, setShowPrevImageModal] = useState(false);
  const [selectedPrevImage, setSelectedPrevImage] = useState('');

  // *** New States for Enhancements ***
  // Terms of Service Agreement
  const [agreedToTOS, setAgreedToTOS] = useState(false);

  // Widget Customization States
  const [includeHeaderImage, setIncludeHeaderImage] = useState(false);
  const [headerImageURL, setHeaderImageURL] = useState('');
  const [includeTitle, setIncludeTitle] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [widgetSize, setWidgetSize] = useState('800x800'); // Default size
  const [generatedWidgetURL, setGeneratedWidgetURL] = useState('');
  const [generatedIframeCode, setGeneratedIframeCode] = useState('');
  const [isGeneratingWidget, setIsGeneratingWidget] = useState(false);

  // *** New States for Minting, Pausing, and Capping ***
  const [isPaused, setIsPaused] = useState(false);
  const [canMint, setCanMint] = useState(false);
  const [canPause, setCanPause] = useState(false);

  // *** New States for Cap Functionality ***
  const [hasCap, setHasCap] = useState(false);
  const [capSet, setCapSet] = useState(false);
  const [capValue, setCapValue] = useState('0'); // Stored as string for consistency
  const [canSetCap, setCanSetCap] = useState(false);
  const [newCap, setNewCap] = useState(''); // New state for cap input

  // Define additional ABI fragments for optional functions, including Cap
  const extraAbi = [
    // paused
    {
      "inputs": [],
      "name": "paused",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    // pause
    {
      "inputs": [],
      "name": "pause",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    // unpause
    {
      "inputs": [],
      "name": "unpause",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    // mint
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "mint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    // *** Cap Functions ***
    {
      "inputs": [],
      "name": "cap",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "capSet",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "newCap",
          "type": "uint256"
        }
      ],
      "name": "setCap",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];

  // Function to combine standard ABI with extra ABI
  const getCombinedAbi = () => {
    return [...TokenABI, ...extraAbi];
  };

  const handlePrevImageClick = (url) => {
    setSelectedPrevImage(url);
    setShowPrevImageModal(true);
  };

  const handleAddressChange = (e) => {
    setContractAddress(e.target.value);
  };

  const getExplorerUrl = (txHashOrAddress) => {
    if (!txHashOrAddress) return '#';
    const isTx = txHashOrAddress.length === 66;
    return isTx
      ? `${explorerUrl}tx/${txHashOrAddress}`
      : `${explorerUrl}address/${txHashOrAddress}`;
  };

  /**
   * Returns the first 4 bytes of the keccak256 hash of the function signature.
   * @param {string} signature - Function signature, e.g., "mint(address,uint256)"
   * @returns {string} - Function selector, e.g., "0x40c10f19"
   */
  const getFunctionSelector = (signature) => {
    return id(signature).substring(0, 10); // 'id' is imported from 'ethers'
  };

  /**
   * Checks if the contract's bytecode contains the function selector.
   * @param {string} contractAddress - Address of the contract
   * @param {string} functionSignature - Function signature, e.g., "mint(address,uint256)"
   * @param {ethers.providers.Provider} provider - Ethers.js provider instance
   * @returns {Promise<boolean>} - True if function exists, else false
   */
  const doesFunctionExist = async (contractAddress, functionSignature, provider) => {
    try {
      const selector = getFunctionSelector(functionSignature);
      const code = await provider.getCode(contractAddress);
      return code.includes(selector.slice(2)); // Remove '0x' before checking
    } catch (error) {
      console.error(`Error checking function ${functionSignature}:`, error);
      return false;
    }
  };

  const connectContract = async (e, isUserInitiated = true) => {
    if (e && e.preventDefault) e.preventDefault();

    if (!isAddress(contractAddress)) {
      return; // Removed toast.error
    }

    if (!provider || !signer) {
      return; // Removed toast.error
    }

    try {
      setLoading(true);
      const combinedAbi = getCombinedAbi();
      const tempContract = new Contract(contractAddress, combinedAbi, signer);

      const [name, symbol, dec, supply, price, contractOwner, currentMetaUrl] = await Promise.all([
        tempContract.name(),
        tempContract.symbol(),
        tempContract.decimals(),
        tempContract.totalSupply(),
        tempContract.tokenPrice(),
        tempContract.owner(),
        tempContract.metaUrl(),
      ]);

      let decNumber = null;
      if (typeof dec === 'number') {
        decNumber = dec;
      } else if (typeof dec === 'bigint') {
        decNumber = Number(dec);
      } else if (dec && typeof dec.toNumber === 'function') {
        decNumber = dec.toNumber();
      }

      setDecimals(decNumber);
      setTokenName(name);
      setTokenSymbol(symbol);
      setTotalSupply(decNumber !== null ? formatNumber(formatUnits(supply, decNumber)) : 'N/A');
      setTokenPrice(formatUnits(price, 'ether')); // Now preserves full precision
      setOwner(contractOwner);
      setMetaUrl(currentMetaUrl);
      setContract(tempContract);
      setIsConnected(true);

      // Detect optional functions
      const canPauseFunc = "pause()";
      const canUnpauseFunc = "unpause()";
      const canMintFunc = "mint(address,uint256)";
      const canWithdrawFunc = "withdraw()";
      const canCapFunc = "cap()";
      const canCapSetFunc = "capSet()";
      const canSetCapFunc = "setCap(uint256)";

      const [hasPause, hasUnpause, hasMint, hasWithdraw, hasCap, hasCapSet, hasSetCap] = await Promise.all([
        doesFunctionExist(contractAddress, canPauseFunc, provider),
        doesFunctionExist(contractAddress, canUnpauseFunc, provider),
        doesFunctionExist(contractAddress, canMintFunc, provider),
        doesFunctionExist(contractAddress, canWithdrawFunc, provider),
        doesFunctionExist(contractAddress, canCapFunc, provider),
        doesFunctionExist(contractAddress, canCapSetFunc, provider),
        doesFunctionExist(contractAddress, canSetCapFunc, provider),
      ]);

      setCanPause(hasPause || hasUnpause); // If either pause or unpause exists
      setCanMint(hasMint);

      if (hasPause) {
        const pausedState = await tempContract.paused();
        setIsPaused(pausedState);
      }

      if (hasMint) {
        // Mint is available
      }

      if (hasWithdraw) {
        // Withdraw is available
      }

      // *** Detect Cap Functions ***
      if (hasCap && hasCapSet && hasSetCap) {
        setHasCap(true);

        // Fetch capSet and cap value
        const [isCapSet, currentCap] = await Promise.all([
          tempContract.capSet(),
          tempContract.cap(),
        ]);

        setCapSet(isCapSet);
        setCapValue(currentCap.toString()); // Convert BigNumber to string

        // *** Check if the user can set the cap (only owner can set) ***
        setCanSetCap(address.toLowerCase() === contractOwner.toLowerCase());
      } else {
        setHasCap(false);
        setCanSetCap(false);
      }

      const balance = await tempContract.balanceOf(address);
      setUserBalance(decNumber !== null ? formatNumber(formatUnits(balance, decNumber)) : 'N/A');

      const ethBal = await provider.getBalance(address);
      setEthBalance(formatNumber(formatUnits(ethBal, 'ether')));

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));

      fetchMetadata(currentMetaUrl);
      await fetchPreviousImages(contractAddress);

      localStorage.setItem('contractAddress', contractAddress);
    } catch (err) {
      console.error('Error connecting to contract:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchMetadata = async (url) => {
    if (!url || url.trim() === '') {
      setMetadata(null);
      return;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch metadata: ${response.statusText}`);
      }
      const data = await response.json();
      setMetadata(data);
    } catch (err) {
      console.error('Error fetching metadata:', err);
      setMetadata(null);
    }
  };

  const fetchPreviousImages = async (contractAddr) => {
    try {
      const response = await fetch(`https://turtleblunt.com/api/images/${contractAddr}`);
      const data = await response.json();
      if (data.success && Array.isArray(data.images)) {
        setPreviousImages(data.images);
      } else {
        setPreviousImages([]);
      }
    } catch (err) {
      console.error('Error fetching previous images:', err);
      setPreviousImages([]);
    }
  };

  const handleDisconnectContract = () => {
    setIsConnected(false);
    setContract(null);
    setContractAddress('');
    setTokenName('');
    setTokenSymbol('');
    setDecimals(null);
    setTotalSupply('');
    setTokenPrice('');
    setOwner('');
    setMetaUrl('');
    setUserBalance('');
    setEthBalance('');
    setContractEthBalance('0');
    setNewPrice('');
    setBurnAmount('');
    setNewMetaUrl('');
    setMetadata(null);
    setSelectedImage('');
    setKeywords('');
    setGeneratedImages([]);
    setGenerationError('');
    setPreviousImages([]);
    setAgreedToTOS(false);
    setIncludeHeaderImage(false);
    setHeaderImageURL('');
    setIncludeTitle(false);
    setCustomTitle('');
    setWidgetSize('800x800');
    setGeneratedWidgetURL('');
    setGeneratedIframeCode('');
    setIsPaused(false);
    setCanMint(false);
    setCanPause(false);
    // *** Reset Cap States ***
    setHasCap(false);
    setCapSet(false);
    setCapValue('0');
    setCanSetCap(false);
    setNewCap('');
    localStorage.removeItem('contractAddress');
  };

  useEffect(() => {
    const fetchUserBalance = async () => {
      if (contract && address && decimals !== null) {
        try {
          const balance = await contract.balanceOf(address);
          setUserBalance(formatNumber(formatUnits(balance, decimals)));
          
          const ethBal = await provider.getBalance(address);
          setEthBalance(formatNumber(formatUnits(ethBal, 'ether')));
        } catch (err) {
          console.error('Error fetching user balance:', err);
        }
      }
    };
    fetchUserBalance();
  }, [contract, address, provider, decimals]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (contract && decimals !== null) {
        try {
          const supply = await contract.totalSupply();
          setTotalSupply(formatNumber(formatUnits(supply, decimals)));

          const price = await contract.tokenPrice();
          setTokenPrice(formatUnits(price, 'ether')); // Now preserves full precision

          const contractBalance = await provider.getBalance(contractAddress);
          setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
        } catch (err) {
          console.error('Error fetching details:', err);
        }
      }
    };
    fetchDetails();
  }, [contract, contractAddress, provider, decimals]);

  useEffect(() => {
    const init = async () => {
      if (urlTokenAddress && isAddress(urlTokenAddress)) {
        setContractAddress(urlTokenAddress);
        await connectContract({ preventDefault: () => {} }, false);
      } else {
        const savedContractAddress = localStorage.getItem('contractAddress');
        if (savedContractAddress && isAddress(savedContractAddress)) {
          setContractAddress(savedContractAddress);
          await connectContract({ preventDefault: () => {} }, false);
        }
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTokenAddress, connected, signer, provider]);

  const handleSetPrice = async (e) => {
    e.preventDefault();

    if (!newPrice || isNaN(newPrice) || Number(newPrice) <= 0) {
      return; // Removed toast.error
    }

    setLoading(true);
    try {
      const tx = await contract.setTokenPrice(parseUnits(newPrice, 'ether'));
      await tx.wait();

      const updatedPrice = await contract.tokenPrice();
      setTokenPrice(formatUnits(updatedPrice, 'ether')); // Now preserves full precision
      setNewPrice('');

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
    } catch (err) {
      console.error('Error setting token price:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleBurn = async (e) => {
    e.preventDefault();

    if (!burnAmount || isNaN(burnAmount) || Number(burnAmount) <= 0) {
      return; // Removed toast.error
    }

    if (Number(burnAmount) > Number(userBalance)) {
      return; // Removed toast.error
    }

    setLoading(true);
    try {
      const burnAmountBN = parseUnits(burnAmount.toString(), decimals);
      const tx = await contract.burn(burnAmountBN);
      await tx.wait();

      const updatedBalance = await contract.balanceOf(address);
      setUserBalance(formatNumber(formatUnits(updatedBalance, decimals)));

      const updatedSupply = await contract.totalSupply();
      setTotalSupply(formatNumber(formatUnits(updatedSupply, decimals)));

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
    } catch (err) {
      console.error('Error burning tokens:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleWithdraw = async () => {
    if (address.toLowerCase() !== owner.toLowerCase()) {
      return; // Removed toast.error
    }

    if (Number(contractEthBalance) <= 0) {
      return; // Removed toast.error
    }

    setLoading(true);
    try {
      const tx = await contract.withdraw();
      await tx.wait();

      const ethBal = await provider.getBalance(address);
      setEthBalance(formatNumber(formatUnits(ethBal, 'ether')));

      const contractBalance = await provider.getBalance(contractAddress);
      setContractEthBalance(formatNumber(formatUnits(contractBalance, 'ether')));
    } catch (err) {
      console.error('Error withdrawing ETH:', err);
    } finally {
      setLoading(false);
    }
  };

  // *** New Handler for Minting Tokens ***
  const handleMint = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const recipient = form.elements.mintTo.value;
    const amount = form.elements.mintAmount.value;

    if (!isAddress(recipient)) {
      return; // Removed toast.error
    }

    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      return; // Removed toast.error
    }

    setLoading(true);
    try {
      const AmountBN = parseUnits(amount.toString(), decimals);
      const tx = await contract.mint(recipient, AmountBN);
      await tx.wait();

      if (recipient.toLowerCase() === address.toLowerCase()) {
        const updatedBalance = await contract.balanceOf(address);
        setUserBalance(formatNumber(formatUnits(updatedBalance, decimals)));
      }

      const updatedSupply = await contract.totalSupply();
      setTotalSupply(formatNumber(formatUnits(updatedSupply, decimals)));
    } catch (err) {
      console.error('Error minting tokens:', err);
    } finally {
      setLoading(false);
    }
  };

  // *** New Handlers for Pausing and Unpausing ***
  const handlePause = async () => {
    setLoading(true);
    try {
      const tx = await contract.pause();
      await tx.wait();
      setIsPaused(true);
    } catch (err) {
      console.error('Error pausing contract:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleUnpause = async () => {
    setLoading(true);
    try {
      const tx = await contract.unpause();
      await tx.wait();
      setIsPaused(false);
    } catch (err) {
      console.error('Error unpausing contract:', err);
    } finally {
      setLoading(false);
    }
  };

  // *** New Handler for Setting Cap ***
  const handleSetCap = async (e) => {
    e.preventDefault();

    if (!newCap || isNaN(newCap) || Number(newCap) <= 0) {
      return; // Removed toast.error
    }

    // Ensure no decimal places
    const integerRegex = /^\d+$/;
    if (!integerRegex.test(newCap)) {
      return; // Removed toast.error
    }

    setLoading(true);
    try {
      const newCapValue = newCap.toString();
      const tx = await contract.setCap(newCapValue);
      await tx.wait();

      const isCapSet = await contract.capSet();
      const currentCap = await contract.cap();
      setCapSet(isCapSet);
      setCapValue(currentCap.toString());

      setNewCap('');
    } catch (err) {
      console.error('Error setting cap:', err);
    } finally {
      setLoading(false);
    }
  };

  // -- Updated to accept an optional image URL --
  const handleOpenMetadataModal = (imgUrl = '') => {
    setMetadataName(metadata?.name || '');
    setMetadataDescription(metadata?.description || '');
    setMetadataImage(imgUrl || metadata?.image || '');
    setMetadataWebsite(metadata?.website || '');
    setMetadataTwitter(metadata?.social?.twitter || '');
    setShowMetadataModal(true);
  };

  const handleCloseMetadataModal = () => {
    setShowMetadataModal(false);
  };

  const handleMetadataSubmit = async (e) => {
    e.preventDefault();

    if (!metadataName.trim() || !metadataDescription.trim()) {
      return; // Removed toast.error
    }

    setLoading(true);
    try {
      // 1) Upload metadata to IPFS
      const response = await fetch('https://turtleblunt.com/api/metadata', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: metadataName,
          description: metadataDescription,
          image: metadataImage,
          website: metadataWebsite,
          social: {
            twitter: metadataTwitter,
          },
        }),
      });

      const data = await response.json();

      if (!response.ok || !data.success) {
        throw new Error(data.error || 'Unknown error occurred.');
      }

      // 2) Now automatically update metaUrl on the contract
      const newIpfsUrl = data.ipfsUrl;
      const tx = await contract.updateMetaUrl(newIpfsUrl);
      await tx.wait();

      setMetaUrl(newIpfsUrl);
      fetchMetadata(newIpfsUrl);

      handleCloseMetadataModal();
    } catch (err) {
      console.error('Error creating and updating metadata:', err);
    } finally {
      setLoading(false);
    }
  };

  // Image Generation Handlers
  const handleKeywordsChange = (e) => {
    const input = e.target.value;
    const words = input.trim().split(/\s+/);
    if (words.length <= 10) {
      setKeywords(input);
      setGenerationError('');
    } else {
      setGenerationError('Maximum of 10 words allowed.');
    }
  };

  const generateImages = async () => {
    if (keywords.trim() === '') {
      setGenerationError('Please enter keywords to generate images.');
      return;
    }

    if (keywords.trim().split(/\s+/).length > 10) {
      setGenerationError('Please enter no more than 10 words.');
      return;
    }

    if (!agreedToTOS) {
      return; // Removed toast.error
    }

    setIsGenerating(true);
    setGenerationError('');
    setGeneratedImages([]);
    setSelectedImage('');

    try {
      const response = await fetch('https://turtleblunt.com/api/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: keywords,
          walletAddress: address,
          contractAddress: contractAddress
        }),
      });

      const data = await response.json();
      if (response.ok && data.success && data.ipfsLink) {
        const imageUrls = [data.ipfsLink];
        setGeneratedImages(imageUrls);

        await fetchPreviousImages(contractAddress);
      } else {
        throw new Error(data.error || 'Failed to generate images.');
      }
    } catch (err) {
      console.error('Error generating images:', err);
      setGenerationError(err.message || 'Failed to generate images.');
    } finally {
      setIsGenerating(false);
    }
  };

  // -- Updated to pass the new image URL immediately into handleOpenMetadataModal --
  const selectImage = (url) => {
    setSelectedImage(url);
    handleOpenMetadataModal(url);
  };

  // *** Widget Code Generation Function ***
  const handleGenerateWidgetCode = () => {
    setIsGeneratingWidget(true);
    const baseUrl = `${window.location.origin}/token-sale/${contractAddress}`;

    const params = new URLSearchParams();
    params.append('widget', 'true');

    if (includeHeaderImage && headerImageURL.trim() !== '') {
      params.append('headerimg', headerImageURL.trim());
    }

    if (includeTitle && customTitle.trim() !== '') {
      params.append('title', customTitle.trim());
    }

    const widgetUrl = `${baseUrl}?${params.toString()}`;
    setGeneratedWidgetURL(widgetUrl);

    const [width, height] = widgetSize.split('x');
    const iframeCode = `<iframe src="${widgetUrl}" width="${width}" height="${height}" style="border: none;"></iframe>`;
    setGeneratedIframeCode(iframeCode);

    setIsGeneratingWidget(false);
  };

  return (
    <Container className={styles.interactorContainer}>
            <h4 className="text-center">Enter Your Contract Address</h4>

      {/* Hover Card for Wallet Connection */}
      <div className={styles.interactorHoverCard}>
        {!provider ? (
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={connectWallet}
              className={`${styles.interactorButtonPrimary} w-50`}
              disabled={walletLoading}
              aria-label="Connect Wallet"
            >
              {walletLoading ? <Spinner animation="border" size="sm" /> : 'Connect Wallet'}
            </Button>
          </div>
        ) : !isConnected ? (
          <Form onSubmit={connectContract}>
            <Form.Group controlId="contractAddress" className="mb-4">
              <Form.Label className="text-center w-100">Enter Your Token Contract Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="0x..."
                value={contractAddress}
                onChange={handleAddressChange}
                className={`${styles.interactorFormControl} text-center`}
                required
                aria-label="Token Contract Address"
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                type="submit"
                className={`${styles.interactorButtonPrimary} w-50`}
                disabled={loading}
                aria-label="Connect to Contract"
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Connect'}
              </Button>
            </div>
          </Form>
        ) : (
          <div>
            <h5>Connected to {tokenName} ({tokenSymbol})</h5>
            <p>Your Address: {address}</p>
            <div className="d-flex justify-content-center mt-2">
              <Button
                variant="outline-danger"
                onClick={handleDisconnectContract}
                className={`${styles.interactorButtonDanger} w-50`}
                disabled={loading}
                aria-label="Disconnect Contract"
              >
                {loading ? <Spinner animation="border" size="sm" /> : 'Disconnect'}
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Main Content Section */}
      {isConnected && (
        <div className={styles.interactorContentSection}>
          {/* Token Details */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Token Details</h4>
            <Table bordered hover variant="dark" className="mt-3 text-center">
              <tbody>
                <tr>
                  <th className="w-50">Name</th>
                  <td><strong>{tokenName}</strong></td>
                </tr>
                <tr>
                  <th>Symbol</th>
                  <td><strong>{tokenSymbol}</strong></td>
                </tr>
                <tr>
                  <th>Decimals</th>
                  <td><strong>{decimals !== null ? decimals : 'N/A'}</strong></td>
                </tr>
                <tr>
                  <th>Total Supply</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{totalSupply} {tokenSymbol}</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Token Price</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{tokenPrice} ETH per {tokenSymbol}</strong> {/* Display full precision */}
                    </div>
                    <Form onSubmit={handleSetPrice} className="mt-3">
                      <Form.Group controlId="newPrice" className="mb-3">
                        <Form.Label className="text-center w-100">Set New Token Price (ETH)</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter new token price in ETH"
                          value={newPrice}
                          onChange={(e) => setNewPrice(e.target.value)}
                          min="0"
                          step="any"
                          required
                          className={styles.interactorFormControl}
                          aria-label="New Token Price in ETH"
                        />
                      </Form.Group>
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="primary"
                          type="submit"
                          className={`${styles.interactorButtonPrimary} w-50`}
                          disabled={loading}
                          aria-label="Set Token Price"
                        >
                          {loading ? <Spinner animation="border" size="sm" /> : 'Set Token Price'}
                        </Button>
                      </div>
                    </Form>
                  </td>
                </tr>
                <tr>
                  <th>Metadata</th>
                  <td>
                    {metadata ? (
                      <div className="text-start">
                        <p><strong>Name:</strong> {metadata.name}</p>
                        <p><strong>Description:</strong> {metadata.description}</p>
                        {metadata.image && (
                          <div className="text-center">
                            <img
                              src={metadata.image}
                              alt={`${metadata.name} Image`}
                              className="img-fluid rounded"
                              style={{ maxHeight: '200px', objectFit: 'contain' }}
                              onError={(e) => { e.target.onerror = null; e.target.src = '/images/broken.png'; }} // Handle broken images
                            />
                          </div>
                        )}
                        {metadata.website && (
                          <p>
                            <strong>Website:</strong>{' '}
                            <a href={metadata.website} target="_blank" rel="noopener noreferrer" className="text-light">
                              {metadata.website}
                            </a>
                          </p>
                        )}
                        {metadata.social && metadata.social.twitter && (
                          <p>
                            <strong>Twitter:</strong>{' '}
                            <a href={metadata.social.twitter} target="_blank" rel="noopener noreferrer" className="text-light">
                              {metadata.social.twitter}
                            </a>
                          </p>
                        )}
                      </div>
                    ) : (
                      <p>No metadata available.</p>
                    )}
                    <div className="mt-3">
                      <strong>Metadata URL:</strong>{' '}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{metaUrl}</Tooltip>}
                      >
                        <a
                          href={metaUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.interactorMetadataLink}
                          aria-label="Metadata URL"
                        >
                          {metaUrl}
                        </a>
                      </OverlayTrigger>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Button variant="info" size="sm" onClick={() => handleOpenMetadataModal()} aria-label="Create or Update Metadata">
                        Create/Update Metadata
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* *** Cap Information Section *** */}
          {hasCap && (
            <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
              <h4 className="text-center">Cap Information</h4>
              <Table bordered hover variant="dark" className="mt-3 text-center">
                <tbody>
                  <tr>
                    <th className="w-50">Cap Set</th>
                    <td><strong>{capSet ? 'Yes' : 'No'}</strong></td>
                  </tr>
                  <tr>
                    <th>Current Cap</th>
                    <td>
                      {capSet ? (
                        <div className="bg-secondary p-2 rounded">
                          <strong>{formatNumber(formatUnits(capValue, decimals))} {tokenSymbol}</strong>
                        </div>
                      ) : (
                        <div className="bg-secondary p-2 rounded">
                          <strong>Not Set</strong>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {canSetCap && !capSet && (
                <Form onSubmit={handleSetCap} className="mt-4">
                  <Form.Group controlId="newCap" className="mb-3">
                    <Form.Label className="text-center w-100">Set New Cap ({tokenSymbol})</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={`Enter new cap in ${tokenSymbol}`}
                      value={newCap}
                      onChange={(e) => setNewCap(e.target.value)}
                      min="0"
                      step="1"
                      required
                      className={`${styles.interactorFormControl} text-center`}
                      aria-label="New Cap Value"
                      title={`Note: Enter the cap value directly without additional scaling.`}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className={`${styles.interactorButtonPrimary} w-50`}
                      disabled={loading}
                      aria-label="Set New Cap"
                    >
                      {loading ? <Spinner animation="border" size="sm" /> : 'Set Cap'}
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          )}

          {/* *** Widget Customization Section *** */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Customize Widget Code</h4>
            <Form onSubmit={(e) => { e.preventDefault(); handleGenerateWidgetCode(); }}>
              <Form.Group controlId="customHeaderImage" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Include Custom Header Image"
                  checked={includeHeaderImage}
                  onChange={(e) => setIncludeHeaderImage(e.target.checked)}
                  aria-label="Include Custom Header Image"
                />
                {includeHeaderImage && (
                  <Form.Control
                    type="url"
                    placeholder="Enter Header Image URL"
                    value={headerImageURL}
                    onChange={(e) => setHeaderImageURL(e.target.value)}
                    required
                    className="mt-2 bg-dark text-light border-secondary"
                    aria-label="Header Image URL"
                  />
                )}
              </Form.Group>

              <Form.Group controlId="customTitle" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Include Custom Title"
                  checked={includeTitle}
                  onChange={(e) => setIncludeTitle(e.target.checked)}
                  aria-label="Include Custom Title"
                />
                {includeTitle && (
                  <Form.Control
                    type="text"
                    placeholder="Enter Custom Title"
                    value={customTitle}
                    onChange={(e) => setCustomTitle(e.target.value)}
                    required
                    className="mt-2 bg-dark text-light border-secondary"
                    aria-label="Custom Title"
                  />
                )}
              </Form.Group>

              <Form.Group controlId="widgetSize" className="mb-3">
                <Form.Label className="text-center d-block mb-2" style={{ fontWeight: 'bold', color: '#a5d6a7' }}>Select Widget Size</Form.Label>
                <Form.Check
                  type="radio"
                  label="800x800"
                  name="widgetSize"
                  id="size800"
                  value="800x800"
                  checked={widgetSize === '800x800'}
                  onChange={(e) => setWidgetSize(e.target.value)}
                  aria-label="Widget Size 800x800"
                  className="me-3"
                />
                <Form.Check
                  type="radio"
                  label="600x600"
                  name="widgetSize"
                  id="size600"
                  value="600x600"
                  checked={widgetSize === '600x600'}
                  onChange={(e) => setWidgetSize(e.target.value)}
                  aria-label="Widget Size 600x600"
                />
              </Form.Group>

              <Button 
                variant="success" 
                type="submit" 
                disabled={
                  (includeHeaderImage && headerImageURL.trim() === '') ||
                  (includeTitle && customTitle.trim() === '') ||
                  isGeneratingWidget
                }
                className="generate-widget-btn w-100"
                aria-label="Generate Widget Code"
              >
                {isGeneratingWidget ? <Spinner animation="border" size="sm" /> : 'Generate Widget Code'}
              </Button>
            </Form>

            {generatedWidgetURL && generatedIframeCode && (
              <div className={styles.generatedWidgetSection}>
                <h5>Generated Widget URL:</h5>
                <InputGroup className="mt-2">
                  <Form.Control
                    readOnly
                    value={generatedWidgetURL}
                    aria-label="Generated Widget URL"
                    className="bg-secondary text-light border-secondary"
                    style={{ wordBreak: 'break-all' }}
                  />
                  <Button
                    variant="outline-success"
                    onClick={() => copyToClipboard(generatedWidgetURL)}
                    title="Copy Widget URL"
                    aria-label="Copy Widget URL"
                  >
                    <FaCopy />
                  </Button>
                </InputGroup>
                <p className="text-muted mt-2" style={{fontSize: '0.9rem'}}>
                  Copy and use this URL for your widget.
                </p>

                <h5 className="mt-4">Generated Iframe Code:</h5>
                <InputGroup className="mt-2">
                  <Form.Control
                    as="textarea"
                    readOnly
                    value={generatedIframeCode}
                    aria-label="Generated Iframe Code"
                    className="bg-secondary text-light border-secondary"
                    rows={3}
                    style={{ wordBreak: 'break-all' }}
                  />
                  <Button
                    variant="outline-success"
                    onClick={() => copyToClipboard(generatedIframeCode)}
                    title="Copy Iframe Code"
                    aria-label="Copy Iframe Code"
                  >
                    <FaCopy />
                  </Button>
                </InputGroup>
                <p className="text-muted mt-2" style={{fontSize: '0.9rem'}}>
                  Copy and embed on your website to add a buy tokens widget.
                </p>
              </div>
            )}
          </div>

          {/* *** Image Generation Section with TOS *** */}
          {address.toLowerCase() === owner.toLowerCase() && (
            <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
              <h4 className="text-center">Generate Images</h4>

              <div className={`${styles.tosWrapper} mb-3`}>
                <h5><strong>Terms of Service for Image Generation</strong></h5>
                <p>By generating images, you agree to adhere to <a href="https://openai.com/policies/usage-policies/" target="_blank" rel="noopener noreferrer">OpenAI's usage policies</a>. Images should not contain disallowed content.</p>
                <p>You are responsible for the images used for your token. If images violate policies, they may be removed.</p>
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      I have read and agree to the <a href="/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                    </>
                  }
                  checked={agreedToTOS}
                  onChange={(e) => setAgreedToTOS(e.target.checked)}
                  required
                  aria-label="Agree to Terms of Service for Image Generation"
                />
              </div>

              <Form.Group controlId="keywords" className="mb-3">
                <Form.Label>Keywords (Max 10 words):</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. COOL FISH TOKEN"
                  value={keywords}
                  onChange={handleKeywordsChange}
                  required
                  aria-label="Image Generation Keywords"
                />
                <Form.Text className="text-muted">
                  {keywords.trim() === ''
                    ? 'Enter up to 10 words describing the image you want to create.'
                    : `${10 - keywords.trim().split(/\s+/).length} words remaining`}
                </Form.Text>
                {generationError && <p className="text-danger mt-1">{generationError}</p>}
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button
                  variant="success"
                  onClick={generateImages}
                  disabled={isGenerating || keywords.trim().split(/\s+/).length === 0 || keywords.trim().split(/\s+/).length > 10 || !agreedToTOS}
                  className={`${styles.interactorButtonPrimary} w-50`}
                  aria-label="Generate Images"
                >
                  {isGenerating ? <Spinner animation="border" size="sm" /> : 'Generate Images'}
                </Button>
              </div>

              {generatedImages.length > 0 && (
                <div className="mt-4">
                  <h5 className="text-center">Newly Generated Images:</h5>
                  <Row className="mt-3">
                    {generatedImages.map((url, index) => (
                      <Col xs={6} md={4} lg={3} key={index} className="mb-3">
                        <div
                          className={`${styles.generatedImageCard} bg-secondary rounded p-2`}
                          onClick={() => selectImage(url)}
                          style={{ cursor: 'pointer', height: '200px', overflow: 'hidden' }}
                          aria-label={`Select generated image ${index + 1}`}
                        >
                          <img
                            src={url}
                            alt={`Generated ${index + 1}`}
                            className="img-fluid rounded"
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            onError={(e) => { e.target.onerror = null; e.target.src = '/images/broken.png'; }} // Handle broken images
                          />
                          <p className="text-center mt-2 mb-0">Select Image {index + 1}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}

              {selectedImage && (
                <div className="mt-4 text-center">
                  <h5>Selected Image:</h5>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="img-fluid rounded"
                    style={{ maxHeight: '300px', objectFit: 'contain' }}
                    onError={(e) => { e.target.onerror = null; e.target.src = '/images/broken.png'; }}
                  />
                  <p className="mt-2">This image is set as your metadata image.</p>
                </div>
              )}

              {previousImages && previousImages.length > 0 && (
                <div className="mt-4">
                  <h5 className="text-center">Previously Generated Images:</h5>
                  <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                    {previousImages.map((entry, index) => (
                      <div key={index} className="mb-3">
                        <p><strong>Prompt:</strong> {entry.prompt}</p>
                        <Row className="g-2">
                          {entry.links.map((link, i) => (
                            <Col xs="auto" key={i}>
                              <div
                                className="bg-secondary rounded"
                                style={{
                                  cursor: 'pointer',
                                  width: '60px',
                                  height: '60px',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                                onClick={() => handlePrevImageClick(link)}
                                aria-label={`View previous image ${i + 1}`}
                              >
                                <img
                                  src={link}
                                  alt={`Previous ${index + 1}-${i + 1}`}
                                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                  onError={(e) => { e.target.onerror = null; e.target.src = '/images/broken.png'; }}
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Addresses Section */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Addresses</h4>
            <Table bordered hover variant="dark" className="mt-3 text-center">
              <tbody>
                <tr>
                  <th className="w-50">Contract Owner</th>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href={getExplorerUrl(owner)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light me-2"
                        style={{ wordBreak: 'break-all' }}
                        aria-label="Contract Owner Address"
                      >
                        {owner}
                      </a>
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(owner)}
                        size="sm"
                        className={styles.interactorCopyButton}
                        aria-label="Copy Contract Owner Address"
                      >
                        <FaCopy />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Token Contract Address</th>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href={getExplorerUrl(contractAddress)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light me-2"
                        style={{ wordBreak: 'break-all' }}
                        aria-label="Token Contract Address"
                      >
                        {contractAddress}
                      </a>
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(contractAddress)}
                        size="sm"
                        className={styles.interactorCopyButton}
                        aria-label="Copy Token Contract Address"
                      >
                        <FaCopy />
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Your Address</th>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <a
                        href={getExplorerUrl(address)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light me-2"
                        style={{ wordBreak: 'break-all' }}
                        aria-label="Your Address"
                      >
                        {address}
                      </a>
                      <Button
                        variant="outline-secondary"
                        onClick={() => copyToClipboard(address)}
                        size="sm"
                        className={styles.interactorCopyButton}
                        aria-label="Copy Your Address"
                      >
                        <FaCopy />
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* Balances Section */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">Balances</h4>
            <Table bordered hover variant="dark" className="mt-3 text-center">
              <tbody>
                <tr>
                  <th className="w-50">Your Token Balance</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{userBalance} {tokenSymbol}</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Your ETH Balance</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{ethBalance} ETH</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Contract ETH Balance</th>
                  <td>
                    <div className="bg-secondary p-2 rounded">
                      <strong>{contractEthBalance} ETH</strong>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Button
                        variant="danger"
                        onClick={handleWithdraw}
                        className={`${styles.interactorButtonDanger} w-50`}
                        disabled={Number(contractEthBalance) <= 0 || loading}
                        aria-label="Withdraw ETH"
                      >
                        {loading ? <Spinner animation="border" size="sm" /> : `Withdraw ETH (${contractEthBalance} ETH)`}
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          {/* User Actions Section */}
          <div className={`mb-4 p-3 bg-dark text-light border-0 ${styles.interactorCustomCard}`}>
            <h4 className="text-center">User Actions</h4>
            <Form onSubmit={handleBurn} className="w-75 mx-auto mb-4 mt-3">
              <Form.Group controlId="burnAmount" className="mb-3">
                <Form.Label className="text-center w-100">Burn Tokens</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter amount to burn"
                  value={burnAmount}
                  onChange={(e) => setBurnAmount(e.target.value)}
                  min="0"
                  step="any"
                  required
                  className={`${styles.interactorFormControl} text-center`}
                  aria-label="Burn Tokens Amount"
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button
                  variant="danger"
                  type="submit"
                  className={`${styles.interactorButtonDanger} w-50`}
                  disabled={loading}
                  aria-label="Burn Tokens"
                >
                  {loading ? <Spinner animation="border" size="sm" /> : 'Burn Tokens'}
                </Button>
              </div>
            </Form>

            {canMint && address.toLowerCase() === owner.toLowerCase() && (
              <Form onSubmit={handleMint} className="w-75 mx-auto mb-4 mt-3">
                <Form.Group controlId="mintTo" className="mb-3">
                  <Form.Label className="text-center w-100">Mint Tokens To</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Recipient Address"
                    required
                    className={`${styles.interactorFormControl} text-center`}
                    aria-label="Mint Tokens Recipient Address"
                  />
                </Form.Group>
                <Form.Group controlId="mintAmount" className="mb-3">
                  <Form.Label className="text-center w-100">Amount to Mint</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    required
                    className={`${styles.interactorFormControl} text-center`}
                    aria-label="Mint Tokens Amount"
                  />
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <Button
                    variant="success"
                    type="submit"
                    className={`${styles.interactorButtonPrimary} w-50`}
                    disabled={loading}
                    aria-label="Mint Tokens"
                  >
                    {loading ? <Spinner animation="border" size="sm" /> : 'Mint Tokens'}
                  </Button>
                </div>
              </Form>
            )}

            {canPause && address.toLowerCase() === owner.toLowerCase() && (
              <div className="w-75 mx-auto mb-4 mt-3 text-center">
                <Button
                  variant={isPaused ? "success" : "warning"}
                  onClick={isPaused ? handleUnpause : handlePause}
                  className={`${isPaused ? styles.interactorButtonPrimary : styles.interactorButtonDanger} w-50`}
                  disabled={loading}
                  aria-label={isPaused ? "Unpause Contract" : "Pause Contract"}
                >
                  {loading ? <Spinner animation="border" size="sm" /> : isPaused ? 'Unpause Contract' : 'Pause Contract'}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        show={showMetadataModal}
        onHide={handleCloseMetadataModal}
        centered
        size="md"
        className={styles.interactorModalContent}
        aria-labelledby="metadata-modal-title"
      >
        <Modal.Header closeButton className={styles.interactorModalHeader}>
          <Modal.Title id="metadata-modal-title">Create Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleMetadataSubmit}>
            <Form.Group className="mb-3" controlId="metadataName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={metadataName}
                onChange={(e) => setMetadataName(e.target.value)}
                required
                className={styles.interactorFormControl}
                aria-label="Metadata Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                value={metadataDescription}
                onChange={(e) => setMetadataDescription(e.target.value)}
                required
                className={styles.interactorFormControl}
                aria-label="Metadata Description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataImage">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://example.com/image.png"
                value={metadataImage}
                onChange={(e) => setMetadataImage(e.target.value)}
                required
                className={styles.interactorFormControl}
                aria-label="Metadata Image URL"
              />
              <Form.Text className="text-muted">
                Provide the URL of the selected image.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataWebsite">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://yourwebsite.com"
                value={metadataWebsite}
                onChange={(e) => setMetadataWebsite(e.target.value)}
                className={styles.interactorFormControl}
                aria-label="Metadata Website URL"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="metadataTwitter">
              <Form.Label>Twitter URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://twitter.com/yourprofile"
                value={metadataTwitter}
                onChange={(e) => setMetadataTwitter(e.target.value)}
                className={styles.interactorFormControl}
                aria-label="Metadata Twitter URL"
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={handleCloseMetadataModal} className="me-2" aria-label="Cancel Metadata Update">
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={loading} aria-label="Submit Metadata">
                {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.interactorModalFooter}>
          {/* Optional Footer */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPrevImageModal}
        onHide={() => setShowPrevImageModal(false)}
        centered
        size="lg"
        aria-labelledby="previous-image-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="previous-image-modal-title">Full-Size Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedPrevImage && (
            <img
              src={selectedPrevImage}
              alt="Previous Full Image"
              className="img-fluid rounded"
              style={{ maxHeight: '80vh', objectFit: 'contain' }}
              onError={(e) => { e.target.onerror = null; e.target.src = '/images/broken.png'; }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setSelectedImage(selectedPrevImage);
              handleOpenMetadataModal(selectedPrevImage);
              setShowPrevImageModal(false);
            }}
            aria-label="Select Image"
          >
            Use Image
          </Button>
          <Button variant="secondary" onClick={() => setShowPrevImageModal(false)} aria-label="Close Image Modal">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InteractorPage;
