// src/pages/FAQ.js

import React, { useState } from 'react';
import {
  Accordion,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Alert,
  InputGroup,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCode, FaCopy, FaExclamationTriangle, FaQuestionCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

const FAQ = () => {
  const [showContractCode, setShowContractCode] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const handleShowContractCode = () => setShowContractCode(true);
  const handleCloseContractCode = () => setShowContractCode(false);

  const contractCode = `
  // SPDX-License-Identifier: MIT
  pragma solidity ^0.8.20;

  // Import OpenZeppelin contracts
  import "@openzeppelin/contracts/token/ERC20/ERC20.sol";
  import "@openzeppelin/contracts/token/ERC20/extensions/ERC20Permit.sol";
  import "@openzeppelin/contracts/access/Ownable.sol";           // For ownership management
  import "@openzeppelin/contracts/security/ReentrancyGuard.sol"; // For reentrancy protection

  contract Token is ERC20, ERC20Permit, Ownable, ReentrancyGuard {
      uint256 public tokenPrice;
      string public metaUrl; // Variable to store the metadata URL

      // Hardcoded fee collector address (replace with actual address)
      address private constant FEE_COLLECTOR = 0xe3e07D2e74021c94ff12B8e81450735bf7b67e49;

      // Events
      event TokenPriceSet(uint256 indexed newPrice);
      event TokensPurchased(address indexed buyer, uint256 amount, uint256 totalCost);
      event Withdrawn(address indexed withdrawnBy, uint256 amount);
      event Burn(address indexed burner, uint256 value);
      event MetaUrlUpdated(string newMetaUrl);

      uint8 private _customDecimals;

      /**
       * @dev Constructor for the Token contract.
       * @param initialOwner Address of the initial owner of the token.
       * @param name_ Name of the token.
       * @param symbol_ Symbol of the token.
       * @param initialSupply Initial total supply of the token.
       * @param decimals_ Number of decimals the token uses.
       * @param initialPrice Initial price of the token in wei.
       */
      constructor(
          address initialOwner,
          string memory name_,
          string memory symbol_,
          uint256 initialSupply,
          uint8 decimals_,
          uint256 initialPrice
      )
          ERC20(name_, symbol_)
          ERC20Permit(name_)
          Ownable(initialOwner)
      {
          _customDecimals = decimals_;
          tokenPrice = initialPrice;

          // Set default metaUrl
          metaUrl = "http://example.com/metadata.json";

          // Mint 90% of the initial supply to the owner
          uint256 ownerAmount = (initialSupply * 90) / 100;
          _mint(initialOwner, ownerAmount * 10 ** uint256(decimals_));

          // Mint 10% of the initial supply to the fee collector
          uint256 feeAmount = initialSupply - ownerAmount;
          _mint(FEE_COLLECTOR, feeAmount * 10 ** uint256(decimals_));
      }

      /**
       * @dev Override the decimals function to return the custom decimals.
       */
      function decimals() public view virtual override returns (uint8) {
          return _customDecimals;
      }

      /**
       * @dev Allows the owner to set a new token price.
       * @param newPrice The new price of the token in wei.
       */
      function setTokenPrice(uint256 newPrice) external onlyOwner {
          require(newPrice != tokenPrice, "New price must be different");
          tokenPrice = newPrice;
          emit TokenPriceSet(newPrice);
      }

      /**
       * @dev Allows users to buy tokens by sending ETH.
       */
      function buyTokens() external payable nonReentrant {
          require(msg.value > 0, "No ETH sent");

          uint256 tokenAmount = (msg.value * 10 ** uint256(_customDecimals)) / tokenPrice;
          require(balanceOf(owner()) >= tokenAmount, "Not enough tokens available for sale");

          _transfer(owner(), msg.sender, tokenAmount);

          emit TokensPurchased(msg.sender, tokenAmount, msg.value);
      }

      /**
       * @dev Allows the owner to withdraw accumulated ETH from the contract.
       */
      function withdraw() external onlyOwner nonReentrant {
          uint256 balance = address(this).balance;
          require(balance > 0, "No ETH to withdraw");

          (bool success, ) = owner().call{value: balance}("");
          require(success, "Withdraw failed");
          emit Withdrawn(msg.sender, balance);
      }

      /**
       * @dev Allows token holders to burn their tokens, reducing the total supply.
       * @param amount The amount of tokens to burn (in smallest units, including decimals).
       */
      function burn(uint256 amount) external {
          require(amount > 0, "Burn amount must be greater than zero");
          require(balanceOf(msg.sender) >= amount, "Burn amount exceeds balance");
          _burn(msg.sender, amount);
          emit Burn(msg.sender, amount);
      }

      /**
       * @dev Allows the owner to update the metadata URL associated with the token.
       * @param newMetaUrl The new metadata URL.
       */
      function updateMetaUrl(string memory newMetaUrl) external onlyOwner {
          require(bytes(newMetaUrl).length > 0, "Metadata URL cannot be empty");
          metaUrl = newMetaUrl;
          emit MetaUrlUpdated(newMetaUrl);
      }

      /**
       * @dev Fallback function to accept ETH.
       */
      receive() external payable {}
  }
  `;

  // Utility function to truncate the transaction hash
  const truncateHash = (hash) => {
    if (!hash) return '';
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  return (
    <Container className="mt-5 pt-5">
      <Row className="justify-content-center">
        <Col lg={8} md={10} sm={12}>
          <h2 className="text-center mb-4 text-light">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="0" alwaysOpen>
            {/* General Questions */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> What is TurtleBlunt Token Creator?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                TurtleBlunt Token Creator is a decentralized application (DApp) built on the Ethereum blockchain that allows users to easily create, interact with, and manage custom ERC-20 tokens. Our platform leverages Web3 technologies and Ethers.js to provide a secure and intuitive experience for both novice and experienced blockchain enthusiasts.
              </Accordion.Body>
            </Accordion.Item>

            {/* Wallet Connection */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I connect my wallet to TurtleBlunt Token Creator?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To connect your wallet:
                <ol>
                  <li>Click the <strong>"Connect Wallet"</strong> button in the top navigation bar.</li>
                  <li>Select your wallet provider (e.g., MetaMask, Coinbase Wallet).</li>
                  <li>Approve the connection request in your wallet extension or mobile app.</li>
                  <li>Your wallet address will now be displayed, granting you access to all DApp features.</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>

            {/* Supported Wallets */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> What wallets are supported?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                TurtleBlunt Token Creator supports Ethereum-compatible wallets such as:
                <ul>
                  <li><strong>MetaMask</strong></li>
                  <li><strong>Trust Wallet</strong></li>
                  <li><strong>Coinbase Wallet</strong></li>
                  <li><strong>WalletConnect</strong></li>
                </ul>
                Ensure your wallet is connected to the Ethereum network or the specified testnet as required by the DApp.
              </Accordion.Body>
            </Accordion.Item>

            {/* Token Creation */}
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I create a new token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To create a new token:
                <ol>
                  <li>Ensure your wallet is connected and you have sufficient ETH and TurtleBlunt (TBLUNT) tokens.</li>
                  <li>Navigate to the <strong>"Create Token"</strong> page.</li>
                  <li>Fill in the required details:
                    <ul>
                      <li><strong>Token Name:</strong> The name of your token.</li>
                      <li><strong>Token Symbol:</strong> The symbol representing your token (e.g., TBL).</li>
                      <li><strong>Initial Supply:</strong> The total supply of tokens to be minted.</li>
                      <li><strong>Decimals:</strong> The number of decimal places your token uses (commonly 18).</li>
                      <li><strong>Initial Price:</strong> The initial price of your token in ETH.</li>
                    </ul>
                  </li>
                  <li>Review the details and confirm the creation.</li>
                  <li>Approve the transaction in your wallet.</li>
                  <li>Your new token will be created and added to the <strong>"Listings"</strong> page.</li>
                </ol>
                <p><strong>Note:</strong> A 3% fee in TBLUNT tokens applies to token creation, supporting platform development and maintenance.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* 3% Fee Explanation */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> Why is there a 3% fee when creating a token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The 3% fee in TBLUNT tokens when creating a token is implemented to support the ongoing development and sustainability of TurtleBlunt Token Creator. This fee helps cover operational costs, fund new features, and ensure the platform remains free of ads and accessible to all users.
              </Accordion.Body>
            </Accordion.Item>

            {/* Using the Interactor Page */}
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I use the Interactor page to manage my tokens?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The Interactor page allows you to manage your tokens directly. To use it:
                <ol>
                  <li>Navigate to the <strong>"Interactor"</strong> page from the main menu.</li>
                  <li>Enter the contract address of your token or select it from your recently created tokens.</li>
                  <li>Click <strong>"Connect"</strong> to load the token details.</li>
                  <li>You can now perform various actions:
                    <ul>
                      <li><strong>Set Token Price:</strong> Adjust the price of your token.</li>
                      <li><strong>Update Metadata:</strong> Change the metadata URL associated with your token.</li>
                      <li><strong>Burn Tokens:</strong> Permanently remove tokens from circulation.</li>
                      <li><strong>Withdraw ETH:</strong> Withdraw ETH accumulated from token sales (owner only).</li>
                    </ul>
                  </li>
                  <li>Execute the desired action and confirm the transaction in your wallet.</li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>

            {/* Updating Metadata */}
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How can I update the metadata for my token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                To update the metadata for your token:
                <ol>
                  <li>Go to the <strong>"Interactor"</strong> page.</li>
                  <li>Connect to your token by entering its contract address.</li>
                  <li>In the token details, find the <strong>"Update Metadata"</strong> section.</li>
                  <li>Click on <strong>"Create/Update Metadata"</strong> to open the metadata form modal.</li>
                  <li>Fill in the required fields:
                    <ul>
                      <li><strong>Name:</strong> The name of your token.</li>
                      <li><strong>Description:</strong> A brief description of your token's purpose and features.</li>
                      <li><strong>Image URL:</strong> The URL of the image associated with your token.</li>
                      <li><strong>Website URL:</strong> A link to your token's official website.</li>
                      <li><strong>Twitter URL:</strong> A link to your token's Twitter profile.</li>
                    </ul>
                  </li>
                  <li>Submit the form. This will upload your metadata to IPFS and update the `metaUrl` in the smart contract.</li>
                  <li>Confirm the transaction in your wallet.</li>
                  <li>Once confirmed, the updated metadata will be reflected on the <strong>"Listings"</strong> page.</li>
                </ol>
                <p><strong>Note:</strong> Ensure that all URLs provided are valid and accessible to avoid broken links in your metadata.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Previously Generated Images */}
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How can I view and use previously generated images for my token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                TurtleBlunt Token Creator allows you to view and utilize previously generated images to maintain consistency and enhance your token's visual appeal. Here's how:
                <ol>
                  <li>Navigate to the <strong>"Interactor"</strong> page.</li>
                  <li>Connect to your token by entering its contract address.</li>
                  <li>In the token details, locate the <strong>"Generate Images"</strong> section.</li>
                  <li>Scroll down to the <strong>"Previously Generated Images"</strong> subsection.</li>
                  <li>Here, you'll find a list of all images you've previously generated, along with their associated prompts.</li>
                  <li>Click on any image thumbnail to view it in full size. From there, you can choose to use the image by clicking the <strong>"Use Image"</strong> button.</li>
                  <li>Selecting an image will automatically populate the metadata form with the chosen image URL, allowing you to update your token's metadata seamlessly.</li>
                </ol>
                <p><strong>Tip:</strong> Reusing previously generated images ensures brand consistency and saves time.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Image Generation */}
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How do I generate images for my token using AI?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                TurtleBlunt Token Creator integrates AI-powered image generation tools to help you create unique visuals for your token. To generate images:
                <ol>
                  <li>Go to the <strong>"Interactor"</strong> page and connect to your token.</li>
                  <li>In the <strong>"Generate Images"</strong> section, ensure you've agreed to the <strong>Terms of Service</strong>.</li>
                  <li>Enter up to 10 descriptive keywords or phrases that represent the image you want to generate.</li>
                  <li>Click on <strong>"Generate Images"</strong>. The AI will process your input and produce relevant images.</li>
                  <li>Once generated, the images will appear below. Click on an image to view it in full size.</li>
                  <li>To use a generated image, click the <strong>"Use Image"</strong> button. This will set the image as your token's metadata image, ensuring it's displayed across listings and other platforms.</li>
                </ol>
                <p><strong>Note:</strong> The quality and relevance of generated images depend on the specificity and clarity of your keywords.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Widget Customization */}
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How can I customize and embed widgets for my token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                TurtleBlunt Token Creator offers widget customization to help you showcase your token on external websites seamlessly. Here's how to customize and embed widgets:
                <ol>
                  <li>Navigate to the <strong>"Interactor"</strong> page and connect to your token.</li>
                  <li>Locate the <strong>"Customize Widget Code"</strong> section.</li>
                  <li>Configure the widget by selecting the following options:
                    <ul>
                      <li><strong>Include Custom Header Image:</strong> Toggle this option to add a header image to your widget. If enabled, provide the image URL.</li>
                      <li><strong>Include Custom Title:</strong> Toggle this option to add a custom title to your widget. If enabled, enter the desired title.</li>
                      <li><strong>Widget Size:</strong> Choose between predefined sizes (e.g., 800x800 or 600x600) to fit your website's layout.</li>
                    </ul>
                  </li>
                  <li>After configuring the options, click on <strong>"Generate Widget Code"</strong>.</li>
                  <li>The system will generate both the widget URL and the iframe code snippet.</li>
                  <li>Use the copy buttons to copy the generated code.</li>
                  <li>Embed the iframe code into your website's HTML where you want the widget to appear.</li>
                </ol>
                <p><strong>Example Iframe Code:</strong></p>
                <pre style={{ backgroundColor: '#343a40', padding: '10px', borderRadius: '5px' }}>
                  {`<iframe src="YOUR_GENERATED_WIDGET_URL" width="800" height="800" style="border: none;"></iframe>`}
                </pre>
                <p><strong>Tip:</strong> Test the widget on different devices to ensure it displays correctly across various screen sizes.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Contract Verification */}
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> How does contract verification work?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Contract verification ensures transparency and trust by allowing users to view and audit the smart contract's source code. TurtleBlunt Token Creator uses an exact match verification method to confirm that the deployed contract matches the published source code.
                <ol>
                  <li>After deploying your token, the DApp automatically attempts to verify the contract on blockchain explorers like Etherscan.</li>
                  <li>If the verification is successful, a verified badge will appear next to your contract address, indicating authenticity.</li>
                  <li>In case of verification failure, you can manually verify the contract by following detailed instructions provided within the DApp.</li>
                </ol>
                <p><strong>Benefits of Contract Verification:</strong></p>
                <ul>
                  <li><strong>Transparency:</strong> Users can inspect the contract code to ensure its integrity.</li>
                  <li><strong>Security:</strong> Verified contracts reduce the risk of hidden malicious code.</li>
                  <li><strong>Trustworthiness:</strong> A verified contract builds trust among users and investors.</li>
                </ul>
                <p><strong>Note:</strong> Ensure that your contract's compiler version and optimization settings match during verification to achieve a successful match.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Security */}
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> Is my wallet and private information safe?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Yes, your wallet information is secure. TurtleBlunt Token Creator prioritizes user security by:
                <ul>
                  <li><strong>Decentralized Authentication:</strong> Wallet connections are handled securely without exposing private keys.</li>
                  <li><strong>Data Encryption:</strong> Sensitive data is encrypted during transmission and storage.</li>
                  <li><strong>No Data Storage:</strong> The DApp does not store any personal or private information on its servers.</li>
                  <li><strong>Best Practices:</strong> Adheres to industry best practices for smart contract interactions and data handling.</li>
                </ul>
                <p><strong>Important:</strong> Always keep your wallet's private keys and seed phrases secure. TurtleBlunt Token Creator will never ask for your private information.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Tokenomics */}
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                <FaQuestionCircle className="me-2" /> What are the tokenomics of my created token?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                The tokenomics of your created token are defined during the creation process. Here's an overview:
                <ul>
                  <li><strong>Total Supply:</strong> Defined by the <strong>Initial Supply</strong> you specify during token creation.</li>
                  <li><strong>Decimals:</strong> The number of decimal places your token uses, typically set to 18.</li>
                  <li><strong>Distribution:</strong>
                    <ul>
                      <li>90% of the initial supply is minted to the token creator (owner).</li>
                      <li>10% of the initial supply is minted to the <strong>Fee Collector</strong> address to support platform development and maintenance.</li>
                    </ul>
                  </li>
                  <li><strong>Token Price:</strong> Initially set in ETH during token creation. The owner can adjust this price at any time using the <strong>"Set Token Price"</strong> feature.</li>
                </ul>
                <p><strong>Burn Functionality:</strong> Token holders can burn their tokens to reduce the total supply, increasing the value of remaining tokens.</p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Troubleshooting */}
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> I'm experiencing issues while using TurtleBlunt Token Creator. How can I troubleshoot?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Here are some common troubleshooting steps:
                <ul>
                  <li><strong>Wallet Connection Issues:</strong> Ensure your wallet extension is installed and updated. Try reconnecting or using a different browser.</li>
                  <li><strong>Network Switching Errors:</strong> Verify that you are connected to the correct network (e.g., Ethereum Mainnet or Testnet). If the error persists, try adding the network manually.</li>
                  <li><strong>Transaction Failures:</strong> Check if you have sufficient funds for gas fees. Ensure that the contract address and parameters are correct.</li>
                  <li><strong>Image Not Displaying:</strong> Make sure the image URL is valid and accessible. Use supported image formats like JPEG or PNG.</li>
                  <li><strong>Unexpected Page Reloads:</strong> Remove any browser extensions that might interfere with the DApp. Clear your browser cache and cookies.</li>
                  <li><strong>Persistent Errors:</strong> Contact our support team at <a href="mailto:support@turtleblunt.com" className="text-info">support@turtleblunt.com</a> with detailed information about the issue.</li>
                </ul>
                <Alert variant="warning" className="mt-3">
                  <strong>Tip:</strong> Always ensure you're interacting with the official TurtleBlunt Token Creator website to avoid phishing attempts.
                </Alert>
              </Accordion.Body>
            </Accordion.Item>

            {/* Additional Troubleshooting */}
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> I'm unable to update my metadata. What might be the issue?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                If you're facing issues updating your metadata:
                <ul>
                  <li><strong>Insufficient Permissions:</strong> Only the contract owner can update metadata. Ensure you're connected with the correct wallet.</li>
                  <li><strong>IPFS Upload Failure:</strong> Verify that your metadata is correctly formatted and that the IPFS upload was successful.</li>
                  <li><strong>Network Issues:</strong> Ensure you have a stable internet connection during the update process.</li>
                  <li><strong>Contract Verification:</strong> If the contract is not verified, certain functionalities might be restricted.</li>
                  <li><strong>Provide Complete Data:</strong> Ensure all required fields in the metadata form are filled out correctly.</li>
                  <li><strong>Contact Support:</strong> If all else fails, reach out to our support team for assistance.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Contact Support */}
            <Accordion.Item eventKey="15">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> How can I contact support for further assistance?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                If you've followed the troubleshooting steps and still face issues, feel free to reach out to our support team:
                <ul>
                  <li>Email: <a href="mailto:support@turtleblunt.com" className="text-info">support@turtleblunt.com</a></li>
                  <li>Discord: <a href="https://discord.gg/yourdiscord" target="_blank" rel="noopener noreferrer" className="text-info">Join our Discord Community</a></li>
                  <li>Twitter: <a href="https://twitter.com/yourtwitter" target="_blank" rel="noopener noreferrer" className="text-info">@yourtwitter</a></li>
                </ul>
                Please provide detailed information about your issue, including screenshots and transaction hashes if applicable, to help us assist you better.
              </Accordion.Body>
            </Accordion.Item>

            {/* Security */}
            <Accordion.Item eventKey="16">
              <Accordion.Header>
                <FaExclamationTriangle className="me-2 text-warning" /> Is my wallet and private information safe?
              </Accordion.Header>
              <Accordion.Body className="bg-dark text-light">
                Yes, your wallet information is secure. TurtleBlunt Token Creator prioritizes user security by:
                <ul>
                  <li><strong>Decentralized Authentication:</strong> Wallet connections are handled securely without exposing private keys.</li>
                  <li><strong>Data Encryption:</strong> Sensitive data is encrypted during transmission and storage.</li>
                  <li><strong>No Data Storage:</strong> The DApp does not store any personal or private information on its servers.</li>
                  <li><strong>Best Practices:</strong> Adheres to industry best practices for smart contract interactions and data handling.</li>
                </ul>
                <p><strong>Important:</strong> Always keep your wallet's private keys and seed phrases secure. TurtleBlunt Token Creator will never ask for your private information.</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Contract Source Code Modal */}
          <Modal show={showContractCode} onHide={handleCloseContractCode} size="lg" centered>
            <Modal.Header closeButton className="bg-dark text-light">
              <Modal.Title>Token Contract Source Code</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-light">
              <Form.Group controlId="contractCode">
                <Form.Label>Smart Contract Code (Solidity)</Form.Label>
                <Form.Control as="textarea" rows={20} readOnly value={contractCode} className="bg-dark text-light" />
              </Form.Group>
              <CopyToClipboard text={contractCode} onCopy={() => {
                setCopySuccess('Copied!');
                toast.success('Contract code copied to clipboard!', {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }}>
                <Button variant="success" className="mt-3">
                  <FaCopy className="me-2" /> Copy to Clipboard
                </Button>
              </CopyToClipboard>
              {copySuccess && <span className="text-success ms-3">{copySuccess}</span>}
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
