// src/components/ListingPage/TokenCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Card, Dropdown, Button, Menu, Typography } from 'antd';
import {
  ShareAltOutlined,
  FacebookFilled,
  TwitterSquareFilled,
  RedditSquareFilled,
  CopyOutlined,
} from '@ant-design/icons';

import { copyToClipboard } from '../utils/clipboard';
import ImageWithFallback from './ImageWithFallback';
import styles from './ListingPage.module.scss';

const { Text } = Typography;

const TokenCard = ({ token, getExplorerUrl }) => {
  const navigate = useNavigate();

  // Full sale page link
  const saleUrl = `${window.location.origin}/token-sale/${token.address}`;

  // Copy the sale page link
  const handleCopySaleLink = (domEvent) => {
    domEvent?.stopPropagation?.();
    copyToClipboard(saleUrl);
    // Removed toast notification
  };

  // Copy only the token address
  const handleCopyAddress = (domEvent) => {
    domEvent?.stopPropagation?.();
    copyToClipboard(token.address);
    // Removed toast notification
  };

  // Social share text
  const shareText = `Check out this token: ${token.name} (${token.symbol})`;

  // Handle share clicks
  const handleShareClick = (platform, domEvent) => {
    domEvent?.stopPropagation?.();

    const encodedSaleUrl = encodeURIComponent(saleUrl);
    const encodedShareText = encodeURIComponent(shareText);

    let url = '';
    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedSaleUrl}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodedSaleUrl}&text=${encodedShareText}`;
        break;
      case 'reddit':
        url = `https://www.reddit.com/submit?url=${encodedSaleUrl}&title=${encodedShareText}`;
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  // When user clicks the card (or hits Enter), go to the sale page
  const handleCardClick = () => {
    navigate(`/token-sale/${token.address}`);
  };

  // Share dropdown menu
  const menu = (
    <Menu>
      <Menu.Item
        key="facebook"
        onClick={(info) => handleShareClick('facebook', info.domEvent)}
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
      >
        <FacebookFilled style={{ color: '#1777F2' }} />
        Share on Facebook
      </Menu.Item>
      <Menu.Item
        key="twitter"
        onClick={(info) => handleShareClick('twitter', info.domEvent)}
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
      >
        <TwitterSquareFilled style={{ color: '#1DA1F2' }} />
        Share on Twitter
      </Menu.Item>
      <Menu.Item
        key="reddit"
        onClick={(info) => handleShareClick('reddit', info.domEvent)}
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
      >
        <RedditSquareFilled style={{ color: '#FF4500' }} />
        Share on Reddit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="copyLink"
        onClick={(info) => handleCopySaleLink(info.domEvent)}
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
      >
        <CopyOutlined />
        Copy Sale Link
      </Menu.Item>
    </Menu>
  );

  // Larger "Buy" button
  const buyButton = (
    <Button
      key="buy"
      size="large"
      type="primary"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/token-sale/${token.address}`);
      }}
      style={{ minWidth: '100px', fontWeight: 'bold' }}
    >
      Buy
    </Button>
  );

  return (
    <Card
      className={styles.tokenCard}
      hoverable
      onClick={handleCardClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleCardClick();
      }}
      tabIndex={0}
      cover={
        <ImageWithFallback
          src={token.imageUrl || '/images/default-token.png'}
          alt={`${token.name} logo`}
          fallback="/images/default-token.png"
          className={styles.cardImage}
        />
      }
      actions={[
        <Dropdown
          key="share"
          overlay={menu}
          trigger={['click']}
          onClick={(e) => e.stopPropagation()} // Prevent card click
        >
          <ShareAltOutlined />
        </Dropdown>,
        buyButton,
      ]}
    >
      <Card.Meta
        title={
          <Text style={{ fontSize: '1.1rem', color: '#e0e0e0' }}>
            {token.name}
          </Text>
        }
        description={
          <>
            <Text style={{ color: '#b3b3b3', display: 'block' }}>
              <strong>Symbol:</strong> {token.symbol}
            </Text>
            <Text style={{ color: '#b3b3b3', display: 'block' }}>
              <strong>Total Supply:</strong> {token.totalSupply}
            </Text>
            <div className={styles.cardAddress}>
              <Text
                style={{
                  color: '#0d6efd',
                  marginRight: '0.5rem',
                  fontSize: '0.85rem', // smaller text
                  whiteSpace: 'nowrap', // single line
                  overflow: 'hidden',   // truncate if long
                  textOverflow: 'ellipsis',
                  maxWidth: '200px',    // adjust as needed
                }}
              >
                <a
                  href={getExplorerUrl(token.address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: 'underline', color: '#0d6efd' }}
                >
                  {token.address}
                </a>
              </Text>
              <Button
                icon={<CopyOutlined />}
                type="link"
                style={{ color: '#0d6efd' }}
                onClick={handleCopyAddress}
              />
            </div>
          </>
        }
      />
    </Card>
  );
};

TokenCard.propTypes = {
  token: PropTypes.shape({
    address: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    totalSupply: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    imageUrl: PropTypes.string,
  }).isRequired,
  getExplorerUrl: PropTypes.func.isRequired,
};

export default React.memo(TokenCard);
