// src/components/BuyTbluntPanelGold.js

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Contract, formatUnits, parseEther, JsonRpcProvider } from "ethers";
import { useWeb3 } from "../Web3Context";
import { toast } from "react-toastify";
import { addTokenToWallet } from "../utils/addTokenToWallet";
import { copyToClipboard } from "../utils/clipboard";
import { formatNumber, formatDate } from "../utils/format";
import TBLUNTAbi from "../abis/TbluntToken.json";

// Ant Design Imports
import {
  Card,
  Spin,
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Tooltip,
  Divider,
} from "antd";

// Icon for copying transaction hash
import { FaCopy } from "react-icons/fa";

// Import our dark/green theme overrides
import "./BuyTbluntPanelGold.scss";

const BuyTbluntPanelGold = ({ selectedContractType, creationFee }) => { // Accept creationFee as a prop
  // ---------------------------------
  // Access Web3Context
  // ---------------------------------
  const {
    provider: connectedProvider,
    signer,
    address,
    connected,
    loading: walletLoading,
    connectWallet,
  } = useWeb3();

  // ---------------------------------
  // Define a read-only provider
  // ---------------------------------
  const readOnlyProvider = useMemo(
    () => new JsonRpcProvider(process.env.REACT_APP_BASE_SEPOLIA_RPC_URL),
    []
  );

  // ---------------------------------
  // Decide which provider to use
  // ---------------------------------
  const activeProvider = useMemo(() => {
    return connected && connectedProvider ? connectedProvider : readOnlyProvider;
  }, [connected, connectedProvider, readOnlyProvider]);

  // ---------------------------------
  // State variables
  // ---------------------------------
  const [tokenPrice, setTokenPrice] = useState(null);
  const [ethPriceUSD, setEthPriceUSD] = useState(null);
  const [usdCost, setUsdCost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataError, setDataError] = useState(null);

  const [tbluntBalance, setTbluntBalance] = useState(null);
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [ethAmount, setEthAmount] = useState("");
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [purchaseError, setPurchaseError] = useState(null);

  const [purchaseAmountError, setPurchaseAmountError] = useState(null);
  const [ethAmountError, setEthAmountError] = useState(null);

  const [receipt, setReceipt] = useState(() => {
    const cachedReceipt = localStorage.getItem(
      `receipt-${process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS}`
    );
    return cachedReceipt ? JSON.parse(cachedReceipt) : null;
  });
  const [showReceipt, setShowReceipt] = useState(receipt !== null);

  const [tokenSymbol, setTokenSymbol] = useState("");
  const [decimals, setDecimals] = useState(18);
  const [ethBalance, setEthBalance] = useState("0");

  // ---------------------------------
  // Environment Variables
  // ---------------------------------
  const tbluntTokenAddress = process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS;
  const explorerBaseUrl =
    process.env.REACT_APP_EXPLORER_URL || "https://sepolia.basescan.org/";

  // ---------------------------------
  // Helper Functions
  // ---------------------------------
  const getExplorerUrl = useCallback(
    (txHashOrAddress) => {
      if (!txHashOrAddress) return "#";
      return `${explorerBaseUrl}${
        txHashOrAddress.length === 66 ? "tx/" : "address/"
      }${txHashOrAddress}`;
    },
    [explorerBaseUrl]
  );

  const truncateHash = (hash) => {
    if (!hash) return "";
    return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
  };

  // Compute USD estimate from ETH
  const usdEstimate =
    ethAmount && ethPriceUSD
      ? formatNumber((parseFloat(ethAmount) * ethPriceUSD).toFixed(2))
      : null;

  // ---------------------------------
  // Fetch ETH balance & price if connected
  // ---------------------------------
  useEffect(() => {
    const fetchEthBalance = async () => {
      if (connected && activeProvider && address) {
        try {
          const balance = await activeProvider.getBalance(address);
          const formattedBalance = parseFloat(formatUnits(balance, 18)).toFixed(
            6
          );
          setEthBalance(formatNumber(formattedBalance));
        } catch (err) {
          console.error("Error fetching ETH balance:", err);
          setEthBalance("0.000000");
        }
      } else {
        setEthBalance("0.000000");
      }
    };

    const fetchEthPriceUSD = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        if (!response.ok) {
          throw new Error(
            `CoinGecko API responded with status ${response.status}`
          );
        }
        const priceData = await response.json();
        const ethPrice = priceData.ethereum.usd;
        setEthPriceUSD(ethPrice);
      } catch (err) {
        console.error("Error fetching ETH price:", err);
        setEthPriceUSD(null);
      }
    };

    fetchEthPriceUSD();
    if (connected && address) {
      fetchEthBalance();

      // Refresh ETH price every 60 seconds
      const interval = setInterval(() => {
        fetchEthPriceUSD();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [connected, activeProvider, address]);

  // ---------------------------------
  // Initialize token details
  // ---------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const tbluntContract = new Contract(
          tbluntTokenAddress,
          TBLUNTAbi.abi,
          activeProvider
        );

        // Symbol & Decimals
        const fetchedTokenSymbol = await tbluntContract.symbol();
        const fetchedDecimals = await tbluntContract.decimals();
        setTokenSymbol(fetchedTokenSymbol);
        setDecimals(fetchedDecimals);

        // Token Price
        const currentTokenPrice = await tbluntContract.tokenPrice();
        setTokenPrice(currentTokenPrice);

        // Estimate cost in USD
        if (ethPriceUSD && currentTokenPrice && creationFee) {
          const creationFeeInTblunt = parseFloat(
            formatUnits(creationFee, fetchedDecimals)
          );
          const tokenPriceInEth = parseFloat(
            formatUnits(currentTokenPrice, "ether")
          );
          const estimatedCostUSD = formatNumber(
            parseFloat(
              (creationFeeInTblunt * tokenPriceInEth * ethPriceUSD).toFixed(2)
            )
          );
          setUsdCost(estimatedCostUSD);
        }

        // TBLUNT Balance (if connected)
        if (connected && address) {
          const userTbluntBalance = await tbluntContract.balanceOf(address);
          const formattedTbluntBalance = parseFloat(
            formatUnits(userTbluntBalance, fetchedDecimals)
          );
          setTbluntBalance(formatNumber(formattedTbluntBalance));
        } else {
          setTbluntBalance(null);
        }

        setDataError(null);
      } catch (err) {
        console.error("Error fetching BuyTbluntPanelGold data:", err);
        setDataError(
          err.message || "An unexpected error occurred while fetching data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [connected, activeProvider, address, tbluntTokenAddress, decimals, ethPriceUSD, creationFee]);


  // ---------------------------------
  // Handlers for purchase amounts
  // ---------------------------------
  const handlePurchaseAmountChange = (e) => {
    const value = e.target.value;
    setPurchaseAmount(value);
    setPurchaseError(null);
    setPurchaseAmountError(null);
    if (value && !isNaN(value) && tokenPrice) {
      const eth =
        parseFloat(value) * parseFloat(formatUnits(tokenPrice, "ether"));
      setEthAmount(eth.toFixed(6));
    } else {
      setEthAmount("");
    }
  };

  const handleEthAmountChange = (e) => {
    const value = e.target.value;
    setEthAmount(value);
    setPurchaseError(null);
    setEthAmountError(null);
    if (value && !isNaN(value) && tokenPrice) {
      const tokens =
        parseFloat(value) / parseFloat(formatUnits(tokenPrice, "ether"));
      setPurchaseAmount(tokens.toFixed(6));
    } else {
      setPurchaseAmount("");
    }
  };

  // ---------------------------------
  // Perform Purchase
  // ---------------------------------
  const performPurchase = async (purchaseAmountValue, ethAmountValue) => {
    // Basic validations
    if (
      !purchaseAmountValue ||
      isNaN(purchaseAmountValue) ||
      Number(purchaseAmountValue) <= 0
    ) {
      setPurchaseAmountError("Please enter a valid amount of tokens to purchase.");
      return;
    }
    if (
      !ethAmountValue ||
      isNaN(ethAmountValue) ||
      Number(ethAmountValue) <= 0
    ) {
      setPurchaseError("Invalid ETH amount.");
      return;
    }
    if (connected && Number(ethAmountValue) > Number(ethBalance)) {
      setEthAmountError(`You have only ${ethBalance} ETH available.`);
      toast.warn(`You have only ${ethBalance} ETH available.`, {
        toastId: "eth-limit-warning",
      });
      return;
    }
    if (!connected) {
      setPurchaseError("Please connect your wallet to perform purchases.");
      toast.warn("Please connect your wallet to perform purchases.", {
        toastId: "connect-wallet-warning",
      });
      return;
    }

    try {
      setPurchaseLoading(true);

      const tbluntContract = new Contract(
        tbluntTokenAddress,
        TBLUNTAbi.abi,
        signer
      );
      const requiredETH = parseEther(ethAmountValue.toString());
      const tx = await tbluntContract.buyTokens({ value: requiredETH });

      toast.info("Transaction submitted. Awaiting confirmation...", {
        toastId: "purchase-tx-submitted",
      });

      const receiptTx = await tx.wait();
      const txHash = receiptTx.hash;
      if (!txHash) {
        throw new Error("Transaction hash is unavailable.");
      }

      // Block info
      const block = await activeProvider.getBlock(receiptTx.blockNumber);
      const timestamp = block.timestamp
        ? formatDate(new Date(block.timestamp * 1000).toISOString())
        : "N/A";

      toast.success("Tokens purchased successfully!", {
        toastId: "purchase-success",
      });

      // Build new receipt
      const newReceipt = {
        purchasedTokens: purchaseAmountValue,
        spentETH: ethAmountValue,
        spentUSD: ethPriceUSD
          ? (parseFloat(ethAmountValue) * ethPriceUSD).toFixed(2)
          : "0.00",
        txHash: txHash,
        blockNumber: receiptTx.blockNumber,
        timestamp: timestamp,
      };
      setReceipt(newReceipt);
      setShowReceipt(true);

      // Clear input fields
      setPurchaseAmount("");
      setEthAmount("");

      // Update balances
      const userTbluntBalance = await tbluntContract.balanceOf(address);
      const formattedTbluntBalance = parseFloat(
        formatUnits(userTbluntBalance, decimals)
      );
      setTbluntBalance(formatNumber(formattedTbluntBalance));

      const updatedEthBalance = await activeProvider.getBalance(address);
      const formattedEthBalance = parseFloat(
        formatUnits(updatedEthBalance, "ether")
      ).toFixed(6);
      setEthBalance(formatNumber(formattedEthBalance));

      // Save to local storage
      localStorage.setItem(
        `receipt-${tbluntTokenAddress}`,
        JSON.stringify(newReceipt)
      );

      // Dispatch event
      window.dispatchEvent(new Event("tbluntBalanceUpdated"));

      // Optionally refresh ETH price
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      if (response.ok) {
        const priceData = await response.json();
        setEthPriceUSD(priceData.ethereum.usd);
      }
    } catch (err) {
      console.error("Error purchasing tokens:", err);
      if (err.code === 4001) {
        toast.error("Transaction rejected by user.", {
          toastId: "purchase-tx-rejected",
        });
      } else if (err.message.includes("revert")) {
        toast.error(`Transaction failed: ${err.reason || err.message}`, {
          toastId: "purchase-tx-failed",
        });
      } else {
        toast.error("Failed to purchase tokens.", {
          toastId: "purchase-tx-failed",
        });
      }
      setPurchaseError("Failed to purchase TBLUNT tokens.");
    } finally {
      setPurchaseLoading(false);
    }
  };

  // ---------------------------------
  // Handle Purchase Submission
  // ---------------------------------
  const handlePurchase = async () => {
    await performPurchase(purchaseAmount, ethAmount);
  };

  // ---------------------------------
  // Close Receipt Modal
  // ---------------------------------
  const handleCloseReceipt = () => {
    setShowReceipt(false);
  };

  // ---------------------------------
  // Add token to wallet
  // ---------------------------------
  const handleAddTokenToWallet = async () => {
    const tokenAddress = tbluntTokenAddress;
    const tokenSymbolEnv =
      process.env.REACT_APP_TBLUNT_TOKEN_SYMBOL || tokenSymbol;
    const tokenDecimalsEnv =
      parseInt(process.env.REACT_APP_TBLUNT_TOKEN_DECIMALS) || decimals;
    const tokenImage = process.env.REACT_APP_TBLUNT_TOKEN_IMAGE_URL || "";

    await addTokenToWallet({
      tokenAddress,
      tokenSymbol: tokenSymbolEnv,
      tokenDecimals: tokenDecimalsEnv,
      tokenImage,
    });
  };

  // ---------------------------------
  // Buy a Fixed Amount (Creation Fee)
  // ---------------------------------
  const handleBuyFixedAmount = async () => {
    if (!creationFee || !tokenPrice) {
      toast.error(
        "Cannot perform purchase. Missing creation fee or token price.",
        {
          toastId: "fixed-purchase-error",
        }
      );
      return;
    }

    const fixedPurchaseAmount = parseFloat(formatUnits(creationFee, decimals));
    const fixedEthAmount =
      fixedPurchaseAmount * parseFloat(formatUnits(tokenPrice, "ether"));

    await performPurchase(
      fixedPurchaseAmount.toString(),
      fixedEthAmount.toFixed(6)
    );
  };

  // ---------------------------------
  // Loading / Error States
  // ---------------------------------
  if (loading || walletLoading) {
    return (
      <div className="buy-tblunt-panel-dark">
        <Card>
          <div style={{ padding: "2rem", textAlign: "center" }}>
            <Spin tip="Loading..." size="large" />
          </div>
        </Card>
      </div>
    );
  }

  if (dataError) {
    return (
      <div className="buy-tblunt-panel-dark">
        <Card>
          <div style={{ padding: "2rem" }}>
            <Alert
              message={`Error: ${dataError}`}
              type="error"
              showIcon
              style={{ marginBottom: "1rem" }}
            />
          </div>
        </Card>
      </div>
    );
  }

  // ---------------------------------
  // Main Return
  // ---------------------------------
  return (
    <div className="buy-tblunt-panel-dark">
      <Card>
        {/* Creation Fee & TBLUNT Balance */}
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "2rem" }}>
            {creationFee !== null && creationFee !== undefined
              ? `${formatUnits(creationFee, decimals)} TBLUNT`
              : "Loading..."}
            <br />
            {usdCost ? <span style={{ fontWeight: "bold" }}>${usdCost} USD</span> : ""}
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <h3>TBLUNT Balance</h3>
            {connected ? (
              tbluntBalance !== null ? (
                `${tbluntBalance} TBLUNT`
              ) : (
                "Loading..."
              )
            ) : (
              <span>Connect your wallet to see your balance.</span>
            )}
          </div>
        </div>

        {/* Buy Fixed Amount */}
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Button
            type="primary"
            onClick={handleBuyFixedAmount}
            loading={purchaseLoading}
            disabled={!creationFee || !tokenPrice}
            style={{ width: "100%", maxWidth: "300px" }}
          >
            {`Buy ${
              creationFee ? formatUnits(creationFee, decimals) : "..."
            } TBLUNT`}
          </Button>
        </div>

        <Divider>OR</Divider>

        {/* Purchase Form */}
        <Form layout="vertical" onFinish={handlePurchase}>
          {/* Purchase Amount */}
          <Form.Item
            label="Amount of TBLUNT"
            help={
              purchaseAmountError && (
                <span style={{ color: "red" }}>{purchaseAmountError}</span>
              )
            }
          >
            <Input
              type="number"
              placeholder="Enter number of TBLUNT"
              value={purchaseAmount}
              onChange={handlePurchaseAmountChange}
              disabled={purchaseLoading || !connected}
              style={{
                textAlign: "center",
                backgroundColor: "#2c2c2c",
                color: "#ffffff",
                borderColor: "#a5d6a7",
              }}
            />
          </Form.Item>

          {/* ETH Amount */}
          <Form.Item
            label="Amount of ETH"
            help={
              <>
                {ethAmountError && (
                  <div style={{ color: "red", marginBottom: "0.5rem" }}>
                    {ethAmountError}
                  </div>
                )}
                {usdEstimate && (
                  <span style={{ fontWeight: "bold" }}>≈ ${usdEstimate} USD</span>
                )}
              </>
            }
          >
            <Input
              type="number"
              placeholder="Enter amount of ETH"
              value={ethAmount}
              onChange={handleEthAmountChange}
              disabled={purchaseLoading || !connected}
              style={{
                textAlign: "center",
                backgroundColor: "#2c2c2c",
                color: "#ffffff",
                borderColor: "#a5d6a7",
              }}
            />
          </Form.Item>

          {/* Purchase or Connect */}
          <Form.Item style={{ textAlign: "center", marginTop: "2rem" }}>
            {connected ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={purchaseLoading}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  backgroundColor: "#a5d6a7",
                  borderColor: "#a5d6a7",
                }}
              >
                Purchase TBLUNT
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={connectWallet}
                loading={walletLoading}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  backgroundColor: "#a5d6a7",
                  borderColor: "#a5d6a7",
                }}
              >
                Connect Wallet to Purchase
              </Button>
            )}
          </Form.Item>

          {/* Purchase Error */}
          {purchaseError && (
            <Alert
              message={purchaseError}
              type="error"
              showIcon
              style={{ marginTop: "1rem" }}
            />
          )}
        </Form>

        {/* Purchase Receipt Modal */}
        <Modal
          title="Purchase Receipt"
          visible={showReceipt}
          onCancel={handleCloseReceipt}
          footer={[
            <Button key="close" type="primary" onClick={handleCloseReceipt}>
              Close
            </Button>,
          ]}
          bodyStyle={{ backgroundColor: '#1e1e1e', color: '#ffffff' }}
        >
          {receipt ? (
            <>
              <Row style={{ marginBottom: "0.5rem" }}>
                <Col span={12}>
                  <strong>Tokens Purchased:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {formatNumber(receipt.purchasedTokens)} {tokenSymbol}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5rem" }}>
                <Col span={12}>
                  <strong>ETH Spent:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {formatNumber(receipt.spentETH)} ETH
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5rem" }}>
                <Col span={12}>
                  <strong>Equivalent USD:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  ${receipt.spentUSD} USD
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5rem" }}>
                <Col span={12}>
                  <strong>Transaction Hash:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {receipt.txHash ? (
                    <Tooltip title={receipt.txHash}>
                      <a
                        href={getExplorerUrl(receipt.txHash)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#a5d6a7",
                          textDecoration: "underline",
                          wordBreak: "break-all",
                        }}
                      >
                        {truncateHash(receipt.txHash)}
                      </a>
                    </Tooltip>
                  ) : (
                    "Transaction hash unavailable."
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5rem" }}>
                <Col span={12}>
                  <strong>Block Number:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {receipt.blockNumber}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5rem" }}>
                <Col span={12}>
                  <strong>Timestamp:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {receipt.timestamp}
                </Col>
              </Row>
            </>
          ) : (
            <p>No receipt information available.</p>
          )}
        </Modal>

        {/* Persistent Receipt Display (below the form) */}
        {receipt && (
          <Card className="receiptCard">
            <div className="ant-card-body">
              <h3 className="receiptTitle">Purchase Receipt</h3>
              <Row style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <strong>Tokens Purchased:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {formatNumber(receipt.purchasedTokens)} {tokenSymbol}
                </Col>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <strong>ETH Spent:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {formatNumber(receipt.spentETH)} ETH
                </Col>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <strong>Equivalent USD:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  ${receipt.spentUSD} USD
                </Col>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <strong>Transaction Hash:</strong>
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {receipt.txHash ? (
                    <>
                      <Tooltip title="Copy Transaction Hash">
                        <a
                          href={getExplorerUrl(receipt.txHash)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#a5d6a7",
                            textDecoration: "underline",
                            fontFamily: "'Courier New', Courier, monospace",
                            marginRight: "8px",
                            maxWidth: "180px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {truncateHash(receipt.txHash)}
                        </a>
                      </Tooltip>
                      <Tooltip title="Copy Transaction Hash">
                        <Button
                          size="small"
                          style={{ borderColor: "#a5d6a7", color: "#a5d6a7" }}
                          onClick={() => copyToClipboard(receipt.txHash)}
                        >
                          <FaCopy />
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    "Transaction hash unavailable."
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <strong>Block Number:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {receipt.blockNumber}
                </Col>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                  <strong>Timestamp:</strong>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {receipt.timestamp}
                </Col>
              </Row>

              {/* Add Token & Clear Buttons */}
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ marginRight: "1rem", backgroundColor: "#a5d6a7", borderColor: "#a5d6a7" }}
                  onClick={handleAddTokenToWallet}
                >
                  Add {tokenSymbol} to Wallet
                </Button>
                <Button
                  danger
                  onClick={() => {
                    setShowReceipt(false);
                    setReceipt(null);
                    localStorage.removeItem(
                      `receipt-${tbluntTokenAddress}`
                    );
                  }}
                >
                  Clear Receipt
                </Button>
              </div>
            </div>
          </Card>
        )}
      </Card>
    </div>
  );
};

export default BuyTbluntPanelGold;
