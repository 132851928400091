import React, { useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Card,
  Collapse,
  Form,
  Input,
  Button,
  Alert,
} from 'antd';
import {
  QuestionCircleOutlined,
  MailOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import './ContactPage.scss';

const { Panel } = Collapse;
const { TextArea } = Input;
const { Content } = Layout;

const ContactPage = () => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    // Honeypot
    if (values.honeypot) {
      setAlert({
        show: true,
        type: 'error',
        message: 'Spam detected.',
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://testnet.turtleblunt.com/api/support/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setAlert({
          show: true,
          type: 'success',
          message: data.message || 'Your message has been sent successfully!',
        });
        form.resetFields();
      } else {
        setAlert({
          show: true,
          type: 'error',
          message: data.error || 'Error sending your message. Please try again later.',
        });
      }
    } catch (error) {
      setAlert({
        show: true,
        type: 'error',
        message: 'Network error occurred. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="contact-container my-5">
      <Content>
        <Row justify="center">
          <Col xs={24} md={20}>
            <Card className="contact-card" bordered={false}>
              <h1 className="text-center">Contact Us</h1>

              {/* FAQ Section */}
              <h2 className="mt-5">Frequently Asked Questions</h2>
              <Collapse accordion className="faq-collapse mt-3">
                <Panel
                  header={
                    <>
                      <QuestionCircleOutlined className="me-2" />
                      Requesting a Refund
                    </>
                  }
                  key="1"
                >
                  <p>
                    If you believe you are eligible for a refund, please contact us through the form below with your transaction details. Refunds are subject to our refund policy and are not guaranteed.
                  </p>
                </Panel>

                <Panel
                  header={
                    <>
                      <QuestionCircleOutlined className="me-2" />
                      Problem With Token Creation
                    </>
                  }
                  key="2"
                >
                  <p>
                    Experiencing issues during token creation? Ensure all required fields are correctly filled and your wallet is properly connected. If the problem persists, reach out to our support team for assistance.
                  </p>
                </Panel>

                <Panel
                  header={
                    <>
                      <QuestionCircleOutlined className="me-2" />
                      Token Failed to Create but Charged
                    </>
                  }
                  key="3"
                >
                  <p>
                    If you were charged but your token was not created successfully, please contact us immediately with your transaction hash and relevant details. We will investigate the issue and work towards a resolution.
                  </p>
                </Panel>

                <Panel
                  header={
                    <>
                      <QuestionCircleOutlined className="me-2" />
                      Mistake with Token Details
                    </>
                  }
                  key="4"
                >
                  <p>
                    Accidentally entered incorrect token details? Unfortunately, once a token is created on the blockchain, details cannot be altered. Please contact our support team to discuss possible solutions or guidance on best practices for future token creations.
                  </p>
                </Panel>

                <Panel
                  header={
                    <>
                      <QuestionCircleOutlined className="me-2" />
                      Other Issues
                    </>
                  }
                  key="5"
                >
                  <p>
                    Facing a different issue not listed above? Please use the contact form below to describe your problem in detail, and our support team will assist you promptly.
                  </p>
                </Panel>
              </Collapse>

              {/* Contact Form */}
              <h2 className="mt-5">Get in Touch</h2>
              {alert.show && (
                <Alert
                  message={alert.message}
                  type={alert.type}
                  showIcon
                  closable
                  className="mt-3"
                  onClose={() => setAlert({ ...alert, show: false })}
                />
              )}

              <Form
                form={form}
                layout="vertical"
                className="contact-form mt-3"
                onFinish={onFinish}
              >
                {/* Honeypot Field */}
                <Form.Item name="honeypot" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please enter your name' }]}
                >
                  <Input placeholder="Enter your name" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your email' },
                    { type: 'email', message: 'Please enter a valid email' },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>

                <Form.Item
                  label="Subject"
                  name="subject"
                  rules={[{ required: true, message: 'Please enter a subject' }]}
                >
                  <Input placeholder="Subject" />
                </Form.Item>

                <Form.Item
                  label="Message"
                  name="message"
                  rules={[{ required: true, message: 'Please enter your message' }]}
                >
                  <TextArea rows={5} placeholder="Your message..." />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>

              {/* Alternative Contact Information */}
              <h2 className="mt-5">Alternative Contact Methods</h2>
              <ul className="alternative-contact mt-3">
                <li>
                  <MailOutlined className="me-2" />
                  <strong>Email:</strong>{' '}
                  <a href="mailto:contact@turtleblunt.com" className="text-info">
                    contact@turtleblunt.com
                  </a>
                </li>
                
              </ul>

              <p className="mt-4 text-center">
                We're here to help! If you have any other questions or need further assistance, don't hesitate to reach out to us.
              </p>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ContactPage;
