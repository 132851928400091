// src/components/CreateTokenFormInputs.js

import React from "react";
import { Form, Input, InputNumber, Checkbox, Alert } from "antd";
import { parseUnits } from "ethers";
import "./CreateTokenFormInputsUnified.scss"; // Updated import

const CreateTokenFormInputs = ({
  name,
  setName,
  symbol,
  setSymbol,
  initialSupply,
  setInitialSupply,
  decimals,
  setDecimals,
  initialPrice,
  setInitialPrice,
  agreed,
  setAgreed,
  MIN_INITIAL_SUPPLY,
  MAX_INITIAL_SUPPLY,
  MIN_INITIAL_PRICE,
}) => {
  // Custom validation for Initial Supply
  const validateInitialSupply = (_, value) => {
    if (value === undefined || value === null || value === "") {
      return Promise.reject(new Error("Initial Supply is required."));
    }
    const supplyNum = Number(value);
    if (Number.isNaN(supplyNum)) {
      return Promise.reject(new Error("Initial Supply must be a valid number."));
    }
    if (supplyNum < Number(MIN_INITIAL_SUPPLY) || supplyNum > Number(MAX_INITIAL_SUPPLY)) {
      return Promise.reject(
        new Error(
          `Initial Supply must be between ${MIN_INITIAL_SUPPLY} and ${MAX_INITIAL_SUPPLY}.`
        )
      );
    }
    return Promise.resolve();
  };

  // Custom validation for Decimals
  const validateDecimals = (_, value) => {
    if (value === undefined || value === null) {
      return Promise.reject(new Error("Decimals is required."));
    }
    if (!Number.isInteger(value) || value < 0) {
      return Promise.reject(new Error("Decimals must be a non-negative integer."));
    }
    return Promise.resolve();
  };

  // Custom validation for Initial Price (ETH)
  const validateInitialPrice = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Initial Price is required."));
    }
    const regex = /^(?:\d+\.\d{1,18}|\.\d{1,18}|\d+)$/;
    if (!regex.test(value)) {
      return Promise.reject(
        new Error("Initial Price must be a valid number with up to 18 decimal places.")
      );
    }
    try {
      const weiVal = parseUnits(String(value), "ether");
      if (weiVal < 1n) {
        return Promise.reject(
          new Error(`Initial Price must be at least ${MIN_INITIAL_PRICE} ETH.`)
        );
      }
    } catch (err) {
      return Promise.reject(new Error("Invalid initial price format."));
    }
    return Promise.resolve();
  };

  return (
    <div className="create-token-form-inputs-container">
      {/* Token Name */}
      <Form.Item
        label="Token Name"
        name="tokenName"
        className="form-item" // Standardized class name
        rules={[{ required: true, message: "Please enter the token name." }]}
      >
        <Input
          placeholder="Enter token name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input-field" // Standardized class name
        />
      </Form.Item>

      {/* Token Symbol */}
      <Form.Item
        label="Token Symbol"
        name="tokenSymbol"
        className="form-item" // Standardized class name
        rules={[{ required: true, message: "Please enter the token symbol." }]}
      >
        <Input
          placeholder="Enter token symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          className="input-field" // Standardized class name
        />
      </Form.Item>

      {/* Initial Supply (InputNumber) */}
      <Form.Item
        label="Initial Supply"
        name="initialSupply"
        className="form-item" // Standardized class name
        rules={[
          {
            required: true,
            message: "Please enter the initial supply.",
          },
          {
            validator: validateInitialSupply,
          },
        ]}
      >
        <InputNumber
          placeholder={`Enter supply (${MIN_INITIAL_SUPPLY} - ${MAX_INITIAL_SUPPLY})`}
          style={{ width: "100%" }}
          value={initialSupply}
          min={Number(MIN_INITIAL_SUPPLY)}
          max={Number(MAX_INITIAL_SUPPLY)}
          onChange={(val) => setInitialSupply(val)}
          className="input-field" // Standardized class name
        />
      </Form.Item>

      {/* Decimals */}
      <Form.Item
        label="Decimals"
        name="decimals"
        className="form-item" // Standardized class name
        rules={[
          { required: true, message: "Please enter the number of decimals." },
          {
            validator: validateDecimals,
          },
        ]}
      >
        <InputNumber
          placeholder="Enter decimals"
          style={{ width: "100%" }}
          value={decimals}
          min={0}
          onChange={(val) => setDecimals(Number(val))}
          className="input-field" // Standardized class name
        />
      </Form.Item>

      {/* Initial Price (ETH) as an Input (string) */}
      <Form.Item
        label="Initial Price (ETH)"
        name="initialPrice"
        className="form-item" // Standardized class name
        rules={[
          { required: true, message: "Please enter the initial price in ETH." },
          {
            validator: validateInitialPrice,
          },
        ]}
      >
        <Input
          placeholder={`Enter initial price (${MIN_INITIAL_PRICE} - up to 18 decimals)`}
          value={initialPrice}
          onChange={(e) => {
            let val = e.target.value;
            // Auto-prepend '0' if user types just "."
            if (val.startsWith(".")) {
              val = "0" + val;
            }
            setInitialPrice(val);
          }}
          className="input-field" // Standardized class name
        />
      </Form.Item>

      {/* Disclaimer Checkbox */}
      <Form.Item
        name="agreement"
        valuePropName="checked"
        className="form-item" // Standardized class name
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    new Error(
                      "You must agree to the Disclaimer and Terms of Service to proceed."
                    )
                  ),
          },
        ]}
      >
        <Checkbox
          checked={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
          className="disclaimer-checkbox"
        >
          I agree to the{" "}
          <a href="/disclaimer" target="_blank" rel="noreferrer">
            Disclaimer
          </a>{" "}
          and{" "}
          <a href="/tos" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          .
        </Checkbox>
      </Form.Item>

      <Alert
        message="After creating your token, you can generate an image and update metadata to customize your sale page."
        type="info"
        showIcon
        className="inputs-alert"
      />
    </div>
  );
};

export default CreateTokenFormInputs;
