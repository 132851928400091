import React, { useState } from "react";
import { Layout, Segmented, Typography, Button, Drawer } from "antd";
import { SwapOutlined, DollarOutlined } from '@ant-design/icons';
import CreateTokenEth from './CreateTokenEth';
import CreateTokenTBLUNT from './CreateTokenTBLUNT';
import FactoryCreateTokenEth from './FactoryCreateTokenEth';
import FactoryCreateToken from './FactoryCreateToken';
import BuyTbluntPanel from './BuyTbluntPanel'; // Import the BuyTbluntPanelGold component
import { useWeb3 } from "../Web3Context"; // Import useWeb3 to access lastCreatedToken
import './CreateToken.scss'; // For any specific styles

const { Content } = Layout;
const { Title, Paragraph } = Typography; // Import Paragraph for descriptions

const CreateToken = () => {
  // State for Mode Selection: 'standard' or 'factory'
  const [creationMode, setCreationMode] = useState('standard');

  // State for Payment Method: 'eth' or 'tblunt'
  const [paymentMethod, setPaymentMethod] = useState('eth');

  const { lastCreatedToken } = useWeb3(); // Destructure lastCreatedToken from context

  const handlePaymentChange = (value) => {
    setPaymentMethod(value);
  };

  const handleModeChange = (value) => {
    setCreationMode(value);
    // Optionally, reset paymentMethod to default when mode changes
    setPaymentMethod('eth');
  };

  // State for Drawer visibility
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const showDrawer = () => { setIsDrawerVisible(true); };
  const closeDrawer = () => { setIsDrawerVisible(false); };

  // **New State for Creation Fee**
  const [creationFee, setCreationFee] = useState(null);

  // Helper function to adjust the creationFee
  const adjustCreationFee = (fee) => {
    if (!fee) return null;
    // Convert to string and remove trailing `.0`
    const feeStr = fee.toString().endsWith('.0') ? fee.toString().slice(0, -2) : fee.toString();
    // Multiply by 100 to add two zeros
    return parseInt(feeStr, 10) * 100;
  };

  // Adjust the creationFee before passing it as a prop
  const adjustedCreationFee = creationFee ? adjustCreationFee(creationFee) : null;

  // Determine which component to render based on mode and payment method
  const renderCreateTokenComponent = () => {
    if (creationMode === 'standard') {
      return paymentMethod === 'eth' ? (
        <CreateTokenEth />
      ) : (
        <CreateTokenTBLUNT onSetCreationFee={setCreationFee} />
      );
    } else { // Factory mode
      return paymentMethod === 'eth' ? (
        <FactoryCreateTokenEth />
      ) : (
        <FactoryCreateToken />
      );
    }
  };

  // Descriptions for each mode
  const modeDescriptions = {
    standard: "Standard mode offers similar match verification and is deployed through a shared smart contract. It does not support custom contract names.",
    factory: "Factory mode provides exact match verification with independently deployed contracts, allowing for custom contract names."
  };

  return (
    <Layout className="create-token-layout">
      <Content style={{ padding: '2rem' }}>
        {/* Conditionally render the toggles and title only if no token has been created */}
        {!lastCreatedToken && (
          <div className="toggle-container">
            {/* Large Toggle Switch for Mode Selection */}
            <Title level={2} className="mode-toggle-title">
              Select Creation Mode
            </Title>
            <div className="mode-toggle-bar">
              <Segmented
                options={[
                  { label: 'Standard', value: 'standard' },
                  { label: 'Factory', value: 'factory' },
                ]}
                value={creationMode}
                onChange={handleModeChange}
                size="large"
                className="mode-segmented"
              />
            </div>

            {/* Description for the selected mode */}
            <Paragraph className="mode-description">
              {modeDescriptions[creationMode]}
            </Paragraph>

            {/* Enhanced Title for Payment Type Selection */}
            <Title level={3} className="toggle-subtitle">
              Choose Your Payment Type
            </Title>
            {/* Payment Method Toggle Switch */}
            <div className="toggle-bar">
              <Segmented
                options={[
                  { label: <span><DollarOutlined /> ETH</span>, value: 'eth' },
                  { label: <span><SwapOutlined /> TBLUNT</span>, value: 'tblunt' },
                ]}
                value={paymentMethod}
                onChange={handlePaymentChange}
                size="large"
              />
            </div>
            {/* "Need TBLUNT?" Button */}
            {paymentMethod === 'tblunt' && (
              <Button
                type="link"
                onClick={showDrawer}
                className="need-tblunt-button"
              >
                Need TBLUNT?
              </Button>
            )}
          </div>
        )}
        {/* Render the appropriate component based on the selected mode and payment method */}
        {renderCreateTokenComponent()}

        {/* Drawer Component for Buy TBLUNT Panel */}
        <Drawer
          title="Buy TBLUNT Tokens"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawerVisible}
          width={400}
          bodyStyle={{ backgroundColor: '#1e1e1e', color: '#ffffff' }} // Match dark theme
        >
          {/* Pass the adjusted creationFee as a prop */}
          <BuyTbluntPanel creationFee={adjustedCreationFee} />
        </Drawer>
      </Content>
    </Layout>
  );
};

export default CreateToken;
