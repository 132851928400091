// src/components/BrandInfoBar.js

import React from 'react';
import { Container, Button, Card } from 'react-bootstrap';
import { FaShieldAlt, FaDollarSign, FaLightbulb } from 'react-icons/fa';
import './BrandInfoBar.scss'; // We'll define styles here

const BrandInfoBar = () => {
  return (
    <Card className="brand-info-bar text-center text-white mb-4">
      <Container className="p-5">
        {/* Replace with your brand's logo */}
        <Card.Img
          variant="top"
          src="/images/TurtleBlunt.png" // Ensure this path is correct
          alt="Your Brand Logo"
          className="brand-logo mb-4" // Increased bottom margin
        />
        <Card.Body>
          <Card.Text className="brand-info-text">
            <p>
              Empower your vision by creating tokens seamlessly on TurtleBlunt TokenCreator. Our platform ensures secure, low-cost, and builder-friendly experiences.
            </p>
            <div className="feature-list">
              <div className="feature-item">
                <FaShieldAlt className="feature-icon" />
                <span>Secure Token Creation</span>
              </div>
              <div className="feature-item">
                <FaDollarSign className="feature-icon" />
                <span>Low Transaction Fees</span>
              </div>
              <div className="feature-item">
                <FaLightbulb className="feature-icon" />
                <span>Innovative Tools</span>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Container>
    </Card>
  );
};

export default BrandInfoBar;
