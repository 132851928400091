// src/components/CreateTokenTBLUNT.js

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Contract, parseUnits, formatUnits, Signature, Interface } from "ethers";
import { useWeb3 } from "../Web3Context";

// ABIs
import TokenCreationManagerAbi from '../abis/TokenCreationManagerAbi.json';
import MintablePausableTokenCreationManagerAbi from '../abis/MintablePausableTokenCreationManagerAbi.json';
import MintableTokenCreationManagerAbi from '../abis/MintableTokenCreationManagerAbi.json';
import PausableTokenCreationManagerAbi from '../abis/PausableTokenCreationManagerAbi.json';
import CappedMintableTokenCreationManagerAbi from '../abis/CappedMintableTokenCreationManagerAbi.json';
import CappedMintablePausableTokenCreationManagerAbi from '../abis/CappedMintablePausableTokenCreationManagerAbi.json';
import TBLUNTAbi from '../abis/TbluntToken.json';

// Small components
import TokenCreationFeeTopPanelTblunt from './TokenCreationFeeTopPanelTblunt'; 
import TokenTypeFeatures from './TokenTypeFeaturesStandard'; 
import CreateTokenFormInputsTblunt from "./CreateTokenFormInputsTblunt";
import TokenInfo from './TokenInfo'; 
import { copyToClipboard } from '../utils/clipboard';

// Ant Design
import {
  Layout,
  Card,
  Form,
  Button,
  Alert,
  Spin,
  Modal,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import { 
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import './CreateTokenForm.scss';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

// Validation Constants
const MIN_INITIAL_PRICE = "0.000000000000000001";  // 1 wei in ETH
const MAX_INITIAL_PRICE = "100000000000000000";    // 1e17 ETH
const MIN_INITIAL_SUPPLY = "1";
const MAX_INITIAL_SUPPLY = "10000000000000000000000"; // 1e22 supply (example)

// TBLUNT decimals
const TBLUNT_DECIMALS = 2;

/**
 * We removed the `BASE_FEATURES` array and no longer store features in `TOKEN_TYPES`.
 * We only keep the data needed for contract interaction (ABI, addresses, decimals).
 */
const TOKEN_TYPES = [
  {
    label: 'Standard',
    value: 'standard',
    abi: TokenCreationManagerAbi.abi,
    address: process.env.REACT_APP_TOKEN_CREATION_MANAGER_ADDRESS,
    creationFeeDecimals: TBLUNT_DECIMALS,
    description: 'Basic ERC20 token with standard functionalities.',
  },
  {
    label: 'Mintable Pausable',
    value: 'mintablePausable',
    abi: MintablePausableTokenCreationManagerAbi.abi,
    address: process.env.REACT_APP_MINTABLE_PAUSABLE_TOKEN_CREATION_MANAGER,
    creationFeeDecimals: TBLUNT_DECIMALS,
    description: 'ERC20 token that allows minting and pausing of transfers.',
  },
  {
    label: 'Pausable',
    value: 'pausable',
    abi: PausableTokenCreationManagerAbi.abi,
    address: process.env.REACT_APP_PAUSABLE_TOKEN_CREATION_MANAGER,
    creationFeeDecimals: TBLUNT_DECIMALS,
    description: 'ERC20 token that allows pausing of transfers.',
  },
  {
    label: 'Mintable',
    value: 'mintable',
    abi: MintableTokenCreationManagerAbi.abi,
    address: process.env.REACT_APP_MINTABLE_TOKEN_CREATION_MANAGER,
    creationFeeDecimals: TBLUNT_DECIMALS,
    description: 'ERC20 token that supports minting of new tokens.',
  },
  {
    label: 'Capped Mintable',
    value: 'cappedmintable',
    abi: CappedMintableTokenCreationManagerAbi.abi,
    address: process.env.REACT_APP_CAPPED_MINTABLE_TOKEN_CREATION_MANAGER,
    creationFeeDecimals: TBLUNT_DECIMALS,
    description: 'ERC20 token with a capped supply and pausable transfers.',
  },
  {
    label: 'Capped Mintable Pausable',
    value: 'cappedmintablepausable',
    abi: CappedMintablePausableTokenCreationManagerAbi.abi,
    address: process.env.REACT_APP_CAPPED_MINTABLE_PAUSABLE_TOKEN_CREATION_MANAGER,
    creationFeeDecimals: TBLUNT_DECIMALS,
    description: 'Comprehensive ERC20 token with cap, minting, and pausing functionalities.',
  },
];

const CreateTokenTBLUNT = ({ onSetCreationFee }) => {
  const { 
    signer,
    address,
    numericChainId,
    error: web3Error,
    lastCreatedToken,
    updateLastCreatedToken,
    clearLastCreatedToken,
  } = useWeb3();

  // ============ State ============
  const [selectedType, setSelectedType] = useState("");
  // Form inputs
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [decimals, setDecimals] = useState(4);
  const [initialPrice, setInitialPrice] = useState("");
  const [agreed, setAgreed] = useState(false);

  const [error, setError] = useState(web3Error);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  // For the review modal
  const [showReview, setShowReview] = useState(false);
  const [tokensAfterTax, setTokensAfterTax] = useState("");

  // For newly created token info
  const [newTokenAddress, setNewTokenAddress] = useState(lastCreatedToken?.tokenAddress || null);
  const [transactionHash, setTransactionHash] = useState(lastCreatedToken?.transactionHash || '');
  const [widgetCode, setWidgetCode] = useState(lastCreatedToken?.widgetCode || '');

  // Processing modal
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const processingSteps = ["Approving TBLUNT Usage", "Creating Token"];

  // ============ Identify the Selected Token Type ============
  const selectedTokenType = useMemo(() => {
    return TOKEN_TYPES.find((type) => type.value === selectedType) || null;
  }, [selectedType]);

  // ============ Contract Instances ============
  const tokenCreationManagerContract = useMemo(() => {
    if (!signer || !selectedTokenType) return null;
    try {
      return new Contract(selectedTokenType.address, selectedTokenType.abi, signer);
    } catch (err) {
      console.error("Error initializing manager contract:", err);
      setError(`Error initializing ${selectedTokenType?.label} TokenCreationManager.`);
      return null;
    }
  }, [signer, selectedTokenType]);

  const tbluntContract = useMemo(() => {
    if (!signer || !process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS) return null;
    try {
      return new Contract(process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS, TBLUNTAbi.abi, signer);
    } catch (err) {
      console.error("Error initializing TBLUNT contract:", err);
      setError("Error initializing TBLUNT contract.");
      return null;
    }
  }, [signer]);

  // ============ Fetch TBLUNT Balance ============
  const fetchBalances = useCallback(async () => {
    if (!tbluntContract || !address) return;
    try {
      const tblunt = await tbluntContract.balanceOf(address);
      // If you need the TBLUNT balance, store it in state
      // setTbluntBalance(tblunt); 
    } catch (err) {
      console.error("Error fetching TBLUNT balance:", err);
      setError("Error fetching TBLUNT balance.");
    }
  }, [tbluntContract, address]);

  // ============ Fetch TBLUNT Sale Price (ETH) ============
  const [salePriceETH, setSalePriceETH] = useState(null);
  useEffect(() => {
    const fetchSalePrice = async () => {
      if (!tbluntContract) return;
      try {
        const price = await tbluntContract.tokenPrice();
        setSalePriceETH(parseFloat(formatUnits(price, "ether")));
      } catch (err) {
        console.error("Error fetching TBLUNT sale price:", err);
        setError("Error fetching TBLUNT sale price.");
      }
    };
    fetchSalePrice();
  }, [tbluntContract]);

  // ============ Fetch ETH→USD for Display Purposes ============
  const [ethPriceUSD, setEthPriceUSD] = useState(() => {
    const savedPrice = localStorage.getItem('ethPriceUSD');
    return savedPrice ? parseFloat(savedPrice) : null;
  });

  useEffect(() => {
    const fetchEthPriceUSD = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        if (!response.ok) {
          throw new Error(`CoinGecko responded with status ${response.status}`);
        }
        const priceData = await response.json();
        const ethPrice = priceData.ethereum.usd;
        setEthPriceUSD(ethPrice);
        localStorage.setItem('ethPriceUSD', ethPrice);
      } catch (err) {
        console.error("Error fetching ETH price:", err);
      }
    };

    if (ethPriceUSD === null) {
      fetchEthPriceUSD();
    }

    // Refresh periodically (every 60s)
    const retryInterval = setInterval(() => {
      if (ethPriceUSD === null) {
        fetchEthPriceUSD();
      }
    }, 60000);

    return () => clearInterval(retryInterval);
  }, [ethPriceUSD]);

  // ============ Prepare "Review" data for display ============
  const reviewItems = [
    { field: 'Token Type', value: selectedTokenType?.label || 'N/A' },
    { field: 'Token Name', value: name },
    { field: 'Token Symbol', value: symbol },
    { field: 'Initial Supply', value: initialSupply },
    { field: 'Decimals', value: decimals },
    { field: 'Initial Price', value: `${initialPrice} ETH` },
    { field: 'Tokens After Tax (99%)', value: `${tokensAfterTax} ${symbol}` },
    { field: 'Payment Method', value: 'TBLUNT' },
  ];

  // ============ Handle Review ============
  const handleReview = () => {
    setError(null);
    setSuccess(null);

    if (!selectedType) {
      setError("Please select a token type first.");
      return;
    }
    if (!agreed) {
      setError("You must agree to the Disclaimer and Terms of Service to proceed.");
      return;
    }
    // Validate decimals
    if (!Number.isInteger(decimals) || decimals < 0) {
      setError("Decimals must be a non-negative integer.");
      return;
    }
    // Validate initialPrice
    const priceString = String(initialPrice);
    const priceRegex = /^(?:\d+\.\d{1,18}|\.\d{1,18}|\d+)$/;
    if (!priceRegex.test(priceString)) {
      setError("Initial Price must be a valid number with up to 18 decimal places.");
      return;
    }
    let initialPriceBN;
    try {
      initialPriceBN = parseUnits(priceString, "ether");
    } catch (err) {
      setError("Invalid initial price format.");
      return;
    }
    // At least 1 wei
    if (initialPriceBN < 1n) {
      setError(`Initial Price must be at least ${MIN_INITIAL_PRICE} ETH.`);
      return;
    }
    // Validate initialSupply
    const supplyNum = Number(initialSupply);
    if (
      isNaN(supplyNum) ||
      supplyNum < Number(MIN_INITIAL_SUPPLY) ||
      supplyNum > Number(MAX_INITIAL_SUPPLY)
    ) {
      setError(`Initial Supply must be between ${MIN_INITIAL_SUPPLY} and ${MAX_INITIAL_SUPPLY}.`);
      return;
    }
    // Calculate tokens after 1% tax
    try {
      const supplyBN = parseUnits(String(initialSupply), decimals);
      const tokensAfterTaxBN = (BigInt(supplyBN.toString()) * 99n) / 100n;
      setTokensAfterTax(formatUnits(tokensAfterTaxBN, decimals));
    } catch (err) {
      setError("Invalid initial supply format.");
      return;
    }

    setShowReview(true);
  };

  // ============ Handle Create Token ============
  const handleCreateToken = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    setShowProcessingModal(true);
    setCurrentStep(0);

    try {
      if (!signer || !address) {
        throw new Error("Wallet not connected.");
      }
      if (!tokenCreationManagerContract) {
        throw new Error("Token creation manager contract unavailable.");
      }
      if (!tbluntContract) {
        throw new Error("TBLUNT contract not initialized.");
      }

      // Step 1: Permit TBLUNT usage (Signature)
      const tbluntName = await tbluntContract.name();
      const tbluntPermit = {
        name: tbluntName,
        version: '1',
        chainId: numericChainId,
        verifyingContract: process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS,
      };
      const types = {
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      };
      const nonce = await tbluntContract.nonces(address);
      const deadline = Math.floor(Date.now() / 1000) + 60 * 60; // 1 hour
      const values = {
        owner: address,
        spender: selectedTokenType.address,
        value: parseUnits("0", selectedTokenType.creationFeeDecimals).toString(),
        nonce: Number(nonce),
        deadline,
      };

      const signature = await signer.signTypedData(tbluntPermit, types, values);
      const sig = Signature.from(signature);
      const { v, r, s } = sig;

      // Step 2: Actually call createToken
      setCurrentStep(1);

      const finalSupply = initialSupply.toString();
      const parsedPrice = parseUnits(String(initialPrice), "ether");

      // The manager contract call
      const createTx = await tokenCreationManagerContract.createToken(
        name,
        symbol,
        finalSupply,
        decimals,
        parsedPrice,
        deadline,
        v,
        r,
        s
      );

      setTransactionHash(createTx.hash);
      const receipt = await createTx.wait();
      console.log("Create token transaction confirmed:", receipt);

      // Parse logs to find newly created token address
      const iface = new Interface(selectedTokenType.abi);
      let createdTokenAddress = null;
      for (const log of receipt.logs) {
        try {
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === 'TokenCreated') {
            createdTokenAddress = parsedLog.args.tokenAddress;
            break;
          }
        } catch (err) {
          // Not this log
        }
      }

      if (createdTokenAddress) {
        setNewTokenAddress(createdTokenAddress);
        const generatedWidgetCode = `<iframe src="${window.location.origin}/token-sale/${createdTokenAddress}?widget=true" width="800" height="800" style="border: none;"></iframe>`;
        setWidgetCode(generatedWidgetCode);

        const tokenData = {
          name,
          symbol,
          initialSupply,
          decimals,
          tokenAddress: createdTokenAddress,
          transactionHash: createTx.hash,
          widgetCode: generatedWidgetCode,
          type: selectedTokenType.label,
        };
        updateLastCreatedToken(tokenData);

        setSuccess("Token created successfully!");
        setShowReview(false);

        // If you need to re-fetch TBLUNT balances, do so
        await fetchBalances();
      } else {
        setError("Token creation succeeded, but no TokenCreated event found.");
      }
    } catch (err) {
      console.error("Error creating token:", err);
      setError(`Error creating token: ${err.message}`);
    } finally {
      setLoading(false);
      setShowProcessingModal(false);
      setCurrentStep(0);
    }
  };

  // ============ Create Another ============
  const handleCreateAnother = () => {
    setName("");
    setSymbol("");
    setInitialSupply("");
    setDecimals(4);
    setInitialPrice("");
    setTokensAfterTax("");
    setNewTokenAddress(null);
    setTransactionHash("");
    setWidgetCode("");
    setSuccess(null);
    setError(null);
    setShowReview(false);
    setAgreed(false);
    clearLastCreatedToken();
    setSelectedType("");
    if (onSetCreationFee) onSetCreationFee(null);
  };

  return (
    <Layout className="create-token-layout">
      <Content style={{ padding: '2rem' }}>
        {!lastCreatedToken ? (
          <>
            <Row gutter={[16, 16]} className="mb-4">
              <Col xs={24} lg={24}>
                <Card className="create-token-form">
                  <div className="instructional-section mb-4">
                    <Title level={4} className="toggle-title-2">
                      <InfoCircleOutlined /> Create Your ERC20 Token with TBLUNT
                    </Title>
                    <Paragraph>
                      Select a token type to begin creating your ERC20 token with TBLUNT.
                      Once you’ve chosen a type, fill out the form below.
                    </Paragraph>
                  </div>

                  {error && <Alert message={error} type="error" showIcon className="mb-4" />}
                  {success && <Alert message={success} type="success" showIcon className="mb-4" />}

                  {/* Only show the form if not in review mode */}
                  {!showReview && (
                    <Form
                      layout="vertical"
                      onFinish={handleReview}
                      style={{ maxWidth: '800px', margin: '0 auto' }}
                    >
                      {/* Token Type Selection */}
                      <Form.Item label="Token Type" required>
                        <div className="token-type-buttons">
                          {TOKEN_TYPES.map((typeObj) => (
                            <Button
                              key={typeObj.value}
                              type={selectedType === typeObj.value ? "primary" : "default"}
                              onClick={() => {
                                setSelectedType(typeObj.value);
                                clearLastCreatedToken();
                                setNewTokenAddress(null);
                                setTransactionHash("");
                                setWidgetCode("");
                                setSuccess(null);
                                setError(null);
                              }}
                              className={`token-type-button ${
                                selectedType === typeObj.value ? 'selected' : ''
                              }`}
                            >
                              {typeObj.label}
                              <Tooltip title={typeObj.description}>
                                <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                              </Tooltip>
                            </Button>
                          ))}
                        </div>
                      </Form.Item>

                      {/* If a token type is selected, show the next items */}
                      {selectedTokenType && (
                        <>
                          <div className="toggle-title">
                            <Title level={5} className="toggle-title">
                              {selectedTokenType.description}
                            </Title>
                          </div>

                          {/* TBLUNT Fee Panel */}
                          <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24}>
                              <TokenCreationFeeTopPanelTblunt
                                tokenCreationManagerAddress={selectedTokenType.address}
                                tokenCreationManagerAbi={selectedTokenType.abi}
                                creationFeeDecimals={selectedTokenType.creationFeeDecimals}
                                tbluntTokenAddress={process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS}
                                tbluntTokenAbi={TBLUNTAbi.abi}
                                ethPriceUSD={ethPriceUSD}
                              />
                            </Col>
                          </Row>

                          {/* Features via the new central file */}
                          <div className="features-section mb-4">
                            <TokenTypeFeatures tokenType={selectedTokenType.value} />
                          </div>
                        </>
                      )}

                      {/* The TBLUNT-specific form inputs */}
                      <CreateTokenFormInputsTblunt
                        name={name}
                        setName={setName}
                        symbol={symbol}
                        setSymbol={setSymbol}
                        initialSupply={initialSupply}
                        setInitialSupply={setInitialSupply}
                        decimals={decimals}
                        setDecimals={setDecimals}
                        initialPrice={initialPrice}
                        setInitialPrice={setInitialPrice}
                        agreed={agreed}
                        setAgreed={setAgreed}
                        MIN_INITIAL_SUPPLY={MIN_INITIAL_SUPPLY}
                        MAX_INITIAL_SUPPLY={MAX_INITIAL_SUPPLY}
                        MIN_INITIAL_PRICE={MIN_INITIAL_PRICE}
                      />

                      <Form.Item>
                        <Button type="primary" htmlType="submit" block className="submit-button">
                          Review
                        </Button>
                      </Form.Item>
                    </Form>
                  )}

                  {/* Review Modal */}
                  {showReview && (
                    <Modal
                      visible={showReview}
                      onCancel={() => setShowReview(false)}
                      footer={null}
                      centered
                      maskClosable={!loading}
                      width={460}
                      style={{ top: 30 }}
                      bodyStyle={{
                        backgroundColor: "#2c2c2c",
                        padding: "1rem",
                        borderRadius: "1rem",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.8)",
                      }}
                      className="review-modal"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          gap: "1rem",
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            margin: 0,
                            color: "#a5d6a7",
                            textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                            fontWeight: "bold",
                            fontSize: "1.25rem",
                          }}
                        >
                          <InfoCircleOutlined style={{ marginRight: "8px" }} />
                          Review Token Creation
                        </Title>

                        {/* Grid of Info Cards */}
                        <Row gutter={[16, 16]} style={{ width: "100%", marginBottom: '1rem' }}>
                          {reviewItems.map((item, index) => (
                            <Col xs={24} sm={12} key={index}>
                              <Card
                                style={{
                                  backgroundColor: "#343a40",
                                  border: "none",
                                  borderRadius: "0.5rem",
                                  boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
                                  padding: "1rem",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    marginBottom: "0.5rem",
                                    color: "#a5d6a7",
                                  }}
                                >
                                  {item.field}
                                </div>
                                <div style={{ wordBreak: "break-word" }}>
                                  {item.value}
                                </div>
                              </Card>
                            </Col>
                          ))}
                        </Row>

                        {/* Fee panel for TBLUNT again, so user can confirm the cost */}
                        <Row gutter={[16, 16]} style={{ width: "100%", marginBottom: '1rem' }}>
                          <Col xs={24}>
                            <TokenCreationFeeTopPanelTblunt
                              tokenCreationManagerAddress={selectedTokenType.address}
                              tokenCreationManagerAbi={selectedTokenType.abi}
                              creationFeeDecimals={selectedTokenType.creationFeeDecimals}
                              tbluntTokenAddress={process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS}
                              tbluntTokenAbi={TBLUNTAbi.abi}
                              ethPriceUSD={ethPriceUSD}
                            />
                          </Col>
                        </Row>

                        <Row gutter={16} style={{ width: "100%", marginTop: "0.5rem" }}>
                          <Col span={12}>
                            <Button
                              onClick={() => setShowReview(false)}
                              disabled={loading}
                              block
                              className="back-button"
                              style={{ fontSize: "0.9rem" }}
                            >
                              Back
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Button
                              type="primary"
                              onClick={handleCreateToken}
                              disabled={loading}
                              block
                              className="confirm-button"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {loading ? (
                                <>
                                  <Spin size="small" style={{ marginRight: "4px" }} />
                                  Processing...
                                </>
                              ) : (
                                "Confirm and Create"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Modal>
                  )}
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          // If token was already created, show success + TokenInfo
          <Card className="create-token-form">
            <Title level={3} className="text-center mb-4">
              Token Created Successfully!
            </Title>
            <TokenInfo
              name={lastCreatedToken.name}
              symbol={lastCreatedToken.symbol}
              initialSupply={lastCreatedToken.initialSupply}
              decimals={lastCreatedToken.decimals}
              newTokenAddress={lastCreatedToken.tokenAddress}
              transactionHash={lastCreatedToken.transactionHash}
              widgetCode={lastCreatedToken.widgetCode}
              copyToClipboard={copyToClipboard}
              chainId={numericChainId}
            />
            <Alert
              message="You can now generate an image and update metadata to customize your sale page."
              type="info"
              showIcon
              className="mb-4"
              style={{ textAlign: 'center' }}
            />
            <Button type="primary" onClick={handleCreateAnother} block className="submit-button">
              Create Another Token
            </Button>
          </Card>
        )}
      </Content>

      {/* Processing Modal */}
      <Modal
        visible={showProcessingModal}
        footer={null}
        closable={false}
        centered
        width={400}
      >
        <div className="stepper-container">
          {processingSteps.map((step, index) => {
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;

            const cardStyle = {
              backgroundColor: isCompleted
                ? '#66bb6a'
                : isCurrent
                ? '#17a2b8'
                : '#343a40',
              transform: isCompleted || isCurrent ? 'scale(1.05)' : 'scale(1)',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              border: 'none',
              borderRadius: '0.5rem',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              width: '100%',
              marginBottom: '1rem',
              padding: '1rem',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            };

            return (
              <div key={index} style={cardStyle}>
                {isCompleted ? (
                  <CheckCircleOutlined style={{ fontSize: '1.5rem' }} />
                ) : isCurrent ? (
                  <Spin />
                ) : (
                  <InfoCircleOutlined style={{ fontSize: '1.5rem' }} />
                )}
                <span>{step}</span>
              </div>
            );
          })}
        </div>
      </Modal>
    </Layout>
  );
};

export default CreateTokenTBLUNT;
