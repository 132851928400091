/********************************************************************
 * src/contexts/TokenSocketContext.js
 ********************************************************************/

import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const TokenSocketContext = createContext(null);

export const TokenSocketProvider = ({ children }) => {
  const [tokenSocket, setTokenSocket] = useState(null);

  useEffect(() => {
    // Connect to /tokeninfo path
    const socket = io('https://testnet.turtleblunt.com/tokeninfo', {
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('[TokenSocketContext] Connected to /tokeninfo namespace!');
    });

    socket.on('connect_error', (err) => {
      console.error('[TokenSocketContext] connect_error:', err.message);
    });

    socket.on('disconnect', () => {
      console.log('[TokenSocketContext] Disconnected from /tokeninfo');
    });

    setTokenSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <TokenSocketContext.Provider value={tokenSocket}>
      {children}
    </TokenSocketContext.Provider>
  );
};

export const useTokenSocket = () => useContext(TokenSocketContext);
