// src/components/CreateTokenFactory.js

/* eslint-env es2020 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Contract, parseUnits, formatUnits, Interface } from "ethers";
import { useWeb3 } from "../Web3Context";
import NotificationBar from './NotificationBar';
import TokenCreationManagerAbi from '../abis/TokenFactoryCreationManagerAbi.json';
import TBLUNTAbi from '../abis/TbluntToken.json';
import {
  Card,
  Form,
  Input,
  InputNumber,
  Button,
  Alert,
  Spin,
  Modal,
  Layout,
  Table,
  Row,
  Col,
  Checkbox,
  Typography,
  Tooltip,
} from "antd";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import TokenInfo from './TokenInfo'; 
import { copyToClipboard } from '../utils/clipboard';
import './CreateTokenFactory.scss';
import { io } from "socket.io-client";
import TokenCreationFeeTopPanelTblunt from './TokenCreationFeeTopPanelTblunt'; // Ensure this is correctly imported
import TokenTypeFeatures from './TokenTypeFeatures'; // Ensure this is correctly imported

const { Content } = Layout;
const { Title, Paragraph, Link } = Typography;

// Define constants for validation
const MIN_INITIAL_PRICE = "0.000000000000000001"; // Minimum initial price in ETH (1 wei)
const MAX_INITIAL_PRICE = "100000000000000000"; // Maximum initial price in ETH (1e+17 ETH)
const MIN_INITIAL_SUPPLY = "1";    // Minimum initial supply
const MAX_INITIAL_SUPPLY = "10000000000000000000000"; // Maximum initial supply

const CreateTokenFactory = () => {
  const { 
    provider, 
    signer, 
    address, 
    numericChainId, 
    error: web3Error, 
    lastCreatedToken, 
    updateLastCreatedToken,
    clearLastCreatedToken 
  } = useWeb3();

  const tokenCreationManagerAddress = process.env.REACT_APP_TOKEN_FACTORY_CREATION_MANAGER_ADDRESS;
  const tbluntTokenAddress = process.env.REACT_APP_TBLUNT_TOKEN_ADDRESS;
  const TBLUNT_DECIMALS = 2;

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [decimals, setDecimals] = useState(4); 
  const [initialPrice, setInitialPrice] = useState("");
  const [creationFee, setCreationFee] = useState(null);
  const [tbluntBalance, setTbluntBalance] = useState(null);
  const [error, setError] = useState(web3Error);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [newTokenAddress, setNewTokenAddress] = useState(lastCreatedToken?.tokenAddress || null);
  const [transactionHash, setTransactionHash] = useState(lastCreatedToken?.transactionHash || '');
  const [widgetCode, setWidgetCode] = useState(lastCreatedToken?.widgetCode || '');
  const [tokensAfterTax, setTokensAfterTax] = useState("");
  const [agreed, setAgreed] = useState(false);

  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [queuePosition, setQueuePosition] = useState(null);

  const [socket, setSocket] = useState(null);

  // **State Variables for Processing Modal**
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [processingSteps] = useState([
    "Approving TBLUNT Token Usage",    // Step 0
    "Requesting Token Creation",       // Step 1
    "Compiling...",                    // Step 2 (Backend)
    "Deploying...",                    // Step 3 (Backend)
    "Finalizing..."                    // Step 4 (Backend)
  ]);
  const [currentStep, setCurrentStep] = useState(0); // Tracks the current step index (0-4)

  // **State Variables for Error Modal**
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // **State for Retry Functionality**
  const [lastRequestParams, setLastRequestParams] = useState(null);

  // **State Variables for Token Type and Contract Name**
  const [tokenType, setTokenType] = useState("StandardERC20"); // Default token type
  const [contractName, setContractName] = useState("");

  const tokenCreationManagerContract = useMemo(() => {
    if (!signer || !tokenCreationManagerAddress) return null;
    return new Contract(tokenCreationManagerAddress, TokenCreationManagerAbi.abi, signer);
  }, [signer, tokenCreationManagerAddress]);

  const tbluntContract = useMemo(() => {
    if (!signer || !tbluntTokenAddress) return null;
    return new Contract(tbluntTokenAddress, TBLUNTAbi.abi, signer);
  }, [signer, tbluntTokenAddress]);

  useEffect(() => {
    // Initialize Socket.IO client to connect via the specified path
    const newSocket = io("http://localhost:5004", {
      path: "/socket.io", // Ensure this matches the backend Socket.IO path
      reconnectionAttempts: 5, // Try to reconnect 5 times
      reconnectionDelay: 3000, // Wait 3 seconds before retrying
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  /**
   * Process the tokenCreated event
   */
  const processTokenCreatedEvent = useCallback(async (data) => {
    const { tokenAddress, requestId } = data;

    // Verify request details from the contract
    if (!currentRequestId || requestId !== currentRequestId.toString()) {
      // Not our requestId, ignore
      return;
    }

    try {
      const requestData = await tokenCreationManagerContract.requests(currentRequestId);
      const requestCreator = requestData.creator.toLowerCase(); // Adjust based on ABI

      if (requestCreator !== address.toLowerCase()) {
        // Not created by this user, ignore event
        return;
      }

      // Now we know this event matches our request and user
      if (!newTokenAddress && tokenAddress) {
        setNewTokenAddress(tokenAddress);

        const generatedWidgetCode = `<iframe src="${window.location.origin}/token-sale/${tokenAddress}?widget=true" width="800" height="800" style="border: none;"></iframe>`;
        setWidgetCode(generatedWidgetCode);
        console.log('Generated Widget Code:', generatedWidgetCode);

        setSuccess("Token created successfully!");
        setShowReview(false);
        setLoading(false);
        setError(null);
        setQueuePosition(null);
      }
    } catch (err) {
      console.error("Error processing tokenCreated event:", err);
      setError("An error occurred while processing your token creation.");
      setErrorMessage("An error occurred while processing your token creation.");
      setShowErrorModal(true);
      setLoading(false);
      setShowProcessingModal(false);
      setCurrentStep(0);
    }
  }, [
    currentRequestId, 
    newTokenAddress, 
    tokenCreationManagerContract, 
    address
  ]);

  /**
   * Process the tokenCreationFailed event
   */
  const processTokenCreationFailedEvent = useCallback(async (data) => {
    const { error: creationError, requestId } = data;
    if (!currentRequestId || requestId !== currentRequestId.toString()) {
      // Not our requestId, ignore
      return;
    }

    try {
      const requestData = await tokenCreationManagerContract.requests(currentRequestId);
      const requestCreator = requestData.creator.toLowerCase(); // Adjust based on ABI

      if (requestCreator !== address.toLowerCase()) {
        // Not created by this user
        return;
      }

      // Set the error message and show the error modal
      setError(`Token creation failed: ${creationError}`);
      setErrorMessage(`Token creation failed: ${creationError}`);
      setShowErrorModal(true);

      // Reset relevant states
      setLoading(false);
      setQueuePosition(null);
      setShowProcessingModal(false); // Close Processing Modal
      setCurrentStep(0); // Reset steps
    } catch (err) {
      console.error("Error processing tokenCreationFailed event:", err);
      setError("An error occurred while processing your token creation failure.");
      setErrorMessage("An error occurred while processing your token creation failure.");
      setShowErrorModal(true);
      setLoading(false);
      setShowProcessingModal(false);
      setCurrentStep(0);
    }
  }, [currentRequestId, tokenCreationManagerContract, address]);

  // **Handlers for Step Completion Events**
  const handleTokenCreationStarted = useCallback((data) => {
    const { requestId } = data;
    if (requestId === currentRequestId) {
      setCurrentStep(0); // "Approving TBLUNT Token Usage"
      console.log("Token creation process started.");
    }
  }, [currentRequestId]);

  const handleCompilationCompleted = useCallback((data) => {
    const { requestId } = data;
    if (requestId === currentRequestId) {
      setCurrentStep(2); // "Compiling..."
      console.log("Compilation completed.");
    }
  }, [currentRequestId]);

  const handleDeploymentCompleted = useCallback((data) => {
    const { requestId, tokenAddress } = data;
    if (requestId === currentRequestId) {
      setNewTokenAddress(tokenAddress);
      setCurrentStep(3); // "Deploying..."
      console.log("Deployment completed.");
    }
  }, [currentRequestId]);

  const handleFinalizationCompleted = useCallback((data) => {
    const { requestId, tokenAddress } = data;
    if (requestId === currentRequestId) {
      setCurrentStep(4); // "Finalizing..."
      setSuccess("Token created successfully!");
      setShowProcessingModal(false);
      setLoading(false);
      console.log("Finalization completed.");

      // **Update lastCreatedToken here to ensure all steps are completed**
      const tokenData = {
        name,
        symbol,
        initialSupply,
        decimals,
        tokenAddress: tokenAddress,
        transactionHash: transactionHash,
        widgetCode: `<iframe src="${window.location.origin}/token-sale/${tokenAddress}?widget=true" width="800" height="800" style="border: none;"></iframe>`,
      };
      updateLastCreatedToken(tokenData);
    }
  }, [currentRequestId, name, symbol, initialSupply, decimals, transactionHash, updateLastCreatedToken]);

  const handleCompilationFailed = useCallback((data) => {
    const { requestId, error } = data;
    if (requestId === currentRequestId) {
      setError(`Compilation failed: ${error}`);
      setErrorMessage(`Compilation failed: ${error}`);
      setShowErrorModal(true);
      setShowProcessingModal(false);
      setLoading(false);
      console.error("Compilation failed.");
    }
  }, [currentRequestId]);

  const handleDeploymentFailed = useCallback((data) => {
    const { requestId, error } = data;
    if (requestId === currentRequestId) {
      setError(`Deployment failed: ${error}`);
      setErrorMessage(`Deployment failed: ${error}`);
      setShowErrorModal(true);
      setShowProcessingModal(false);
      setLoading(false);
      console.error("Deployment failed.");
    }
  }, [currentRequestId]);

  const handleFinalizationFailed = useCallback((data) => {
    const { requestId, error } = data;
    if (requestId === currentRequestId) {
      setError(`Finalization failed: ${error}`);
      setErrorMessage(`Finalization failed: ${error}`);
      setShowErrorModal(true);
      setShowProcessingModal(false);
      setLoading(false);
      console.error("Finalization failed.");
    }
  }, [currentRequestId]);

  const handleTokenCreatedEvent = useCallback((data) => {
    console.log("Received tokenCreated event:", data);
    if (currentRequestId) {
      processTokenCreatedEvent(data);
    } else {
      setPendingEvents((prev) => [...prev, data]);
    }
  }, [currentRequestId, processTokenCreatedEvent]);

  const handleTokenCreationFailedEvent = useCallback((data) => {
    console.log("Received tokenCreationFailed event:", data);
    if (currentRequestId) {
      processTokenCreationFailedEvent(data);
    } else {
      setPendingEvents((prev) => [...prev, data]);
    }
  }, [currentRequestId, processTokenCreationFailedEvent]);

  // **Handle queue updates from the backend**
  const handleQueueUpdate = useCallback((data) => {
    console.log("Queue Update:", data.queue);
    if (currentRequestId) {
      const myItem = data.queue.find(item => item.requestId === currentRequestId.toString());
      if (myItem) {
        const position = myItem.position;
        setQueuePosition(position);
      } else {
        // If not found in the queue, possibly processed or no longer in queue
        setQueuePosition(null);
      }
    }
  }, [currentRequestId]);

  useEffect(() => {
    if (!socket) return;

    socket.on("tokenCreated", (data, callback) => {
      handleTokenCreatedEvent(data);
      callback && callback({ status: 'received' });
    });
    socket.on("tokenCreationFailed", (data, callback) => {
      handleTokenCreationFailedEvent(data);
      callback && callback({ status: 'received' });
    });
    socket.on("queueUpdate", (data, callback) => {
      handleQueueUpdate(data);
      callback && callback({ status: 'received' });
    });

    // **New step completion events**
    socket.on("tokenCreationStarted", handleTokenCreationStarted);
    socket.on("compilationCompleted", handleCompilationCompleted);
    socket.on("deploymentCompleted", handleDeploymentCompleted);
    socket.on("finalizationCompleted", handleFinalizationCompleted);

    // **New error events**
    socket.on("compilationFailed", handleCompilationFailed);
    socket.on("deploymentFailed", handleDeploymentFailed);
    socket.on("finalizationFailed", handleFinalizationFailed);

    socket.on("contractCompilationFailed", (data, callback) => {
      const { error: compilationError } = data;
      setError(`Contract compilation failed: ${compilationError}`);
      setErrorMessage(`Contract compilation failed: ${compilationError}`);
      setShowErrorModal(true);
      callback && callback({ status: 'received' });
    });

    socket.on("contractDeployed", (data, callback) => {
      // Handle contract deployed event if needed
      callback && callback({ status: 'received' });
    });

    socket.on("connect_error", (err) => {
      console.error("Socket.IO connection error:", err);
      setErrorMessage("Failed to connect to the backend server.");
      setShowErrorModal(false);
    });

    socket.on("reconnect_attempt", () => {
      console.log("Attempting to reconnect to backend...");
      setError(null); // Clear previous connection errors
    });

    socket.on("reconnect_failed", () => {
      console.error("Reconnection to backend failed.");
      setError("Unable to reconnect to the backend server. Please try again later.");
      setErrorMessage("Unable to reconnect to the backend server. Please try again later.");
      setShowErrorModal(true);
    });

    // Handle reconnection
    socket.on("reconnect", () => {
      console.log("Reconnected to backend.");
      // Request the status of the currentRequestId to synchronize state
      if (currentRequestId) {
        socket.emit("requestStatus", { requestId: currentRequestId.toString() }, (response) => {
          if (response.status === 'ok' && response.data) {
            const { tokenAddress, transactionHash, widgetCode, successMessage } = response.data;
            if (tokenAddress) {
              setNewTokenAddress(tokenAddress);
              setTransactionHash(transactionHash);
              setWidgetCode(widgetCode);
              setSuccess(successMessage);
              setShowReview(false);
              setLoading(false);
              setError(null);
              setQueuePosition(null);
              setCurrentStep(4); // Final step completed

              // **Update lastCreatedToken here to ensure all steps are completed**
              const tokenData = {
                name,
                symbol,
                initialSupply,
                decimals,
                tokenAddress: tokenAddress,
                transactionHash: transactionHash,
                widgetCode: widgetCode,
              };
              updateLastCreatedToken(tokenData);
            }
          } else if (response.status === 'error') {
            setError(`Failed to synchronize status: ${response.message}`);
            setErrorMessage(`Failed to synchronize status: ${response.message}`);
            setShowErrorModal(true);
          }
        });
      }
    });

    return () => {
      if (!socket) return;
      socket.off("tokenCreated", handleTokenCreatedEvent);
      socket.off("tokenCreationFailed", handleTokenCreationFailedEvent);
      socket.off("queueUpdate", handleQueueUpdate);

      // **Remove new event listeners**
      socket.off("tokenCreationStarted", handleTokenCreationStarted);
      socket.off("compilationCompleted", handleCompilationCompleted);
      socket.off("deploymentCompleted", handleDeploymentCompleted);
      socket.off("finalizationCompleted", handleFinalizationCompleted);

      socket.off("compilationFailed", handleCompilationFailed);
      socket.off("deploymentFailed", handleDeploymentFailed);
      socket.off("finalizationFailed", handleFinalizationFailed);

      socket.off("contractCompilationFailed");
      socket.off("contractDeployed");
    };
  }, [
    socket, 
    handleTokenCreatedEvent, 
    handleTokenCreationFailedEvent, 
    handleQueueUpdate, 
    currentRequestId,
    handleTokenCreationStarted,
    handleCompilationCompleted,
    handleDeploymentCompleted,
    handleFinalizationCompleted,
    handleCompilationFailed,
    handleDeploymentFailed,
    handleFinalizationFailed
  ]);

  useEffect(() => {
    // Once we have currentRequestId, process pending events
    if (currentRequestId && pendingEvents.length > 0) {
      pendingEvents.forEach((evt) => {
        if (evt.tokenAddress) {
          // tokenCreated event
          processTokenCreatedEvent(evt);
        } else if (evt.error) {
          // tokenCreationFailed event
          processTokenCreationFailedEvent(evt);
        }
      });
      setPendingEvents([]);
    }
  }, [currentRequestId, pendingEvents, processTokenCreatedEvent, processTokenCreationFailedEvent]);

  useEffect(() => {
    if (!tbluntTokenAddress) {
      setError("TBLUNT Token Address is not defined. Please check your environment variables.");
      setErrorMessage("TBLUNT Token Address is not defined. Please check your environment variables.");
      setShowErrorModal(true);
    }
  }, [tbluntTokenAddress]);

  const fetchBalances = useCallback(async () => {
    try {
      if (!tbluntContract || !address) return;
      console.log("Fetching balances...");
      const tblunt = await tbluntContract.balanceOf(address);
      console.log("Raw TBLUNT Balance:", tblunt.toString());
      setTbluntBalance(tblunt);
    } catch (err) {
      console.error("Error fetching balances:", err);
      setError("Error fetching balances.");
      setErrorMessage("Error fetching balances.");
      setShowErrorModal(true);
    }
  }, [tbluntContract, address]);

  useEffect(() => {
    const handleBalanceUpdate = () => {
      console.log("tbluntBalanceUpdated event received. Refreshing balance...");
      fetchBalances();
    };

    window.addEventListener('tbluntBalanceUpdated', handleBalanceUpdate);

    return () => {
      window.removeEventListener('tbluntBalanceUpdated', handleBalanceUpdate);
    };
  }, [fetchBalances]);

  useEffect(() => {
    const fetchData = async () => {
      if (!tokenCreationManagerContract || !tbluntContract || !address) return;

      try {
        const fee = await tokenCreationManagerContract.creationFee();
        console.log("Fetched creationFee:", fee.toString());
        setCreationFee(formatUnits(fee, TBLUNT_DECIMALS)); // Convert to human-readable format
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Error fetching data from contracts.");
        setErrorMessage("Error fetching data from contracts.");
        setShowErrorModal(true);
      }
    };

    fetchData();
  }, [tokenCreationManagerContract, tbluntContract, address]);

  const handleReview = () => {
    setError(null);
    setSuccess(null);

    // Validation Constraints
    // 1. Agreement checkbox
    if (!agreed) {
      setError("You must agree to the Disclaimer and Terms of Service to proceed.");
      setErrorMessage("You must agree to the Disclaimer and Terms of Service to proceed.");
      setShowErrorModal(true);
      return;
    }

    // 2. Decimals must be a non-negative integer
    if (!Number.isInteger(decimals) || decimals < 0) {
      setError("Decimals must be a non-negative integer.");
      setErrorMessage("Decimals must be a non-negative integer.");
      setShowErrorModal(true);
      return;
    }

    // 3. Initial Price Validation
    const regex = /^(?:\d+\.\d{1,18}|\.\d{1,18}|\d+)$/;
    if (!regex.test(initialPrice)) {
      setError("Initial Price must be a valid number with up to 18 decimal places.");
      setErrorMessage("Initial Price must be a valid number with up to 18 decimal places.");
      setShowErrorModal(true);
      return;
    }

    let initialPriceBN;
    try {
      initialPriceBN = parseUnits(initialPrice, "ether"); // BigInt
    } catch (err) {
      setError("Invalid initial price format.");
      setErrorMessage("Invalid initial price format.");
      setShowErrorModal(true);
      return;
    }

    if (initialPriceBN < 1n) { // Minimum 1 wei
      setError("Initial Price must be at least 0.000000000000000001 ETH.");
      setErrorMessage("Initial Price must be at least 0.000000000000000001 ETH.");
      setShowErrorModal(true);
      return;
    }

    // 4. Initial Supply Validation
    const initialSupplyNumber = parseFloat(initialSupply);
    if (isNaN(initialSupplyNumber) || initialSupplyNumber < parseFloat(MIN_INITIAL_SUPPLY) || initialSupplyNumber > parseFloat(MAX_INITIAL_SUPPLY)) {
      setError(`Initial Supply must be a number between ${MIN_INITIAL_SUPPLY} and ${MAX_INITIAL_SUPPLY}.`);
      setErrorMessage(`Initial Supply must be a number between ${MIN_INITIAL_SUPPLY} and ${MAX_INITIAL_SUPPLY}.`);
      setShowErrorModal(true);
      return;
    }

    // **Convert initialSupply to BigInt correctly**
    const initialSupplyBN = parseUnits(initialSupply.toString(), decimals); // BigInt
    const tokensAfterTaxBN = (initialSupplyBN * 9999n) / 10000n; 
    const tokensAfterTaxFormatted = formatUnits(tokensAfterTaxBN, decimals);
    setTokensAfterTax(tokensAfterTaxFormatted);

    setShowReview(true);
  };

  /**
   * Handle the creation of the token
   */
  const handleCreateToken = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      if (!signer || !address) {
        throw new Error("Wallet not connected.");
      }

      if (!tbluntBalance) {
        throw new Error("TBLUNT balance is unavailable.");
      }

      if (creationFee === null) {
        throw new Error("Token creation fee is unavailable.");
      }

      const balanceNum = BigInt(tbluntBalance.toString()); // Convert to BigInt
      const feeNum = BigInt(parseUnits(creationFee, TBLUNT_DECIMALS).toString());       // Convert to BigInt
      if (balanceNum < feeNum) {
        throw new Error("Insufficient TBLUNT balance to create token.");
      }

      if (!numericChainId || isNaN(numericChainId)) {
        throw new Error("Invalid chain ID. Please ensure you are connected to a valid network.");
      }

      console.log("Approving TBLUNT token usage...");
      setShowProcessingModal(true); // Show Processing Modal
      setCurrentStep(0); // Start with first step

      // Store request parameters for potential retry
      setLastRequestParams({
        name,
        symbol,
        initialSupply,
        decimals,
        initialPrice,
        tokenType,       // **Added to lastRequestParams**
        contractName,    // **Added to lastRequestParams**
      });

      // Step 0: Approve TBLUNT spending
      const approveTx = await tbluntContract.approve(tokenCreationManagerAddress, parseUnits(creationFee, TBLUNT_DECIMALS));
      console.log("Approval transaction sent:", approveTx.hash);
      setCurrentStep(0); // "Approving TBLUNT Token Usage"
      
      // Wait for approval to be mined
      await approveTx.wait();
      console.log("TBLUNT approved to TokenCreationManager.");
      setCurrentStep(1); // "Requesting Token Creation"

      // Step 1: Request token creation

      const finalSupply = initialSupply.toString(); // Use the BigInt as a string

      const initialPriceParsed = parseUnits(initialPrice.toString(), "ether"); // BigInt

      // **Modified requestTokenCreation to include tokenType and contractName**
      const createTx = await tokenCreationManagerContract.requestTokenCreation(
        name,
        symbol,
        finalSupply,                     // Use the BigInt as string
        decimals,
        initialPriceParsed.toString(),   // Ensure it's a string
        tokenType,                       // **Added parameter**
        contractName                     // **Added parameter**
      );
      console.log("Request Token Creation Transaction Hash:", createTx.hash);
      setTransactionHash(createTx.hash);

      const receipt = await createTx.wait();
      console.log("Token creation request sent and mined.", receipt);

      // Parse logs to find the requestId
      let reqId = null;
      for (const log of receipt.logs) {
        try {
          const iface = new Interface(TokenCreationManagerAbi.abi);
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === 'TokenCreationRequested') {
            reqId = parsedLog.args.requestId;
            console.log("Found requestId:", reqId.toString());
            break;
          }
        } catch (err) {
          // Not this log
        }
      }

      if (reqId) {
        setCurrentRequestId(reqId.toString()); // Ensure it's a string
        // No need to set currentStep here; backend events will handle steps 2-4
      } else {
        console.warn("TokenCreationRequested event not found, cannot track requestId.");
        throw new Error("Unable to retrieve request ID from transaction logs.");
      }

    } catch (err) {
      console.error("Error creating token:", err);
      setError(`Error creating token: ${err.message}`);
      setErrorMessage(`Error creating token: ${err.message}`);
      setShowErrorModal(true);
      setLoading(false);
      setShowProcessingModal(false); // Close Processing Modal on error
      setCurrentStep(0); // Reset steps
    }
  };

  const formatBalance = (balance, decimals = 18, displayDecimals = 18) => {
    if (!balance) {
      return "0";
    }
    try {
      const formatted = formatUnits(balance.toString(), decimals);
      const decimalIndex = formatted.indexOf('.');
      if (decimalIndex === -1) return formatted;
      return formatted.slice(0, decimalIndex + displayDecimals + 1);
    } catch (err) {
      console.error("Error formatting balance:", err);
      return "0";
    }
  };

  const handleCreateAnother = () => {
    setName("");
    setSymbol("");
    setInitialSupply("");
    setDecimals(4);
    setInitialPrice("");
    setTokensAfterTax("");
    setNewTokenAddress(null);
    setTransactionHash('');
    setWidgetCode('');
    setSuccess(null);
    setError(null);
    setShowReview(false);
    setAgreed(false);
    setCurrentRequestId(null); 
    setQueuePosition(null);
    clearLastCreatedToken();
    setCurrentStep(0); // Reset processing steps
    setLastRequestParams(null); // Reset last request params
    setTokenType("StandardERC20"); // **Reset tokenType to default**
    setContractName("");          // **Reset contractName**
  };

  const handleRetry = () => {
    if (lastRequestParams) {
      setName(lastRequestParams.name);
      setSymbol(lastRequestParams.symbol);
      setInitialSupply(lastRequestParams.initialSupply);
      setDecimals(lastRequestParams.decimals);
      setInitialPrice(lastRequestParams.initialPrice);
      setTokenType(lastRequestParams.tokenType);       // **Restore tokenType**
      setContractName(lastRequestParams.contractName); // **Restore contractName**
      setShowErrorModal(false);
      setShowReview(true);
    }
  };

  // Columns for a cleaner review table
  const reviewColumns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      render: (text) => <span style={{ fontWeight: 'bold', color: '#a5d6a7' }}>{text}</span>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => <span style={{ color: '#ffffff' }}>{text}</span>,
    },
  ];

  // Data for the review table
  const reviewData = [
    { key: '1', field: 'Token Type', value: tokenType },
    { key: '2', field: 'Contract Name', value: contractName },
    { key: '3', field: 'Token Name', value: name },
    { key: '4', field: 'Token Symbol', value: symbol },
    { key: '5', field: 'Initial Supply', value: initialSupply },
    { key: '6', field: 'Decimals', value: decimals },
    { key: '7', field: 'Initial Price', value: `${initialPrice} ETH` },
    { key: '8', field: 'Tokens After Tax (99.99%)', value: `${tokensAfterTax} ${symbol}` },
    { key: '9', field: 'Token Creation Fee', value: `${formatBalance(creationFee, TBLUNT_DECIMALS)} TBLUNT` },
  ];

  return (
    <Layout className="create-token-layout mt-5 px-3">
      <NotificationBar />
      {!lastCreatedToken ? (
        <Content>
          {/* Main Form Row */}
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card className="create-token-form">
                {/* Instructional Section */}
                <div className="instructional-section mb-4">
                  <Title level={4} className="toggle-title-2">
                    <InfoCircleOutlined /> How to Create Your ERC20 Token
                  </Title>
                  <Paragraph>
                    Make sure you have enough TBLUNT and have approved this contract to spend the creation fee. After confirming, the fee will be taken immediately and your token creation request will be sent to the backend.
                  </Paragraph>
                </div>

                {/* Error and Success Alerts */}
                {error && <Alert message={error} type="error" showIcon className="mb-4" />}
                {success && <Alert message={success} type="success" showIcon className="mb-4" />}

                {/* Display queue position if waiting */}
                {currentRequestId && queuePosition && loading && (
                  <Alert message={`Your token creation request is in the queue. Position: ${queuePosition}`} type="info" showIcon className="text-center mb-4" />
                )}

                {/* Hide Review Modal when Processing Modal is Open */}
                {!showProcessingModal && (
                  !showReview ? (
                    <Form
                      onFinish={handleReview}
                      layout="vertical"
                      className="create-token-form-container"
                    >
                      {/* ====== Token Type Selection as Buttons ====== */}
                      <Form.Item
                        label="Token Type"
                        required
                      >
                        <div className="token-type-buttons">
                          <Button
                            type={tokenType === "StandardERC20" ? "primary" : "default"}
                            onClick={() => {
                              setTokenType("StandardERC20");
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash('');
                              setWidgetCode('');
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${tokenType === "StandardERC20" ? 'selected' : ''}`}
                          >
                            Standard ERC20
                            <Tooltip title="Basic ERC20 token without additional functionalities.">
                              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                          <Button
                            type={tokenType === "MintableERC20" ? "primary" : "default"}
                            onClick={() => {
                              setTokenType("MintableERC20");
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash('');
                              setWidgetCode('');
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${tokenType === "MintableERC20" ? 'selected' : ''}`}
                          >
                            Mintable ERC20
                            <Tooltip title="ERC20 token with minting capabilities.">
                              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                          <Button
                            type={tokenType === "PausableERC20" ? "primary" : "default"}
                            onClick={() => {
                              setTokenType("PausableERC20");
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash('');
                              setWidgetCode('');
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${tokenType === "PausableERC20" ? 'selected' : ''}`}
                          >
                            Pausable ERC20
                            <Tooltip title="ERC20 token with pausable token transfers.">
                              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                          <Button
                            type={tokenType === "MintablePausableERC20" ? "primary" : "default"}
                            onClick={() => {
                              setTokenType("MintablePausableERC20");
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash('');
                              setWidgetCode('');
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${tokenType === "MintablePausableERC20" ? 'selected' : ''}`}
                          >
                            Mintable & Pausable ERC20
                            <Tooltip title="ERC20 token that can mint new tokens and pause transfers.">
                              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                          <Button
                            type={tokenType === "CappedMintableERC20" ? "primary" : "default"}
                            onClick={() => {
                              setTokenType("CappedMintableERC20");
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash('');
                              setWidgetCode('');
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${tokenType === "CappedMintableERC20" ? 'selected' : ''}`}
                          >
                            Capped & Mintable ERC20
                            <Tooltip title="ERC20 token with a supply cap and minting capabilities.">
                              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                          <Button
                            type={tokenType === "CappedMintablePausableERC20" ? "primary" : "default"}
                            onClick={() => {
                              setTokenType("CappedMintablePausableERC20");
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash('');
                              setWidgetCode('');
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${tokenType === "CappedMintablePausableERC20" ? 'selected' : ''}`}
                          >
                            Capped, Mintable & Pausable ERC20
                            <Tooltip title="ERC20 token with supply cap, minting, and pausing functionalities.">
                              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          </Button>
                          {/* Add more contract types as needed */}
                        </div>
                      </Form.Item>
                      {/* ====== End of Token Type Selection ====== */}

                      {/* ====== Contract Name Input ====== */}
                      <Form.Item
                        label="Contract Name"
                        name="contractName"
                        rules={[{ required: true, message: 'Please enter the contract name.' }]}
                      >
                        <Input
                          placeholder="Enter contract name"
                          value={contractName}
                          onChange={(e) => setContractName(e.target.value)}
                          className="form-control"
                        />
                      </Form.Item>
                      {/* ====== End of Contract Name Input ====== */}

                      {/* ====== Dynamic Description Based on Selected Token Type ====== */}
                      <div className="toggle-title">
                        <Title level={5} className="toggle-title">
                          {tokenType === "StandardERC20" && "Basic ERC20 token with standard functionalities."}
                          {tokenType === "MintableERC20" && "ERC20 token that allows minting of new tokens."}
                          {tokenType === "PausableERC20" && "ERC20 token with the ability to pause transfers."}
                          {tokenType === "MintablePausableERC20" && "ERC20 token that can mint new tokens and pause transfers."}
                          {tokenType === "CappedMintableERC20" && "ERC20 token with a capped supply and minting capabilities."}
                          {tokenType === "CappedMintablePausableERC20" && "ERC20 token with supply cap, minting, and pausing functionalities."}
                          {/* Add more descriptions as needed */}
                        </Title>
                      </div>
                      {/* ====== End of Dynamic Description ====== */}

                      {/* ====== Display Creation Fee at the Top ====== */}
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24}>
                          <TokenCreationFeeTopPanelTblunt 
                            creationFee={creationFee} // Pass as string
                            tbluntContract={tbluntContract}
                            ethPriceUSD={null} // Pass actual ETH price if available
                          />
                        </Col>
                      </Row>
                      {/* ====== End of Creation Fee Panel ====== */}

                      {/* ====== Features Section ====== */}
                      <div className="features-section mb-4">
                        <TokenTypeFeatures 
                          features={[
                            'ERC20 Standard',
                            'Permit (EIP-2612)',
                            'Ownable',
                            'Reentrancy Guard',
                            'Burnable',
                            'Set Token Price',
                            'Metadata URL',
                            'Interact seamlessly with your token using our intuitive interface.',
                            'Metadata Updates',
                            'Free AI-Generated Images',
                            'Full Contract Ownership',
                            'No Post-Creation Contracts',
                            'Sales Page Integration Widget',
                            'Customer Support',
                            // Add additional features based on tokenType if needed
                          ]}
                          description={
                            tokenType === "StandardERC20" ? "Basic ERC20 token with standard functionalities." :
                            tokenType === "MintableERC20" ? "ERC20 token that allows minting of new tokens." :
                            tokenType === "PausableERC20" ? "ERC20 token with the ability to pause transfers." :
                            tokenType === "MintablePausableERC20" ? "ERC20 token that can mint new tokens and pause transfers." :
                            tokenType === "CappedMintableERC20" ? "ERC20 token with a capped supply and minting capabilities." :
                            tokenType === "CappedMintablePausableERC20" ? "ERC20 token with supply cap, minting, and pausing functionalities." :
                            ""
                          }
                        />
                      </div>
                      {/* ====== End of Features Section ====== */}

                      {/* Token Name */}
                      <Form.Item
                        label="Token Name"
                        name="tokenName"
                        rules={[{ required: true, message: 'Please enter the token name.' }]}
                      >
                        <Input
                          placeholder="Enter token name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                        />
                      </Form.Item>

                      {/* Token Symbol */}
                      <Form.Item
                        label="Token Symbol"
                        name="tokenSymbol"
                        rules={[{ required: true, message: 'Please enter the token symbol.' }]}
                      >
                        <Input
                          placeholder="Enter token symbol"
                          value={symbol}
                          onChange={(e) => setSymbol(e.target.value)}
                          className="form-control"
                        />
                      </Form.Item>

                      {/* Initial Supply (InputNumber) */}
                      <Form.Item
                        label="Initial Supply"
                        name="initialSupply"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the initial supply.',
                          },
                          {
                            validator: (_, value) => {
                              if (value === undefined || value === null || value === '') {
                                return Promise.reject(new Error('Initial Supply is required.'));
                              }
                              // Must be a valid positive number
                              const supplyNum = Number(value);
                              if (Number.isNaN(supplyNum)) {
                                return Promise.reject(new Error('Initial Supply must be a valid number.'));
                              }
                              if (
                                supplyNum < Number(MIN_INITIAL_SUPPLY) ||
                                supplyNum > Number(MAX_INITIAL_SUPPLY)
                              ) {
                                return Promise.reject(new Error(
                                  `Initial Supply must be between ${MIN_INITIAL_SUPPLY} and ${MAX_INITIAL_SUPPLY}.`
                                ));
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <InputNumber
                          placeholder={`Enter supply (${MIN_INITIAL_SUPPLY} - ${MAX_INITIAL_SUPPLY})`}
                          style={{ width: '100%' }}
                          value={initialSupply}
                          min={Number(MIN_INITIAL_SUPPLY)}
                          max={Number(MAX_INITIAL_SUPPLY)}
                          onChange={(val) => setInitialSupply(val)}
                          className="form-control"
                        />
                      </Form.Item>

                      {/* Decimals */}
                      <Form.Item
                        label="Decimals"
                        name="decimals"
                        rules={[
                          { required: true, message: 'Please enter the number of decimals.' },
                          {
                            validator: (_, value) => {
                              if (value === undefined || value === null) {
                                return Promise.reject(new Error('Decimals is required.'));
                              }
                              if (!Number.isInteger(value) || value < 0) {
                                return Promise.reject(new Error('Decimals must be a non-negative integer.'));
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter decimals"
                          style={{ width: '100%' }}
                          value={decimals}
                          min={0}
                          onChange={(val) => setDecimals(Number(val))}
                          className="form-control"
                        />
                      </Form.Item>

                      {/* Initial Price (ETH) as an Input (string) */}
                      <Form.Item
                        label="Initial Price (ETH)"
                        name="initialPrice"
                        rules={[
                          { required: true, message: 'Please enter the initial price in ETH.' },
                          {
                            validator: (_, value) => {
                              if (!value) {
                                return Promise.reject(new Error('Initial Price is required.'));
                              }
                              // Check the same 18-decimal rule:
                              const regex = /^(?:\d+\.\d{1,18}|\.\d{1,18}|\d+)$/;
                              if (!regex.test(value)) {
                                return Promise.reject(
                                  new Error('Initial Price must be a valid number with up to 18 decimal places.')
                                );
                              }
                              // Check >= 1 wei
                              try {
                                const weiVal = parseUnits(String(value), "ether");
                                if (weiVal < 1n) {
                                  return Promise.reject(new Error(
                                    `Initial Price must be at least ${MIN_INITIAL_PRICE} ETH.`
                                  ));
                                }
                              } catch (err) {
                                return Promise.reject(new Error('Invalid initial price format.'));
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <Input
                          placeholder={`Enter initial price (${MIN_INITIAL_PRICE} - up to 18 decimals)`}
                          value={initialPrice}
                          onChange={(e) => {
                            let val = e.target.value;
                            // Auto-prepend '0' if the user types just "."
                            if (val.startsWith('.')) {
                              val = '0' + val;
                            }
                            setInitialPrice(val);
                          }}
                          className="form-control"
                        />
                      </Form.Item>

                      {/* Disclaimer Checkbox */}
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(new Error('You must agree to the Disclaimer and Terms of Service to proceed.'))
                          }
                        ]}
                      >
                        <Checkbox
                          checked={agreed}
                          onChange={(e) => setAgreed(e.target.checked)}
                        >
                          I agree to the <Link href="/disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</Link> and <Link href="/tos" target="_blank" rel="noopener noreferrer">Terms of Service</Link>.
                        </Checkbox>
                      </Form.Item>

                      <Alert
                        message="After creating your token, you can generate an image and update metadata to customize your sale page."
                        type="info"
                        showIcon
                        className="mb-4"
                        style={{ textAlign: 'center' }}
                      />

                      {/* TBLUNT Balance */}
                      <Card className="bg-secondary text-light text-center mb-4">
                        <Card.Meta
                          title="TBLUNT Balance"
                          description={`${formatBalance(tbluntBalance, TBLUNT_DECIMALS)} TBLUNT`}
                        />
                      </Card>

                          <TokenCreationFeeTopPanelTblunt 
                            creationFee={creationFee} // Pass as string
                            tbluntContract={tbluntContract}
                            ethPriceUSD={null} // Pass actual ETH price if available
                          />


                      <Form.Item>
                        <Button type="primary" htmlType="submit" block className="submit-button">
                          Review
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : null
                )}
                
                {/* Conditionally Render Review Modal if Processing Modal is Not Open */}
                {showReview && !showProcessingModal && (
                  <Modal 
                    visible={showReview} 
                    onCancel={() => setShowReview(false)} 
                    footer={null}
                    centered
                    maskClosable={!loading}
                    className="review-modal"
                  >
                    <Typography>
                      <Title level={3} style={{ textAlign: 'center', marginBottom: '1rem', color: '#a5d6a7' }}>
                        <InfoCircleOutlined style={{ marginRight: "10px" }} />
                        Review Token Creation
                      </Title>
                    </Typography>
                    <Table 
                      columns={reviewColumns}
                      dataSource={reviewData}
                      pagination={false}
                      bordered
                      size="middle"
                      className="review-table"
                      style={{ marginBottom: '1rem' }}
                    />
                    <Alert 
                      message={`You will receive ${tokensAfterTax} ${symbol} after a 0.01% tax on the initial supply.`} 
                      type="info" 
                      showIcon 
                      className="mb-4" 
                      style={{ textAlign: 'center' }}
                    />
                    <Alert 
                      message={`You are about to spend ${formatBalance(creationFee, TBLUNT_DECIMALS)} TBLUNT to create this token.`} 
                      type="info" 
                      showIcon 
                      className="mb-4" 
                      style={{ textAlign: 'center' }}
                    />
                    <Row gutter={16}>
                      <Col span={12}>
                        <Button 
                          onClick={() => setShowReview(false)} 
                          disabled={loading} 
                          block
                          className="back-button"
                        >
                          Back
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button 
                          type="primary" 
                          onClick={handleCreateToken} 
                          disabled={loading || (tbluntBalance && BigInt(tbluntBalance.toString()) < BigInt(parseUnits(creationFee, TBLUNT_DECIMALS).toString()))}
                          block
                          className="confirm-button"
                        >
                          {loading ? (
                            <>
                              <Spin size="small" style={{ marginRight: '8px' }} />
                              Processing...
                            </>
                          ) : (
                            "Confirm and Create"
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Modal>
                )}
              </Card>
            </Col>
          </Row>
        </Content>
      ) : (
        <Content>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card className="create-token-form">
                <Title level={3} className="text-center mb-4">Token Created Successfully!</Title>
                <TokenInfo
                  name={lastCreatedToken.name}
                  symbol={lastCreatedToken.symbol}
                  initialSupply={lastCreatedToken.initialSupply}
                  decimals={lastCreatedToken.decimals}
                  newTokenAddress={lastCreatedToken.tokenAddress}
                  transactionHash={lastCreatedToken.transactionHash}
                  widgetCode={lastCreatedToken.widgetCode}
                  copyToClipboard={copyToClipboard}
                  chainId={numericChainId}
                />
                <Alert
                  message="You can now generate an image and update metadata to customize your sale page."
                  type="info"
                  showIcon
                  className="mb-4"
                  style={{ textAlign: 'center' }}
                />
                <Button type="primary" onClick={handleCreateAnother} block className="create-another-btn">
                  Create Another Token
                </Button>
              </Card>
            </Col>
          </Row>
        </Content>
      )}

      {/* **Processing Modal with Enhanced Stepper** */}
      <Modal 
        visible={showProcessingModal} 
        footer={null}
        closable={false}
        centered 
        width={400}
        className="processing-modal"
      >
        <div className="stepper-container">
          {processingSteps.map((step, index) => {
            // Determine if the step is completed, current, or upcoming
            const isCompleted = index < currentStep;
            const isCurrent = index === currentStep;

            // Inline styles for background color and transform
            const cardStyle = {
              backgroundColor: isCompleted ? '#66bb6a' : isCurrent ? '#17a2b8' : '#343a40',
              transform: isCompleted || isCurrent ? 'scale(1.05)' : 'scale(1)',
              transition: 'background-color 0.3s ease, transform 0.3s ease',
              border: 'none',
              borderRadius: '0.5rem',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              width: '100%',
              marginBottom: '1rem',
              padding: '1rem',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            };

            const iconStyle = {
              fontSize: '1.5rem',
            };

            return (
              <div key={index} style={cardStyle}>
                {isCompleted ? (
                  <CheckCircleOutlined style={iconStyle} />
                ) : isCurrent ? (
                  <Spin />
                ) : (
                  <InfoCircleOutlined style={iconStyle} />
                )}
                <span>{step}</span>
              </div>
            );
          })}
        </div>
      </Modal>

      {/* **Error Modal** */}
      <Modal
        visible={showErrorModal}
        onCancel={() => setShowErrorModal(false)}
        footer={[
          <Button key="close" onClick={() => setShowErrorModal(false)} className="close-button">
            Close
          </Button>,
          lastRequestParams && (
            <Button key="retry" type="primary" onClick={handleRetry} disabled={loading} className="retry-button">
              Retry
            </Button>
          )
        ]}
        centered
        className="error-modal"
      >
        <Alert
          message={errorMessage || "An unexpected error occurred."}
          type="error"
          showIcon
        />
      </Modal>
    </Layout>
  );
};

export default CreateTokenFactory;
