// src/components/UserTokensPanel/UserTokensPanel.jsx

import React, { useEffect, useState, useRef } from 'react';
import { Drawer, Button, List, Typography, Spin, Alert, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faWallet,
  faCopy,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import { useWeb3 } from '../Web3Context';
import { useTokenSocket } from '../contexts/TokenSocketContext';
import './UserTokensPanel.scss';
import { formatNumber } from '../utils/format';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const { Text } = Typography;

// Helper functions for cookies
const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days*24*60*60*1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
};

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
};

// Helper for copying text to clipboard
function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    () => console.log(`Copied to clipboard: ${text}`),
    (err) => console.error('Failed to copy:', err)
  );
}

const UserTokensPanel = () => {
  const { connected, address, loading: walletLoading, connectWallet } = useWeb3();
  const tokenSocket = useTokenSocket();
  const navigate = useNavigate(); // Initialize useNavigate

  const [tokens, setTokens] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  // Draggable icon position
  const [position, setPosition] = useState({ x: 50, y: 100 }); // Default position (x: 50px, y: 100px below top)
  const [dragging, setDragging] = useState(false);
  const dragRef = useRef(null);
  const offset = useRef({ x: 0, y: 0 });

  // Load position from cookies on mount
  useEffect(() => {
    const pos = getCookie('userTokensPanelPosition');
    if (pos) {
      try {
        const parsedPos = JSON.parse(pos);
        setPosition(parsedPos);
      } catch (e) {
        console.error('Failed to parse userTokensPanelPosition cookie:', e);
      }
    }
  }, []);

  // Fetch tokens when socket, connection, and address are ready
  useEffect(() => {
    if (!tokenSocket) {
      console.log('[UserTokensPanel] tokenSocket is null, waiting...');
      return;
    }
    if (!connected || !address) {
      console.log('[UserTokensPanel] Not connected or no address, skip socket init...');
      return;
    }

    console.log('[UserTokensPanel] Socket + wallet are ready, requesting initial data...');
    setLoading(true);
    setDataError(null);

    // Request entire tokens array (server is sending { testnet: [] })
    tokenSocket.emit('requestInitialData');

    // Handle initial data
    const handleInitialData = (data) => {
      console.log('[UserTokensPanel] Received initialData:', data);

      if (!data || !Array.isArray(data.testnet)) {
        setDataError('Server returned invalid data (not an array in data.testnet).');
        setTokens([]);
        setLoading(false);
        return;
      }

      // Filter by user address
      const userTokens = data.testnet.filter(
        (t) => t.owner && t.owner.toLowerCase() === address.toLowerCase()
      );

      console.log(
        `[UserTokensPanel] Found ${userTokens.length} tokens for user: ${address}`
      );

      // Fetch metadata for each token (async)
      fetchAllTokenMetadata(userTokens).then((enrichedTokens) => {
        setTokens(enrichedTokens);
        setLoading(false);
      });
    };

    // Handle tokenUpdate
    const handleTokenUpdate = (data) => {
      console.log('[UserTokensPanel] Received tokenUpdate:', data);

      if (!data || !Array.isArray(data.testnet)) return;

      const userTokens = data.testnet.filter(
        (t) => t.owner && t.owner.toLowerCase() === address.toLowerCase()
      );
      console.log(
        `[UserTokensPanel] Updated token list: ${userTokens.length} tokens for user ${address}`
      );

      // Fetch metadata again
      fetchAllTokenMetadata(userTokens).then((enrichedTokens) => {
        setTokens(enrichedTokens);
      });
    };

    // Attach event listeners
    tokenSocket.on('initialData', handleInitialData);
    tokenSocket.on('tokenUpdate', handleTokenUpdate);

    // Cleanup on unmount
    return () => {
      tokenSocket.off('initialData', handleInitialData);
      tokenSocket.off('tokenUpdate', handleTokenUpdate);
    };
  }, [tokenSocket, connected, address]);

  // Fetch metadata for all tokens
  const fetchAllTokenMetadata = async (tokenArray) => {
    const results = await Promise.all(
      tokenArray.map(async (token) => {
        if (!token.metaUrl) {
          return { ...token, meta: null };
        }
        // Fetch the metadata
        try {
          const metaData = await fetchMetadata(token.metaUrl);
          return { ...token, meta: metaData };
        } catch (error) {
          console.error(
            `Failed to fetch metadata for token ${token.address}:`,
            error
          );
          return { ...token, meta: null };
        }
      })
    );
    return results;
  };

  // Fetch individual token metadata
  const fetchMetadata = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Metadata fetch failed: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  };

  // Draggable handlers
  const handleMouseDown = (e) => {
    setDragging(true);
    const rect = dragRef.current.getBoundingClientRect();
    offset.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    const newX = e.clientX - offset.current.x;
    const newY = e.clientY - offset.current.y;

    // Ensure the button stays within the viewport
    const maxX = window.innerWidth - 60; // assuming button width ~50px
    const maxY = window.innerHeight - 60; // assuming button height ~50px
    const clampedX = Math.max(0, Math.min(newX, maxX));
    const clampedY = Math.max(0, Math.min(newY, maxY));

    setPosition({ x: clampedX, y: clampedY });
  };

  const handleMouseUp = () => {
    if (dragging) {
      setDragging(false);
      // Save position to cookie
      setCookie('userTokensPanelPosition', JSON.stringify(position), 7); // expire in 7 days
    }
  };

  // Attach mouse event listeners when dragging
  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, position]);

  // Domain for interactor links (adjust for production)
  const interactorDomain = '/interactor'; // Use relative path

  // Toggle drawer visibility
  const toggleDrawer = () => {
    setIsDrawerVisible((prev) => !prev);
  };

  // Open interactor within the DApp using React Router
  const openInteractor = (tokenAddress) => {
    navigate(`/interactor/${tokenAddress}`); // Navigate to the interactor route
  };

  // Handle copy address without triggering parent click
  const handleCopyAddress = (e, tokenAddress) => {
    e.stopPropagation();
    copyToClipboard(tokenAddress);
  };

  // Render the component
  return (
    <>
      {connected && tokens.length > 0 && address && (
        <div
          className="user-tokens-container"
          ref={dragRef}
          onMouseDown={handleMouseDown}
          style={{
            position: 'fixed',
            top: position.y,
            left: position.x,
            cursor: 'move',
            zIndex: 10000,
          }}
        >
          <Tooltip title="Toggle Token Panel" placement="right">
            <Button
              type="primary"
              shape="circle"
              icon={<FontAwesomeIcon icon={faWallet} />}
              size="large"
              className="user-tokens-toggle-button"
              onClick={toggleDrawer}
              aria-label="Toggle Token Panel"
            />
          </Tooltip>
          <span className="user-tokens-label">Your Tokens</span>
        </div>
      )}

      <Drawer
        className="dark-drawer"
        title={
          <div className="panel-header">
            <Text strong>Connected Wallet:</Text>
            {address ? (
              <div style={{ marginTop: '4px' }}>
                {address.slice(0, 6)}...{address.slice(-4)}
              </div>
            ) : (
              <div style={{ marginTop: '4px' }}>Loading address...</div>
            )}
          </div>
        }
        placement="right"
        width={350}
        open={isDrawerVisible}
        onClose={toggleDrawer}
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        style={{
          padding: '16px',
        }}
        bodyStyle={{
          backgroundColor: 'rgba(43, 43, 43, 0.95)', // semi-transparent dark
          color: '#fff',
        }}
      >
        {loading ? (
          <div className="drawer-loading">
            <Spin tip="Loading tokens..." />
          </div>
        ) : dataError ? (
          <Alert message={dataError} type="error" showIcon />
        ) : tokens.length === 0 ? (
          <Text>No tokens found for this wallet.</Text>
        ) : (
          <List
            itemLayout="vertical"
            dataSource={tokens}
            renderItem={(token) => (
              <List.Item
                className="token-card"
                onClick={() => openInteractor(token.address)}
              >
                <List.Item.Meta
                  title={
                    <div className="token-title">
                      <Text strong>
                        {token.name} ({token.symbol})
                      </Text>
                      <div className="token-actions">
                        {/* Copy Address */}
                        <Tooltip title="Copy Token Address">
                          <Button
                            icon={<FontAwesomeIcon icon={faCopy} />}
                            size="small"
                            onClick={(e) => handleCopyAddress(e, token.address)}
                          />
                        </Tooltip>
                        <Tooltip title="Open Interactor">
                          <Button
                            icon={<FontAwesomeIcon icon={faArrowRight} />}
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              openInteractor(token.address);
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                  description={
                    <>
                      <div>
                        <Text>Address: </Text>
                        <a
                          href={`https://basescan.org/address/${token.address}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {token.address.slice(0, 6)}...
                          {token.address.slice(-4)}
                        </a>
                      </div>
                      <div>
                        <Text>Total Supply: </Text>
                        {formatNumber(token.totalSupply)}
                      </div>
                      {token.meta && (
                        <div className="token-meta-container">
                          {token.meta.image ? (
                            <div className="token-image-wrapper">
                              <img
                                src={token.meta.image}
                                alt={`${token.name} token`}
                                className="token-image"
                              />
                            </div>
                          ) : (
                            <div className="token-image-wrapper">
                              <FontAwesomeIcon icon={faWallet} size="2x" color="#66bb6a" />
                            </div>
                          )}
                          {token.meta.description && (
                            <div className="token-description">
                              <Text>{token.meta.description}</Text>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Drawer>
    </>
  );
};

export default UserTokensPanel;
