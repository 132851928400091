// src/components/NetworkBar.js

import React from 'react';
import { Navbar, Nav, Button, OverlayTrigger, Tooltip, Spinner, Container } from 'react-bootstrap';
import { FaCopy, FaSignOutAlt, FaBars } from 'react-icons/fa';
import { useWeb3 } from '../Web3Context';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

const NetworkBar = () => {
  const { address, connectWallet, disconnectWallet, connected, loading } = useWeb3();

  const shortenAddress = (addr) =>
    `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;

  // Function to copy text using navigator.clipboard with fallback
  const copyToClipboard = async (text) => {
    if (!text) return;

    // Check if navigator.clipboard is available
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
        toast.success('Address copied to clipboard!', { toastId: 'copy-address-success' });
      } catch (err) {
        console.error('Failed to copy via navigator.clipboard.writeText:', err);
        fallbackCopyTextToClipboard(text);
      }
    } else {
      // Fallback for older browsers or if navigator.clipboard is not available
      fallbackCopyTextToClipboard(text);
    }
  };

  // Fallback method using a temporary textarea element
  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Prevent scrolling to bottom
    textArea.style.position = 'fixed';
    textArea.style.top = '-9999px';
    textArea.style.left = '-9999px';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        toast.success('Address copied to clipboard!', { toastId: 'copy-address-success' });
      } else {
        throw new Error('Copy command was unsuccessful');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      toast.error('Failed to copy address.', { toastId: 'copy-address-failed' });
    }

    document.body.removeChild(textArea);
  };

  const handleCopyAddress = () => {
    if (address) {
      copyToClipboard(address);
    }
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      fixed="top"
      className="shadow-sm"
      style={{ minHeight: '60px', zIndex: 1000 }}
    >
      <Container fluid>
        {/* Brand and Toggle Button for Mobile */}
        <Navbar.Brand as={NavLink} to="/" style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
          <img
            src="/images/turtle-logo.png"
            alt="Logo"
            style={{ height: '40px', marginRight: '10px' }}
          />
          
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="network-navbar-nav" />

        {/* Collapsible Navigation Links and Wallet Buttons */}
        <Navbar.Collapse id="network-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/" end>
              TokenCreator
            </Nav.Link>
            <Nav.Link as={NavLink} to="/interactor">
              Interactor
            </Nav.Link>
            <Nav.Link as={NavLink} to="/listings">
              Listings
            </Nav.Link>
            <Nav.Link as={NavLink} to="/documentation">
              Docs
            </Nav.Link>
          </Nav>
          <Nav className="d-flex align-items-center" style={{ gap: '1rem' }}>
            {/* AppKit network button for network switching and display */}
            <appkit-network-button></appkit-network-button>

            {connected ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-copy">Copy Address</Tooltip>}
                >
                  <Button
                    variant="outline-light"
                    className="d-flex align-items-center"
                    onClick={handleCopyAddress}
                    aria-label="Copy wallet address"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <FaCopy className="me-1" /> {shortenAddress(address)}
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-disconnect">Disconnect Wallet</Tooltip>}
                >
                  <Button
                    variant="outline-danger"
                    onClick={disconnectWallet}
                    className="d-flex align-items-center"
                    aria-label="Disconnect wallet"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <FaSignOutAlt className="me-1" /> Disconnect
                  </Button>
                </OverlayTrigger>
              </>
            ) : (
              <Button
                variant="primary"
                onClick={connectWallet}
                disabled={loading}
                className="d-flex align-items-center"
                style={{ whiteSpace: 'nowrap' }}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Connecting...
                  </>
                ) : (
                  <>
                    <i className="fas fa-sign-in-alt me-2"></i> Connect Wallet
                  </>
                )}
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NetworkBar;
