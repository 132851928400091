// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss'; // Import the SCSS file

const Footer = () => {
  return (
    <footer className="footer custom-footer text-center p-3">
      <div className="footer-links d-flex flex-wrap justify-content-center align-items-center">
        <Link to="/whitepaper" className="footer-link mx-3">
          White Paper
        </Link>
        <Link to="/privacy-policy" className="footer-link mx-3">
          Privacy Policy
        </Link>
        <Link to="/terms-of-use" className="footer-link mx-3">
          Terms of Use
        </Link>
        <Link to="/disclaimer" className="footer-link mx-3">
          Disclaimer
        </Link>
        <Link to="/contact" className="footer-link mx-3">
          Contact Us
        </Link>
      </div>
      <p className="mt-2 footer-text">© {new Date().getFullYear()} TurtleBlunt TokenCreator</p>
    </footer>
  );
};

export default Footer;
