// src/contexts/SocketContext.js

import React, { createContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize Socket.IO client
    const newSocket = io('https://testnet.turtleblunt.com', {
      path: '/support/socket.io',
      transports: ['websocket'],
    });

    setSocket(newSocket);

    // Clean up on unmount
    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
