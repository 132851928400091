// src/pages/WhitePaper.js

import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import './WhitePaper.scss'; // Ensure this file exists and matches your theme

const WhitePaper = () => {
  return (
    <Container className="whitepaper-container my-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <Card className="bg-dark text-light p-4 border-0 shadow-lg">
            <Card.Body>
              <h1 className="text-center mb-4">TBLUNT Token White Paper</h1>
              <div className="metadata text-center mb-5">
                <p><strong>Version:</strong> 2.0</p>
                <p><strong>Date:</strong> January 6th, 2025</p>
                <p><strong>TBLUNT Contract Address:</strong> <a
                    href="https://basescan.org/token/0x4b840478bC779E46393B8Ddf884dbe06F7c4bAD2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-info"
                  >
                    0x4b840478bC779E46393B8Ddf884dbe06F7c4bAD2
                  </a></p>
              </div>

              <section className="section">
                <h2>Abstract</h2>
                <p>
                  The TBLUNT token is a utility-driven ERC20 token designed to facilitate the creation and management of custom ERC20 tokens through our intuitive decentralized application (dApp). By leveraging TBLUNT and Ethereum (ETH) as payment methods, users can seamlessly deploy their own tokens with various customization options, enhancing accessibility and reducing the technical barriers associated with token creation.
                </p>
                <p>
                  This document outlines the core functionalities, technical specifications, and strategic vision of the TBLUNT token and its associated dApp. It serves as a comprehensive guide for users and stakeholders to understand the platform's capabilities and the role of TBLUNT within the tokenization ecosystem.
                </p>
              </section>

              <section className="section">
                <h2>Introduction</h2>
                <p><strong>Background:</strong> The proliferation of ERC20 tokens has democratized access to decentralized finance (DeFi) and blockchain-based projects. However, the technical complexity involved in deploying ERC20 tokens often poses significant challenges for entrepreneurs, creators, and communities. TBLUNT addresses this gap by providing a user-friendly platform that simplifies token creation without compromising on customization and security.
                </p>
                <p><strong>Problem Statement:</strong> Creating and deploying ERC20 tokens typically requires a deep understanding of smart contract development, meticulous configuration, and adherence to security best practices. This complexity can deter potential users from leveraging tokenization for their projects.
                </p>
                <p><strong>Our Solution:</strong> Our dApp offers a streamlined interface for ERC20 token creation and management, utilizing TBLUNT and ETH as payment methods. By abstracting the technical intricacies, we empower a broader audience to engage with blockchain technologies confidently and efficiently.
                </p>
              </section>

              <section className="section">
                <h2>Platform Overview</h2>
                <p>
                  The TBLUNT dApp is a comprehensive platform designed to facilitate the creation, customization, and management of ERC20 tokens. Users can choose between two primary payment methods—TBLUNT and ETH—to access a range of features tailored to their specific needs.
                </p>

                <h3>Key Features</h3>
                <ListGroup variant="flush" className="mb-3">
                  <ListGroup.Item className="bg-dark text-light">
                    <FaCheckCircle className="text-success me-2" /> User-Friendly Interface for Token Creation
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light">
                    <FaCheckCircle className="text-success me-2" /> Multiple Deployment Options: Standard and Factory Contracts
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light">
                    <FaCheckCircle className="text-success me-2" /> Customizable Token Parameters (Name, Symbol, Supply, etc.)
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light">
                    <FaCheckCircle className="text-success me-2" /> Integration with Ethereum (ETH) for Flexible Payments
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light">
                    <FaCheckCircle className="text-success me-2" /> Advanced Token Management Tools
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light">
                    <FaCheckCircle className="text-success me-2" /> Secure and Audited Smart Contracts
                  </ListGroup.Item>
                </ListGroup>
              </section>

              <section className="section">
                <h2>TBLUNT’s Utility</h2>
                <p><strong>1. Token Creation Credits:</strong> TBLUNT functions as a credit system within our platform. Users utilize TBLUNT tokens to deploy new ERC20 tokens, streamlining the process and eliminating traditional fees and complexities.</p>
                <p><strong>2. Enhanced Token Management:</strong> Beyond creation, TBLUNT grants access to advanced token management functionalities, including metadata updates, token parameter adjustments, and integration with ETH-based features for testing and community incentives.</p>
                <p><strong>3. Platform Sustainability:</strong> The demand for TBLUNT as a utility token ensures the platform's sustainability. As users create and manage more tokens, the circulation of TBLUNT supports ongoing development and operational costs.</p>
                <p><strong>4. No Intrinsic Financial Value:</strong> TBLUNT is designed solely as a utility token. Its value is derived from the services it provides within the platform, not from investment potential.</p>
              </section>

              <section className="section">
                <h2>Technical Overview</h2>
                <p><strong>Core Contract:</strong> TBLUNT is an ERC20 token adhering to the EIP-2612 permit standard, enabling gasless approvals. It operates with a fixed supply of 20 million tokens, allocated between the platform owner and a designated fee collector for operational expenses. There is no minting function, ensuring that the total supply remains constant.</p>
                <p><strong>Platform Integration:</strong> Users connect their cryptocurrency wallets to the dApp, acquire TBLUNT through ETH exchanges or direct purchases, and spend TBLUNT to create and manage ERC20 tokens. The platform ensures secure transactions and seamless interaction with the Ethereum blockchain.</p>
              </section>

              <section className="section">
                <h2>Governance and Future Developments</h2>
                <p>
                  Currently, governance and platform parameter settings are managed by the platform owner to ensure stability and security. Future plans include transitioning to a decentralized governance model, allowing the community to participate in decision-making processes related to token supply adjustments, feature enhancements, and fee structures. This evolution aims to foster a more inclusive and community-driven ecosystem.
                </p>
              </section>

              <section className="section">
                <h2>Token Economics</h2>
                <p><strong>Distribution and Supply:</strong> The total supply of TBLUNT is fixed at 20 million tokens. The majority of tokens are allocated to the platform owner for distribution through sales and as credits. A smaller portion is reserved for the fee collector address to cover operational costs.</p>
                <p><strong>Pricing and Availability:</strong> TBLUNT tokens can be acquired through direct purchases using ETH, token sales, airdrops, or secondary markets. The token’s utility-driven demand supports its value within the platform.</p>
                <p><strong>Market Perception:</strong> While TBLUNT can be traded on secondary markets, its primary function remains as a utility token within the platform. Its value is intrinsically linked to the platform’s adoption and the demand for token creation services.</p>
              </section>

              <section className="section">
                <h2>Risks and Disclaimers</h2>
                <p><strong>Utility Token Nature:</strong> TBLUNT is not an investment vehicle. It does not offer financial returns, dividends, or equity ownership. Its value is strictly tied to the utility it provides within the platform.</p>
                <p><strong>Platform Dependence:</strong> The effectiveness and value of TBLUNT are directly related to the platform’s performance and user adoption. Should the platform experience operational issues or fail to attract users, the utility and value of TBLUNT may decline.</p>
                <p><strong>Regulatory Considerations:</strong> Cryptocurrency regulations are evolving and vary by jurisdiction. Users are responsible for understanding and complying with local laws and regulations related to cryptocurrency transactions and token usage.</p>
              </section>

              <section className="section">
                <h2>Token Creation Options</h2>
                <p>
                  Our platform offers multiple tiers for ERC20 token creation, each designed to cater to different project requirements. Users can select the tier that best aligns with their specific needs, ensuring flexibility and scalability in their tokenization efforts.
                </p>

                <Row className="mt-4">
                  {/* Standard Tier */}
                  <Col lg={4} md={6} className="mb-4">
                    <Card className="tier-card">
                      <Card.Header className="tier-header bg-secondary text-center">
                        <h4>Standard Tier</h4>
                        <p className="text-muted">Essential Token Creation</p>
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="mb-3"><FaCheckCircle className="check-icon" /> Core Features</Card.Title>
                        <ListGroup variant="flush" className="mb-3">
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Deploy via Shared Smart Contract
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> 1% Initial Token Supply Tax
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Integrated Sales Page &amp; Interactor
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Set and Update Token Price Anytime
                          </ListGroup.Item>
                        </ListGroup>
                        <Card.Title className="mb-3"><FaCheckCircle className="check-icon" /> Additional Functionalities</Card.Title>
                        <ListGroup variant="flush" className="mb-3">
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Ownable &amp; Secure (Reentrancy Guard)
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Metadata URL Updatable
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Free Metadata Updates
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Free AI-Generated Images (up to 5)
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Sales Widget Integration
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Dedicated Support
                          </ListGroup.Item>
                        </ListGroup>
                        <h5>Token Distribution</h5>
                        <p>99% of the initial supply is minted to the token creator (owner). 1% is minted to the Fee Collector address.</p>
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* Factory Tier */}
                  <Col lg={4} md={6} className="mb-4">
                    <Card className="tier-card">
                      <Card.Header className="tier-header bg-warning text-center">
                        <h4>Factory Tier</h4>
                        <p className="text-muted">Advanced Token Creation</p>
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="mb-3"><FaCheckCircle className="check-icon" /> Core Features</Card.Title>
                        <ListGroup variant="flush" className="mb-3">
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Deploy via Independent Smart Contract
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> 0.01% Initial Token Supply Tax
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Integrated Sales Page &amp; Interactor
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Set and Update Token Price Anytime
                          </ListGroup.Item>
                        </ListGroup>
                        <Card.Title className="mb-3"><FaCheckCircle className="check-icon" /> Additional Functionalities</Card.Title>
                        <ListGroup variant="flush" className="mb-3">
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Ownable &amp; Secure (Reentrancy Guard)
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Metadata URL Updatable
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Free Metadata Updates
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Free AI-Generated Images (up to 5)
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Sales Widget Integration
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Dedicated Support
                          </ListGroup.Item>
                        </ListGroup>
                        <h5>Token Distribution</h5>
                        <p>99.99% of the initial supply is minted to the token creator (owner). 0.01% is minted to the Fee Collector address.</p>
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* Premium Tier */}
                  <Col lg={4} md={12} className="mb-4">
                    <Card className="tier-card">
                      <Card.Header className="tier-header bg-primary text-center">
                        <h4>Premium Tier</h4>
                        <p className="text-muted">Comprehensive Token Solutions</p>
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="mb-3"><FaCheckCircle className="check-icon" /> Core Features</Card.Title>
                        <ListGroup variant="flush" className="mb-3">
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Custom Smart Contract Deployment
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Flexible Token Supply Tax Rates
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Integrated Sales Page &amp; Interactor
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Set and Update Token Price Anytime
                          </ListGroup.Item>
                        </ListGroup>
                        <Card.Title className="mb-3"><FaCheckCircle className="check-icon" /> Additional Functionalities</Card.Title>
                        <ListGroup variant="flush" className="mb-3">
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Ownable &amp; Secure (Reentrancy Guard)
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Metadata URL Updatable
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Free Metadata Updates
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Free AI-Generated Images (up to 10)
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Advanced Sales Widget Integration
                          </ListGroup.Item>
                          <ListGroup.Item className="bg-dark text-light">
                            <FaCheckCircle className="text-success me-2" /> Priority Dedicated Support
                          </ListGroup.Item>
                        </ListGroup>
                        <h5>Token Distribution</h5>
                        <p>100% of the initial supply is minted to the token creator (owner). 0% is minted to the Fee Collector address.</p>
                        <p className="text-center mt-3"><strong>Coming Soon:</strong> The Premium Tier will offer next-generation token creation features, including advanced customization and enhanced verification options.</p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {/* End of Tier Sections */}
              </section>

              <section className="section">
                <h2>Conclusion</h2>
                <p>
                  TBLUNT serves as a pivotal utility token within our token creation and management platform, simplifying the deployment of ERC20 tokens while providing robust management tools. By leveraging TBLUNT and ETH, users can efficiently create customized tokens, fostering greater participation and innovation within the decentralized ecosystem. Our platform aims to democratize access to tokenization, enabling users of all technical backgrounds to engage with blockchain technologies seamlessly.
                </p>
              </section>

              <section className="section">
                <h2>Contact &amp; More Information</h2>
                <p><strong>Website:</strong> <a
                    href="https://turtleblunt.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-info"
                  >
                    TurtleBlunt.com
                  </a></p>
                <p><strong>Email:</strong> <a
                    href="mailto:contact@turtleblunt.com"
                    className="text-info"
                  >
                    contact@turtleblunt.com
                  </a></p>
              </section>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhitePaper;
