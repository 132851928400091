// src/components/NotificationBar.js
import React from 'react';
import './NotificationBar.scss';

const NotificationBar = () => {
  return (
    <div className="notification-bar">
      <strong style={{ marginRight: '10px' }}>
        We Strongly Recommend Creating Your Token On The Test Network First.
      </strong>
      <button
        onClick={() => window.open('https://testnet.turtleblunt.com', '_blank')}
        className="go-testnet-button"
      >
        Go To Testnet
      </button>
    </div>
  );
};

export default NotificationBar;
