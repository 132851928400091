import React, { useState, useRef } from 'react';
import { Card as RBCard, Container, Row, Col } from 'react-bootstrap';
import { Layout } from 'antd';
import {
  Segmented,
  Typography,
  Button as AntdButton,
  Drawer,
  Alert as AntdAlert,
} from 'antd';
import { SwapOutlined, DollarOutlined } from '@ant-design/icons';
import {
  FaCheck,
  FaShieldAlt,
  FaCog,
  FaSyncAlt,
  FaImage,
  FaShoppingCart,
  FaLifeRing,
} from 'react-icons/fa';

// Import token creation components exactly as in your original code
import CreateTokenEth from '../components/CreateTokenEth';
import CreateTokenTBLUNT from '../components/CreateTokenTBLUNT';
import FactoryCreateTokenEth from '../components/FactoryCreateTokenEth';
// IMPORTANT: Use the original naming/path so that TBLUNT factory contracts function as before
import CreateTokenFactoryTblunt from '../components/FactoryCreateToken';
import BuyTbluntPanel from '../components/BuyTbluntPanel';
import TokenInfo from '../components/TokenInfo';
import { copyToClipboard } from '../utils/clipboard';
import { useWeb3 } from '../Web3Context';

// Import tier images
import silverImage from '../images/SilverToken.png';
import goldImage from '../images/GoldToken.png';
import platinumImage from '../images/PlatinumToken.png';

// Import SCSS styles
import './HomePage.scss';
import './CreateToken.scss';

const { Title, Paragraph } = Typography;

const CombinedHomePage = () => {
  // Retrieve data from your Web3 context
  const { lastCreatedToken, numericChainId, clearLastCreatedToken } = useWeb3();

  // Mode state: 'standard' (silver) or 'factory' (gold)
  const [creationMode, setCreationMode] = useState('standard');
  const [paymentMethod, setPaymentMethod] = useState('eth');

  // Separate fee states for each mode to pass to the TBLUNT panel
  const [creationFeeStandard, setCreationFeeStandard] = useState(null);
  const [creationFeeFactory, setCreationFeeFactory] = useState(null);

  // Drawer state for TBLUNT purchase panel
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  // Ref to the token creation form (for scrolling)
  const creationFormRef = useRef(null);
  const scrollToCreationForm = () => {
    if (creationFormRef.current) {
      creationFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // When a tier card is clicked, pre-select the mode and scroll down
  const handleTierCardClick = (mode) => {
    setCreationMode(mode);
    scrollToCreationForm();
  };

  // Toggle handlers for the creation form
  const handleModeChange = (value) => setCreationMode(value);
  const handlePaymentChange = (value) => setPaymentMethod(value);

  // Drawer controls
  const showDrawer = () => setIsDrawerVisible(true);
  const closeDrawer = () => setIsDrawerVisible(false);

  // Adjust fee value (as in your original logic)
  const adjustCreationFee = (fee) => {
    if (!fee) return null;
    const feeStr = fee.toString().endsWith('.0')
      ? fee.toString().slice(0, -2)
      : fee.toString();
    const feeNumber = parseFloat(feeStr);
    return isNaN(feeNumber) ? null : Math.round(feeNumber * 100);
  };

  const adjustedCreationFee =
    creationMode === 'standard'
      ? creationFeeStandard && adjustCreationFee(creationFeeStandard)
      : creationFeeFactory && adjustCreationFee(creationFeeFactory);

  // Render the appropriate token creation component based on mode and payment method.
  const renderCreateTokenComponent = () => {
    if (creationMode === 'standard') {
      return paymentMethod === 'eth' ? (
        <CreateTokenEth />
      ) : (
        <CreateTokenTBLUNT
          onSetCreationFee={setCreationFeeStandard}
          creationMode={creationMode}
        />
      );
    } else {
      return paymentMethod === 'eth' ? (
        <FactoryCreateTokenEth />
      ) : (
        <CreateTokenFactoryTblunt
          onSetCreationFee={setCreationFeeFactory}
          creationMode={creationMode}
        />
      );
    }
  };

  // Reset for creating another token
  const handleCreateAnother = () => {
    clearLastCreatedToken();
    setCreationFeeStandard(null);
    setCreationFeeFactory(null);
  };

  // Descriptions for each mode
  const modeDescriptions = {
    standard:
      'Standard mode offers similar match verification and is deployed through our shared smart contract.',
    factory:
      'Factory mode provides exact match verification with independently deployed contracts, allowing for custom contract names.',
  };

  return (
    <Container fluid className="combined-homepage-container homepage-container">
      {/* --- Last Created Token Section --- */}
      {lastCreatedToken && (
        <RBCard className="p-4 bg-dark text-light create-token-form mt-4">
          <RBCard.Body>
            <RBCard.Title className="text-center mb-4">
              Token Created Successfully!
            </RBCard.Title>
            <TokenInfo
              name={lastCreatedToken.name}
              symbol={lastCreatedToken.symbol}
              initialSupply={lastCreatedToken.initialSupply}
              decimals={lastCreatedToken.decimals}
              newTokenAddress={lastCreatedToken.tokenAddress}
              transactionHash={lastCreatedToken.transactionHash}
              widgetCode={lastCreatedToken.widgetCode}
              copyToClipboard={copyToClipboard}
              chainId={numericChainId}
            />
            <AntdAlert
              message="You can now generate an image and update metadata to customize your sale page."
              type="info"
              showIcon
              style={{ textAlign: 'center', marginTop: '1rem' }}
            />
            <div className="text-center mt-3">
              <AntdButton type="primary" onClick={handleCreateAnother}>
                Create Another Token
              </AntdButton>
            </div>
          </RBCard.Body>
        </RBCard>
      )}

      {/* --- Tier Cards Section --- */}
      <Row className="justify-content-center align-items-stretch mt-4">
        {/* Standard (Silver) Tier Card */}
        <Col xs={12} md={4} className="mb-4">
          {/* Using onClick instead of Link for mode selection */}
          <RBCard
            className="tier-card tier-1 h-100"
            onClick={() => handleTierCardClick('standard')}
          >
            <RBCard.Header
              className="tier-header"
              style={{ backgroundImage: `url(${silverImage})` }}
            />
            <RBCard.Body className="d-flex flex-column">
              <div className="tier-title text-center mb-2">
                <h3>Standard Token Creation</h3>
              </div>
              <div className="tier-info-section mb-3">
                <h5>Main Features:</h5>
                <ul className="tier-features-list">
                  <li>
                    <FaCheck className="check-icon" /> Deploy through our
                    contract (Similar Source Verification)
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> 1% Initial Token Supply Tax
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Integrated Sales Page
                    &amp; Interactor
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Set and Update Token Price Anytime
                  </li>
                </ul>
              </div>
              <div className="tier-info-section mb-3">
                <h5>Included Functionalities:</h5>
                <ul className="tier-features-list">
                  <li>
                    <FaShieldAlt className="check-icon" /> Ownable &amp; Secure (Reentrancy Guard)
                  </li>
                  <li>
                    <FaCog className="check-icon" /> Metadata URL Updatable
                  </li>
                  <li>
                    <FaSyncAlt className="check-icon" /> Free Metadata Updates
                  </li>
                  <li>
                    <FaImage className="check-icon" /> Free AI-Generated Images (up to 5)
                  </li>
                  <li>
                    <FaShoppingCart className="check-icon" /> Sales Widget Integration
                  </li>
                  <li>
                    <FaLifeRing className="check-icon" /> Dedicated Support
                  </li>
                </ul>
              </div>
              <div className="tier-verification-status mb-3">
                <strong>Verification Status:</strong>
                <br />
                <span className="verification-text">
                  Similar match source code verification. Because it’s created via a shared contract, an exact match isn’t possible.
                </span>
              </div>
              <div className="mt-auto text-center">
                <AntdButton type="primary">Select Standard</AntdButton>
              </div>
            </RBCard.Body>
          </RBCard>
        </Col>

        {/* Factory (Gold) Tier Card */}
        <Col xs={12} md={4} className="mb-4">
          <RBCard
            className="tier-card tier-2 h-100"
            onClick={() => handleTierCardClick('factory')}
          >
            <RBCard.Header
              className="tier-header"
              style={{ backgroundImage: `url(${goldImage})` }}
            />
            <RBCard.Body className="d-flex flex-column">
              <div className="tier-title text-center mb-2">
                <h3>Factory Token Creation</h3>
              </div>
              <div className="tier-info-section mb-3">
                <h5>Main Features:</h5>
                <ul className="tier-features-list">
                  <li>
                    <FaCheck className="check-icon" /> Direct Factory Deployment (Exact Source Verification)
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Only 0.01% Initial Token Supply Tax
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Integrated Sales Page &amp; Interactor
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Set and Update Token Price Anytime
                  </li>
                </ul>
              </div>
              <div className="tier-info-section mb-3">
                <h5>Included Functionalities:</h5>
                <ul className="tier-features-list">
                  <li>
                    <FaShieldAlt className="check-icon" /> Ownable &amp; Secure (Reentrancy Guard)
                  </li>
                  <li>
                    <FaCog className="check-icon" /> Metadata URL Updatable
                  </li>
                  <li>
                    <FaSyncAlt className="check-icon" /> Free Metadata Updates
                  </li>
                  <li>
                    <FaImage className="check-icon" /> Free AI-Generated Images (up to 5)
                  </li>
                  <li>
                    <FaShoppingCart className="check-icon" /> Sales Widget Integration
                  </li>
                  <li>
                    <FaLifeRing className="check-icon" /> Dedicated Support
                  </li>
                </ul>
              </div>
              <div className="tier-verification-status mb-3">
                <strong>Verification Status:</strong>
                <br />
                <span className="verification-text">
                  Exact match source code verification for cleaner explorer results.
                </span>
              </div>
              <div className="mt-auto text-center">
                <AntdButton type="primary">Select Factory</AntdButton>
              </div>
            </RBCard.Body>
          </RBCard>
        </Col>

        {/* Platinum Tier Card - Coming Soon */}
        <Col xs={12} md={4} className="mb-4">
          <RBCard className="tier-card tier-3 h-100 disabled-link">
            <RBCard.Header
              className="tier-header"
              style={{ backgroundImage: `url(${platinumImage})` }}
            />
            <RBCard.Body className="d-flex flex-column justify-content-center">
              <div className="tier-title text-center mb-2">
                <h3>Platinum Token Creation</h3>
              </div>
              <div className="tier-info-section mb-3">
                <h5>Coming Soon Features:</h5>
                <ul className="tier-features-list">
                  <li>
                    <FaCheck className="check-icon" /> Advanced Customization
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Enhanced Verification Options
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Additional Deployment Strategies
                  </li>
                  <li>
                    <FaCheck className="check-icon" /> Lower Token Supply Tax Rate
                  </li>
                </ul>
              </div>
              <div className="tier-info-section mb-3">
                <p className="text-center">
                  Stay tuned for our next-generation token creation tier packed with more features.
                </p>
              </div>
              <div className="mt-auto text-center">
                <AntdButton type="default" disabled>
                  Coming Soon
                </AntdButton>
              </div>
            </RBCard.Body>
          </RBCard>
        </Col>
      </Row>

      {/* --- Token Creation Form Section --- */}
      <div ref={creationFormRef} className="token-creation-form-section mt-5">
        {!lastCreatedToken && (
          <>
            <Title level={2} className="mode-toggle-title text-center">
              Create Your Token
            </Title>
            {/* Mode Selection Toggle (centered via SCSS) */}
            <div className="toggle-bar mb-3">
              <Segmented
                options={[
                  { label: 'Standard', value: 'standard' },
                  { label: 'Factory', value: 'factory' },
                ]}
                value={creationMode}
                onChange={handleModeChange}
                size="large"
              />
            </div>
            <Paragraph className="mode-description text-center mb-4">
              {modeDescriptions[creationMode]}
            </Paragraph>

            {/* Payment Method Toggle (centered via SCSS) */}
            <Title level={2} className="mode-toggle-title text-center">
              Choose Your Payment Type
            </Title>
            <div className="toggle-bar mb-3">
              <Segmented
                options={[
                  { label: <span><DollarOutlined /> ETH</span>, value: 'eth' },
                  { label: <span><SwapOutlined /> TBLUNT</span>, value: 'tblunt' },
                ]}
                value={paymentMethod}
                onChange={handlePaymentChange}
                size="large"
              />
            </div>
            {/* "Need TBLUNT?" Button */}
            {paymentMethod === 'tblunt' && (
              <div className="text-center mb-4">
                <AntdButton type="link" onClick={showDrawer}>
                  Need TBLUNT?
                </AntdButton>
              </div>
            )}
          </>
        )}

        {/* Render the appropriate token creation component */}
        {renderCreateTokenComponent()}

        {/* Drawer for buying TBLUNT tokens */}
        <Drawer
          title="Buy TBLUNT Tokens"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawerVisible}
          width={400}
          bodyStyle={{ backgroundColor: '#1e1e1e', color: '#ffffff' }}
        >
          <BuyTbluntPanel creationFee={adjustedCreationFee} />
        </Drawer>
      </div>
    </Container>
  );
};

export default CombinedHomePage;
