import React from 'react';
import styles from './Documentation.module.scss';

const Documentation = () => {
  return (
    <div className={styles.documentationContainer}>
      <h1 className={styles.mainTitle}>TurtleBlunt Token Creator Documentation</h1>

      {/* 1. Introduction */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>1. Introduction</h2>
        <p>
          Welcome to the TurtleBlunt Token Creator Documentation. This guide will help you
          navigate through the features and functionalities of our platform.
        </p>
      </section>

      {/* 2. Prerequisites */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>2. Prerequisites</h2>
        <ul className={styles.bulletList}>
          <li>Ethereum Wallet: Such as MetaMask.</li>
          <li>ETH and TBLUNT Tokens: To cover gas fees and platform fees.</li>
        </ul>
      </section>

      {/* 3. Connecting Your Wallet */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>3. Connecting Your Wallet</h2>
        <ol className={styles.numberedList}>
          <li>
            <strong>Install a Wallet:</strong> If you haven’t already, install a compatible Ethereum
            wallet like MetaMask.
          </li>
          <li>
            <strong>Create an Account:</strong> Set up your wallet account and secure your seed
            phrase.
          </li>
          <li>
            <strong>Connect to DApp:</strong> Click on the &quot;Connect Wallet&quot; button on the
            platform to establish a connection.
          </li>
        </ol>
      </section>

      {/* 4. Supported Networks and Wallets */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>4. Supported Networks and Wallets</h2>

        <h3 className={styles.subTitle}>4.1 Supported Networks</h3>
        <ul className={styles.bulletList}>
          <li>Mainnet: For deploying and managing live tokens.</li>
          <li>Testnets: Such as Sepolia and Goerli for testing purposes.</li>
        </ul>

        <h3 className={styles.subTitle}>4.2 Supported Wallets</h3>
        <ul className={styles.bulletList}>
          <li>MetaMask: A widely used browser extension wallet.</li>
          <li>Trust Wallet: A mobile wallet supporting Ethereum and other blockchains.</li>
          <li>Coinbase Wallet: A mobile wallet integrated with Coinbase accounts.</li>
          <li>WalletConnect: Allows connection to various wallet providers via QR code scanning.</li>
        </ul>
        <p className={styles.tip}>
          <strong>Tip:</strong> Ensure your wallet is connected to the correct network required by
          the DApp to avoid any connectivity issues.
        </p>
      </section>

      {/* 5. Create Token */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>5. Create Token</h2>
        <p>
          TurtleBlunt Token Creator offers multiple tiers for token creation, each tailored to
          different needs and offering varying features and benefits. Choose the tier that best
          aligns with your project’s requirements.
        </p>

        {/* 5.1 Silver Tier */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>5.1 Silver Tier - Standard Token Creation</h3>
          <ul className={styles.bulletList}>
            <li>Deploy Through Our Contract: Similar source verification to ensure transparency.</li>
            <li>1% Initial Token Supply Tax: A nominal fee applied to support platform maintenance.</li>
            <li>Integrated Sales Page &amp; Interactor: Manage your token sales seamlessly.</li>
            <li>Set and Update Token Price Anytime: Flexibility to adjust pricing based on market conditions.</li>
          </ul>
          <p>
            <strong>Note:</strong> Ensure you have sufficient ETH and TBLUNT tokens to cover gas
            fees and creation costs.
          </p>
        </div>

        {/* 5.2 Gold Tier */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>5.2 Gold Tier - Factory Token Creation</h3>
          <ul className={styles.bulletList}>
            <li>Direct Factory Deployment: Exact source verification for higher transparency.</li>
            <li>0.01% Initial Token Supply Tax: Minimal fee to maximize your token’s initial distribution.</li>
            <li>Integrated Sales Page &amp; Interactor: Comprehensive tools to manage your token sales.</li>
            <li>Set and Update Token Price Anytime: Complete control over your token’s pricing strategy.</li>
          </ul>
          <p>
            <strong>Note:</strong> The Gold Tier offers a more efficient token creation process with
            enhanced verification features.
          </p>
        </div>

        {/* 5.3 Platinum Tier */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>5.3 Platinum Tier - Premium Token Creation (Coming Soon)</h3>
          <ul className={styles.bulletList}>
            <li>Advanced Customization: Tailor your token’s features to your project’s unique needs.</li>
            <li>Enhanced Verification Options: Multiple verification methods for increased trust.</li>
            <li>Additional Deployment Strategies: Flexible deployment options for various use cases.</li>
            <li>Lower Token Supply Tax Rate: Competitive fee structures to support large-scale projects.</li>
          </ul>
          <p>
            <em>Coming Soon:</em> The Platinum Tier will be available shortly with exclusive
            features to elevate your token project.
          </p>
        </div>
      </section>

      {/* 6. Interactor Page Overview */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>6. Interactor Page Overview</h2>
        <p>
          The Interactor Page is your central hub for managing and interacting with your created
          tokens. Here’s how to leverage its features:
        </p>

        {/* 6.1 Connecting to Your Token Contract */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.1 Connecting to Your Token Contract</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Navigate to Interactor Page:</strong> From the main menu, select &quot;Interactor&quot;.
            </li>
            <li>
              <strong>Enter Contract Address:</strong> Input the Ethereum address of your token contract.
            </li>
            <li>
              <strong>Connect:</strong> Click the &quot;Connect&quot; button to load your token’s details.
            </li>
            <li>
              <strong>View Token Details:</strong> Once connected, you’ll see information like Token
              Name, Symbol, Total Supply, Decimals, Token Price, and Metadata URL.
            </li>
          </ol>
        </div>

        {/* 6.2 Managing Token Price */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.2 Managing Token Price</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Locate Token Price Section:</strong> In the Interactor Page, find the &quot;Set
              Token Price&quot; section.
            </li>
            <li>
              <strong>Enter New Price:</strong> Input the desired price of your token in ETH.
            </li>
            <li>
              <strong>Confirm Transaction:</strong> Click &quot;Set Token Price&quot; and approve
              the transaction in your wallet.
            </li>
            <li>
              <strong>Price Update:</strong> Once confirmed, the new price will reflect in the Token Details.
            </li>
          </ol>
        </div>

        {/* 6.3 Burning Tokens */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.3 Burning Tokens</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Navigate to Burn Section:</strong> Scroll to the &quot;Burn Tokens&quot; section.
            </li>
            <li>
              <strong>Enter Amount to Burn:</strong> Specify the number of tokens you wish to burn.
            </li>
            <li>
              <strong>Execute Burn:</strong> Click &quot;Burn Tokens&quot; and approve the
              transaction in your wallet.
            </li>
            <li>
              <strong>Confirm Burn:</strong> The specified amount will be permanently removed from circulation.
            </li>
          </ol>
        </div>

        {/* 6.4 Withdrawing ETH */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.4 Withdrawing ETH</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Locate Withdraw Section:</strong> Find the &quot;Withdraw ETH&quot; section
              within the Interactor Page.
            </li>
            <li>
              <strong>Initiate Withdrawal:</strong> Click on &quot;Withdraw ETH&quot; to transfer
              accumulated ETH from token sales to your wallet.
            </li>
            <li>
              <strong>Approve Transaction:</strong> Confirm the withdrawal transaction in your wallet.
            </li>
            <li>
              <strong>Completion:</strong> After confirmation, the ETH balance in your wallet will
              increase accordingly.
            </li>
          </ol>
        </div>

        {/* 6.5 Updating Metadata */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.5 Updating Metadata</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Open Metadata Form:</strong> Click on &quot;Create/Update Metadata&quot; to open
              the update modal.
            </li>
            <li>
              <strong>Fill Metadata Details:</strong> Enter or update fields like Name, Description,
              Image URL, Website URL, and Twitter URL.
            </li>
            <li>
              <strong>Submit Metadata:</strong> Click &quot;Submit&quot; to upload metadata to IPFS
              and update the metaUrl in the contract.
            </li>
            <li>
              <strong>Confirm Transaction:</strong> Approve the transaction in your wallet.
            </li>
            <li>
              <strong>Metadata Update:</strong> Once confirmed, your token’s metadata will be
              updated across the DApp.
            </li>
          </ol>
        </div>

        {/* 6.6 Generating Images */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.6 Generating Images</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Access Image Generation:</strong> In the Interactor Page, scroll to the
              &quot;Generate Images&quot; section.
            </li>
            <li>
              <strong>Agree to TOS:</strong> Read and agree to the Terms of Service.
            </li>
            <li>
              <strong>Enter Keywords:</strong> Input up to 10 descriptive keywords or phrases for
              the image you want to generate.
            </li>
            <li>
              <strong>Generate Images:</strong> Click &quot;Generate Images&quot; to initiate the
              AI-powered creation.
            </li>
            <li>
              <strong>View Generated Images:</strong> Once generated, view the images and select
              your preferred one.
            </li>
            <li>
              <strong>Use Selected Image:</strong> Click &quot;Use Image&quot; to set it as your
              token’s metadata image.
            </li>
          </ol>
        </div>

        {/* 6.7 Previously Generated Images */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>6.7 Previously Generated Images</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Locate Image History:</strong> Below the image generation section, find the
              &quot;Previously Generated Images&quot; subsection.
            </li>
            <li>
              <strong>Browse Images:</strong> View all images you’ve previously generated along
              with their prompts.
            </li>
            <li>
              <strong>Select an Image:</strong> Click on any image thumbnail to view it in full size.
            </li>
            <li>
              <strong>Use Image:</strong> From the full-size view, click &quot;Use Image&quot; to
              set it as your token’s metadata image.
            </li>
          </ol>
          <p className={styles.tip}>
            <strong>Tip:</strong> Reusing previously generated images ensures brand consistency and
            saves time.
          </p>
        </div>
      </section>

      {/* 7. Token Sale Page */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>7. Token Sale Page</h2>
        <p>
          The Token Sale Page (or Listings Page) showcases your token to potential buyers. Here’s
          how to utilize this feature:
        </p>

        {/* 7.1 Viewing Token Details */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>7.1 Viewing Token Details</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Access Token Sale Page:</strong> Navigate to the &quot;Listings&quot; page.
            </li>
            <li>
              <strong>Select Your Token:</strong> Click on your token from the listings to view
              detailed information.
            </li>
            <li>
              <strong>Review Information:</strong> View details such as Name, Symbol, Total Supply,
              Decimals, Token Price, and Metadata.
            </li>
          </ol>
        </div>

        {/* 7.2 Purchasing Tokens */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>7.2 Purchasing Tokens</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Enter Purchase Amount:</strong> Specify the amount of ETH you wish to spend.
            </li>
            <li>
              <strong>Calculate Tokens:</strong> The DApp calculates how many tokens you’ll receive
              based on the current price.
            </li>
            <li>
              <strong>Initiate Purchase:</strong> Click &quot;Purchase Tokens&quot; to begin.
            </li>
            <li>
              <strong>Confirm Transaction:</strong> Approve it in your connected wallet.
            </li>
            <li>
              <strong>Receive Tokens:</strong> Upon confirmation, the tokens transfer to your wallet.
            </li>
          </ol>
        </div>

        {/* 7.3 Receipt and Transaction Info */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>7.3 Receipt and Transaction Info</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Transaction Hash:</strong> A reference for verifying your purchase on the
              blockchain.
            </li>
            <li>
              <strong>Block Explorer Link:</strong> Click the hash to view details on Etherscan or
              similar.
            </li>
            <li>
              <strong>Receipt Details:</strong> Includes tokens purchased, ETH spent, and status.
            </li>
          </ul>
        </div>

        {/* 7.4 Sharing and Adding to Wallet */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>7.4 Sharing and Adding to Wallet</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Share Token Sale Page:</strong> Use the generated link to promote your token.
            </li>
            <li>
              <strong>Add to Wallet:</strong> Click &quot;Add to Wallet&quot; to prompt recognition
              in supported wallets.
            </li>
            <li>
              <strong>Verify in Wallet:</strong> Your token should appear in the wallet’s assets section.
            </li>
          </ol>
          <p className={styles.tip}>
            <strong>Tip:</strong> Encourage users to add your token to their wallets for easy tracking.
          </p>
        </div>
      </section>

      {/* 8. Widget Customization */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>8. Widget Customization</h2>
        <p>
          Customize and embed your token’s sale widget on external websites to promote your token
          effectively.
        </p>

        {/* 8.1 Header Image and Title */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>8.1 Header Image and Title</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Access Widget Customization:</strong> In the Interactor Page, find &quot;Customize
              Widget Code&quot;.
            </li>
            <li>
              <strong>Include Custom Header Image:</strong> Toggle the option and provide a valid
              image URL.
            </li>
            <li>
              <strong>Include Custom Title:</strong> Toggle and enter a custom title to display.
            </li>
          </ol>
        </div>

        {/* 8.2 Widget Sizing */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>8.2 Widget Sizing</h3>
          <ul className={styles.bulletList}>
            <li>Select Widget Size: Choose from predefined sizes like 800x800 or 600x600.</li>
            <li>Responsive Design: Make sure the widget complements your site’s layout.</li>
          </ul>
        </div>

        {/* 8.3 Generating and Embedding Widget Code */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>8.3 Generating and Embedding Widget Code</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Generate Widget Code:</strong> After configuring, click &quot;Generate Widget
              Code&quot;.
            </li>
            <li>
              <strong>View Generated Code:</strong> The DApp displays the Widget URL and the Iframe
              Code snippet.
            </li>
            <li>
              <strong>Copy Code:</strong> Use the copy buttons to copy the generated code snippets.
            </li>
            <li>
              <strong>Embed on Website:</strong> Paste the iframe code into your website’s HTML
              where you want the widget to appear.
            </li>
            <li>
              <strong>Test Widget:</strong> Preview your site to ensure correct display and functionality.
            </li>
          </ol>
          <div className={styles.codeBox}>
            <strong>Example Iframe Code:</strong>
            <pre>
{`<iframe src="YOUR_GENERATED_WIDGET_URL" width="800" height="800" style="border: none;"></iframe>`}
            </pre>
          </div>
          <p className={styles.tip}>
            <strong>Tip:</strong> Adjust the <code>width</code> and <code>height</code> attributes
            if you selected a different widget size.
          </p>
        </div>
      </section>

      {/* 9. Contract Verification */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>9. Contract Verification</h2>
        <p>
          Ensuring your smart contract is verified enhances transparency and builds trust among
          users and investors.
        </p>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>9.1 What is Contract Verification?</h3>
          <p>
            It’s the process of publishing your contract’s source code on blockchain explorers like
            Etherscan, allowing users to read and audit it.
          </p>
        </div>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>9.2 How Does TurtleBlunt Token Creator Handle Verification?</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Automatic Verification:</strong> The DApp attempts to verify your contract on
              Etherscan upon deployment.
            </li>
            <li>
              <strong>Exact Match Method:</strong> Ensures the deployed bytecode matches the
              published source code.
            </li>
            <li>
              <strong>Manual Verification Support:</strong> If automatic verification fails,
              detailed instructions are provided.
            </li>
            <li>
              <strong>Verification Status:</strong> Users can view the status on the Interactor Page
              or the Listings Page.
            </li>
          </ol>
        </div>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>9.3 Benefits of Contract Verification</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Transparency:</strong> Users can inspect the contract code to ensure no
              malicious functions.
            </li>
            <li>
              <strong>Security:</strong> Verified contracts reduce the risk of vulnerabilities.
            </li>
            <li>
              <strong>Trust:</strong> A verified contract builds trust among users, investors, and
              the broader community.
            </li>
          </ul>
        </div>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>9.4 Steps to Manually Verify Your Contract</h3>
          <ol className={styles.numberedList}>
            <li>
              <strong>Access Etherscan:</strong> Navigate to{' '}
              <a href="https://etherscan.io/" target="_blank" rel="noreferrer">
                https://etherscan.io/
              </a>{' '}
              and search for your contract address.
            </li>
            <li>
              <strong>Initiate Verification:</strong> Click on the &quot;Verify and Publish&quot;
              button.
            </li>
            <li>
              <strong>Select Compiler Version:</strong> Choose the exact compiler version (e.g.,
              Solidity ^0.8.20).
            </li>
            <li>
              <strong>Enable Optimization:</strong> Match the optimization settings used during
              compilation.
            </li>
            <li>
              <strong>Provide Contract Code:</strong> Paste your smart contract’s source code
              (including imports).
            </li>
            <li>
              <strong>Submit:</strong> Complete the captcha and submit the verification request.
            </li>
            <li>
              <strong>Confirmation:</strong> Once verified, a verified badge will appear next to
              your contract address.
            </li>
          </ol>
          <p>
            <strong>Note:</strong> Ensure all compiler settings match exactly to avoid verification
            failures.
          </p>
        </div>
      </section>

      {/* 10. Security Considerations */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>10. Security Considerations</h2>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>10.1 Is My Wallet and Private Information Safe?</h3>
          <p>
            Yes, TurtleBlunt Token Creator prioritizes the security and privacy of its users:
          </p>
          <ul className={styles.bulletList}>
            <li>
              <strong>Decentralized Authentication:</strong> Wallet connections are handled securely
              without exposing private keys.
            </li>
            <li>
              <strong>Data Encryption:</strong> Sensitive data transmitted between your wallet and
              the DApp is encrypted.
            </li>
            <li>
              <strong>No Data Storage:</strong> The DApp does not store any personal or private
              information on its servers.
            </li>
            <li>
              <strong>Best Practices:</strong> Adheres to industry-standard security practices for
              smart contract interactions and data handling.
            </li>
          </ul>
          <p>
            <strong>Important:</strong> Always keep your wallet’s private keys and seed phrases
            secure. TurtleBlunt Token Creator will never ask for your private information.
          </p>
        </div>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>10.2 How Does TurtleBlunt Token Creator Ensure Smart Contract Security?</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Audited Contracts:</strong> All smart contracts are thoroughly audited to
              identify and fix vulnerabilities.
            </li>
            <li>
              <strong>Exact Match Verification:</strong> Ensures the deployed contract matches the
              published source code, preventing unauthorized modifications.
            </li>
            <li>
              <strong>Open Source:</strong> Contracts are open for community review, allowing
              transparency and collective security assurance.
            </li>
            <li>
              <strong>Reentrancy Guards:</strong> Implements protection against reentrancy attacks
              using OpenZeppelin’s ReentrancyGuard.
            </li>
          </ul>
        </div>
      </section>

      {/* 11. Tokenomics */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>11. Tokenomics</h2>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>11.1 What Are the Tokenomics of My Created Token?</h3>
          <p>
            The tokenomics of your created token are defined during the creation process. Here’s an
            overview:
          </p>
          <ul className={styles.bulletList}>
            <li>
              <strong>Total Supply:</strong> Defined by the Initial Supply you specify during token
              creation.
            </li>
            <li>
              <strong>Decimals:</strong> Typically set to 18.
            </li>
            <li>
              <strong>Distribution:</strong>
              <ul>
                <li>99% of the initial supply is minted to the token creator (owner).</li>
                <li>1% of the initial supply is minted to the Fee Collector address.</li>
              </ul>
            </li>
            <li>
              <strong>Token Price:</strong> Initially set in ETH and can be adjusted by the owner at
              any time.
            </li>
          </ul>
        </div>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>11.2 Updated Token Distribution</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Silver Tier:</strong>
              <ul>
                <li>99% minted to the token creator (owner).</li>
                <li>1% minted to the Fee Collector address.</li>
              </ul>
            </li>
            <li>
              <strong>Gold Tier:</strong>
              <ul>
                <li>99.99% minted to the token creator (owner).</li>
                <li>0.01% minted to the Fee Collector address.</li>
              </ul>
            </li>
            <li>
              <strong>Platinum Tier (Coming Soon):</strong>
              <ul>
                <li>100% minted to the token creator (owner).</li>
                <li>0% minted to the Fee Collector address.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>11.3 Burn Functionality</h3>
          <p>
            Token holders can burn their tokens to reduce the total supply, potentially increasing
            the value of remaining tokens. To burn tokens, navigate to the &quot;Burn Tokens&quot;
            section on the Interactor Page.
          </p>
        </div>
      </section>

      {/* 12. Troubleshooting */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>12. Troubleshooting</h2>
        <p>
          Encountering issues while using TurtleBlunt Token Creator? Below are some common problems
          and their solutions:
        </p>

        {/* 12.1 I’m Unable to Connect My Wallet */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>12.1 I’m Unable to Connect My Wallet</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Ensure Wallet is Installed:</strong> Verify that you have a compatible
              Ethereum wallet (e.g., MetaMask).
            </li>
            <li>
              <strong>Check Network Connection:</strong> Ensure your wallet is connected to the
              correct network.
            </li>
            <li>
              <strong>Browser Compatibility:</strong> Use a supported browser (e.g., Chrome,
              Firefox) and ensure it’s up to date.
            </li>
            <li>
              <strong>Refresh and Retry:</strong> Refresh the DApp page and attempt to reconnect
              your wallet.
            </li>
            <li>
              <strong>Clear Cache:</strong> Clear your browser’s cache and cookies, then try again.
            </li>
            <li>
              <strong>Disable Extensions:</strong> Temporarily disable other browser extensions
              that might interfere with wallet connections.
            </li>
          </ul>
          <p className={styles.tip}>
            <strong>Tip:</strong> If issues persist, try using a different browser or updating your
            wallet extension.
          </p>
        </div>

        {/* 12.2 Transactions Are Failing or Taking Too Long */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>12.2 Transactions Are Failing or Taking Too Long</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Check Gas Fees:</strong> Ensure you have sufficient ETH to cover gas fees.
              Consider increasing the gas limit or gas price to expedite the transaction.
            </li>
            <li>
              <strong>Confirm Transaction Status:</strong> Use the transaction hash to check the
              status on a blockchain explorer like Etherscan.
            </li>
            <li>
              <strong>Retry Transaction:</strong> If a transaction is stuck, you might need to
              resend it with a higher gas fee.
            </li>
            <li>
              <strong>Network Congestion:</strong> Ethereum network congestion can delay
              transactions. Consider waiting for less busy times.
            </li>
            <li>
              <strong>Wallet Synchronization:</strong> Ensure your wallet is properly connected and
              synchronized with the network.
            </li>
          </ul>
          <p>
            <strong>Note:</strong> Avoid multiple simultaneous transactions to prevent nonce
            conflicts.
          </p>
        </div>

        {/* 12.3 Generated Images Are Not Displaying Correctly */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>12.3 Generated Images Are Not Displaying Correctly</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Verify Image URL:</strong> Ensure that the image URL provided in the metadata
              is correct and accessible.
            </li>
            <li>
              <strong>Check IPFS Availability:</strong> If using IPFS, verify that the link is
              active and not restricted.
            </li>
            <li>
              <strong>Supported Image Formats:</strong> Ensure the images are in supported formats
              like PNG or JPEG.
            </li>
            <li>
              <strong>Refresh Metadata:</strong> Update and refresh your metadata to ensure the
              latest image URL is used.
            </li>
            <li>
              <strong>Use Reliable Hosting:</strong> Host images on reliable platforms to prevent
              downtime or broken links.
            </li>
          </ul>
          <p className={styles.tip}>
            <strong>Tip:</strong> Test image URLs directly in your browser to confirm accessibility.
          </p>
        </div>

        {/* 12.4 I Can’t Update My Metadata */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>12.4 I Can’t Update My Metadata</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Insufficient Permissions:</strong> Only the contract owner can update metadata.
            </li>
            <li>
              <strong>Invalid Metadata Format:</strong> Verify that the metadata is correctly
              formatted and all required fields are filled out.
            </li>
            <li>
              <strong>IPFS Upload Failure:</strong> Ensure that the IPFS upload was successful and
              the resulting URL is valid.
            </li>
            <li>
              <strong>Network Issues:</strong> A stable internet connection is required during the
              metadata update process.
            </li>
            <li>
              <strong>Contract Verification:</strong> If the contract is not verified, certain
              functionalities might be restricted.
            </li>
          </ul>
          <p>
            <strong>Solution:</strong> Double-check all input fields and try updating the metadata
            again. If the issue persists, contact support.
          </p>
        </div>

        {/* 12.5 The Widget Code I Generated Isn’t Working on My Website */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>12.5 The Widget Code I Generated Isn’t Working on My Website</h3>
          <ul className={styles.bulletList}>
            <li>
              <strong>Verify Code Snippets:</strong> Ensure you have copied the complete iframe code
              without any modifications.
            </li>
            <li>
              <strong>Check Widget URL:</strong> Verify that the widget URL is correct and publicly
              accessible.
            </li>
            <li>
              <strong>Website Restrictions:</strong> Some website platforms may have restrictions on
              embedding iframes. Check your website’s embedding policies.
            </li>
            <li>
              <strong>Browser Console:</strong> Inspect your website’s console for any errors
              related to the widget.
            </li>
            <li>
              <strong>Network Issues:</strong> Ensure there are no network issues preventing the
              widget from loading.
            </li>
            <li>
              <strong>Regenerate Code:</strong> Try generating the widget code again and re-embed it.
            </li>
          </ul>
          <p className={styles.tip}>
            <strong>Tip:</strong> Test the iframe code in a simple HTML file to verify
            functionality before embedding it into your website.
          </p>
        </div>

        {/* 12.6 I See a Message About Contract Verification Failure */}
        <div className={styles.subSection}>
          <h3 className={styles.subTitle}>12.6 I See a Message About Contract Verification Failure</h3>
          <p>
            <strong>Verification Attempt Failed:</strong> The DApp tried to verify your contract on
            Etherscan but encountered issues.
          </p>
          <p><strong>Possible Reasons:</strong></p>
          <ul className={styles.bulletList}>
            <li>Mismatch between source code and deployed bytecode.</li>
            <li>Incorrect compiler version used during deployment.</li>
            <li>Optimization settings not matching.</li>
            <li>Incomplete source code submission, including imports.</li>
          </ul>
          <p><strong>Steps to Resolve:</strong></p>
          <ol className={styles.numberedList}>
            <li>Ensure the compiler version matches exactly.</li>
            <li>Verify optimization settings.</li>
            <li>Provide the complete source code, including all imported contracts.</li>
            <li>Attempt manual verification following the DApp’s provided instructions.</li>
            <li>Contact support if issues persist.</li>
          </ol>
          <p>
            <strong>Note:</strong> Accurate contract verification is crucial for transparency and
            trust. Ensure all parameters match during the verification process.
          </p>
        </div>
      </section>

      {/* 13. Contact Support */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>13. Contact Support</h2>
        <p>
          If you’ve followed all troubleshooting steps and still face issues, our support team is
          here to help.
        </p>
        <ul className={styles.bulletList}>
          <li>
            <strong>Email:</strong> <a href="mailto:contact@turtleblunt.com">contact@turtleblunt.com</a>
          </li>
          <li>
            <strong>Discord:</strong>{' '}
            <a href="https://discord.gg/yourdiscord" target="_blank" rel="noreferrer">
              https://discord.gg/yourdiscord
            </a>{' '}
            (Join our Discord Community)
          </li>
          <li>
            <strong>Twitter:</strong>{' '}
            <a href="https://x.com/xturtleblunt" target="_blank" rel="noreferrer">
              https://x.com/xturtleblunt
            </a>{' '}
            (@xTurtleBlunt)
          </li>
        </ul>
        <p>
          When contacting support: Provide detailed information about your issue, including
          screenshots and transaction hashes if applicable, to help us assist you better.
        </p>
      </section>

      {/* 14. Best Practices */}
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>14. Best Practices</h2>
        <ul className={styles.bulletList}>
          <li>
            <strong>Security:</strong> Never share your wallet’s private keys or seed phrases.
            TurtleBlunt Token Creator will never request this information.
          </li>
          <li>
            <strong>Contract Verification:</strong> Always verify your contract on Etherscan to
            enhance transparency and build trust with users.
          </li>
          <li>
            <strong>Stable URLs:</strong> Use reliable hosting services for your images and metadata
            to prevent broken links.
          </li>
          <li>
            <strong>Consistent Branding:</strong> Maintain a consistent visual identity by reusing
            previously generated images and keeping your token’s metadata updated.
          </li>
          <li>
            <strong>Monitor Gas Fees:</strong> Keep an eye on Ethereum gas prices to optimize
            transaction costs.
          </li>
          <li>
            <strong>Regular Updates:</strong> Periodically review and update your token’s metadata
            and widget configurations to keep information current.
          </li>
        </ul>
        <p className={styles.tip}>
          <strong>Tip:</strong> Engage with your community through social channels to gather
          feedback and improve your token’s offerings.
        </p>
      </section>
    </div>
  );
};

export default Documentation;
