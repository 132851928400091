// src/components/CreateTokenETH.js

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Contract, parseUnits, formatUnits, Interface } from "ethers";
import { useWeb3 } from "../Web3Context";

// Import only ETH ABI
import EthTokenCreationManagerAbi from '../abis/EthTokenCreationManagerAbi.json';

// We still import the smaller display components
import TokenInfo from './TokenInfo'; 
import TokenCreationFeeTopPanel from './TokenCreationFeeTopPanel';
import { copyToClipboard } from '../utils/clipboard';
import CreateTokenFormInputs from "./CreateTokenFormInputs"; 

// Import our new, simplified TokenTypeFeatures
import TokenTypeFeatures from './TokenTypeFeaturesStandard';

// Ant Design Imports
import {
  Layout,
  Card,
  Form,
  Button,
  Alert,
  Spin,
  Modal,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import { 
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import './CreateTokenFormEth.scss';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

// Validation Constants
const MIN_INITIAL_PRICE = "0.000000000000000001";  // 1 wei in ETH
const MAX_INITIAL_PRICE = "100000000000000000";    // 1e17 ETH
const MIN_INITIAL_SUPPLY = "1";
const MAX_INITIAL_SUPPLY = "10000000000000000000000"; // 1e22 supply (example)

/**
 * Note: We removed the "features" arrays from these objects. 
 * We only store what's necessary for creation logic (addresses, ABIs, etc.).
 */
const TOKEN_TYPES = [
  {
    label: 'Standard',
    value: 'standard',
    paymentMethods: [
      {
        label: 'ETH',
        value: 'eth',
        abi: EthTokenCreationManagerAbi.abi,
        address: process.env.REACT_APP_ETH_TOKEN_CREATION_MANAGER_ADDRESS,
        creationFeeDecimals: 18, // ETH has 18 decimals
      },
    ],
    description: 'Basic ERC20 token with standard functionalities.'
  },
  {
    label: 'Mintable Pausable',
    value: 'mintablePausable',
    paymentMethods: [
      {
        label: 'ETH',
        value: 'eth',
        abi: EthTokenCreationManagerAbi.abi,
        address: process.env.REACT_APP_ETH_MINTABLE_PAUSABLE_TOKEN_CREATION_MANAGER,
        creationFeeDecimals: 18,
      },
    ],
    description: 'ERC20 token that allows minting and pausing of transfers.'
  },
  {
    label: 'Pausable',
    value: 'pausable',
    paymentMethods: [
      {
        label: 'ETH',
        value: 'eth',
        abi: EthTokenCreationManagerAbi.abi,
        address: process.env.REACT_APP_ETH_PAUSABLE_TOKEN_CREATION_MANAGER,
        creationFeeDecimals: 18,
      },
    ],
    description: 'ERC20 token that allows pausing of transfers.'
  },
  {
    label: 'Mintable',
    value: 'mintable',
    paymentMethods: [
      {
        label: 'ETH',
        value: 'eth',
        abi: EthTokenCreationManagerAbi.abi,
        address: process.env.REACT_APP_ETH_MINTABLE_TOKEN_CREATION_MANAGER,
        creationFeeDecimals: 18,
      },
    ],
    description: 'ERC20 token that supports minting of new tokens.'
  },
  {
    label: 'Capped Mintable',
    value: 'cappedmintable',
    paymentMethods: [
      {
        label: 'ETH',
        value: 'eth',
        abi: EthTokenCreationManagerAbi.abi,
        address: process.env.REACT_APP_ETH_CAPPED_MINTABLE_TOKEN_CREATION_MANAGER,
        creationFeeDecimals: 18,
      },
    ],
    description: 'ERC20 token with a capped supply and pausable transfers.'
  },
  {
    label: 'Capped Mintable Pausable',
    value: 'cappedmintablepausable',
    paymentMethods: [
      {
        label: 'ETH',
        value: 'eth',
        abi: EthTokenCreationManagerAbi.abi,
        address: process.env.REACT_APP_ETH_CAPPED_MINTABLE_PAUSABLE_TOKEN_CREATION_MANAGER,
        creationFeeDecimals: 18,
      },
    ],
    description: 'Comprehensive ERC20 token with cap, minting, and pausing functionalities.'
  },
];

// Helper functions for cookies
const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const CreateTokenETH = () => {
  const {
    provider,
    signer,
    address,
    numericChainId,
    error: web3Error,
    lastCreatedToken,
    updateLastCreatedToken,
    clearLastCreatedToken,
  } = useWeb3();

  // =========== State for selected token type (NO DEFAULT) ===========
  const [selectedType, setSelectedType] = useState(""); // '' means none selected

  // Payment method is always 'eth' for this page
  const paymentMethod = "eth";

  // Form states
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [initialSupply, setInitialSupply] = useState("");
  const [decimals, setDecimals] = useState(4);
  const [initialPrice, setInitialPrice] = useState("");

  const [creationFeeETH, setCreationFeeETH] = useState("0"); // Updated state for creation fee

  const [ethBalance, setEthBalance] = useState(null); // ETH balance

  const [error, setError] = useState(web3Error);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  // For review modal
  const [showReview, setShowReview] = useState(false);
  const [tokensAfterTax, setTokensAfterTax] = useState("");

  // For new token info
  const [newTokenAddress, setNewTokenAddress] = useState(lastCreatedToken?.tokenAddress || null);
  const [transactionHash, setTransactionHash] = useState(lastCreatedToken?.transactionHash || "");
  const [widgetCode, setWidgetCode] = useState(lastCreatedToken?.widgetCode || "");

  // For disclaimer/terms checkbox
  const [agreed, setAgreed] = useState(false);

  // =========== Processing Modal (Stepper) ==========
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const processingSteps = ["Processing Payment", "Creating Token"];

  // =========== Define Selected Token Type (Null if none) ==========
  const selectedTokenType = useMemo(() => {
    return TOKEN_TYPES.find((type) => type.value === selectedType) || null;
  }, [selectedType]);

  // =========== Contract Instance Based on Selection ==========
  const [tokenCreationManagerContract, setTokenCreationManagerContract] = useState(null);

  useEffect(() => {
    const initializeContract = async () => {
      // If user hasn't picked a token type yet, skip
      if (!selectedTokenType) {
        setTokenCreationManagerContract(null);
        return;
      }

      const method = selectedTokenType.paymentMethods.find(
        (pm) => pm.value === paymentMethod
      );
      if (!method) {
        console.error(
          `Payment method '${paymentMethod}' not supported for type '${selectedType}'.`
        );
        setError(
          `Payment method '${paymentMethod}' not supported for token type '${selectedType}'.`
        );
        setTokenCreationManagerContract(null);
        return;
      }

      // NOTE: Removed the wallet-connected check so the fallback signer is used for read-only operations.
      if (!method.address || method.address.trim() === "") {
        console.error(
          `The contract address for payment method '${method.label}' is missing or empty.`
        );
        setError(
          `The contract address for payment method '${method.label}' is missing or empty.`
        );
        setTokenCreationManagerContract(null);
        return;
      }

      try {
        const contract = new Contract(method.address, method.abi, signer);
        setTokenCreationManagerContract(contract);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error(`Error initializing ${method.label} contract:`, err);
        setError(`Error initializing ${method.label} contract.`);
        setTokenCreationManagerContract(null);
      }
    };

    initializeContract();
  }, [selectedType, selectedTokenType, signer, paymentMethod]);

  // =========== Fetch Balances ==========
  const fetchBalances = useCallback(async () => {
    if (!address || !provider || paymentMethod !== "eth") return;
    try {
      const balance = await provider.getBalance(address);
      setEthBalance(balance);
    } catch (err) {
      console.error("Error fetching ETH balance:", err);
      setError("Error fetching ETH balance.");
    }
  }, [address, provider, paymentMethod]);

  // =========== Fetch Balances when Contract is Ready ==========
  useEffect(() => {
    if (!tokenCreationManagerContract) return;
    fetchBalances();
  }, [tokenCreationManagerContract, fetchBalances]);

  // =========== Utility: Format Balance ==========
  const formatBalance = (balance, decimals = 18, displayDecimals = 6) => {
    if (!balance) return "0";
    try {
      const formatted = formatUnits(balance, decimals);
      const decimalIndex = formatted.indexOf(".");
      if (decimalIndex === -1) return formatted;
      return formatted.slice(0, decimalIndex + displayDecimals + 1);
    } catch (err) {
      console.error("Error formatting balance:", err);
      return "0";
    }
  };

  // =========== Prepare the "Review" Cards Data ==========
  const reviewItems = useMemo(
    () => [
      { field: "Token Type", value: selectedTokenType?.label || "N/A" },
      { field: "Token Name", value: name },
      { field: "Token Symbol", value: symbol },
      { field: "Initial Supply", value: initialSupply },
      { field: "Decimals", value: decimals },
      { field: "Initial Price", value: `${initialPrice} ETH` },
      { field: "Tokens After Tax (99%)", value: `${tokensAfterTax} ${symbol}` },
      { field: "Payment Method", value: "ETH" },
    ],
    [selectedTokenType, name, symbol, initialSupply, decimals, initialPrice, tokensAfterTax]
  );

  // =========== Handle Review ==========
  const handleReview = () => {
    setError(null);
    setSuccess(null);

    // Must have chosen a token type
    if (!selectedType) {
      setError("Please select a token type first.");
      return;
    }

    // Must check "agreed"
    if (!agreed) {
      setError("You must agree to the Disclaimer and Terms of Service to proceed.");
      return;
    }

    // Validate decimals
    if (!Number.isInteger(decimals) || decimals < 0) {
      setError("Decimals must be a non-negative integer.");
      return;
    }

    // Validate initialPrice
    const priceString = String(initialPrice);
    const priceRegex = /^(?:\d+\.\d{1,18}|\.\d{1,18}|\d+)$/;
    if (!priceRegex.test(priceString)) {
      setError("Initial Price must be a valid number with up to 18 decimal places.");
      return;
    }
    let initialPriceBN;
    try {
      initialPriceBN = parseUnits(priceString, "ether");
    } catch (err) {
      setError("Invalid initial price format.");
      return;
    }
    if (initialPriceBN < parseUnits(MIN_INITIAL_PRICE, "ether")) {
      setError(`Initial Price must be at least ${MIN_INITIAL_PRICE} ETH.`);
      return;
    }

    // Validate initialSupply
    const supplyNum = Number(initialSupply);
    if (
      isNaN(supplyNum) ||
      supplyNum < Number(MIN_INITIAL_SUPPLY) ||
      supplyNum > Number(MAX_INITIAL_SUPPLY)
    ) {
      setError(`Initial Supply must be between ${MIN_INITIAL_SUPPLY} and ${MAX_INITIAL_SUPPLY}.`);
      return;
    }

    // Calculate tokens after 1% tax
    try {
      // BigInt usage for 1% tax
      const supplyBN = BigInt(parseUnits(String(initialSupply), decimals).toString());
      const tokensAfterTaxBN = (supplyBN * 99n) / 100n;
      setTokensAfterTax(formatUnits(tokensAfterTaxBN, decimals));
    } catch (err) {
      console.error(err);
      setError("Invalid initial supply format.");
      return;
    }

    setShowReview(true);
  };

  // =========== Handle Create Token ==========
  const handleCreateToken = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    setShowProcessingModal(true);
    setCurrentStep(0);

    try {
      if (!signer || !address) {
        throw new Error("Wallet not connected.");
      }
      if (!ethBalance) {
        throw new Error("ETH balance unavailable. Please connect your wallet.");
      }
      if (!creationFeeETH || creationFeeETH === "0") {
        throw new Error("Creation fee is unavailable.");
      }
      if (!tokenCreationManagerContract) {
        throw new Error("Token creation manager contract unavailable.");
      }

      // Check ETH Balance
      const creationFeeInEth = parseUnits(creationFeeETH, "ether");
      if (ethBalance < creationFeeInEth) {
        throw new Error("Insufficient ETH balance to create token.");
      }

      if (!numericChainId || isNaN(numericChainId)) {
        throw new Error("Invalid chain ID. Please ensure you're connected to the correct network.");
      }

      // Move to next step
      setCurrentStep(1);

      const finalSupply = initialSupply.toString();
      const parsedPrice = parseUnits(String(initialPrice), "ether");

      // Send creationFeeInEth as value
      const createTx = await tokenCreationManagerContract.createToken(
        name,
        symbol,
        finalSupply,
        decimals,
        parsedPrice,
        { value: creationFeeInEth }
      );

      setTransactionHash(createTx.hash);

      const receipt = await createTx.wait();
      console.log("Create token transaction confirmed:", receipt);

      // Parse logs
      const iface = new Interface(tokenCreationManagerContract.interface.fragments);
      let createdTokenAddress = null;
      for (const log of receipt.logs) {
        try {
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === "TokenCreated") {
            createdTokenAddress = parsedLog.args.tokenAddress;
            break;
          }
        } catch (err) {
          // Not this log
        }
      }

      if (createdTokenAddress) {
        setNewTokenAddress(createdTokenAddress);
        const generatedWidgetCode = `<iframe src="${window.location.origin}/token-sale/${createdTokenAddress}?widget=true" width="800" height="800" style="border: none;"></iframe>`;
        setWidgetCode(generatedWidgetCode);

        // Save to context
        const tokenData = {
          name,
          symbol,
          initialSupply,
          decimals,
          tokenAddress: createdTokenAddress,
          transactionHash: createTx.hash,
          widgetCode: generatedWidgetCode,
          type: selectedTokenType.label,
        };
        updateLastCreatedToken(tokenData);

        setSuccess("Token created successfully!");
        setShowReview(false);

        // Refetch balance (creationFee may have changed)
        await fetchBalances();
      } else {
        setError("Token creation transaction succeeded but no TokenCreated event found.");
      }
    } catch (err) {
      console.error("Error creating token:", err);
      setError(`Error creating token: ${err.message}`);
    } finally {
      setLoading(false);
      setShowProcessingModal(false);
      setCurrentStep(0);
    }
  };

  // =========== Create Another Token ==========
  const handleCreateAnother = () => {
    setName("");
    setSymbol("");
    setInitialSupply("");
    setDecimals(4);
    setInitialPrice("");
    setTokensAfterTax("");
    setNewTokenAddress(null);
    setTransactionHash("");
    setWidgetCode("");
    setSuccess(null);
    setError(null);
    setShowReview(false);
    setAgreed(false);
    clearLastCreatedToken();
    // Unselect token type
    setSelectedType("");
  };

  return (
    <Layout className="create-token-layout">
      <Content style={{ padding: "2rem" }}>
        {/* If token was not created yet, show the form */}
        {!lastCreatedToken ? (
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24}>
              <Card className="create-token-form">
                <div className="instructional-section mb-4">
                  <Title level={4} className="toggle-title-2">
                    <InfoCircleOutlined /> Create Your Own ERC20 Token on Base
                  </Title>
                  <Paragraph>
                    Follow the steps below to create your own ERC20 token.
                    Ensure that you understand each field to set up your token correctly.
                  </Paragraph>
                </div>

                {error && (
                  <Alert message={error} type="error" showIcon className="mb-4" />
                )}

                {success && (
                  <Alert message={success} type="success" showIcon className="mb-4" />
                )}

                {/* Only show the form if not in "review" mode */}
                {!showReview && (
                  <Form
                    layout="vertical"
                    onFinish={handleReview}
                    style={{ maxWidth: "800px", margin: "0 auto" }}
                  >
                    {/* Token Type Selection as Buttons */}
                    <Form.Item label="Token Type" required>
                      <div className="token-type-buttons">
                        {TOKEN_TYPES.map((typeObj) => (
                          <Button
                            key={typeObj.value}
                            type={selectedType === typeObj.value ? "primary" : "default"}
                            onClick={() => {
                              setSelectedType(typeObj.value);
                              clearLastCreatedToken();
                              setNewTokenAddress(null);
                              setTransactionHash("");
                              setWidgetCode("");
                              setSuccess(null);
                              setError(null);
                            }}
                            className={`token-type-button ${
                              selectedType === typeObj.value ? "selected" : ""
                            }`}
                          >
                            {typeObj.label}
                            <Tooltip title={typeObj.description}>
                              <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                            </Tooltip>
                          </Button>
                        ))}
                      </div>
                    </Form.Item>

                    {/* Only show details/fee if user selected a type */}
                    {selectedTokenType && (
                      <>
                        {/* Dynamic Title Based on Selected Token Type */}
                        <div className="toggle-title">
                          <Title level={5} className="toggle-title">
                            {selectedTokenType.description}
                          </Title>
                        </div>

                        {/* Display Creation Fee at the Top (New Component) */}
                        <TokenCreationFeeTopPanel
                          paymentMethod={paymentMethod}
                          tokenCreationManagerAddress={
                            selectedTokenType.paymentMethods[0].address
                          }
                          creationFeeDecimals={
                            selectedTokenType.paymentMethods[0].creationFeeDecimals
                          }
                          onFeeChange={setCreationFeeETH}
                        />

                        {/* Display the features via our new simplified approach */}
                        <div className="features-section mb-4">
                          <TokenTypeFeatures tokenType={selectedTokenType.value} />
                        </div>
                      </>
                    )}

                    {/* Always show the form inputs */}
                    <CreateTokenFormInputs
                      name={name}
                      setName={setName}
                      symbol={symbol}
                      setSymbol={setSymbol}
                      initialSupply={initialSupply}
                      setInitialSupply={setInitialSupply}
                      decimals={decimals}
                      setDecimals={setDecimals}
                      initialPrice={initialPrice}
                      setInitialPrice={setInitialPrice}
                      agreed={agreed}
                      setAgreed={setAgreed}
                      MIN_INITIAL_SUPPLY={MIN_INITIAL_SUPPLY}
                      MAX_INITIAL_SUPPLY={MAX_INITIAL_SUPPLY}
                      MIN_INITIAL_PRICE={MIN_INITIAL_PRICE}
                    />

                    <Form.Item>
                      <Button type="primary" htmlType="submit" block className="submit-button">
                        Review
                      </Button>
                    </Form.Item>
                  </Form>
                )}

                {/* Review Modal */}
                {showReview && (
                  <Modal
                    visible={showReview}
                    onCancel={() => setShowReview(false)}
                    footer={null}
                    centered
                    maskClosable={!loading}
                    width={460}
                    style={{ top: 30 }}
                    bodyStyle={{
                      backgroundColor: "#2c2c2c",
                      padding: "1rem",
                      borderRadius: "1rem",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.8)",
                    }}
                    className="review-modal"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "1rem",
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          margin: 0,
                          color: "#a5d6a7",
                          textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
                          fontWeight: "bold",
                          fontSize: "1.25rem",
                        }}
                      >
                        <InfoCircleOutlined style={{ marginRight: "8px" }} />
                        Review Token Creation
                      </Title>

                      {/* Grid of Info Cards */}
                      <Row gutter={[16, 16]} style={{ width: "100%", marginBottom: "1rem" }}>
                        {reviewItems.map((item, index) => (
                          <Col xs={24} sm={12} key={index}>
                            <Card
                              style={{
                                backgroundColor: "#343a40",
                                border: "none",
                                borderRadius: "0.5rem",
                                boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
                                padding: "1rem",
                                color: "#fff",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "0.5rem",
                                  color: "#a5d6a7",
                                }}
                              >
                                {item.field}
                              </div>
                              <div style={{ wordBreak: "break-word" }}>{item.value}</div>
                            </Card>
                          </Col>
                        ))}
                      </Row>

                      {/* TokenCreationFeeTopPanel for Fee Display */}
                      <TokenCreationFeeTopPanel
                        paymentMethod={paymentMethod}
                        tokenCreationManagerAddress={
                          selectedTokenType.paymentMethods[0].address
                        }
                        creationFeeDecimals={
                          selectedTokenType.paymentMethods[0].creationFeeDecimals
                        }
                        onFeeChange={() => {}}
                      />

                      {/* Additional Info Cards */}
                      <Row gutter={[16, 16]} style={{ width: "100%", marginBottom: "1rem" }}>
                        <Col xs={24}>
                          <Card
                            style={{
                              backgroundColor: "#343a40",
                              border: "none",
                              borderRadius: "0.5rem",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
                              padding: "1rem",
                              color: "#fff",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0.5rem",
                                color: "#a5d6a7",
                              }}
                            >
                              Tokens After Tax
                            </div>
                            <div>
                              {tokensAfterTax} {symbol} (1% tax applied)
                            </div>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={16} style={{ width: "100%", marginTop: "0.5rem" }}>
                        <Col span={12}>
                          <Button
                            onClick={() => setShowReview(false)}
                            disabled={loading}
                            block
                            className="back-button"
                            style={{ fontSize: "0.9rem" }}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            type="primary"
                            onClick={handleCreateToken}
                            disabled={
                              loading ||
                              (ethBalance && ethBalance < parseUnits(creationFeeETH, "ether"))
                            }
                            block
                            className="confirm-button"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {loading ? (
                              <>
                                <Spin size="small" style={{ marginRight: "4px" }} />
                                Processing...
                              </>
                            ) : (
                              "Confirm and Create"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                )}
              </Card>
            </Col>
          </Row>
        ) : (
          // If token is already created, show success + TokenInfo
          <Card className="create-token-form">
            <Title level={3} className="text-center mb-4">
              Token Created Successfully!
            </Title>
            <TokenInfo
              name={lastCreatedToken.name}
              symbol={lastCreatedToken.symbol}
              initialSupply={lastCreatedToken.initialSupply}
              decimals={lastCreatedToken.decimals}
              newTokenAddress={lastCreatedToken.tokenAddress}
              transactionHash={lastCreatedToken.transactionHash}
              widgetCode={lastCreatedToken.widgetCode}
              copyToClipboard={copyToClipboard}
              chainId={numericChainId}
            />
            <Alert
              message="You can now generate an image and update metadata to customize your sale page."
              type="info"
              showIcon
              className="mb-4"
              style={{ textAlign: "center" }}
            />
            <Button type="primary" onClick={handleCreateAnother} block className="submit-button">
              Create Another Token
            </Button>
          </Card>
        )}
      </Content>

      {/* Processing Modal with Stepper */}
      <Modal visible={showProcessingModal} footer={null} closable={false} centered width={400}>
        <div className="stepper-container">
          {paymentMethod === "eth" ? (
            processingSteps.map((step, index) => {
              const isCompleted = index < currentStep;
              const isCurrent = index === currentStep;

              const cardStyle = {
                backgroundColor: isCompleted
                  ? "#66bb6a" // Green for completed
                  : isCurrent
                  ? "#17a2b8" // Teal for current
                  : "#343a40", // Dark for pending
                transform: isCompleted || isCurrent ? "scale(1.05)" : "scale(1)",
                transition: "background-color 0.3s ease, transform 0.3s ease",
                border: "none",
                borderRadius: "0.5rem",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                width: "100%",
                marginBottom: "1rem",
                padding: "1rem",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              };

              return (
                <div key={index} style={cardStyle}>
                  {isCompleted ? (
                    <CheckCircleOutlined style={{ fontSize: "1.5rem" }} />
                  ) : isCurrent ? (
                    <Spin />
                  ) : (
                    <InfoCircleOutlined style={{ fontSize: "1.5rem" }} />
                  )}
                  <span>{step}</span>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <InfoCircleOutlined style={{ fontSize: "2rem", color: "#17a2b8" }} />
              <Title level={4} style={{ color: "#ffffff", marginTop: "1rem" }}>
                Processing Payment with ETH
              </Title>
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default CreateTokenETH;
