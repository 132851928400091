// src/pages/TokenInfo.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  InputGroup,
  FormControl,
  Alert,
  Modal,
  Form,
  Spinner,
  Card,
  Row,
  Col,
  Container,
  Accordion,
} from 'react-bootstrap';
import { FaCopy, FaCube, FaCoins, FaListOl, FaSortNumericUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from '../utils/clipboard';
import { Contract, ethers } from 'ethers';
import TokenABI from '../abis/TokenABI.json';
import { useWeb3 } from '../Web3Context';
import { toast } from 'react-toastify';

import styles from './TokenInfo.module.scss';

const TokenInfo = ({
  name,
  symbol,
  initialSupply,
  decimals,
  newTokenAddress,
  transactionHash,
  widgetCode,
  chainId,
}) => {
  const navigate = useNavigate();
  const { provider: contextProvider, signer, address, connected } = useWeb3();
  const [providerInstance, setProviderInstance] = useState(contextProvider || null);

  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [metaDescription, setMetaDescription] = useState('');
  const [metaImage, setMetaImage] = useState('');
  const [metaWebsite, setMetaWebsite] = useState('');
  const [metaTwitter, setMetaTwitter] = useState('');
  const [customizing, setCustomizing] = useState(false);
  const [customizeError, setCustomizeError] = useState('');
  const [customizeSuccess, setCustomizeSuccess] = useState(false);

  const [fetchedMetadata, setFetchedMetadata] = useState(null);
  const [fetchingMetadata, setFetchingMetadata] = useState(false);
  const [fetchMetadataError, setFetchMetadataError] = useState('');

  const [tokenContract, setTokenContract] = useState(null);

  const [previousImages, setPreviousImages] = useState([]);

  const [keywords, setKeywords] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState('');

  const [showPrevImageModal, setShowPrevImageModal] = useState(false);
  const [selectedPrevImage, setSelectedPrevImage] = useState('');

  const [agreedToTOS, setAgreedToTOS] = useState(false);

  const [includeHeaderImage, setIncludeHeaderImage] = useState(false);
  const [headerImageURL, setHeaderImageURL] = useState('');
  const [includeTitle, setIncludeTitle] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [widgetSize, setWidgetSize] = useState('800x800');
  const [generatedWidgetURL, setGeneratedWidgetURL] = useState('');
  const [generatedIframeCode, setGeneratedIframeCode] = useState('');

  useEffect(() => {
    if (!contextProvider && window.ethereum) {
      const ethersProvider = new ethers.BrowserProvider(window.ethereum);
      setProviderInstance(ethersProvider);
    }
  }, [contextProvider]);

  useEffect(() => {
    if (connected && signer && providerInstance && newTokenAddress) {
      try {
        const contract = new Contract(newTokenAddress, TokenABI, signer);
        setTokenContract(contract);
      } catch (error) {
        console.error("Error initializing Token Contract:", error);
      }
    }
  }, [connected, signer, providerInstance, newTokenAddress]);

  const fetchMetadata = async () => {
    if (!connected || !tokenContract) return false;
    try {
      setFetchingMetadata(true);
      const metaUrl = await tokenContract.metaUrl();
      const response = await fetch(metaUrl, { cache: 'no-cache' });
      if (!response.ok) {
        throw new Error(`Failed to fetch metadata, status ${response.status}`);
      }
      const data = await response.json();
      setFetchedMetadata(data);
      setFetchMetadataError('');
      return true;
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setFetchMetadataError("Failed to fetch metadata.");
      return false;
    } finally {
      setFetchingMetadata(false);
    }
  };

  useEffect(() => {
    if (!connected || !tokenContract || fetchedMetadata) return;
    let attempts = 0;
    const maxAttempts = 10;
    const interval = setInterval(async () => {
      if (fetchedMetadata) {
        clearInterval(interval);
        return;
      }
      const success = await fetchMetadata();
      if (success || attempts >= maxAttempts) {
        clearInterval(interval);
      } else {
        attempts++;
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [connected, tokenContract, fetchedMetadata]);

  useEffect(() => {
    const fetchPreviousImages = async () => {
      if (!newTokenAddress) return;
      try {
        const response = await fetch(`https://turtleblunt.com/api/images/${newTokenAddress}`, {
          cache: 'no-cache',
        });
        const data = await response.json();
        if (data.success && Array.isArray(data.images)) {
          setPreviousImages(data.images);
        }
      } catch (error) {
        console.error("Error fetching previous images:", error);
      }
    };
    fetchPreviousImages();
  }, [newTokenAddress]);

  const handleInteract = () => {
    navigate(`/interactor/${newTokenAddress}`);
  };

  const handleAddToken = async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed.");
      return;
    }

    const tokenData = {
      type: 'ERC20',
      options: {
        address: newTokenAddress,
        symbol: symbol,
        decimals: decimals,
        image: fetchedMetadata?.image || '',
      },
    };

    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: tokenData,
      });

      if (wasAdded) {
        toast.success(`Token ${symbol} added to your wallet!`);
      } else {
        toast.info(`Token ${symbol} was not added.`);
      }
    } catch (error) {
      console.error('Error adding token to wallet:', error);
      toast.error(`Failed to add token: ${error.message}`);
    }
  };

  const handleOpenCustomizeModal = () => {
    setMetaDescription(fetchedMetadata?.description || '');
    setMetaImage(fetchedMetadata?.image || '');
    setMetaWebsite(fetchedMetadata?.website || '');
    setMetaTwitter(fetchedMetadata?.twitter || '');
    setCustomizeError('');
    setCustomizeSuccess(false);
    setShowCustomizeModal(true);
  };

  const handleCloseCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const handleCustomizeSubmit = async (e) => {
    e.preventDefault();
    setCustomizeError('');
    setCustomizeSuccess(false);
    setCustomizing(true);

    try {
      const metadataPayload = {
        name,
        description: metaDescription,
        image: metaImage,
        ...(metaWebsite && { website: metaWebsite }),
        ...(metaTwitter && { twitter: metaTwitter }),
      };

      const response = await fetch('https://turtleblunt.com/api/metadata', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(metadataPayload),
        cache: 'no-cache',
      });

      const data = await response.json();

      if (!response.ok || !data.success) {
        throw new Error(data.error || 'Failed to create metadata.');
      }

      const ipfsUrl = data.ipfsUrl;
      if (!ipfsUrl) {
        throw new Error('No IPFS URL returned.');
      }

      if (!tokenContract) {
        throw new Error('Token contract not initialized.');
      }

      const tx = await tokenContract.updateMetaUrl(ipfsUrl);
      await tx.wait();

      setCustomizeSuccess(true);
      setShowCustomizeModal(false);
      toast.success('Metadata updated successfully!');
    } catch (error) {
      console.error("Error customizing metadata:", error);
      setCustomizeError(`Error: ${error.message}`);
      toast.error(`Error: ${error.message}`);
    } finally {
      setCustomizing(false);
    }
  };

  const handlePrevImageClick = (url) => {
    setSelectedPrevImage(url);
    setShowPrevImageModal(true);
  };

  const handleKeywordsChange = (e) => {
    const input = e.target.value;
    const words = input.trim().split(/\s+/);
    if (words.length <= 10) {
      setKeywords(input);
      setGenerationError('');
    } else {
      setGenerationError('Maximum of 10 words allowed.');
    }
  };

  const generateImages = async () => {
    if (!agreedToTOS) {
      toast.error('You must agree to the Terms of Service before generating images.');
      return;
    }

    if (keywords.trim() === '') {
      setGenerationError('Please enter keywords.');
      return;
    }

    if (keywords.trim().split(/\s+/).length > 10) {
      setGenerationError('No more than 10 words allowed.');
      return;
    }

    setIsGenerating(true);
    setGenerationError('');
    setGeneratedImages([]);
    setSelectedPrevImage('');

    try {
      const response = await fetch('https://turtleblunt.com/api/generate-image', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt: keywords,
          walletAddress: address,
          contractAddress: newTokenAddress,
        }),
        cache: 'no-cache',
      });

      const data = await response.json();

      if (response.ok && data.success && data.ipfsLink) {
        setGeneratedImages([data.ipfsLink]);
        toast.success('Images generated successfully!');
      } else {
        throw new Error(data.error || 'Failed to generate images.');
      }
    } catch (err) {
      console.error('Error generating images:', err);
      setGenerationError(err.message || 'Failed to generate images.');
      toast.error(`Error: ${err.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSelectGeneratedImage = () => {
    setMetaImage(selectedPrevImage);
    setShowPrevImageModal(false);

    setMetaDescription(fetchedMetadata?.description || '');
    setMetaWebsite(fetchedMetadata?.website || '');
    setMetaTwitter(fetchedMetadata?.twitter || '');
    setCustomizeError('');
    setCustomizeSuccess(false);
    setShowCustomizeModal(true);
  };

  const handleGenerateWidgetCode = () => {
    const baseUrl = `${window.location.origin}/token-sale/${newTokenAddress}`;
    const params = new URLSearchParams();
    params.append('widget', 'true');

    if (includeHeaderImage && headerImageURL.trim() !== '') {
      params.append('headerimg', headerImageURL.trim());
    }

    if (includeTitle && customTitle.trim() !== '') {
      params.append('title', customTitle.trim());
    }

    setGeneratedWidgetURL(`${baseUrl}?${params.toString()}`);

    const [width, height] = widgetSize.split('x');
    const iframeCode = `<iframe src="${baseUrl}?${params.toString()}" width="${width}" height="${height}" style="border: none;"></iframe>`;
    setGeneratedIframeCode(iframeCode);
  };

  return (
    <Container className={styles['token-info-container']}>
      {/* Section Heading */}

      {/* 1) Basic Token Info Row */}
      <Row className="gx-3 gy-3 mb-4 text-center">
        <Col xs={12} md={6} lg={3}>
          <Card className="h-100 bg-secondary token-info-card">
            <Card.Body>
              <FaCube size={30} className="mb-2" color="#00c8ff" />
              <Card.Title className="info-title">Name</Card.Title>
              <Card.Text className="info-value">{name}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="h-100 bg-secondary token-info-card">
            <Card.Body>
              <FaCoins size={30} className="mb-2" color="#00c8ff" />
              <Card.Title className="info-title">Symbol</Card.Title>
              <Card.Text className="info-value">{symbol}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="h-100 bg-secondary token-info-card">
            <Card.Body>
              <FaListOl size={30} className="mb-2" color="#00c8ff" />
              <Card.Title className="info-title">Initial Supply</Card.Title>
              <Card.Text className="info-value">{initialSupply}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card className="h-100 bg-secondary token-info-card">
            <Card.Body>
              <FaSortNumericUp size={30} className="mb-2" color="#00c8ff" />
              <Card.Title className="info-title">Decimals</Card.Title>
              <Card.Text className="info-value">{decimals}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* 2) Token & Tx Info Row */}
      <Row className="gx-3 gy-3 mb-4">
        <Col xs={12} lg={6}>
          <Card className="h-100 bg-secondary">
            <Card.Header className="bg-dark text-light text-center">
              <strong>Token Address</strong>
            </Card.Header>
            <Card.Body className="text-center">
              <p className="full-text">{newTokenAddress}</p>
              <div className="d-flex justify-content-center align-items-center flex-wrap gap-2">
                <a
                  href={`${
                    process.env.REACT_APP_EXPLORER_URL || "https://basescan.org/"
                  }address/${newTokenAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-info"
                >
                  View on Explorer
                </a>
                <Button
                  variant="outline-info"
                  size="sm"
                  onClick={() => copyToClipboard(newTokenAddress)}
                  title="Copy Token Address"
                >
                  <FaCopy /> Copy
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card className="h-100 bg-secondary">
            <Card.Header className="bg-dark text-light text-center">
              <strong>Transaction Hash</strong>
            </Card.Header>
            <Card.Body className="text-center">
              <p className="full-text">{transactionHash}</p>
              <div className="d-flex justify-content-center align-items-center flex-wrap gap-2">
                <a
                  href={`${
                    process.env.REACT_APP_EXPLORER_URL || "https://basescan.org/"
                  }tx/${transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-success"
                >
                  View on Explorer
                </a>
                <Button
                  variant="outline-success"
                  size="sm"
                  onClick={() => copyToClipboard(transactionHash)}
                  title="Copy Transaction Hash"
                >
                  <FaCopy /> Copy
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* 3) Current Metadata */}
      <Card className="bg-secondary text-light mb-4">
        <Card.Header className="bg-dark text-light text-center">
          <strong>Current Metadata</strong>
        </Card.Header>
        <Card.Body className="text-center">
          {fetchingMetadata ? (
            <Spinner animation="border" variant="light" />
          ) : fetchedMetadata ? (
            <>
              {fetchedMetadata.image && (
                <img
                  src={fetchedMetadata.image}
                  alt={`${name} Logo`}
                  className="img-fluid mb-3"
                  style={{ maxWidth: '200px' }}
                />
              )}
              <Card.Text>
                {fetchedMetadata.description || 'No description available.'}
              </Card.Text>
            </>
          ) : (
            <Card.Text className="no-metadata">No metadata available.</Card.Text>
          )}
          {fetchMetadataError && (
            <Alert variant="danger" className="mt-3">
              {fetchMetadataError}
            </Alert>
          )}
        </Card.Body>
      </Card>

      {/* 4) Action Buttons */}
      <Card className="bg-secondary text-light mb-4">
        <Card.Body className="d-flex justify-content-center flex-wrap gap-3">
          <Button
            variant="primary"
            onClick={handleOpenCustomizeModal}
            disabled={!tokenContract}
            className="px-4"
          >
            Customize Token
          </Button>
          <Button variant="secondary" onClick={handleAddToken} className="px-4">
            Add {symbol} to Wallet
          </Button>
          <Button variant="outline-primary" onClick={handleInteract} className="px-4">
            Interact with Your Token
          </Button>
        </Card.Body>
      </Card>

      {/* 5) Advanced Tools Accordion (Widget, Generate Images, Previous Images) */}
      <Accordion alwaysOpen>
        {/* 5a) Customize Widget Code */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Customize Widget Code</Accordion.Header>
          <Accordion.Body>
            <Card className="bg-secondary text-light">
              <Card.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleGenerateWidgetCode();
                  }}
                >
                  {/* Include Header Image */}
                  <Card className="bg-dark text-light mb-3 p-3">
                    <Card.Title>Include Custom Header Image</Card.Title>
                    <Form.Check
                      type="checkbox"
                      checked={includeHeaderImage}
                      onChange={(e) => setIncludeHeaderImage(e.target.checked)}
                      label="Check to add a custom header image"
                    />
                    {includeHeaderImage && (
                      <>
                        <Form.Control
                          type="url"
                          placeholder="Enter Header Image URL"
                          value={headerImageURL}
                          onChange={(e) => setHeaderImageURL(e.target.value)}
                          required
                          className="mt-2 bg-dark text-light border-secondary"
                        />
                        <Form.Text className="text-muted">
                          Provide a direct image URL that will appear above your sale page.
                        </Form.Text>
                      </>
                    )}
                  </Card>

                  {/* Include Custom Title */}
                  <Card className="bg-dark text-light mb-3 p-3">
                    <Card.Title>Include Custom Title</Card.Title>
                    <Form.Check
                      type="checkbox"
                      checked={includeTitle}
                      onChange={(e) => setIncludeTitle(e.target.checked)}
                      label="Check to add a custom title"
                    />
                    {includeTitle && (
                      <>
                        <Form.Control
                          type="text"
                          placeholder="Enter Custom Title"
                          value={customTitle}
                          onChange={(e) => setCustomTitle(e.target.value)}
                          required
                          className="mt-2 bg-dark text-light border-secondary"
                        />
                        <Form.Text className="text-muted">
                          A custom title can help brand your sale page and make it recognizable.
                        </Form.Text>
                      </>
                    )}
                  </Card>

                  {/* Select Widget Size */}
                  <Card className="bg-dark text-light mb-3 p-3">
                    <Card.Title>Select Widget Size</Card.Title>
                    <div className="d-flex flex-column gap-2">
                      <Form.Check
                        type="radio"
                        label="800x800"
                        name="widgetSize"
                        id="size800"
                        value="800x800"
                        checked={widgetSize === '800x800'}
                        onChange={(e) => setWidgetSize(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="600x600"
                        name="widgetSize"
                        id="size600"
                        value="600x600"
                        checked={widgetSize === '600x600'}
                        onChange={(e) => setWidgetSize(e.target.value)}
                      />
                    </div>
                  </Card>

                  {/* Generate Button */}
                  <Button
                    variant="success"
                    type="submit"
                    disabled={
                      (includeHeaderImage && headerImageURL.trim() === '') ||
                      (includeTitle && customTitle.trim() === '')
                    }
                    className="generate-widget-btn"
                  >
                    Generate Widget Code
                  </Button>
                </Form>

                {/* Display Generated Widget Code */}
                {generatedWidgetURL && generatedIframeCode && (
                  <div className="mt-4">
                    {/* Widget URL Card */}
                    <Card className="bg-dark text-light mb-3 p-3">
                      <Card.Title>Widget URL:</Card.Title>
                      <InputGroup>
                        <FormControl
                          readOnly
                          value={generatedWidgetURL}
                          className="bg-light text-dark border-secondary"
                          style={{ wordBreak: 'break-all' }}
                        />
                        <Button
                          variant="outline-dark"
                          onClick={() => copyToClipboard(generatedWidgetURL)}
                        >
                          <FaCopy />
                        </Button>
                      </InputGroup>
                      <Form.Text className="text-muted">
                        Copy and use this URL for your widget.
                      </Form.Text>
                    </Card>

                    {/* Iframe Code Card */}
                    <Card className="bg-dark text-light mb-3 p-3">
                      <Card.Title>Iframe Code:</Card.Title>
                      <InputGroup>
                        <FormControl
                          as="textarea"
                          readOnly
                          value={generatedIframeCode}
                          className="bg-light text-dark border-secondary"
                          rows={3}
                        />
                        <Button
                          variant="outline-dark"
                          onClick={() => copyToClipboard(generatedIframeCode)}
                        >
                          <FaCopy />
                        </Button>
                      </InputGroup>
                      <Form.Text className="text-muted">
                        Copy and embed on your website to add a buy-tokens widget.
                      </Form.Text>
                    </Card>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>

        {/* 5b) Generate Images */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Generate Images</Accordion.Header>
          <Accordion.Body>
            <Card className="bg-secondary text-light">
              <Card.Body>
                <Card className="bg-dark text-light mb-3 p-3">
                  <Card.Text>
                    <p>
                      Enter up to 10 keywords describing the image you want (e.g., "COOL FISH TOKEN").
                      Once generated, you can select an image to update your token's metadata. 
                    </p>
                  </Card.Text>
                </Card>

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    generateImages();
                  }}
                >
                  {/* Terms of Service */}
                  <Card className="bg-dark text-light mb-3 p-3">
                    <Card.Title>Terms of Service for Image Generation</Card.Title>
                    <Card.Text>
                      By generating images, you agree to adhere to{' '}
                      <a
                        href="https://openai.com/policies/usage-policies/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        OpenAI's usage policies
                      </a>
                      . Images should not contain disallowed content.
                    </Card.Text>
                    <Form.Check
                      type="checkbox"
                      label={
                        <>
                          I agree to the{' '}
                          <a href="/tos" target="_blank" rel="noopener noreferrer">
                            Terms of Service
                          </a>
                          .
                        </>
                      }
                      checked={agreedToTOS}
                      onChange={(e) => setAgreedToTOS(e.target.checked)}
                      required
                    />
                  </Card>

                  {/* Keywords Input */}
                  <Card className="bg-dark text-light mb-3 p-3">
                    <Card.Title>Enter Keywords</Card.Title>
                    <Form.Group controlId="keywords" className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="e.g. COOL FISH TOKEN"
                        value={keywords}
                        onChange={handleKeywordsChange}
                        required
                        className="bg-dark text-light border-secondary"
                      />
                      <Form.Text className="text-muted">
                        Be descriptive but concise. Examples: "Futuristic City Skyline", "Golden Turtle Logo".
                      </Form.Text>
                      {generationError && (
                        <Form.Text className="text-danger">{generationError}</Form.Text>
                      )}
                    </Form.Group>
                    <Button
                      variant="success"
                      onClick={generateImages}
                      disabled={
                        !agreedToTOS ||
                        isGenerating ||
                        keywords.trim().split(/\s+/).length === 0 ||
                        keywords.trim().split(/\s+/).length > 10
                      }
                      type="button"
                      className="generate-btn"
                    >
                      {isGenerating ? <Spinner animation="border" size="sm" /> : 'Generate Images'}
                    </Button>
                  </Card>
                </Form>

                {/* Display Generated Images */}
                {generatedImages.length > 0 && (
                  <Card className="bg-dark text-light p-3 mb-3">
                    <Card.Title>Generated Images:</Card.Title>
                    <Row className="mt-2">
                      {generatedImages.map((imgUrl, index) => (
                        <Col md={4} sm={6} xs={12} className="mb-3" key={index}>
                          <Card className="h-100 text-center bg-secondary">
                            <Card.Img
                              variant="top"
                              src={imgUrl}
                              alt={`Generated ${index + 1}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handlePrevImageClick(imgUrl)}
                            />
                            <Card.Body>
                              <Button
                                variant="outline-info"
                                size="sm"
                                onClick={() => handlePrevImageClick(imgUrl)}
                              >
                                <FaCopy /> Select Image
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>

        {/* 5c) Previously Generated Images */}
        {previousImages.length > 0 && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>Previously Generated Images</Accordion.Header>
            <Accordion.Body>
              <Card className="bg-secondary text-light">
                <Card.Body>
                  {previousImages.map((entry, idx) => (
                    <Card key={idx} className="bg-dark text-light mb-3 p-3">
                      <Card.Title>Prompt: {entry.prompt}</Card.Title>
                      <Row>
                        {entry.links.map((link, i) => (
                          <Col md={4} sm={6} xs={12} className="mb-3" key={i}>
                            <Card className="h-100 text-center bg-secondary">
                              <Card.Img
                                variant="top"
                                src={link}
                                alt={`Previous ${idx + 1}-${i + 1}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handlePrevImageClick(link)}
                              />
                              <Card.Body>
                                <Button
                                  variant="outline-info"
                                  size="sm"
                                  onClick={() => handlePrevImageClick(link)}
                                >
                                  <FaCopy /> Select Image
                                </Button>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Card>
                  ))}
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>

      {/* Image Selection Modal */}
      <Modal
        show={showPrevImageModal}
        onHide={() => setShowPrevImageModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title>Full-Size Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center bg-dark text-light">
          {selectedPrevImage && (
            <img
              src={selectedPrevImage}
              alt="Selected Full Image"
              className="img-fluid"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="bg-dark">
          <Button variant="primary" onClick={handleSelectGeneratedImage}>
            Use this Image
          </Button>
          <Button variant="secondary" onClick={() => setShowPrevImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Customize Metadata Modal */}
      <Modal show={showCustomizeModal} onHide={handleCloseCustomizeModal} centered>
        <Modal.Header closeButton className="bg-dark text-light">
          <Modal.Title>Customize Token Metadata</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          {customizeError && <Alert variant="danger">{customizeError}</Alert>}
          {customizeSuccess && <Alert variant="success">Metadata updated successfully!</Alert>}
          <Form onSubmit={handleCustomizeSubmit}>
            <Card className="bg-dark text-light mb-3 p-3">
              <Card.Title>Description</Card.Title>
              <Form.Control
                as="textarea"
                rows={3}
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
                className="bg-dark text-light border-secondary"
              />
            </Card>

            <Card className="bg-dark text-light mb-3 p-3">
              <Card.Title>Image URL</Card.Title>
              <Form.Control
                type="url"
                placeholder="Enter Image URL"
                value={metaImage}
                onChange={(e) => setMetaImage(e.target.value)}
                className="bg-dark text-light border-secondary"
              />
            </Card>

            <Card className="bg-dark text-light mb-3 p-3">
              <Card.Title>Website</Card.Title>
              <Form.Control
                type="url"
                placeholder="Enter Website URL"
                value={metaWebsite}
                onChange={(e) => setMetaWebsite(e.target.value)}
                className="bg-dark text-light border-secondary"
              />
            </Card>

            <Card className="bg-dark text-light mb-3 p-3">
              <Card.Title>Twitter</Card.Title>
              <Form.Control
                type="url"
                placeholder="Enter Twitter Profile URL"
                value={metaTwitter}
                onChange={(e) => setMetaTwitter(e.target.value)}
                className="bg-dark text-light border-secondary"
              />
            </Card>

            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={handleCloseCustomizeModal}
                className="me-2"
                disabled={customizing}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={customizing}>
                {customizing ? <Spinner animation="border" size="sm" /> : 'Update Metadata'}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default TokenInfo;
